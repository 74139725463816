import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getField = async ({ id, endpoint = `borrowing-base-criteria/${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Field. Field ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getByCreditId = async ({ creditId, endpoint = `borrowing-base-criteria?creditId=${creditId}`, iStore = store }) => {
  
  if (!creditId) {
    const message = `Unable to get this Criteria. Criteria creditId (${creditId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getBySectionId = async ({ sectionId, endpoint = `borrowing-base-criteria?sectionId=${sectionId}`, iStore = store }) => {
  if (!sectionId) {
    const message = `Unable to get this Criteria. Criteria sectionId (${sectionId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getByCriteriaId = async ({ criteriaId, endpoint = `borrowing-base-criteria?criteriaId=${criteriaId}`, iStore = store }) => {
  if (!criteriaId) {
    const message = `Unable to get this Criteria. Criteria criteriaId (${criteriaId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const createCriteria = async ({ body, endpoint = `borrowing-base-criteria`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Field. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getByCreditId({creditId: body?.creditId});
  return response;
};

const updateField = async ({ id, body, endpoint = `borrowing-base-criteria/${id}`, method = 'PUT', iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Field. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getByCreditId({creditId: body?.creditId});
  return response;
};

const deleteField = async ({ id, endpoint = `borrowing-base-criteria/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Field. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get({id, creditId}){
    if(id){
      return getField({ id });
    }
    if(creditId){
      return getByCreditId({ creditId });
    }
  },
  getByCreditId,
  getBySectionId,
  getByCriteriaId,
  create: createCriteria,
  update: updateField,
  delete: deleteField
};

export default endpoints;