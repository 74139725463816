import { Table } from "rsuite";
import { useState, useEffect, useCallback } from "react";
import TextInput from "../../components/TextInput";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import api from "../../api";
import Search from "../../components/Search";
import { isEmpty, camelCase, orderBy } from "lodash";
import { getTableContainerHeight } from "../../services/tsTables.microservices";
import { InputEditCell } from "../../components/tableComponents/customCells";

const PortfolioPerformanceDashboardTable = ({ creditId, ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [tableHeight, setTableHeight] = useState(200);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [reportName, setReportName] = useState("");
  const [tableauEmbedUrl, setTableauEmbedUrl] = useState("");
  const [reportSort, setReportSort] = useState();
  const [disabledRow, setDisabledRow] = useState("");
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);

  const update = async ({ body, setUpdateActionTriggered, setUpdateActionDone, setNextSort }) => {
    setUpdateActionTriggered(body?.id);
    setDisabledRow(body?.id);
    body.creditId = creditId;
    const response = await api.reports.update({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setUpdateActionDone(body?.id);
    setTimeout(() => {
      setUpdateActionDone(false);
      setDisabledRow(false);
      setUpdateActionTriggered(false);
      setNextSort({});
    }, 2000);
    return response;
  };

  const deleteAction = async ({ body }) => {
    const response = await api.reports.delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setTimeout(() => {
      setDisabledRow(false);
      props.refreshDataset({ creditId });
      setResetSearch(true);
    }, 2000);
  };

  const ActionsCell = ({ rowData, dataKey, setNextSort, ...props }) => {
    return (
      <Cell {...props}>
        {rowData?.name !== "Borrowing Base Summary" && (
          <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
            <ApiSubmitButton
              action={() => update({ body: rowData, setUpdateActionTriggered, setUpdateActionDone, setNextSort })}
              className="mr-2 row-action-button"
              // used to force correct rendering when parent re-renders
              disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
              actionTriggered={updateActionTriggered === rowData?.id}
              actionDone={updateActionDone === rowData?.id}
            />
            <AreYouSureDelete
              action={() => deleteAction({ body: rowData })}
              icon={<i className="fa-solid fa-trash" />}
              style={{ maxHeight: "30px" }}
              disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
            />
          </div>
        )}
      </Cell>
    );
  };

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

  const handleNameInput = (e) => {
    if (!validateText(e)) return;
    setReportName(e.target.value);
  };

  const handletableauEmbedUrlInput = (e) => {
    if (!validateText(e)) return;
    setTableauEmbedUrl(e.target.value);
  };

  const validateSort = (e) => {
    const regex = /^[0-9 ]+$/; // This regex allows only digits. Due to input type=number, "+" is still allowed, but isn't carried over to the e.target.value.
    return regex.test(e.target.value) && !(Number(e.target.value) < 0 || (e.target.value.trim().length === 0 && e.target.value.length > 0));
  };

  const handleSortInput = (e) => {
    if (!validateSort(e)) return;
    setReportSort(e.target.value);
  };

  const submit = async ({ creditId }) => {
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const response = await api.reports.create({
      body: { name: reportName, creditId: creditId, tableauEmbedUrl: tableauEmbedUrl, sortOrder: reportSort },
    });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setActionDone(true);
    const updatedData = await props.refreshDataset({ creditId });
    setActionDone(false);
    setDisableFields(false);
    setSubmitActionTriggered(false);
    setReportName("");
    setTableauEmbedUrl("");
    setNextSort({ updatedData });
    setResetSearch(updatedData);
    return response;
  };

  const setNextSort = useCallback(
    ({ updatedData }) => {
      const dataSource = updatedData ? updatedData : props.dataset;
      if (dataSource?.length) {
        const highest = dataSource?.reduce((a, b) => ((a.sortOrder || 0) >= (b.sortOrder || 0) ? a : b))?.sortOrder;
        setReportSort(Number(highest) + 1);
      }
    },
    [props.dataset]
  );

  useEffect(() => {
    setTableHeight(getTableContainerHeight({ id: "#bb-report-table-container" }));
    setNextSort({});
  }, [setNextSort]);

  return (
    <div className="custom-flex-container p-3">
      {filtered && <div className="has-text-warning-dark is-capitalized help">Clear search to enable new Report creation</div>}
      <div className="is-flex is-align-items-center">
        <TextInput
          label="Report Name"
          value={reportName || ""}
          style={{ width: "510px" }}
          maxlength={32}
          onChange={handleNameInput}
          disabled={disableFields || filtered}
        />
        <span style={{ width: "1rem" }}></span>
        <TextInput
          label="Tableau URL"
          value={tableauEmbedUrl || ""}
          style={{ width: "510px" }}
          maxlength={250}
          onChange={handletableauEmbedUrlInput}
          disabled={disableFields || filtered}
        />
        <TextInput
          label="sort order"
          value={reportSort || ""}
          type="number"
          onChange={handleSortInput}
          disabled={disableFields || filtered}
          controlClass="mt-3 ml-3 pb-5"
          style={{ width: "100px" }}
        />
        <ApiSubmitButton
          action={() => submit({ creditId })}
          className="ml-3 mt-3"
          style={{ height: "40px" }}
          icon={<i className="fa-solid fa-plus" />}
          // used to force correct rendering when parent re-renders
          disabled={filtered || !tableauEmbedUrl || !reportName}
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
        />
      </div>
      <Search
        className="pt-3"
        dataset={props.dataset}
        setDataset={props.setDataset}
        refreshDataset={props.refreshDataset}
        placeholder="Find Tableau Report"
        searchKey="name"
        currentSortKey={sortColumn}
        currentSortDirection={sortType}
        style={{ maxWidth: "510px" }}
        filtered={filtered}
        setFiltered={setFiltered}
        resetSearch={resetSearch}
        setResetSearch={setResetSearch}
      />
      <div id="bb-report-table-container" className="custom-flex-container flex-scroll extend-last-row" style={{ "--lastRowHeight": "200px" }}>
        <Table
          data={props.dataset}
          height={tableHeight}
          rowHeight={65}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={(sortColumn, sortType) => {
            const fixedSortColumn = camelCase(sortColumn);
            props.setLoading(true);
            setTimeout(() => {
              props.dataset = orderBy(
                props?.dataset,
                [(data) => (typeof data[fixedSortColumn] === "string" ? data[fixedSortColumn].toLowerCase() : data[fixedSortColumn])],
                [sortType]
              );
              props.setDataset(props.dataset);
              setSortColumn(fixedSortColumn);
              setSortType(sortType);
              props.setLoading(false);
            }, 500);
          }}
          loading={props.loading}
        >
          <Column flexGrow={1} sortable>
            <HeaderCell>Report Name</HeaderCell>
            <InputEditCell
              dataKey="name"
              disabled={disabledRow}
              setmaxlength={32}
              customValidation={({ val }) => validateText({ target: { value: val } })}
            />
          </Column>

          <Column flexGrow={1} sortable>
            <HeaderCell>Tableau URL</HeaderCell>
            <InputEditCell
              dataKey="tableauEmbedUrl"
              disabled={disabledRow}
              setmaxlength={250}
              customValidation={({ val }) => validateText({ target: { value: val } })}
            />
          </Column>

          <Column width={100} sortable>
            <HeaderCell>Sort Order</HeaderCell>
            <InputEditCell
              dataKey="sortOrder"
              type="number"
              disabled={disabledRow}
              min={0}
              max={9999}
              step={1}
              customValidation={({ val }) => validateSort({ target: { value: val } })}
            />
          </Column>

          <Column width={235}>
            <HeaderCell></HeaderCell>
            <ActionsCell dataKey="actions" setNextSort={setNextSort} />
          </Column>
        </Table>
      </div>
    </div>
  );
};
export default PortfolioPerformanceDashboardTable;
