import PageTemplate from "../../pages/PageTemplate";
import { useState, useRef, useEffect } from "react";
import api from "../../api";
import { Loader, SelectPicker, Table, Animation } from "rsuite";
import { isEmpty, isEqual, orderBy, startCase } from "lodash";
import * as com from "../../components";
import store, { env, amplifyEnvironment, getUserCognitoGroups } from "../../store";
import { convertTimeToLocal, getCurrentQuarter, getYearList, stringToNumber, handleAutoCreditSelection } from "../../services/microservices";
import skynet from "../../api/skynetData";
import { InputEditCell, DroplistCellSimple, AreaCell, CellOverride } from "../../components/tableComponents/customCells";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import { Button } from "primereact/button";

const accurateEnv = env !== amplifyEnvironment ? amplifyEnvironment : env;

const Content = ({ ...props }) => {
  const [criteriaTableData, setCriteriaTableData] = useState([]);
  const [facilityTableData, setFacilityTableData] = useState([]);
  const [submittedBy, setSubmittedBy] = useState("");
  const [reviewedBy, setReviewedBy] = useState("");
  const { Column, HeaderCell, Cell } = Table;
  const handleCreditSelect = async (e) => {
    props.setHideCrud(true);
    // reset lqrData.current.report
    props.lqrData.current = {
      originalReport: structuredClone({ ...props.blankReportDetails }),
      allReports: [],
      report: {},
      saveControl: {
        criteria: false,
        report: false,
      },
    };
    // set selected credit
    const temp = props.credits?.find((credit) => credit?.id === e.target.value);
    props.setSelectedCredit(temp);
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    if (e.target.value === "0") {
      sessionStorage.removeItem("selectedCredit");
      return;
    }
    await props.getLqrReports({ creditId: temp?.id });
    props.setHideCrud(false);
  };

  const handleInputs = async ({ val, key }) => {
    props.lqrData.current.report[key] = val;
    props.setSelectedReport({ ...props.lqrData.current.report });
    await props.checkReportIsDirty({ body: props.lqrData.current.report });
  };

  const getRange = ({ rowData }) => {
    const minValues = rowData.choices.map((choice) => choice.numericMin);
    const maxValues = rowData.choices.map((choice) => choice.numericMax);
    const minValue = Math.min(...minValues);
    const maxValue = Math.max(...maxValues);
    return `${minValue} - ${maxValue}`;
  };

  const MetricHandler = ({
    rowData,
    dataKey,
    tableData,
    setTableData,
    lqrData,
    disabled,
    checkCriteriaDirty,
    getGrade,
    setOverride,
    overrideRef,
    ...props
  }) => {
    const [disabledRows, setDisabledRows] = useState();
    useEffect(() => {
      setDisabledRows(disabled ? rowData?.id : false);
      // eslint-disable-next-line
    }, [disabled]);
    return rowData[dataKey] === "text" ? (
      <DroplistCellSimple
        rowData={rowData}
        {...props}
        dataKey="choiceId"
        dataSet={rowData.choices}
        showDefault="Choose Response"
        listDisplay={({ entry }) => entry.textResponse}
        cb={({ val, dataSet }) => {
          const match = dataSet?.find((choice) => choice?.id === val);
          // set table view
          rowData.choiceId = match?.id;
          rowData.choiceRowId = match?.rowId;
          rowData.choice = match;
          rowData.response.choice = match;
          rowData.response.choiceId = match?.id;
          rowData.response.choiceRowId = match?.rowId;
          rowData.grade = match?.grade;
          rowData.metric = rowData?.response?.metric;
          rowData.notes = rowData?.response?.notes;
          setTableData([...tableData]);
          setCriteriaTableData([...criteriaTableData]);
          setFacilityTableData([...facilityTableData]);
          setOverride(overrideRef.current?.length ? [...overrideRef.current] : []);
          checkCriteriaDirty({ rowData, key: "choiceId" });
          getTotals({ criteria: lqrData.current?.report?.criteria });
        }}
        disabled={disabledRows}
      />
    ) : (
      <div className="is-relative">
        <InputEditCell
          rowData={rowData}
          {...props}
          dataKey="metric"
          containerStyle={{ display: "flex", flex: "1 1" }}
          controlClass="is-flex is-flex-grow-1"
          inputContainerClass="is-flex-grow-1"
          style={{ maxHeight: "30px", marginTop: "-10px", display: "flex", flex: "1 1" }}
          customValidation={({ val, key }) => {
            if (key === "metric") {
              const regex = /^\d{1,6}(?:\.\d{0,2})?$/;
              const valid = regex.test(val) || val === "";
              return valid;
            }
          }}
          decoration={{ condition: rowData.isPercent, character: "%", position: { position: "absolute", right: ".5rem", top: "-.3rem" } }}
          disabled={disabledRows}
          onBlur={async () => {
            const propsCriteria = lqrData.current?.report.criteria?.find((criteria) => criteria?.id === rowData?.id);
            if (!propsCriteria?.metric) {
              rowData.choice = null;
              rowData.choiceId = null;
              rowData.response.choice = null;
              rowData.response.choiceId = null;
              rowData.response.choiceRowId = null;
              rowData.grade = null;
            } else {
              // set table view
              const matchedChoice = rowData.choices?.find((choice) => choice?.criteriaId === rowData?.id);
              rowData.choice = matchedChoice;
              rowData.choiceId = matchedChoice?.id;
              rowData.choiceRowId = matchedChoice?.rowId;
              rowData.response.choice = rowData.choice;
              rowData.response.choiceId = rowData.choice?.id;
              rowData.response.choiceRowId = rowData.choice?.rowId;
              rowData.response.metric = rowData.metric || rowData?.response?.metric;
              rowData.metric = rowData.metric || rowData?.response?.metric;
              rowData.notes = rowData.notes || rowData?.response?.notes;
              rowData.grade = await getGrade({ data: rowData.metric, rowData, returnHtml: true });
              // convert metrics to Number type
              rowData.metric = typeof rowData.metric === "string" ? Number(rowData.metric) : rowData.metric;
              rowData.response.metric = typeof rowData.response.metric === "string" ? Number(rowData.response.metric) : rowData.response.metric;
              // set ref data
              propsCriteria.choice = rowData.choice;
              propsCriteria.choiceId = rowData.choiceId;
              propsCriteria.choiceRowId = rowData.choiceRowId;
              propsCriteria.response = rowData?.response;
              propsCriteria.metric = rowData?.metric;
              propsCriteria.notes = rowData?.notes;
              propsCriteria.grade = rowData.grade;
            }
            setTableData([...tableData]);
            setCriteriaTableData([...criteriaTableData]);
            setFacilityTableData([...facilityTableData]);
            checkCriteriaDirty({ rowData });
            getTotals({ criteria: lqrData.current?.report?.criteria });
          }}
          cellCallout={<div className="is-absolute is-italic help" style={{ top: "1.5em", right: ".5em" }}>{`Range: ${getRange({ rowData })}`}</div>}
        />
      </div>
    );
  };

  const getPersonName = ({ source, personList, sourceKey, returnKey }) => {
    const tempPerson = personList?.find((person) => person[sourceKey] === source);
    return tempPerson ? tempPerson[returnKey] : "";
  };

  const getPersonNameByOktaName = ({ source, personList }) => {
    const tempPerson = personList?.find((person) => {
      return source?.includes("okta") ? source?.split("okta_")[1] === person.email : source === person.email;
    });
    return tempPerson?.name;
  };

  const getTotals = async ({ criteria }) => {
    const immutableCriteria = JSON.parse(JSON.stringify(criteria));
    const tempCriteria = structuredClone([...immutableCriteria]);
    const responses = tempCriteria.filter((c) => c?.response?.grade || c?.grade);
    let total = 0;
    let criteriaLength = 0;
    let sum = 0;
    let totalWeights = 0;
    let totalPortfolioPerformance = 0;
    let totalPortfolioPerformanceFields = 0;
    let highestFacilityRuleGrade = 0;
    for (let criteria of responses) {
      if (criteria.grade && !criteria?.isFacilityRule && !criteria?.isPortfolioPerformance) {
        total += criteria.grade;
        criteriaLength++;
        let wGrade = criteria.grade * criteria.weight;
        sum += wGrade;
        totalWeights += criteria.weight;
      }
      if (criteria?.grade && criteria?.isPortfolioPerformance && props.oldReport) {
        totalPortfolioPerformance += criteria.grade;
        totalPortfolioPerformanceFields += 1;
      }
      if (criteria?.grade && criteria?.isFacilityRule && !props.oldReport) {
        if (highestFacilityRuleGrade === null || criteria.grade > highestFacilityRuleGrade) {
          highestFacilityRuleGrade = criteria.grade;
        }
      }
    }
    props.lqrData.current.report.average = (total / criteriaLength).toFixed(2);
    props.lqrData.current.report.weightedAverage = (sum / totalWeights).toFixed(2);
    if (props.oldReport) {
      props.lqrData.current.report.averagePortfolioPerformance = (totalPortfolioPerformance / totalPortfolioPerformanceFields).toFixed(2) ?? 0;
    }
    let highestValue;
    if (!props.oldReport) {
      props.lqrData.current.report.highestFacilityGrade = highestFacilityRuleGrade;
      const { average, weightedAverage, highestFacilityGrade } = props.lqrData.current.report;
      highestValue = Math.max(average, weightedAverage, highestFacilityGrade);
    } else {
      const { average, weightedAverage, averagePortfolioPerformance } = props.lqrData.current.report;
      highestValue = Math.max(average, weightedAverage, averagePortfolioPerformance);
    }
    const roundedHighestValue = Math.round(highestValue).toFixed(2);
    props.lqrData.current.report.overallGrade = roundedHighestValue;
    props.setSelectedReport({ ...props.lqrData.current.report });
  };

  useEffect(() => {
    handleAutoCreditSelection({ selectedCredit: props.selectedCredit, handleCreditSelect });
    props.setSelectedReport({ ...props.lqrData.current.report });
    if (!!props.lqrData.current?.report?.criteria?.length) {
      // split props.lqrData.current?.report?.criteria into two arrays. One for criteria and one for facility rules.
      const criteria = props.lqrData.current?.report?.criteria?.filter((criteria) => !criteria?.isFacilityRule);
      const facilityRules = props.lqrData.current?.report?.criteria?.filter((criteria) => criteria?.isFacilityRule);
      setCriteriaTableData([...criteria]);
      setFacilityTableData([...facilityRules]);
    }
    if (props.selectedReport.status === "approved" || (props.selectedReport.status === "submitted" && !props.selectedReport?.reviewedBy)) {
      props.setDisableFields(true);
    } else {
      props.setDisableFields(false);
    }
    if (props.selectedReport?.reviewedBy || props.selectedReport?.createdBy) {
      const submitter = getPersonNameByOktaName({ source: props.selectedReport.createdBy, personList: props?.personsList?.current });
      const reviewer = getPersonName({
        source: props.selectedReport?.reviewedBy,
        personList: props?.personsList?.current,
        sourceKey: "email",
        returnKey: "name",
      });
      setSubmittedBy(submitter);
      setReviewedBy(reviewer);
    }
    if (props.lqrData.current?.report?.criteria && !isEmpty(props.lqrData.current?.report?.criteria)) {
      getTotals({ criteria: props.lqrData.current?.report?.criteria });
    }
    // eslint-disable-next-line
  }, [props.lqrData.current.report, props.selectedReport.status]);

  const exportPdf = ({ fileName, setState }) => {
    setState(true);
    setTimeout(() => {
      const doc = new jsPDF({ orientation: "p", unit: "pt", compress: true });
      const blankRow = ["", "", "", ""];
      // Convert headers to a regular row - normally would use headers but they repeat on each page, which is unwanted
      const headersAsRow = [
        [
          { content: "CREDIT: " + props.selectedCredit?.name },
          { content: "REPORTING PERIOD: " + props?.quarters[props.selectedReport?.quarter]?.label + " " + props.selectedReport?.year, colSpan: 2 },
          { content: "STATUS: " + startCase(props.selectedReport?.status) },
        ],
        blankRow,
        [{ content: "CRITERIA:", colSpan: 4 }],
        ["NAME", "METRIC", "GRADE", "NOTES"],
      ];
      const criteriaData = headersAsRow.concat(criteriaTableData.map((row) => [row.name, row.metric, row.grade, row.notes]));
      // combine criteriaData and facility data into a single array with criteria values at the start of the array
      criteriaData.push(blankRow, [{ content: "FACILITY RULES:", colSpan: 4 }], ["NAME", "METRIC", "GRADE", "NOTES"]);
      const combinedData = criteriaData.concat(facilityTableData.map((row) => [row.name, row.metric, row.grade, row.notes]));

      // Append an empty row betweem the core table data and the additional rows
      combinedData.push(blankRow);

      // add summary
      combinedData.push(
        [{ content: "SUMMARY:", colSpan: 4 }],
        [
          `Average Grade: ${props.selectedReport?.average ?? 0}`,
          `Weighted Average: ${props.selectedReport?.weightedAverage ?? 0}`,
          props.oldReport
            ? `Average Portfolio Performance: ${props.selectedReport?.averagePortfolioPerformance ?? 0}`
            : `Highest Facility Rule Grade: ${props.selectedReport?.highestFacilityGrade ?? 0}`,
          `Overall Grading: ${props.selectedReport?.overallGrade ?? 0}`,
        ]
      );

      // add analyst summary
      combinedData.push(
        blankRow,
        [{ content: "ANALYST SUMMARY:", colSpan: 4 }],
        [{ content: props.selectedReport?.summary, colSpan: 4 }, "", "", ""]
      );

      // add reviewer
      combinedData.push(blankRow, [
        {
          content: `REVIEWER: ${
            props.selectedReport?.requestedReviewer !== undefined
              ? props.selectedReport?.requestedReviewer
              : reviewedBy !== undefined
              ? reviewedBy
              : ""
          }`,
          colSpan: 2,
        },
        {
          content: `REVIEW DATE: ${
            props.selectedReport?.reviewedDate?.displayDate !== undefined ? props.selectedReport?.reviewedDate?.displayDate : ""
          }`,
          colspan: 2,
        },
      ]);

      combinedData.push(blankRow, [
        { content: "SUBMITTED BY: " + (submittedBy !== undefined ? submittedBy : ""), colSpan: 2 },
        {
          content:
            "SUBMITTED DATE: " +
            (props.selectedReport?.submittedDate?.displayDate !== undefined ? props.selectedReport?.submittedDate?.displayDate : ""),
          colSpan: 2,
        },
      ]);

      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const tableWidth = pageWidth * 0.95; // Use 95% of page width
      const columnWidth = tableWidth / 4.05; // Divide by number of columns

      doc.autoTable({
        body: combinedData,
        columnStyles: {
          0: { cellWidth: columnWidth },
          1: { cellWidth: columnWidth },
          2: { cellWidth: columnWidth },
          3: { cellWidth: columnWidth },
        },
        margin: { top: 15, right: 15, bottom: 15, left: 15 },
        horizontalPageBreak: true,
        didParseCell: (data) => {
          // Check if the cell's row index is the one you want to style
          const rowKeys = ["criteria:", "facility rules:", "summary:", "analyst summary:"];
          // check if cell?.content?.toLowerCase() exists in the rowKeys array, if it does, return the index
          if (data.row.raw.findIndex((cell) => rowKeys.includes(cell?.content?.toLowerCase())) !== -1) {
            data.cell.styles.textColor = [255, 255, 255]; // Change background color to blue
            data.cell.styles.fillColor = [111, 170, 235]; // Change background color to blue
          }
          if (data.row.index === 0) {
            data.cell.styles.textColor = [255, 255, 255]; // Change background color to blue
            data.cell.styles.fillColor = [47, 90, 198]; // Change background color to blue
          }
        },
      });

      doc.save(`${fileName}.pdf`);
      setState(false);
    }, 1000);
  };

  return props?.loadingCredits && !!props?.credits?.length && !!props.personsList.current.length ? (
    <Loader center size="sm" content="Loading..." />
  ) : (
    <div className="custom-flex-container">
      <div className="is-flex is-justify-content-space-between">
        <div className="pl-0 py-2 pr-2 card is-flex-grow-1" style={{ zIndex: 10 }}>
          <div className="card-content is-flex is-flex-direction-row is-align-items-center p-0">
            <div className="pl-2 is-flex is-flex-direction-row is-align-items-center mr-3" style={{ zIndex: 9, background: "#fff" }}>
              <label className="is-size-6 mr-2">Credit:</label>
              <div className="select" style={{ maxHeight: "36px" }}>
                <select
                  id="source-temp-select"
                  className="has-text-link"
                  onChange={handleCreditSelect}
                  value={props.selectedCredit?.id}
                  style={{ maxHeight: "36px", fontSize: "14px" }}
                  disabled={props.submitting}
                >
                  <option defaultValue={true} value={0}>
                    Choose Credit
                  </option>
                  {props.credits?.map((entry) => (
                    <option key={entry?.id} value={entry?.id}>
                      {entry?.name}
                    </option>
                  ))}
                </select>
              </div>
              {props?.selectedCredit?.id && !props?.reportsLoading && props.selectedReport?.id && (
                <div className="is-flex">
                  <div className="is-flex is-justify-content-end">
                    <Button
                      icon="pi pi-file-pdf"
                      severity="secondary"
                      onClick={() =>
                        exportPdf({
                          fileName: `${props.selectedCredit?.name}-LQR-Report-${props?.quarters[props.selectedReport?.quarter]?.label}-${
                            props.selectedReport?.year
                          }`,
                          setState: () => null,
                        })
                      }
                      tooltip="Export PDF"
                      tooltipOptions={{ position: "right" }}
                      style={{ height: "36px", width: "36px", marginLeft: "10px" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="is-flex-grow-1">
              <Animation.Slide in={props?.selectedCredit?.id && !props?.reportsLoading} placement="left">
                <div className="custom-flex-container">
                  <div className="is-flex is-align-items-center is-justify-content-end">
                    {!!props?.lqrData.current?.allReports?.length && (
                      <com.CustomSelect
                        defaultText="Historical LQR Reports"
                        id="lqr-history"
                        data={props?.lqrData.current?.allReports}
                        rows={[
                          "status",
                          {
                            key: "quarter",
                            action({ entry, key }) {
                              return `${props.quarters.find((q) => q.value === entry[key]).label} of ${entry.year}`;
                            },
                          },
                          {
                            key: "startDate",
                            action({ entry, key }) {
                              return `Last Updated: ${convertTimeToLocal({ date: new Date(entry[key]) })}`;
                            },
                          },
                          {
                            key: "submittedDate",
                            action({ entry, key }) {
                              return entry[key] && `Submitted: ${convertTimeToLocal({ date: new Date(entry[key]?.date) })}`;
                            },
                          },
                          {
                            key: "reviewedDate",
                            action({ entry, key }) {
                              return entry[key] && `Reviewed: ${convertTimeToLocal({ date: new Date(entry[key]?.date) })}`;
                            },
                          },
                        ]}
                        onChange={(e) => {
                          props.lqrData.current.report = props.lqrData.current.allReports.find((report) => report.id === e);
                          props.getReport({});
                        }}
                        disabled={props.submitting}
                      />
                    )}
                  </div>
                </div>
              </Animation.Slide>
            </div>
          </div>
        </div>
      </div>
      {props?.selectedCredit?.id && (
        <div className="p-3 custom-flex-container">
          {props?.reportsLoading ? (
            <Loader center size="sm" content="Loading LQR Reports..." />
          ) : !props.selectedReport?.id ? (
            <div className="is-width-full" style={{ height: "500px", textAlign: "center" }}>
              {props.selectedCredit?.id ? "Please Create a New LQR Report" : "Select Credit To Continue"}
            </div>
          ) : (
            <div className="custom-flex-container">
              <span className="has-text-info is-size-6">{`Report for: ${props?.quarters[props.selectedReport?.quarter]?.label} of ${
                props.selectedReport?.year
              }`}</span>
              <div className="custom-flex-container flex-scroll">
                <div>
                  <div>
                    <div>
                      <Table id="lqr-report-pdf" data={criteriaTableData} bordered={false} cellBordered={false} autoHeight wordWrap="break-word">
                        <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>Criteria</HeaderCell>
                          <Cell dataKey="name" />
                        </Column>
                        <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>Metric</HeaderCell>
                          <MetricHandler
                            dataKey="type"
                            tableData={criteriaTableData}
                            setTableData={setCriteriaTableData}
                            lqrData={props.lqrData}
                            disabled={props.disableFields}
                            checkCriteriaDirty={props.checkCriteriaDirty}
                            getGrade={props.getGrade}
                            overrideRef={props.overrideRef}
                            setOverride={props.setOverride}
                          />
                        </Column>
                        <Column width={150} verticalAlign="middle" align="center">
                          <HeaderCell>Grade</HeaderCell>
                          <CellOverride
                            refTableData={criteriaTableData}
                            dataKey="grade"
                            override={props.override}
                            className="is-flex is-align-items-center is-justify-content-center is-flex-grow-1"
                          />
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle">
                          <HeaderCell>Notes</HeaderCell>
                          <AreaCell
                            dataKey="notes"
                            controlClass="pr-3 mt-3 is-flex is-flex-direction-column is-flex-grow-1"
                            disabled={props.disableFields}
                            cb={({ rowId, val, key, rowData, valid }) => {
                              if (valid) {
                                const propsCriteria = props.lqrData.current?.report?.criteria?.find((criteria) => criteria?.id === rowId);
                                propsCriteria.response[key] = val;
                                rowData.response[key] = propsCriteria.response[key];
                              }
                              props.checkCriteriaDirty({ rowData, key });
                            }}
                          />
                        </Column>
                      </Table>
                      <hr style={{ borderStyle: "dashed", borderColor: "cornflowerblue" }} />
                      <Table id="lqr-report-pdf" data={facilityTableData} bordered={false} cellBordered={false} autoHeight wordWrap="break-word">
                        <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>Facility Rule</HeaderCell>
                          <Cell dataKey="name" />
                        </Column>
                        <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>Metric</HeaderCell>
                          <MetricHandler
                            dataKey="type"
                            tableData={facilityTableData}
                            setTableData={setFacilityTableData}
                            lqrData={props.lqrData}
                            disabled={props.disableFields}
                            checkCriteriaDirty={props.checkCriteriaDirty}
                            getGrade={props.getGrade}
                            overrideRef={props.overrideRef}
                            setOverride={props.setOverride}
                          />
                        </Column>
                        <Column width={150} verticalAlign="middle" align="center">
                          <HeaderCell>Grade</HeaderCell>
                          <CellOverride
                            refTableData={facilityTableData}
                            dataKey="grade"
                            override={props.override}
                            className="is-flex is-align-items-center is-justify-content-center is-flex-grow-1"
                          />
                        </Column>
                        <Column flexGrow={2} verticalAlign="middle">
                          <HeaderCell>Notes</HeaderCell>
                          <AreaCell
                            dataKey="notes"
                            controlClass="pr-3 mt-3 is-flex is-flex-direction-column is-flex-grow-1"
                            disabled={props.disableFields}
                            cb={({ rowId, val, key, rowData, valid }) => {
                              if (valid) {
                                const propsCriteria = props.lqrData.current?.report?.criteria?.find((criteria) => criteria?.id === rowId);
                                propsCriteria.response[key] = val;
                                rowData.response[key] = propsCriteria.response[key];
                              }
                              props.checkCriteriaDirty({ rowData, key });
                            }}
                          />
                        </Column>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {props.selectedReport?.overallGrade && !isNaN(props.selectedReport?.overallGrade) && (
                  <div>
                    <label className="is-size-6">Summary</label>
                    <div style={{ border: "1px, solid, #d3d3d3" }}>
                      <div className="p-3 mb-3" style={{ border: "1px solid #d2d2d2" }}>
                        <div className="is-flex is-justify-content-space-between is-align-items-center">
                          <div>{`Average Grade: ${props.selectedReport?.average ?? 0}`}</div>
                          <div>{`Weighted Average: ${props.selectedReport?.weightedAverage ?? 0}`}</div>
                          {props.oldReport ? (
                            <div>{`Average Portfolio Performance: ${props.selectedReport?.averagePortfolioPerformance ?? 0}`}</div>
                          ) : (
                            <div>{`Highest Facility Rule Grade: ${props.selectedReport?.highestFacilityGrade ?? 0}`}</div>
                          )}
                          <div>{`Overall Grading: ${props.selectedReport?.overallGrade ?? 0}`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <com.TextInput
                    label="Analyst Summary"
                    type="textarea"
                    maxlength={5000}
                    value={props.selectedReport?.summary}
                    onChange={(e) => handleInputs({ val: e.target.value, key: "summary" })}
                    disabled={props.disableFields}
                  />
                </div>
                <div className="p-3 my-3" style={{ border: "1px solid #d2d2d2" }}>
                  <table>
                    <tbody>
                      {(props.selectedReport.status === "submitted" || props.selectedReport.status === "approved") && (
                        <tr style={{ height: "2em" }}>
                          <td className="pr-2">Submitted By:</td>
                          <td>{props.selectedReport?.submittedBy || submittedBy}</td>
                          <td className="pr-2 pl-6">Submitted Date:</td>
                          <td>{props.selectedReport?.submittedDate?.displayDate}</td>
                        </tr>
                      )}
                      <tr style={{ height: "2em" }}>
                        <td className={`pr-2`} style={{ verticalAlign: "middle" }}>
                          {props.selectedReport?.status === "approved" ? "Reviewed By:" : "Reviewer:"}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {props.selectedReport?.reviewedBy ? (
                            reviewedBy
                          ) : (
                            <SelectPicker
                              container={document.getElementById("lqr-report")}
                              style={{ width: "23em" }}
                              placeholder="Choose Reviewer"
                              placement="topStart"
                              searchable
                              data={props?.personsList?.current?.map((person) => ({ label: person?.name, value: person?.email })) || []}
                              labelKey="label"
                              valueKey="value"
                              value={props.selectedReport?.requestedReviewer || ""}
                              onChange={async (e) => {
                                const matched = props?.personsList?.current?.find((person) => person?.email === e);
                                props.lqrData.current.report.requestedReviewer = matched?.email;
                                props.setSelectedReport({ ...props.lqrData.current.report });
                                await props.checkReportIsDirty({ body: props?.lqrData?.current?.report });
                              }}
                              disabled={props.disableFields}
                            />
                          )}
                        </td>
                        {props.selectedReport?.status === "draft" && (
                          <td className="pl-6 pt-1 help is-danger" style={{ verticalAlign: "middle" }}>
                            Email will be only be sent once LQR report is submitted.
                          </td>
                        )}
                        {props.selectedReport?.status === "submitted" && !props.selectedReport?.reviewedBy && (
                          <td className="pl-6" style={{ verticalAlign: "middle" }}>
                            Not Reviewed
                          </td>
                        )}
                        {props.selectedReport?.reviewedBy && (
                          <td className="pr-2 pl-6" style={{ verticalAlign: "middle" }}>
                            Reviewed Date:
                          </td>
                        )}
                        {props.selectedReport?.reviewedBy && (
                          <td style={{ verticalAlign: "middle" }}>
                            {props.selectedReport?.requestedDate || convertTimeToLocal({ date: new Date(), localOrigin: true })}
                          </td>
                        )}
                        {props.selectedReport?.reviewedBy &&
                          (props.selectedReport?.status === "approved" || props.selectedReport?.status === "rejected") && (
                            <td
                              className={`pl-6 ${props.selectedReport?.status === "approved" ? "has-text-success" : "has-text-danger"}`}
                              style={{ verticalAlign: "middle" }}
                            >{`Status: ${startCase(props.selectedReport?.status)}`}</td>
                          )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CrudControls = ({ ...props }) => {
  const [createTriggered, setCreateTriggered] = useState(false);
  const [createDone, setCreateDone] = useState(false);
  const [actionTriggered, setActionTriggered] = useState();
  const [actionDone, setActionDone] = useState();
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [rejectTriggered, setRejectTriggered] = useState(false);
  const [rejectDone, setRejectDone] = useState(false);
  const [approveTriggered, setApproveTriggered] = useState(false);
  const [approveDone, setApproveDone] = useState(false);
  const [superUser, setSuperUser] = useState(false);

  const submitCriteria = async ({ status, method, responseCriteria }) => {
    if (status === "create" && method === "create") return;
    const endpoints = [];
    if (!!responseCriteria?.length) {
      for (let userResponse of responseCriteria) {
        const body = {
          id: userResponse?.response?.id,
          criteriaRowId: userResponse?.rowId,
          reportId: userResponse?.response?.reportId,
          criteriaId: userResponse?.id,
          metric: userResponse?.metric !== userResponse?.response?.metric ? userResponse?.response?.metric : userResponse?.metric,
          grade: userResponse?.grade,
          notes: userResponse?.notes !== userResponse?.response?.notes ? userResponse?.response?.notes : userResponse?.notes,
          choiceId: userResponse?.response?.choice?.id,
          choiceRowId: userResponse?.response?.choice?.rowId,
        };
        endpoints.push(api.lqrReport.updateResponses({ id: body.id, body }));
      }
      await Promise.all(endpoints);
    }
  };

  const submit = async ({ status }) => {
    props.setSubmitting(true);
    props.setDisableFields(true);
    const method = props.lqrData.current.report?.id && status !== "create" ? "update" : "create";
    const body =
      status === "create" && method === "create"
        ? { ...props.blankReportDetails, creditId: props?.selectedCredit?.id, status: "draft" }
        : {
            id: props.lqrData.current.report?.id,
            creditId: props?.selectedCredit?.id,
            status: status === "new" || status === "create" ? "draft" : status,
            quarter: props?.lqrData.current.report?.displayQuarter
              ? props?.lqrData.current.report?.displayQuarter
              : props?.lqrData.current.report?.quarter,
            year: props?.lqrData.current.report?.displayYear ? props?.lqrData.current.report?.displayYear : props?.lqrData.current.report?.year,
            summary: props?.lqrData.current.report?.summary,
            requestedReviewer: props?.lqrData.current.report?.requestedReviewer,
            submittedBy: props?.lqrData.current?.report?.submittedBy
              ? props?.lqrData.current?.report?.submittedBy
              : status === "submitted"
              ? store.user?.email
              : null,
            submittedDate: props?.lqrData.current.report?.submittedDate?.date
              ? props?.lqrData.current.report?.submittedDate?.date
              : status === "submitted" || status === "approved"
              ? new Date()
              : null,
            reviewedDate: status === "rejected" || status === "approved" ? new Date() : null,
            reviewedBy: status === "rejected" || status === "approved" ? store.user.email : null,
            average: props?.lqrData.current.report?.average,
            weightedAverage: props?.lqrData.current.report?.weightedAverage,
            overallGrade: props?.lqrData.current.report?.overallGrade,
          };
    // handle legacy portfolio performance reporting
    if (props?.oldReport) {
      body.highestFacilityGrade = null;
      body.averagePortfolioPerformance = props?.lqrData.current?.report?.averagePortfolioPerformance;
    } else {
      body.averagePortfolioPerformance = null;
      body.highestFacilityGrade = props?.lqrData.current?.report?.highestFacilityGrade;
    }
    // if totals exist, always save the report
    if (
      props?.lqrData.current?.report?.average ||
      props?.lqrData.current?.report?.weightedAverage ||
      (props?.lqrData.current?.report?.averagePortfolioPerformance && props.oldReport) ||
      (props?.lqrData.current?.report?.highestFacilityGrade && !props.oldReport) ||
      props?.lqrData.current.report?.overallGrade
    ) {
      props.lqrData.current.saveControl.report = true;
    }
    // set Triggers
    let trigger, done;
    switch (status) {
      case "create":
        trigger = setCreateTriggered;
        done = setCreateDone;
        break;
      case "submitted":
        trigger = setSubmitTriggered;
        done = setSubmitDone;
        break;
      case "rejected":
        trigger = setRejectTriggered;
        done = setRejectDone;
        break;
      case "approved":
        trigger = setApproveTriggered;
        done = setApproveDone;
        break;
      case "new":
      default:
        trigger = setActionTriggered;
        done = setActionDone;
        break;
    }
    trigger(true);
    if (status === "create" && method === "create") {
      body.quarter =
        props?.selectedReport?.displayQuarter || props?.selectedReport?.displayQuarter === 0 || props?.selectedReport?.displayQuarter === "0"
          ? props?.selectedReport?.displayQuarter
          : body.quarter;
      body.year = props?.selectedReport?.displayYear ? props?.selectedReport?.displayYear : body.year;
      body.submittedDate = null;
    }
    // check if should update report
    let reportResponse;
    if (props.lqrData?.current?.saveControl?.report || status === "create" || status === "approved" || status === "rejected") {
      reportResponse = await api.lqrReport[method]({ id: body?.id, body });
    }
    // order report criteria
    if (reportResponse?.criteria) {
      reportResponse.criteria = orderBy(reportResponse.criteria, "sortOrder", "asc");
    }
    // save responses
    if (!!props.lqrData.current?.saveControl?.criteria?.length) {
      await submitCriteria({ status, method, responseCriteria: props.lqrData.current?.saveControl?.criteria });
    }

    // update new report to be in the proper format
    const updatedReport = await props.getReport({ updatedReport: reportResponse });
    if (!props.lqrData.current.allReports) props.lqrData.current.allReports = [{ ...reportResponse }];
    // update report in allReports
    let matchedAllReportIdx = props.lqrData.current.allReports?.findIndex((report) => report?.id === updatedReport?.id);
    if ((!matchedAllReportIdx && matchedAllReportIdx !== 0 && matchedAllReportIdx !== "0") || matchedAllReportIdx === -1) {
      props.lqrData.current.allReports.push({ ...updatedReport });
    } else {
      props.lqrData.current.allReports[matchedAllReportIdx] = updatedReport;
    }
    // check if there is an approved status to sort by reviewedDate. secondary sort should be by startDate
    props.lqrData.current.allReports = orderBy(
      props.lqrData.current.allReports,
      [(item) => [item?.status === "approved", item?.startDate]],
      ["desc"]
    );
    // update original report
    props.setSelectedReport({ ...props.lqrData.current.report });

    done(true);
    setTimeout(() => {
      trigger(false);
      done(false);
      if (props.lqrData.current.report.status !== "submitted" && props.lqrData.current.report.status !== "approved") {
        props.setDisableFields(false);
      }
      props.setSubmitting(false);
      props.setDisableSaveSubmit(true);
      props.lqrData.current.saveControl = {
        criteria: false,
        report: false,
      };
    }, 2000);
    return [];
  };

  const navigate = useNavigate();
  const deleteReport = async () => {
    props.setDisableFields(true);
    await api.lqrReport.delete({ id: props.lqrData.current.report?.id });
  };

  // dropcard
  const [newLqrActive, setNewLqrActive] = useState(false);
  const cSSVarsAdjustment = {
    dropBackgroundColor: "rgb(85 85 141)",
    dropBorderColor: "rgb(179 179 220)",
    dropHeight: "185px",
    dropWidth: "475px",
    dropCardButtonHeight: "25px",
    dropCardButtonWidth: "114px",
    dropCardTopPosition: "0",
  };

  const setupSu = async () => {
    const isSu = await getUserCognitoGroups("superUser");
    setSuperUser(isSu);
  };
  useEffect(() => {
    setupSu();
  });

  return (
    !isEmpty(props.selectedCredit) &&
    !props.hideCrud && (
      <div className="is-flex is-align-items-center">
        {props.lqrData.current.report?.id && superUser && (
          <com.ApiDeleteButton controlClass="mr-3 title-button" action={deleteReport} cb={() => navigate(0)} disabled={props.submitting} />
        )}
        {(!!props?.lqrData.current?.allReports?.length || !props.selectedReport?.id) && (
          <com.DropCard
            id="lqr-drop-container"
            controlStyle={{ height: "20px", zIndex: "99" }}
            controlClass="title-button is-link mr-3 button"
            permission={true}
            triggerButtonText="Create New Report"
            controlIcon={<i className="fa-solid fa-plus mr-2"></i>}
            cSSVars={cSSVarsAdjustment}
            content={
              <div className="is-flex is-flex-direction-column">
                <div className="is-flex mb-4">
                  <SelectPicker
                    container={document.getElementById("lqr-drop-container")}
                    style={{ width: "5em" }}
                    className="mr-5"
                    searchable={false}
                    cleanable={false}
                    data={props?.quarters || []}
                    labelKey="label"
                    valueKey="value"
                    value={
                      props.selectedReport?.displayQuarter ||
                      props.selectedReport?.displayQuarter === 0 ||
                      props.selectedReport?.displayQuarter === "0"
                        ? props.selectedReport?.displayQuarter
                        : props.quarters.findIndex((q) => q.label === getCurrentQuarter())
                    }
                    onChange={(e) => {
                      props.lqrData.current.report.displayQuarter = e;
                      props.setSelectedReport({ ...props.lqrData.current.report });
                    }}
                    disabled={props.submitting}
                  />
                  <SelectPicker
                    container={document.getElementById("lqr-drop-container")}
                    style={{ width: "8em" }}
                    data={props?.years?.map((item) => ({ label: item, value: item })) || []}
                    searchable={false}
                    cleanable={false}
                    value={props.selectedReport?.displayYear || new Date().getFullYear()}
                    onChange={(e) => {
                      props.lqrData.current.report.displayYear = e;
                      props.setSelectedReport({ ...props.lqrData.current.report });
                    }}
                    disabled={props.submitting}
                  />
                </div>
                <div className="has-text-warning is-flex">
                  <i className="fas fa-triangle-exclamation mr-2" />
                  <div style={{ whiteSpace: "normal", textAlign: "left" }}>
                    Warning: Data from the current form will not be saved to the new LQR Report
                  </div>
                </div>
              </div>
            }
            dropCardActive={newLqrActive}
            setDropCardActive={setNewLqrActive}
            actions={[
              {
                text: "Close",
                icon: <i className="fas fa-close mr-2" />,
                className: "is-warning",
                action() {
                  setNewLqrActive(!newLqrActive);
                },
                disabled: props?.submitting,
              },
              {
                isCustom: (
                  <com.ApiSubmitButton
                    className={`mr-3`}
                    defaultLabel="Create Report"
                    operationLabel="Creating Report"
                    icon={<i className={`fa-solid fa-plus`} />}
                    action={() => submit({ status: "create" })}
                    actionTriggered={createTriggered}
                    actionDone={createDone}
                    disabled={props.submitting}
                    cb={() => setNewLqrActive(false)}
                  />
                ),
              },
            ]}
          />
        )}
        {props.selectedReport?.requestedReviewer !== store?.user?.email ||
        props.selectedReport?.status === "rejected" ||
        props.selectedReport?.status === "draft" ? (
          <div className="is-flex" style={{ height: "20px" }}>
            {(((props.selectedReport?.status === "draft" || props.selectedReport?.status === "rejected") && props.selectedReport?.id) ||
              (props.selectedReport?.status === "submitted" && !!props.selectedReport?.reviewedBy && props.selectedReport?.id)) && (
              <span className="is-flex is-align-items-center">
                <com.ApiSubmitButton
                  className={`title-button is-primary ${props.selectedReport?.requestedReviewer && "mr-3"}`}
                  defaultLabel="Save Progress"
                  operationLabel="Saving"
                  icon={<i className={`fa-solid fa-floppy-disk`} />}
                  action={() => {
                    const status = props.lqrData.current.report?.id ? "draft" : "new";
                    return submit({ status });
                  }}
                  actionTriggered={actionTriggered}
                  actionDone={actionDone}
                  disabled={props.disableSaveSubmit || !isEmpty(props.override)}
                />
                {props.selectedReport?.requestedReviewer && (
                  <div className="is-flex is-align-items-center is-flex-direction-row">
                    <div className="mb-2 mr-4"> or </div>
                    <com.ApiSubmitButton
                      className={`title-button btn-call-to-action`}
                      defaultLabel="Submit"
                      operationLabel="Submitting"
                      icon={<i className={`fa-solid fa-cloud-arrow-up`} />}
                      action={() => submit({ status: "submitted" })}
                      actionTriggered={submitTriggered}
                      actionDone={submitDone}
                      disabled={
                        !props.selectedReport?.requestedReviewer ||
                        props.selectedReport?.requestedReviewer === store?.user?.email ||
                        !isEmpty(props.override)
                      }
                    />
                  </div>
                )}
              </span>
            )}
          </div>
        ) : (
          props.selectedReport?.status !== "approved" &&
          props.selectedReport?.status !== "draft" && (
            <div style={{ height: "20px" }}>
              <com.ApiSubmitButton
                className="is-primary title-button mr-3"
                defaultLabel="Approve"
                operationLabel="Updating"
                icon={<i className={`fa-solid fa-check`} />}
                action={() => submit({ status: "approved" })}
                actionTriggered={approveTriggered}
                actionDone={approveDone}
                disabled={props.submitting}
              />
              <com.ApiSubmitButton
                className="is-danger title-button"
                defaultLabel="Reject"
                operationLabel="Updating"
                icon={<i className={`fa-solid fa-thumbs-down`} />}
                action={() => submit({ status: "rejected" })}
                actionTriggered={rejectTriggered}
                actionDone={rejectDone}
                disabled={props.submitting}
              />
            </div>
          )
        )}
      </div>
    )
  );
};

export const LqrReport = () => {
  const [hideCrud, setHideCrud] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [loadingCredits, setLoadingCredits] = useState(true);
  const [credits, setCredits] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState({});
  const [reportsLoading, setReportsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [personsListLoaded, setPersonsListLoaded] = useState(false);
  const [disableSaveSubmit, setDisableSaveSubmit] = useState(true);
  const [override, setOverride] = useState();
  const quarters = ["Q1", "Q2", "Q3", "Q4"].map((item, idx) => ({ label: item, value: idx }));
  const [oldReport, setOldReport] = useState(false);
  const blankReportDetails = {
    quarter: quarters.findIndex((q) => q.label === getCurrentQuarter()),
    year: new Date().getFullYear(),
    summary: "",
    requestedReviewer: "",
    submittedDate: "",
  };

  const lqrData = useRef({
    originalReport: structuredClone({ ...blankReportDetails }),
    allReports: [],
    report: {},
    saveControl: {
      criteria: false,
      report: false,
    },
  });

  const getMetric = async ({ choice }) => {
    if (choice?.textResponse) {
      return choice?.textResponse;
    }
    return null;
  };

  const overrideRef = useRef();

  const getGrade = async ({ data, rowData, returnHtml }) => {
    let val = typeof data === "string" && rowData.type === "number" ? stringToNumber(data) : data;
    let tempChoice;
    if (isNaN(val)) {
      tempChoice = rowData.choices?.find((choice) => data && choice?.textResponse === data);
    } else {
      tempChoice = rowData.choices?.find((choice) => val && choice?.numericMin <= val && val <= choice?.numericMax);
    }
    if (returnHtml && (data || val)) {
      if (!isEmpty(tempChoice)) {
        const tempOverride = overrideRef.current?.filter((o) => o.id === rowData?.id);
        if (tempOverride?.length) {
          for (let item of tempOverride) {
            const overrideIdx = overrideRef.current?.findIndex((o) => o.id === item?.id);
            overrideRef.current?.splice(overrideIdx, 1);
          }
          setOverride([...overrideRef.current]);
          return tempChoice?.grade;
        } else {
          return !isEmpty(tempChoice) ? tempChoice?.grade : "";
        }
      } else {
        if (isEmpty(overrideRef.current)) {
          overrideRef.current = [{ id: rowData?.id, html: <div className="help is-danger">Invalid Metric</div> }];
          setOverride([...overrideRef.current]);
        } else {
          overrideRef.current.push({ id: rowData?.id, html: <div className="help is-danger">Invalid Metric</div> });
          setOverride([...overrideRef.current]);
        }
        return "";
      }
    } else {
      return !isEmpty(tempChoice) ? tempChoice?.grade : "";
    }
  };

  const fixDateDisplay = async () => {
    // fix date to be useful
    if (lqrData.current.allReports.length) {
      for (let r of lqrData.current.allReports) {
        if (r.submittedDate) {
          r.submittedDate = { date: r.submittedDate, displayDate: convertTimeToLocal({ date: r.submittedDate }) };
        }
        if (r.reviewedDate) {
          r.reviewedDate = { date: r.reviewedDate, displayDate: convertTimeToLocal({ date: r.reviewedDate }) };
        }
      }
      lqrData.current.report = lqrData.current?.allReports[0];
    }
  };

  const personsList = useRef([]);

  const getCredits = async () => {
    setLoadingCredits(true);
    let response = await api.credits.get({});
    if (!response) return [];
    response = orderBy(response, [(item) => (typeof item?.name === "string" ? item?.name?.toLowerCase() : item?.name)], ["asc"]);
    setCredits([...response]);
    setLoadingCredits(false);
    return response;
  };

  const getLqrReports = async ({ creditId }) => {
    setReportsLoading(true);
    let response = await api.lqrReport.get({ creditId });
    if (!response) response = [];
    // check if there is an approved status to sort by reviewedDate. secondary sort should be by startDate
    response = orderBy(response, [(item) => [item?.status === "approved", item?.startDate]], ["desc"]);
    lqrData.current.allReports = !!response?.length && structuredClone([...response]);
    // fix date to be useful
    await fixDateDisplay();
    await getLqrReportWithResponses({});
    setReportsLoading(false);
    return response;
  };

  const getLqrReportWithResponses = async ({ updatedReport }) => {
    setReportsLoading(true);
    let response = !updatedReport ? await api.lqrReport.get({ id: lqrData.current.report?.id }) : updatedReport;
    const createdDate = Date.parse(response?.createdDate);
    const facilityRuleChangeStart = Date.parse("2024-03-21T01:00:00");
    const old = createdDate ? createdDate < facilityRuleChangeStart : true;
    setOldReport(old);
    if (!response || isEmpty(response)) {
      response = structuredClone({ ...blankReportDetails });
    }
    // Get Criteria if it's missing on the report
    if (!response?.criteria?.length) {
      response.criteria = await getLqrCriteria({ bypassApi: !!response?.id });
    }
    // limit criteria to ones that have choices
    response.criteria = response?.criteria?.filter((r) => !isEmpty(r.choices));
    // set choice, choiceId, notes on each criteria
    for (let criteria of response.criteria) {
      if (!criteria.response) {
        criteria.response = {
          choice: "",
          choiceId: "",
          notes: "",
        };
      } else {
        criteria.originalResponse = criteria.response;
        criteria.metric = criteria.response.metric || criteria?.metric || (await getMetric({ choice: criteria.response.choice }));
        criteria.choice = criteria.response?.choice;
        criteria.choiceId = criteria.response?.choiceId || criteria.response.choice?.id;
        criteria.choiceRowId = criteria.response?.choice?.rowId;
        criteria.notes = criteria.response.notes;
        if (!criteria?.grade) {
          if (criteria?.response?.grade) {
            criteria.grade = criteria.response.grade;
          } else if (criteria?.response?.metric || criteria?.metric) {
            criteria.grade = await getGrade({ data: criteria.response.metric ?? criteria?.metric, rowData: criteria, returnHtml: true });
          }
        }
        if (!criteria?.response?.grade && criteria?.grade) {
          criteria.response.grade = criteria.grade;
        }
      }
    }
    // order report criteria
    response.criteria = orderBy(response.criteria, "sortOrder", "asc");
    // set report data on ref
    lqrData.current.originalReport = structuredClone({ ...response });
    lqrData.current.report = structuredClone({ ...lqrData.current.originalReport });
    setSelectedReport({ ...lqrData.current.report });
    setReportsLoading(false);
    return lqrData.current.report;
  };

  const getLqrCriteria = async ({ bypassApi }) => {
    let criteriaResponse = !bypassApi ? await api.lqrCriteria.get({}) : lqrData.current?.report?.criteria;
    if (!criteriaResponse) return [];
    criteriaResponse = orderBy(criteriaResponse, "sortOrder", "asc");
    return criteriaResponse;
  };

  const checkCriteriaDirty = async ({ rowData }) => {
    const compareKeys = ["choiceId", "reportId", "metric", "notes"];
    let mismatch = false;
    for (let loopCriteria of lqrData.current.report.criteria) {
      for (let compKey of compareKeys) {
        const response = loopCriteria[compKey] !== loopCriteria?.response[compKey] ? loopCriteria?.response[compKey] : loopCriteria[compKey];
        const originalCriteria = lqrData.current.originalReport?.criteria?.find((criteria) => criteria?.id === loopCriteria?.id);
        const originalResponse =
          originalCriteria[compKey] !== originalCriteria?.originalResponse[compKey]
            ? originalCriteria?.originalResponse[compKey]
            : originalCriteria[compKey];
        if (!isEqual(originalResponse, response)) {
          if (!originalResponse && !response && !mismatch) {
            mismatch = false;
          } else {
            mismatch = true;
            break;
          }
        }
      }
    }
    const combinedMismatch = lqrData.current.saveControl?.report ? true : mismatch;
    setDisableSaveSubmit(!combinedMismatch);
    if (lqrData?.current?.saveControl) {
      if (!lqrData.current.saveControl.criteria.length || !mismatch) {
        lqrData.current.saveControl.criteria = [];
      }
      const exists = lqrData.current.saveControl.criteria?.find((criteria) => criteria?.id === rowData?.id);
      if ((!exists || isEmpty(exists)) && mismatch) {
        lqrData.current.saveControl.criteria.push(rowData);
      }
    }
  };

  const checkReportIsDirty = async ({ body, status, method }) => {
    if (!lqrData.current.originalReport?.id || (status === "create" && method === "create")) return true;
    const compareKeys = ["quarter", "year", "summary", "requestedReviewer"];
    let mismatch = false;
    for (let key of compareKeys) {
      if (!isEqual(lqrData.current.originalReport[key], body[key])) {
        if (!lqrData.current.originalReport[key] && !body[key]) {
          mismatch = false;
        } else {
          mismatch = true;
        }
        break;
      }
    }
    const isDirty = status === "approved" || status === "rejected" || mismatch;
    if (lqrData?.current?.saveControl) {
      lqrData.current.saveControl.report = isDirty;
    }
    const combinedMismatch = !!lqrData.current.saveControl?.criteria?.length ? true : isDirty;
    setDisableSaveSubmit(!combinedMismatch);
  };

  const setup = async () => {
    setReportsLoading(true);
    const endpoints = [getCredits()];
    if (!store?.skynetPersonsList || isEmpty(store?.skynetPersonsList)) {
      endpoints.push(skynet.persons.get({}));
    }
    await Promise.all(endpoints);
    // limit personList to Information Technology (development env only) && Credit
    personsList.current = store.skynetPersonsList?.filter((person) => {
      const include =
        accurateEnv !== "production"
          ? person?.department === "Information Technology" && person.title.includes("oftware")
          : person?.department?.toLowerCase()?.includes("credit");
      return include;
    });
    setPersonsListLoaded(true);
    if (isEmpty(lqrData.current.report)) {
      lqrData.current.report = structuredClone({ ...lqrData.current.originalReport });
      setSelectedReport({ ...lqrData.current.report });
    }
    setReportsLoading(true);
  };

  // Tab View's props mapping
  const sharedProps = {
    loadingCredits,
    credits,
    selectedCredit,
    setSelectedCredit,
    reportsLoading,
    getReport: getLqrReportWithResponses,
    selectedReport,
    setSelectedReport,
    getLqrReports,
    lqrData,
    personsList,
    personsListLoaded,
    quarters,
    years: getYearList({ startYear: new Date().getFullYear() - 1, yearCount: 50 }),
    disableFields,
    setDisableFields,
    blankReportDetails,
    submitting,
    setSubmitting,
    hideCrud,
    setHideCrud,
    disableSaveSubmit,
    setDisableSaveSubmit,
    checkCriteriaDirty,
    checkReportIsDirty,
    getGrade,
    override,
    setOverride,
    overrideRef,
    oldReport,
    setOldReport,
  };

  return (
    <PageTemplate
      id="lqr-report"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="LQR Report"
      defaultOpenMenus={["1"]}
      setup={setup}
      content={<Content {...sharedProps} />}
      crudControls={<CrudControls {...sharedProps} />}
    />
  );
};
