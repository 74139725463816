import { useState, useEffect, useRef } from "react";
import { Table, DatePicker } from "rsuite";
import Toggle from "../ToggleSlider";
import { findIndex, isEmpty, noop } from "lodash";
import microservices from "../../services/microservices";
import InlineSaveIndicator from "../InlineSaveIndicator";
import * as com from "../";
import { v4 as uuidV4 } from "uuid";

export const InputEditCell = ({
  rowData,
  dataKey,
  disabled,
  cb,
  customValidation,
  style,
  containerStyle,
  validationMessage,
  continueOnError,
  controlClass,
  inputContainerClass,
  decoration,
  cellCallout,
  ...props
}) => {
  const { Cell } = Table;
  const [cellData, setCellData] = useState(rowData[dataKey]);
  const [invalid, setInvalid] = useState(false);

  const handleChange = (e) => {
    let valid;
    if (customValidation) {
      valid = customValidation({ val: e.target.value, key: dataKey, rowData });
      setInvalid(!valid);
      if (!valid) {
        if (continueOnError) {
          setCellData(e.target.value);
          rowData[dataKey] = e.target.value;
        }
        return;
      }
    }
    setCellData(e.target.value);
    rowData[dataKey] = e.target.value;
    if (cb) {
      cb({ rowId: rowData?.id, data: e.target.value, key: dataKey, rowData, valid: !customValidation ? true : valid });
    }
  };

  const getDecorationPosition = ({ position }) => {
    if (style) {
      if (!isEmpty(style) && !style?.textAlign) {
        style.textAlign = typeof position === "object" ? "end" : position;
        style.paddingRight = "1.3rem";
      } else {
        style = { textAlign: position, paddingRight: "1.3rem" };
      }
    }
    if (position === "end") {
      return { right: "0.5rem", top: "0.55rem", position: "absolute" };
    } else if (position === "start") {
      return { left: "0.1rem", top: "0.55rem", position: "absolute" };
    } else if (typeof position === "object") {
      return position;
    }
  };

  useEffect(() => {
    setCellData(rowData[dataKey]);
  }, [rowData, dataKey, customValidation]);

  return (
    <Cell {...props}>
      <div className={`is-relative`} style={containerStyle}>
        <com.TextInput
          placeholder={rowData[dataKey]}
          value={cellData || ""}
          onChange={handleChange}
          disabled={disabled === rowData?.id || disabled === true || rowData?.name === "Borrowing Base Summary" || (disabled && disabled?.includes(rowData?.id))}
          controlClass={controlClass}
          inputContainerClass={inputContainerClass}
          style={style || { maxHeight: "30px", marginTop: "-10px" }}
          maxlength={props.setmaxlength}
          {...props}
        />
        {!!decoration?.condition && (
          <div
            className={`is-flex is-align-items-center is-justify-content-center`}
            style={getDecorationPosition({ position: decoration?.position })}
          >
            {decoration?.character}
          </div>
        )}
        {invalid && validationMessage && (
          <div
            className="is-absolute"
            style={{
              top: "-10px",
              right: "0rem",
              border: "1px solid hsl(348, 86%, 61%)",
              color: "hsl(348, 86%, 61%)",
              borderRadius: "4px",
              padding: "4px",
            }}
          >
            <div>{validationMessage}</div>
          </div>
        )}
        {cellCallout}
      </div>
    </Cell>
  );
};

export const InputEditCellInlineSave = ({ rowData, dataKey, disabled, setDisabled, cb, inlineSubmitHandler, controlClass, handleValidation, ...props }) => {
  const { Cell } = Table;
  const [cellData, setCellData] = useState(rowData[dataKey]);
  const [showSavingId, setShowSavingId] = useState(null);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const detail = useRef({});
  const originalData = useRef({});
  const keyBindingHandled = useRef(false); // for debouncing of keypress listener
  const allInputs = useRef([]); // used in keypress handler

  const handleChange = ({ e, column }) => {
    if(handleValidation){
      const isValid = handleValidation({ e, column, rowData });
      if(!isValid) return;
    }
    setCellData(e.target.value);
    rowData[dataKey] = e.target.value;
    if (props.tableid === "covenant-table-container") {
      if (isEmpty(detail.current)) {
        detail.current = { ...rowData };
      } else {
        detail.current[column] = e.target.value;
      }
    }
    if (cb) {
      cb({ rowId: rowData?.id, data: e.target.value, key: dataKey });
    }
  };

  const checkForChange = () => {
    return originalData.current[dataKey] !== detail.current[dataKey];
  };

  const handleBlur = async () => {
    const hasChange = checkForChange();
    if (!hasChange) return;
    setDisabled(rowData.id);
    setShowSavingId(`${dataKey}-${rowData?.fieldName}`);
    await inlineSubmitHandler({ detail, detailProps: props.parentprops.current });
    originalData.current = structuredClone({ ...detail.current });
    setShowSavingDone(true);
    setTimeout(() => {
      setShowSavingId(null);
      setShowSavingDone(false);
      setDisabled(false);
    }, 2000);
  };

  const handleFocus = async () => {
    microservices.enterKeyPressHandler({ action: handleBlur, keyBindingHandled, rowData, dataKey, allInputs, tableid: props.tableid });
  };

  useEffect(() => {
    setCellData(rowData[dataKey]);
    // Get a list of all inputs so the system can determine which is the next field upon "ENTER" keypress
    if (!allInputs.current?.length) {
      allInputs.current = Array.from(document.querySelectorAll("input"));
    }
    // Clone the data to compare before save
    detail.current = structuredClone({ ...rowData });
    originalData.current = structuredClone({ ...rowData });
  }, [rowData, dataKey]);

  return (
    <Cell {...props}>
      <div className={`is-flex is-align-items-center ${controlClass ?? ""}`}>
        <com.TextInput
          id={props.tableid === "covenant-table-container" ? `${dataKey}-${rowData?.id}` : null}
          controlClass={props.tableid === "covenant-table-container" ? "is-flex-grow-1" : ""}
          placeholder={rowData[dataKey]}
          value={cellData || ""}
          onChange={(e) => handleChange({ e, column: dataKey })}
          disabled={(disabled && disabled === rowData?.id) || rowData?.name === "Borrowing Base Summary"}
          style={{ maxHeight: "30px", marginTop: "-10px" }}
          maxlength={props.setmaxlength}
          onBlur={props.tableid === "covenant-table-container" ? handleBlur : noop}
          onFocus={props.tableid === "covenant-table-container" ? handleFocus : noop}
          {...props}
        />
        {showSavingId && (
          <div style={{ width: "115px", paddingBottom: "21px" }}>
            <InlineSaveIndicator showId={`${dataKey}-${rowData?.fieldName}`} showSaving={showSavingId} showSavingDone={showSavingDone} />
          </div>
        )}
      </div>
    </Cell>
  );
};

export const InputEditCurrencyCell = ({ rowData, dataKey, type, disabled, data, refreshData, ...props }) => {
  const { Cell } = Table;
  const [cellData, setCellData] = useState(rowData[dataKey]);

  const calculations = ({ rowData, operation }) => {
    if (typeof rowData.reported === "string") {
      if (rowData.reported.includes(",")) {
        rowData.reported = rowData.reported.split(",").join("");
      }
      if (rowData.reported.includes("$")) {
        rowData.reported = rowData.reported.split("$").join("");
      }
    }
    let reported, calculated;
    reported = typeof rowData.reported === "string" ? Number(rowData.reported) : rowData.reported;
    calculated = typeof rowData.calculated === "string" ? Number(rowData.calculated) : rowData.calculated;
    if (rowData.reported && rowData.reported !== "0") {
      const getPercentChange = ({ reported, calculated }) => {
        let percentDif = (100 - (Math.abs(reported) / calculated) * 100).toString();
        if (percentDif.includes(".")) {
          const splitDecimal = percentDif.split(".");
          const zeroValueArray = splitDecimal[1].split("");
          const indexOfFirstNonZero = findIndex(zeroValueArray, (num) => Number(num) > 0);
          percentDif = Number(percentDif);
          const float = indexOfFirstNonZero + 2;
          return Math.abs(percentDif).toFixed(float);
        } else if (percentDif.includes("nfinity")) {
          return percentDif;
        } else {
          return Math.abs(percentDif).toFixed(2);
        }
      };
      return operation === "dollar" ? currency(Math.abs(reported - calculated), operation) : getPercentChange({ reported, calculated });
    }
    return "--";
  };

  const currency = (value, operation) => {
    const usd = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (value?.target?.value) {
      value = value?.target?.value;
    }
    if (typeof value === "string") {
      const tempVal = value.split(",").join("");
      value = Number(tempVal);
    }
    if (value?.target?.value === "") {
      value = 0;
    }
    value = value.toFixed(2);
    value = usd.format(value);
    value = operation === "dollar" ? value : value.substring(1);
    return value;
  };

  const selectText = ({ id }) => {
    const input = document.getElementById(id);
    input.focus();
    input.select();
  };

  const handleChange = (e) => {
    const amount = e.target.value;
    rowData[dataKey] = amount;
    setCellData(amount);
  };

  useEffect(() => {
    if (rowData.isPercent) {
      rowData.calcRptDiff = rowData.reported ? `${Math.abs(Number(rowData.reported) - Number(rowData.calculated)).toFixed(2)}%` : "--";
    } else {
      setCellData(currency(rowData[dataKey] || 0));
      rowData.calcRptDiff = calculations({ rowData, operation: "dollar" });
    }
    rowData.differencePercent = calculations({ rowData, operation: "percent" });
    // eslint-disable-next-line
  }, [rowData, dataKey]);

  return (
    <Cell {...props}>
      <com.TextInput
        autoComplete="off"
        placeholder={rowData[dataKey]}
        value={cellData || ""}
        onChange={handleChange}
        disabled={disabled && disabled === rowData?.id}
        style={{ maxHeight: "30px", marginTop: "-10px" }}
        id={rowData?.id}
        onFocus={() => selectText({ id: rowData?.id })}
        onBlur={(e) => {
          const row = data.indexOf(data.find((d) => d?.id === rowData?.id));
          if (rowData.isPercent) {
            setCellData(e.target.value);
            data[row].calcRptDiff = rowData.reported ? `${Math.abs(Number(rowData.reported) - Number(rowData.calculated)).toFixed(2)}%` : "--";
          } else {
            setCellData(currency(e));
            data[row].calcRptDiff = calculations({ rowData, operation: "dollar" });
          }
          data[row].differencePercent = calculations({ rowData, operation: "percent" });
          refreshData([...data]);
        }}
      />
    </Cell>
  );
};

export const CurrencyCell = ({ rowData, dataKey, ...props }) => {
  const { Cell } = Table;
  const currency = (value) => {
    const usd = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const amount = typeof value === "string" ? Number(value) : value;
    return usd.format(amount);
  };
  const amount = currency(rowData[dataKey]);
  return <Cell {...props}>{amount}</Cell>;
};

export const PercentCell = ({ rowData, dataKey, ...props }) => {
  const { Cell } = Table;
  return <Cell {...props}>{`${rowData[dataKey] || "0.00"}%`}</Cell>;
};

export const ToggleCell = ({ rowData, dataKey, disabled, validation, updateRow, hide, className, cb, ...props }) => {
  const [value, setValue] = useState(false);
  const [hideCell, setHideCell] = useState(false);
  const { Cell } = Table;

  const handleToggle = (e) => {
    if (validation) {
      const isValid = validation({ rowData, dataKey });
      if (!isValid) {
        return;
      }
    }
    rowData[dataKey] = e;
    setValue(e);
    if (cb) {
      cb({ dataKey, rowData, val: e });
    }
  };

  useEffect(() => {
    setValue(rowData[dataKey]);
    if (hide) {
      hide({ rowData, setHideCell });
    }
  }, [rowData, dataKey, updateRow, hide]);

  return (
    <Cell {...props}>
      {!hideCell && (
        <Toggle
          value={value}
          setValue={setValue}
          className={`is-justify-content-center ${className ?? ""}`}
          disabled={disabled === rowData?.id || !validation({ rowData, dataKey })}
          validation={validation}
          cb={handleToggle}
        />
      )}
    </Cell>
  );
};

export const DroplistCell = ({ rowData, dataKey, disabled, dataset, updateRow, setUpdateRow, selectionCb, ...props }) => {
  const [selected, setSelected] = useState("");
  const { Cell } = Table;

  const handleSelection = (e) => {
    setSelected(e.target.value);
    rowData[dataKey] = e.target.value;
    if (selectionCb) selectionCb({rowData});
  };

  useEffect(() => {
    setSelected(rowData[dataKey]);
  }, [rowData, dataKey]);

  return (
    <Cell {...props}>
      <div className="select" style={{ maxHeight: "30px", width: "100%", marginTop: "2px" }}>
        <select
          id={rowData?.id}
          className="select-input-row is-capitalized"
          onChange={handleSelection}
          value={selected}
          disabled={disabled === rowData?.id}
        >
          {dataset?.map((entry) => (
            <option key={entry?.id || entry} value={entry?.id || entry}>
              {entry?.name || entry}
            </option>
          ))}
        </select>
      </div>
    </Cell>
  );
};

export const DroplistCellSimple = ({ rowData, dataKey, disabled, listDisplay, showDefault, dataSet, cb, ...props }) => {
  const [showSavingId, setShowSavingId] = useState(null);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const [selected, setSelected] = useState("");
  const { Cell } = Table;

  const handleSelection = async (e) => {
    setShowSavingId(`${dataKey}-${rowData[dataKey]}`);
    setSelected(e.target.value);
    if (cb) {
      await cb({ id: rowData?.id, val: e.target.value, key: dataKey, rowData, dataSet, showDefault });
      setShowSavingDone(true);
      setTimeout(() => {
        setShowSavingId(null);
        setShowSavingDone(false);
      }, 2000);
    }
  };

  useEffect(() => {
    setSelected(rowData[dataKey]);
  }, [rowData, dataKey]);

  return (
    <Cell {...props}>
      <div className="select" style={{ maxHeight: "30px", width: "100%", marginTop: "2px" }}>
        <select
          id={rowData?.id}
          className="select-input-row is-capitalized"
          onChange={handleSelection}
          value={selected || ""}
          disabled={disabled === rowData?.id}
        >
          {showDefault && (
            <option defaultValue={true} value={0}>
              {showDefault}
            </option>
          )}
          {dataSet?.map((entry) => {
            return (
              <option key={entry?.id || entry} value={entry?.id || entry}>
                {listDisplay({ rowData, entry })}
              </option>
            );
          })}
        </select>
      </div>
      {showSavingId && (
        <div style={{ width: "115px", paddingBottom: "21px" }}>
          <InlineSaveIndicator showId={`${dataKey}-${rowData?.fieldName}`} showSaving={showSavingId} showSavingDone={showSavingDone} />
        </div>
      )}
    </Cell>
  );
};

export const LinkCell = ({ rowData, dataKey, target, ...props }) => {
  const { Cell } = Table;
  return (
    <Cell {...props}>
      <a href={rowData[dataKey]} target={target}>
        {rowData[dataKey]}
      </a>
    </Cell>
  );
};

export const AreaCell = ({ rowData, dataKey, className, controlClass, cb, customValidation, disabled, ...props }) => {
  const { Cell } = Table;
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    let valid;
    if (customValidation) {
      valid = customValidation;
      if (!valid) return;
    }
    setValue(e.target.value);

    if (cb) {
      cb({ rowId: rowData?.id, val: e.target.value, key: dataKey, rowData, valid: !customValidation ? true : valid });
    }
  };

  useEffect(() => {
    setValue(rowData[dataKey]);
  }, [rowData, dataKey]);

  return (
    <Cell {...props}>
      <com.TextInput
        controlClass={controlClass}
        className={className}
        type="textarea"
        style={{ height: "7em", minHeight: "unset", maxHeight: "unset" }}
        maxlength={5000}
        value={value || ""}
        onChange={handleChange}
        disabled={disabled}
      />
    </Cell>
  );
};

export const CellOverride = ({refTableData, rowData, dataKey, className, disabled, override, ...props }) => {
  const [val, setVal] = useState();
  const { Cell } = Table;

  useEffect(() => {
    if(Array.isArray(override)){
      const found = override.find(o => o.id === rowData?.id);
      if(found){
        setVal(found.html);
      } else {
        setVal(rowData[dataKey]);
      }
      return;
    }
    if (override?.id === rowData?.id) {
      setVal(override?.html);
    } else {
      setVal(rowData[dataKey]);
    }
  }, [rowData, dataKey, override?.html, override?.id, override, rowData?.id, refTableData])
  return (
    <Cell {...props}>
      <div className={className} disabled={disabled}>
        {val ?? rowData[dataKey]}
      </div>
    </Cell>
  );
};

export const DateCell = ({ rowData, dataKey, className, containerClass, disabled, style, id, oneTap, container, ...props }) => {
  const [showSavingId, setShowSavingId] = useState(null);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const [displayDate, setDisplayDate] = useState(null);
  const { Cell } = Table;
  const handleChange = async (dateObj) => {
    const apiDate = microservices.handleDatePicker({ date: dateObj, bodyDate: { dateObj }, setter: setDisplayDate });
    if (props?.onChange) {
      setShowSavingId(`${dataKey}-${rowData?.fieldName}`);
      await props.onChange({ rowData, dataKey, apiDate });
      setShowSavingDone(true);
      setTimeout(() => {
        setShowSavingId(null);
        setShowSavingDone(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (props?.value) {
      setDisplayDate(new Date(props?.value));
    } else if (rowData[dataKey]) {
      if (typeof rowData[dataKey] === "string") {
        const utcOffset = new Date(rowData[dataKey]).toTimeString().split(" ")[1];
        const utcString = new Date(rowData[dataKey]).toUTCString();
        const dateStringWithOffset = utcString.replace('GMT', utcOffset);
        setDisplayDate(new Date(dateStringWithOffset));
      } else {
        setDisplayDate(rowData[dataKey]);
      }
    }
    if (props?.defaultDate) {
      setDisplayDate(new Date(props?.defaultDate));
    }
  }, [props?.value, props?.defaultDate, rowData, dataKey]);

  return (
    <Cell {...props}>
      <div id={id ?? uuidV4()} className={`custom-cell-date ${containerClass ?? ""}`} style={style}>
        <DatePicker
          value={displayDate}
          onChange={handleChange}
          oneTap={oneTap}
          cleanable={!!props?.cleanable}
          disabled={disabled}
          shouldDisableDate={props.shouldDisableDate}
          {...container ? {container} : {}}
        />
      </div>
      {showSavingId && (
        <div style={{ width: "115px", paddingBottom: "21px" }}>
          <InlineSaveIndicator showId={`${dataKey}-${rowData?.fieldName}`} showSaving={showSavingId} showSavingDone={showSavingDone} />
        </div>
      )}
    </Cell>
  );
};

const components = {
  InputEditCell,
  InputEditCellInlineSave,
  InputEditCurrencyCell,
  CurrencyCell,
  ToggleCell,
  PercentCell,
  DroplistCell,
  DroplistCellSimple,
  AreaCell,
  CellOverride,
  DateCell,
};
export default components;
