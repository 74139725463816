import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getSection = async ({ id, endpoint = `borrowing-base-criteria-sections/${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Section. Section ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getAllSections = async ({ creditId, endpoint = `borrowing-base-criteria-sections?creditId=${creditId}`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  iStore.tsSections = [...response];
  return iStore.tsSections;
};

const createSection = async ({ body, endpoint = `borrowing-base-criteria-sections`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Section. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllSections({ creditId: body?.creditId });
  return response;
};

const updateSection = async ({ id, body, endpoint = `borrowing-base-criteria-sections/${id}`, method = 'PUT', iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Section. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllSections({ creditId: body?.creditId });
  return response;
};

const deleteSection = async ({ id, endpoint = `borrowing-base-criteria-sections/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Section. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get: ({ id, creditId }) => id ? getSection({ id }) : creditId ? getAllSections({ creditId }) : getAllSections({}),
  create: createSection,
  update: updateSection,
  delete: deleteSection
};

export default endpoints;