import { Table } from "rsuite";
import { useState, useEffect, useCallback } from "react";
import TextInput from "../../components/TextInput";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import api from "../../api";
import Search from "../../components/Search";
import { isEmpty, startCase, orderBy } from "lodash";
import { getTableContainerHeight } from "../../services/tsTables.microservices";
import { InputEditCell, ToggleCell } from "../../components/tableComponents/customCells";
import Toggle from "../../components/ToggleSlider";

const TransactionSummaryFieldNameTable = ({ ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [tableHeight, setTableHeight] = useState(200);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [fieldName, setFieldName] = useState("");
  const [fieldSection, setFieldSection] = useState("");
  const [fieldSort, setFieldSort] = useState();
  const [disabledRow, setDisabledRow] = useState("");
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [textBox, setTextBox] = useState(false);

  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);

  const update = async ({ body, setUpdateActionTriggered, setUpdateActionDone }) => {
    setUpdateActionTriggered(body?.id);
    setDisabledRow(body?.id);
    body.sectionId = body?.sectionId?.id ? body?.sectionId?.id : body?.sectionId;
    const response = await api[props.endpoint].update({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setUpdateActionDone(body?.id);
    setTimeout(() => {
      setUpdateActionDone(false);
      setDisabledRow(false);
      setUpdateActionTriggered(false);
      setNextSort({});
    }, 2000);
    return response;
  };

  const deleteAction = async ({ body }) => {
    const response = await api[props.endpoint].delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setTimeout(() => {
      setDisabledRow(false);
      props.refreshDataset({});
      setResetSearch(true);
    }, 2000);
  };

  const DroplistCell = ({ rowData, dataKey, disabled, sections, ...props }) => {
    const [selectedSection, setSelectedSection] = useState("");
    const handleSectionSelect = (e) => {
      setSelectedSection(e.target.value);
      rowData[dataKey] = sections.find((section) => section?.id === e.target.value);
    };

    useEffect(() => {
      setSelectedSection(rowData[dataKey]);
    }, [rowData, dataKey]);

    return (
      <Cell {...props}>
        <div className="select" style={{ maxHeight: "30px", width: "100%", marginTop: "2px" }}>
          <select
            id="ts-field-section-select"
            className="select-input-row"
            onChange={handleSectionSelect}
            value={selectedSection || 0}
            disabled={disabled === rowData?.id}
          >
            {sections?.map((entry) => (
              <option key={entry?.id} value={entry?.id}>
                {entry?.name}
              </option>
            ))}
          </select>
        </div>
      </Cell>
    );
  };

  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <ApiSubmitButton
            action={() => update({ body: rowData, setUpdateActionTriggered, setUpdateActionDone })}
            className="mr-2 row-action-button"
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
            actionTriggered={updateActionTriggered === rowData?.id}
            actionDone={updateActionDone === rowData?.id}
          />
          <AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
          />
        </div>
      </Cell>
    );
  };

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

  const handleFieldInput = (e) => {
    if (!validateText(e)) return;
    setFieldName(e.target.value);
  };

  const handleNewSectionSelect = (e) => {
    setFieldSection(e.target.value);
  };

  const validateSort = (e) => {
    const regex = /^[0-9 ]+$/; // This regex allows only digits. Due to input type=number, "+" is still allowed, but isn't carried over to the e.target.value.
    return regex.test(e.target.value) && !(Number(e.target.value) < 0 || (e.target.value.trim().length === 0 && e.target.value.length > 0));
  };

  const handleSortInput = (e) => {
    if (!validateSort(e)) return;
    setFieldSort(e.target.value);
  };

  const submit = async () => {
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const response = await api[props.endpoint].create({
      body: { name: fieldName, sectionId: fieldSection, sortOrder: fieldSort, isTextBox: textBox },
    });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setActionDone(true);
    const updatedData = await props.refreshDataset({});
    setActionDone(false);
    setDisableFields(false);
    setSubmitActionTriggered(false);
    setFieldName("");
    setFieldSection(0);
    setNextSort({ updatedData });
    setResetSearch(updatedData);
    return response;
  };

  const setNextSort = useCallback(
    ({ updatedData }) => {
      const dataSource = updatedData ? updatedData : props.dataset;
      const highest = !!dataSource?.length ? dataSource?.reduce((a, b) => ((a.sortOrder || 0) >= (b.sortOrder || 0) ? a : b))?.sortOrder : dataSource;
      setFieldSort(Number(highest) + 1);
    },
    [props.dataset]
  );

  useEffect(() => {
    setTableHeight(getTableContainerHeight({ id: "#ts-field-table-container" }));
    setNextSort({});
  }, [setNextSort]);

  return (
    <div className="custom-flex-container p-3">
      {filtered && <div className="has-text-warning-dark is-capitalized help">Clear search to enable new Section creation</div>}
      <div className="is-flex is-align-items-center">
        <TextInput
          label="New Field"
          value={fieldName || ""}
          maxlength={32}
          onChange={handleFieldInput}
          controlClass="mt-3 is-flex-grow-1"
          disabled={disableFields || filtered}
        />
        <div className="is-flex is-flex-direction-column is-flex-grow-1 ml-3 mb-4">
          <label className="is-size-6">{startCase("section".toLowerCase())}</label>
          <div className="select">
            <select
              id="ts-field-section-select"
              className="select-input"
              onChange={handleNewSectionSelect}
              value={fieldSection}
              disabled={disableFields || filtered}
            >
              <option defaultValue={true} value={0}>
                Choose Section
              </option>
              {props.sections?.map((entry) => (
                <option key={entry?.id} value={entry?.id}>
                  {entry?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <TextInput
          label="global sort"
          value={fieldSort || ""}
          type="number"
          onChange={handleSortInput}
          disabled={disableFields || filtered}
          controlClass="mt-3 ml-3 pb-5"
          style={{ width: "100px" }}
        />
        <div className="is-flex is-flex-direction-column is-align-items-center mx-5" style={{ width: "80px" }}>
          <label className="is-size-6 is-capitalized" style={{ paddingBottom: "9px" }}>
            Text Box
          </label>
          <Toggle className="mb-5" value={textBox} setValue={setTextBox} disabled={disableFields || filtered} cb={setTextBox} />
        </div>
        <ApiSubmitButton
          action={() => submit()}
          className="ml-3 mt-2"
          style={{ height: "40px" }}
          icon={<i className="fa-solid fa-plus" />}
          // used to force correct rendering when parent re-renders
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
          disabled={!fieldName || !fieldSection || fieldSection === "0" || !fieldSort || filtered}
        />
      </div>
      <Search
        className="pt-3"
        dataset={props.dataset}
        setDataset={props.setDataset}
        refreshDataset={props.refreshDataset}
        placeholder="Find Field"
        searchKey="name"
        currentSortKey={sortColumn}
        currentSortDirection={sortType}
        style={{ maxWidth: "510px" }}
        filtered={filtered}
        setFiltered={setFiltered}
        resetSearch={resetSearch}
        setResetSearch={setResetSearch}
      />
      <div id="ts-field-table-container" className="custom-flex-container flex-scroll extend-last-row" style={{ "--lastRowHeight": "200px" }}>
        <Table
          data={props.dataset}
          height={tableHeight}
          sortColumn={sortColumn}
          sortType={sortType}
          rowHeight={65}
          onSortColumn={(sortColumn, sortType) => {
            const handleSort = ({ sortColumn, setSortColumn, sortType, setSortType, ...props }) => {
              props.setLoading(true);
              let ordered = orderBy(
                props?.dataset,
                [
                  (data) => {
                    if (sortColumn === "sectionId") {
                      const section = props.sections.find((section) => section?.id === data[sortColumn]);
                      return section?.name?.toLowerCase();
                    }
                    if (sortColumn === "isTextBox") {
                      return data[sortColumn] ? 1 : 0;
                    }
                    return typeof data[sortColumn] === "string" ? data[sortColumn]?.toLowerCase() : data[sortColumn];
                  },
                ],
                [sortType]
              );

              props.setDataset([...ordered]);

              setTimeout(() => {
                props.setLoading(false);
                setSortColumn(sortColumn);
                setSortType(sortType);
              }, 500);
            };
            handleSort({ sortColumn, setSortColumn, setSortType, sortType, ...props });
          }}
          loading={props.loading}
        >
          <Column flexGrow={1} sortable>
            <HeaderCell>Field Name</HeaderCell>
            <InputEditCell
              dataKey="name"
              disabled={disabledRow}
              setmaxlength={32}
              customValidation={({ val }) => validateText({ target: { value: val } })}
            />
          </Column>

          <Column flexGrow={1} sortable>
            <HeaderCell>Section</HeaderCell>
            <DroplistCell dataKey="sectionId" dataset={props.dataset} disabled={disabledRow} sections={props.sections} />
          </Column>

          <Column width={100} sortable>
            <HeaderCell>Sort Order</HeaderCell>
            <InputEditCell
              dataKey="sortOrder"
              type="number"
              disabled={disabledRow}
              min={0}
              max={9999}
              step={1}
              customValidation={({ val }) => validateSort({ target: { value: val } })}
            />
          </Column>

          <Column flexGrow={1} sortable align="center">
            <HeaderCell>Is Textbox</HeaderCell>
            <ToggleCell className="mb-3" dataKey="isTextBox" disabled={disabledRow} validation={() => true} />
          </Column>

          <Column width={235}>
            <HeaderCell></HeaderCell>
            <ActionsCell dataKey="actions" />
          </Column>
        </Table>
      </div>
    </div>
  );
};

export default TransactionSummaryFieldNameTable;
