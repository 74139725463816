import { Table } from 'rsuite';
import { useState, useEffect, useRef } from 'react';
import TextInput from '../../components/TextInput';
import ApiSubmitButton from '../../components/buttons/ApiSubmitButton';
import AreYouSureDelete from '../../components/buttons/AreYouSureDelete';
import api from '../../api';
import { isEmpty, words, keys, camelCase, orderBy } from 'lodash';
import { InputEditCell, LinkCell } from '../../components/tableComponents/customCells';
import { validateText } from '../../services/microservices';

const DealDocuments = ({ ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [url, setUrl] = useState('');
  const [notes, setNotes] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [tableAttributes, setTableAttributes] = useState({});
  const [tableData, setTableData] = useState([]);

  const detail = useRef({
    name: '',
    date: '',
    url: '',
    notes: ''
  });

  const checkIsValid = async () => {
    await setTimeout(() => {
      const valid = !!detail.current.name && !!detail.current.date && !!detail.current.url;
      setIsValid(valid);
    }, 50);
  };

  const handleInput = ({ e, setFunction }) => {
    let prop = null;
    switch (setFunction) {
      case (setName):
        prop = "name";
        break;
      case (setDate):
        prop = "date";
        break;
      case (setUrl):
        prop = "url";
        break;
      case (setNotes):
        prop = "notes";
        break;
      default:
        break;
    }
    if(!validateText(e) && prop !== "date") return;
    detail.current[prop] = e.target.value;
    setFunction(e.target.value);
    checkIsValid();
  };

  const submit = async ({ id, data, method = 'create' }) => {
    setSubmitActionTriggered(id || true);
    const body = isEmpty(data) ? { creditId: props?.creditId, name, date, url, notes } : data;
    const response = await api[props.tableProps.current?.endpoint][method]({ id: data?.id, body });
    if (!response || isEmpty(response)) {
      console.error('no response from api call');
    }
    setActionDone(id || true);
    setTimeout(() => {
      if (method === 'create') props.refreshDataset({ creditId: props?.creditId });
      setActionDone(false);
      setSubmitActionTriggered(false);
      setName('');
    }, 2000);
    return response;
  };

  const defaultFields = {
    id: "",
    creditId: props?.creditId,
    name: "",
    date: "",
    url: "",
    notes: ""
  };
  const tableColumns = keys(defaultFields);
  const details = useRef(structuredClone({ ...defaultFields }));

  const addToDetails = ({ rowId, data, key }) => {
    const exists = props.tableProps.current?.detailsDataSet?.find(d => d?.id === rowId);
    if (!exists || isEmpty(exists)) {
      const tempBody = {};
      tempBody.id = rowId;
      tempBody[key] = data;
      props.tableProps.current?.detailsDataSet?.push({ ...tempBody });
    } else {
      exists[key] = data;
    }
  };

  const [tableLoading, setTableLoading] = useState(false);

  const clearFields = () => {
    details.current = structuredClone({ ...defaultFields });
  };

  useEffect(() => {
    if (props.tableProps.current?.cancelled) {
      clearFields();
    }
    if (props.edit) {
      tempTableAttributes.rowHeight = 65;
    }
    setTableAttributes(tempTableAttributes);
    setTableData(props.tableProps.current.dataset);
    // eslint-disable-next-line
  }, [props.tableProps.current?.cancelled, props.edit]);

  const deleteAction = async ({ body }) => {
    const response = await api[props.tableProps.current?.endpoint].delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error('no response from api call');
    }
    setTimeout(() => {
      props.refreshDataset({ creditId: props?.creditId });
    }, 2000);
  };

  const [actionWidth, setActionWidth] = useState(false);
  const areYouSureRef = useRef([]);
  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} >
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <ApiSubmitButton
            action={() => submit({ id: rowData?.id, data: rowData, method: 'update' })}
            className="mr-3 mb-3 row-action-button"
            icon={<i className='fa-solid fa-floppy-disk' />}
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered === rowData?.id || (!!areYouSureRef.current.length && !areYouSureRef.current.includes(rowData?.id))}
            actionTriggered={submitActionTriggered === rowData?.id}
            actionDone={actionDone === rowData?.id}
          />
          <AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className='fa-solid fa-trash' />}
            style={{ maxHeight: "30px", marginBottom: '12px' }}
            disabled={submitActionTriggered === rowData?.id || (!!areYouSureRef.current.length && !areYouSureRef.current.includes(rowData?.id))}
            hideOtherElementsCb={setActionWidth}
            cb={() => setActionDone(false)}
            data={rowData?.id}
            areYouSureRef={areYouSureRef}
          />
        </div>
      </Cell>
    )
  };

  const setFieldLengths = ({ field }) => {
    switch (field) {
      case ('name'):
        return 128;
      case ('url'):
        return 256;
      case ('notes'):
        return 500;
      default:
        return null;
    }
  };

  const setFieldWidths = ({ field }) => {
    switch (field) {
      case ('date'):
        return 170;
      default:
        return 300;
    }
  };

  // broke it out so I can dynamically add a height for the field length displays
  const tempTableAttributes = {
    autoHeight: true,
    wordWrap: "break-word",
    loading: tableLoading
  }

  return (
    <div className='custom-flex-container'>
      {(!isEmpty(props.tableProps.current?.dataset) || props.edit) && <label className='is-capitalized has-text-info-dark is-size-5 has-text-weight-bold'>{props.tableProps.current.sectionLabel}</label>}
      {props.edit && <div className='is-flex'>
        <TextInput
          label={`New ${props.tableProps.current.sectionLabel} Name`}
          value={name || ''}
          maxlength={setFieldLengths({ field: 'name' })}
          onChange={(e) => handleInput({ e, setFunction: setName })}
          controlClass="mt-3 is-flex-grow-1"
          disabled={submitActionTriggered === true || !!areYouSureRef.current.length}
        />
        <TextInput
          label="Date"
          value={date || ''}
          type="date"
          onChange={(e) => handleInput({ e, setFunction: setDate })}
          disabled={submitActionTriggered === true || !!areYouSureRef.current.length}
          controlClass="mt-3 mx-3"
          style={{ width: "145px" }}
        />
        <TextInput
          label="Box Link"
          placeholder="https://example.com"
          value={url || ''}
          maxlength={setFieldLengths({ field: 'url' })}
          onChange={(e) => handleInput({ e, setFunction: setUrl })}
          disabled={submitActionTriggered === true || !!areYouSureRef.current.length}
          controlClass="mt-3 is-flex-grow-1 mr-3"
        />
        <TextInput
          label="Notes"
          value={notes || ''}
          maxlength={setFieldLengths({ field: 'notes' })}
          onChange={(e) => handleInput({ e, setFunction: setNotes })}
          disabled={submitActionTriggered === true || !!areYouSureRef.current.length}
          controlClass="mt-3 is-flex-grow-1"
        />
        <ApiSubmitButton
          action={() => submit({})}
          className="mx-3 field-entry-row-submit"
          icon={<i className='fa-solid fa-plus' />}
          // used to force correct rendering when parent re-renders
          disabled={submitActionTriggered === true || !!areYouSureRef.current.length || !isValid}
          actionTriggered={submitActionTriggered === true ? submitActionTriggered : false}
          actionDone={actionDone === true ? submitActionTriggered : false}
        />
      </div>}
      {!!props.tableProps.current?.dataset.length && <div id="covenant-table-container" className={`custom-flex-container flex-scroll p-2 ${props.extendTableHeight ? 'extend-last-row' : ''}`} style={{ "--lastRowHeight": props.extendTableHeight }}>
        <div>
          <div>

            <Table
              data={tableData}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={async (sortColumn, sortType) => {
                const handleSort = async ({ sortColumn, setSortColumn, sortType, setSortType, ...props }) => {
                  const fixedSortColumn = camelCase(sortColumn);
                  setTableLoading(true);
          
                  let ordered = orderBy(
                    props.tableProps?.current?.dataset,
                    [(data) => (typeof data[fixedSortColumn] === "string" ? data[fixedSortColumn]?.toLowerCase() : data[fixedSortColumn])],
                    [sortType]
                  );
          
                  props.tableProps.current.dataset = [...ordered];
                  setTableData([...props.tableProps.current.dataset]);
          
                  setSortColumn(sortColumn);
                  setSortType(sortType);
                };
                await handleSort({ sortColumn, setSortColumn, setSortType, sortType, ...props });
                setTableLoading(false);
              }}
              {...tableAttributes}
            >
              {tableColumns?.map(field => {
                const columnProps = {
                  sortable: true,
                  key: field,
                };
                switch (field) {
                  case ('name'):
                  case ('url'):
                  case ('notes'):
                    columnProps.flexGrow = 1;
                    break;
                  default:
                    columnProps.width = setFieldWidths({ field });
                    break;
                }
                return (
                  field !== 'id' && field !== 'creditId' && <Column {...columnProps}>
                    <HeaderCell className='is-capitalized'>{words(field).join(' ')}</HeaderCell>
                    {props.edit ?
                      <InputEditCell
                        dataKey={field}
                        disabled={submitActionTriggered || !!areYouSureRef.current.length}
                        cb={addToDetails}
                        type={field === 'date' ? 'date' : 'text'}
                        autoComplete='off'
                        setmaxlength={(() => setFieldLengths({ field }))()}
                        parentprops={props.tableProps}
                        customValidation={(e) => {
                          return validateText({ target: { value: e?.val } });
                        }}
                      />
                      : field === 'url' ? <LinkCell dataKey={field} target='_blank' />
                        : <Cell dataKey={field} />}
                  </Column>
                )
              })}
              {props.edit && <Column width={actionWidth ? 235 : 140}>
                <HeaderCell>...</HeaderCell>
                <ActionsCell dataKey="actions" />
              </Column>}
            </Table>
          </div>
        </div>
      </div>}
    </div >
  )
};
export default DealDocuments;