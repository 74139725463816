import { fetchAuthSession } from 'aws-amplify/auth';
import { set, get } from 'lodash';
import store from '../store';
// import config from '../config/appSettings.json';
import awsmobile from '../aws-exports'; 
import credits from './credits';
import tsSections from './transactionSummary/sections';
import tsFields from './transactionSummary/fields';
import tsAmendments from './transactionSummary/transactionSummaryAmendments';
import bbSections from './borrowingBase/sections';
import borrowingBaseReview from './borrowingBase/borrowingBaseReview';
import borrowingBaseReviewData from './borrowingBase/borrowingBaseReviewData';
import asOfDates from './borrowingBase/asOfDates';
import bbCriteria from './borrowingBase/criteria';
import transactionSummary from './transactionSummary/transactionSummary';
import covenants from './transactionSummary/covenants';
import reportingRequirements from './transactionSummary/reportingRequirements';
import columns from './borrowingBase/columns';
import dealDocuments from './transactionSummary/dealDocuments';
import reports from './reports';
import bbAdjustments from './borrowingBase/borrowingBaseAdjustments';
import fundingRequest from './borrowingBase/fundingRequest';
import lqrCriteria from './lqr/lqrCriteria';
import lqrChoices from './lqr/lqrChoices';
import lqrReport from './lqr/lqrReport';
import reportingHistory from './transactionSummary/reportingHistory';

// Authenticated Endpoint
// const _url = `https://${config.apiTestId}.execute-api.us-east-2.amazonaws.com/test/`; //test
const _url = awsmobile.aws_cloud_logic_custom[0].endpoint;

let token;
export async function apiFetch({ endpoint, method = 'GET', fallback, body, skynetUrl }) {
  const url = `${!skynetUrl ? _url : skynetUrl}/${endpoint}`;
  const options = {
    method,
    headers: await getHeaders(),
  }
  if (body) options.body = JSON.stringify(body)
  try {
    const response = await fetch(url, options)
    if (!response.ok) { console.log(response); throw new Error(await response.text()) }

    return response.json()
  } catch (e) {
    const message = typeof e.message === 'string' ? { message: e.message } : JSON.parse(e.message);
    store.actions.handleError(message, store)
    return fallback
  }
};

// this is so the unit tests would be able to load in cypress/docker container inside bitbucket pipelines
export const setTokenForUnitTests = (val) => {
  token = val;
};

export const getHeaders = async () => {
  if (!token?.length) {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    token = get(store,'session.accessToken.jwtToken') ?? accessToken?.toString();
    set(store, 'session.accessToken.jwtToken', token);
  }
  return {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
  }
};

const endpoints = {
  credits,
  tsSections,
  tsFields,
  bbSections,
  bbCriteria,
  columns,
  transactionSummary,
  tsAmendments,
  covenants,
  reportingRequirements,
  borrowingBaseReview,
  borrowingBaseReviewData,
  bbAdjustments,
  fundingRequest,
  asOfDates,
  dealDocuments,
  reports,
  lqrCriteria,
  lqrChoices,
  lqrReport,
  reportingHistory,
};

export default endpoints