import { isEmpty, orderBy } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { Input, InputGroup } from "rsuite";

const typingWatcher = (() => {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

const Search = ({ dataset, placeholder, style, className, resetSearch, setResetSearch, ...props }) => {
  const [query, setQuery] = useState("");
  const originalData = useRef("");

  const handleSearch = ({ val, customSearch, ...props }) => {
    return customSearch ? customSearch() : handleSearchSubmit({ val, ...props });
  };

  const handleSearchSubmit = ({ val, dataset, setDataset, searchKey, currentSortKey, currentSortDirection, setFiltered, ...props }) => {
    if (!val.trim()) {
      return handleClearClick({ setFiltered, ...props });
    }

    const tempResult = originalData.current?.filter((data) => data[searchKey]?.toLowerCase().includes(val?.toLowerCase()));
    if (tempResult && !isEmpty(tempResult)) {
      const result = orderBy(tempResult, [(data) => data[currentSortKey]?.toLowerCase()], [currentSortDirection]);
      setDataset([...result]);
      setFiltered(true);
    }
  };

  const handleClearClick = async ({ refreshDataset, currentSortKey, currentSortDirection, setFiltered }) => {
    setQuery("");
    if(originalData.current !== dataset) {
      await refreshDataset({ sortColumn: currentSortKey, sortType: currentSortDirection });
    }
    setFiltered(false);
    setResetSearch(false);
    originalData.current = resetSearch;
  };

  useEffect(() => {
    if (!originalData.current?.length && !resetSearch?.length) {
      originalData.current = structuredClone([...dataset]);
      setResetSearch(false);
    } else if (resetSearch) {
      handleClearClick({ ...props });
    }
    // eslint-disable-next-line
  }, [dataset, resetSearch]);

  return (
    <span className={`search-input-group ${className ? className : ""}`} style={style}>
      <InputGroup size="sm">
        <Input
          placeholder={placeholder}
          value={query}
          onChange={(val) => {
            setQuery(val);
            typingWatcher(() => handleSearch({ val, dataset, ...props }), 1000);
          }}
        />
        <InputGroup.Button onClick={() => handleClearClick({ ...props })}>
          <span className="icon">
            <i className={`fas fa-${query ? "close" : "search"}`} aria-hidden="true"></i>
          </span>
        </InputGroup.Button>
      </InputGroup>
    </span>
  );
};

export default Search;
