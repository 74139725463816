import { apiFetch } from "..";
import store from "../../store";
import { isEmpty } from "lodash";
import { convertTimeToLocal } from "../../services/microservices";

const listReportsByCreditId = async ({ creditId, endpoint = `lqr-reports?creditId=${creditId}`, iStore = store }) => {
  if (!creditId) {
    const message = `Unable to get reports. creditId ${creditId} is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return [];
  return response;
};

const getReportById = async ({ id, endpoint = `lqr-reports/${id}?fullyNested=true`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get report. id ${id} is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return {};
  if (response?.submittedDate) {
    response.submittedDate = {
      date: response.submittedDate ? response.submittedDate : new Date(),
      displayDate: convertTimeToLocal({ date: response.submittedDate ? new Date(response.submittedDate) : new Date() }),
    };
  }
  if (response?.reviewedDate) {
    response.reviewedDate = {
      date: response.reviewedDate ? new Date(response.reviewedDate) : new Date(),
      displayDate: convertTimeToLocal({ date: response.reviewedDate ? new Date(response.reviewedDate) : new Date() }),
    };
  }
  return response;
};

const getLqrSummary = async ({ endpoint = `portfolio-summary-reports`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return {};
  return response;
};

const createReport = async ({ body, endpoint = `lqr-reports`, method = "POST", iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Field. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return {};
  if (response?.submittedDate) {
    response.submittedDate = {
      date: response.submittedDate ? response.submittedDate : new Date(),
      displayDate: convertTimeToLocal({ date: response.submittedDate ? new Date(response.submittedDate) : new Date() }),
    };
  }
  if (response?.reviewedDate) {
    response.reviewedDate = {
      date: response.reviewedDate ? new Date(response.reviewedDate) : new Date(),
      displayDate: convertTimeToLocal({ date: response.reviewedDate ? new Date(response.reviewedDate) : new Date() }),
    };
  }
  return response;
};

const updateResponses = async ({ id, body, endpoint = `lqr-report-responses/${id}`, method = "PUT", iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Field. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const updateReport = async ({ id, body, endpoint = `lqr-reports/${id}`, method = "PUT", iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Field. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  if (response?.submittedDate) {
    response.submittedDate = {
      date: response.submittedDate ? response.submittedDate : new Date(),
      displayDate: convertTimeToLocal({ date: response.submittedDate ? new Date(response.submittedDate) : new Date() }),
    };
  }
  if (response?.reviewedDate) {
    response.reviewedDate = {
      date: response.reviewedDate ? new Date(response.reviewedDate) : new Date(),
      displayDate: convertTimeToLocal({ date: response.reviewedDate ? new Date(response.reviewedDate) : new Date() }),
    };
  }
  return response;
};

const deleteReport = async ({ id, endpoint = `lqr-reports/${id}`, method = "DELETE", iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Field. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get({ id, creditId }) {
    if (id) {
      return getReportById({ id });
    }
    if (creditId) {
      return listReportsByCreditId({ creditId });
    }
  },
  create: createReport,
  update: updateReport,
  delete: deleteReport,
  updateResponses,
  getLqrSummary,
};

export default endpoints;
