import { apiFetch } from '..';
import { isEmpty } from 'lodash';

const updateTransactionSummary = async ({ id, body, endpoint = `transaction-summaries/${id}`, method = 'PUT' }) => {
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  update: updateTransactionSummary
};

export default endpoints;