import { apiFetch } from "..";
import store from "../../store";

const getReportingHistoryByCredit = async ({ creditId, endpoint = `reporting-history?creditId=${creditId}`, iStore = store }) => {
  if (!creditId) {
    const message = `Unable to get report history. creditId is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response) {
    const message = "no response from reporting-history api call";
    console.error(message);
    return message;
  }
  return response;
};

const create = async ({ body, endpoint = `reporting-history`, iStore = store, method = "POST" }) => {
  if (!body) {
    const message = `Unable to create report history. required parameter is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint, body, method });
  if (!response) {
    const message = "no response from reporting-history api call";
    console.error(message);
    return message;
  }
  return response;
};

const update = async ({ id, body, endpoint = `reporting-history/${id}`, iStore = store, method = "PUT" }) => {
  if (!id || !body) {
    const message = `Unable to update report history. required parameter is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  console.log("id and body", { id, body });
  const response = await apiFetch({ id, endpoint, body, method });
  if (!response) {
    const message = "no response from reporting-history api call";
    console.error(message);
    return message;
  }
  return response;
};

const getPeriods = async ({id, endpoint = `reporting-periods?requirementId=${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get report history periods. required parameter is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response) {
    const message = "no response from reporting-history api call";
    console.error(message);
    return message;
  }
  return response;
};

const endpoints = {
  get: getReportingHistoryByCredit,
  create,
  update,
  getPeriods,
};
export default endpoints;
