import { apiFetch } from "..";
import store from "../../store";
import { isEmpty } from "lodash";

const listCriteria = async ({ endpoint = `lqr-criteria?includeChoices=true`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return [];
  return response;
};

const createCriteria = async ({ body, endpoint = `lqr-criteria`, method = "POST", iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Field. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const updateField = async ({ id, body, endpoint = `lqr-criteria/${id}`, method = "PUT", iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Field. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const deleteField = async ({ id, endpoint = `lqr-criteria/${id}`, method = "DELETE", iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Field. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get: listCriteria,
  create: createCriteria,
  update: updateField,
  delete: deleteField,
};

export default endpoints;
