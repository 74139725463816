import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getBbReviewByReviewId = async ({ id, endpoint = `borrowing-base-reviews/${id}?includeReviewData=true`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Borrowing Base Review. id (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getBbReviewByCreditId = async ({ creditId, endpoint = `borrowing-base-reviews?creditId=${creditId}`, iStore = store }) => {
  if (!creditId) {
    const message = `Unable to get this Borrowing Base Review. creditId (${creditId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getBbReviewBySectionId = async ({ sectionId, endpoint = `borrowing-base-reviews?sectionId=${sectionId}`, iStore = store }) => {
  if (!sectionId) {
    const message = `Unable to get this Borrowing Base Review. sectionId (${sectionId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const createBorrowingBaseReview = async ({ body, endpoint = `borrowing-base-reviews`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Borrowing Base Review. body (${body}) is undefined.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, method, endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const bbReviewUpdate = async ({ id, body, endpoint = `borrowing-base-reviews/${id}`, method = 'PUT', iStore = store }) => {
  if (!id) {
    const message = `Unable to update this Borrowing Base Review. id (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, method, endpoint, body });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get: ({ creditId, sectionId }) => creditId ? getBbReviewByCreditId({ creditId }) : getBbReviewBySectionId({ sectionId }),
  getById: getBbReviewByReviewId,
  create: createBorrowingBaseReview,
  update: bbReviewUpdate
};

export default endpoints;