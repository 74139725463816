import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Checkbox } from "primereact/checkbox";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function Layout(props) {
  return (
    <div
      className={
        "layout-container layout-colorscheme-menu layout-static " +
        (typeof props.menuVisible !== "undefined" && props.menuVisible === false
          ? "layout-static-inactive "
          : getWindowDimensions().width < 992
          ? "layout-mobile-active "
          : "") +
        (props.className ?? "")
      }
    >
      {props.children}
      <div className="layout-mask" onClick={props.hideMenu}></div>
    </div>
  );
}

export function LayoutLogo({onClick, alt, env}) {
  return (
    <div className="app-logo" onClick={onClick}>
      <span className="logo app-logo-normal">{alt}</span>
      {env && env.toLowerCase() !== 'prod' && <span className={'app-logo-beta env-'+env.toLowerCase()}>{env.toUpperCase()}</span>}
    </div>
  );
}

export function LayoutSiteTitle(props) {
  return (
    <div className="layout-menu">
      <div className="layout-root-menuitem">
        <div className="layout-root-menuitem active-menuitem">
          <div className="layout-menuitem-root-text">
            <span style={{ display: "flex", alignItems: "center" }}>
              <span>{props.siteTitle}</span>
              <span>{props.siteDescription}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function LayoutSidebar(props) {
  return <div className={"layout-sidebar " + (props.className ?? "")}>{props.children}</div>;
}

export function LayoutSidebarHeader(props) {
  return <div className={"sidebar-header " + (props.className ?? "")}>{props.children}</div>;
}

export function LayoutMenu(props) {
  return (
    <div className="layout-menu-container">
      <ul className={"layout-menu " + (props.className ?? "")}>{props.children}</ul>
    </div>
  );
}

export function LayoutMenuSection(props) {
  return (
    <li className={"layout-root-menuitem " + (props.className ?? "") + (props.active ? " active-menuitem" : "")}>
      <div className="layout-menuitem-root-text flex align-items-center" onClick={props.onClick} style={props.onClick ? { cursor: "pointer" } : {}}>
        {props.icon && <i className={`${props.icon} mr-2`} />}
        {props.label}
      </div>
      <ul>{props.children}</ul>
    </li>
  );
}

export function LayoutMenuItem(props) {
  return (
    <li className={" " + (props.className ?? "") + (props.active ? " active-menuitem" : "")}>
      <a href="#/" className={"p-ripple tooltip-target" + (props.active ? " active-route" : "")} onClick={props.onClick}>
        <i className={`layout-menuitem-icon ${props.icon ?? "pi pi-book"}`} />
        <span className="layout-menuitem-text">{props.label}</span>
        {props.children}
      </a>
    </li>
  );
}

export function LayoutContentWrapper(props) {

  const ref = useRef();
  const anchor = useRef();
  const observer = useRef();

  useEffect(() => {
    // This is used when a page is anchored to the bottom of the screen, such as a chat thread

    if (!ref.current) return;

    ref.current.scroll(0, 1);

    observer.current = new ResizeObserver(entries => {
      if (anchor.current) window.dispatchEvent(new CustomEvent("anchor", { detail: anchor.current }));
    });

    observer.current.observe(ref.current);
  }, [ref]);

  return <div ref={ref} className={"layout-content-wrapper " + (props.className ?? "") + (props.anchored && "anchored")}>
    {props.children}
    <div ref={anchor}></div>
  </div>;
}

export function LayoutContent(props) {
  return <div className={"layout-content " + (props.className ?? "")}>{props.children}</div>;
}

export function Topbar(props) {
  return <div className={"layout-topbar " + (props.className ?? "")}>{props.children}</div>;
}

export function TopbarStart(props) {
  return <div className={"topbar-start " + (props.className ?? "")}>{props.children}</div>;
}

export function TopbarEnd(props) {
  return <div className={"topbar-end " + (props.className ?? "")}>{props.children}</div>;
}

export function TopbarBreadcrumbs(props) {
  return (
    <div className={"topbar-breadcrumb " + (props.className ?? "")}>
      <nav className="layout-breadcrumb">
        <ol>{props.children}</ol>
      </nav>
    </div>
  );
}

export function TopbarBreadcrumb(props) {
  return (
    <li className={"topbar-end " + (props.className ?? "")} onClick={props.onClick}>
      {props.children}
    </li>
  );
}

export const PrimeLoader = ({ message, className }) => {
  return (
    <span className={`flex align-items-center ${className ?? ""}`} style={{ color: "var(--text-color-secondary)" }}>
      <span className="loader mr-2"></span>
      <span className="help">{message}</span>
    </span>
  );
};

export const MultiSelectAllHeader = ({ selected, setSelected, dataset1, dataset2, setDataset1, cb }) => {
  // dataset1 represents visibleColumns
  // dataset2 represents allColumns
  // selected and setSelected need to be defined at the parent level to handle single option selection callbacks to setSelected
  const handleCheckAll = (e) => {
    selected === false ? setDataset1(dataset2) : setDataset1([]);
    setSelected(!selected);
    e.preventDefault();
    if (cb) {
      cb();
    }
  };
  return (
    <div className="p-3" onClick={handleCheckAll} style={{ cursor: "pointer" }}>
      <Checkbox inputId="columns-all" name="category" value={selected ?? false} checked={dataset1?.length === dataset2?.length} />
      <label htmlFor="columns-all" className="ml-2" style={{ cursor: "pointer" }}>
        Select All
      </label>
    </div>
  );
};

export const exportPdf = ({ fileName, setState = () => null }) => {
  setState(true);
  setTimeout(() => {
    const doc = new jsPDF({ orientation: "l", unit: "pt", compress: true });

    autoTable(doc, {
      html: ".p-datatable-table",
      // tableWidth: "wrap",
      horizontalPageBreak: true,
      horizontalPageBreakRepeat: 0,
    });
    doc.save(`${fileName}.pdf`);
    setState(false);
  }, 1000);
};
