import { Table } from "rsuite";
import { useState, useEffect, useCallback } from "react";
import TextInput from "../../components/TextInput";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import api from "../../api";
import Search from "../../components/Search";
import { isEmpty, orderBy, camelCase } from "lodash";
import { getTableContainerHeight } from "../../services/tsTables.microservices";
import { InputEditCell, ToggleCell } from "../../components/tableComponents/customCells";
import Toggle from "../../components/ToggleSlider";

const BorrowingBaseCriteriaTable = ({ creditId, ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [tableHeight, setTableHeight] = useState(200);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [criteria, setCriteria] = useState("");
  const [columnName, setColumnName] = useState("");
  const [criteriaSection, setCriteriaSection] = useState("");
  const [isSummary, setIsSummary] = useState(false);
  const [isPercent, setIsPercent] = useState(false);
  const [criteriaSort, setCriteriaSort] = useState();
  const [disabledRow, setDisabledRow] = useState("");
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [updateRow, setUpdateRow] = useState(false);
  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);

  const update = async ({ body, setUpdateActionTriggered, setUpdateActionDone }) => {
    setUpdateActionTriggered(body?.id);
    setDisabledRow(body?.id);
    if (typeof body?.sectionId !== "string" && body?.sectionId?.id) {
      body.sectionId = body?.sectionId?.id;
    }
    const response = await api[props.endpoint].update({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setUpdateActionDone(body?.id);
    setTimeout(() => {
      setUpdateActionDone(false);
      setDisabledRow(false);
      setUpdateActionTriggered(false);
      setNextSort({});
    }, 2000);
    return response;
  };

  const deleteAction = async ({ body }) => {
    const response = await api[props.endpoint].delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setTimeout(() => {
      setDisabledRow(false);
      props.refreshDataset({ creditId });
      setResetSearch(true);
    }, 2000);
  };

  const DroplistCell = ({ rowData, dataKey, disabled, dataset, updateRow, setUpdateRow, ...props }) => {
    const [selected, setSelected] = useState("");
    const handleSelection = (e) => {
      if (dataKey === "sectionId") {
        const section = dataset?.find((section) => section?.id === e.target.value);
        rowData.isSummary = section?.name === "Borrowing Base Summary";
        setUpdateRow(!updateRow);
      }
      setSelected(e.target.value);
      rowData[dataKey] = e.target.value;
    };

    useEffect(() => {
      setSelected(rowData[dataKey]);
    }, [rowData, dataKey]);

    return (
      <Cell {...props}>
        <div className="select" style={{ maxHeight: "30px", width: "100%", marginTop: "2px" }}>
          <select id="bb-criteria-section-select" className="select-input-row" onChange={handleSelection} value={selected || 0} disabled={disabled}>
            {dataset?.map((entry) => (
              <option key={entry?.id || entry} value={entry?.id || entry}>
                {entry?.name || entry}
              </option>
            ))}
          </select>
        </div>
      </Cell>
    );
  };

  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <ApiSubmitButton
            action={() => update({ body: rowData, setUpdateActionTriggered, setUpdateActionDone })}
            className="mr-2 row-action-button"
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered || updateActionTriggered}
            actionTriggered={updateActionTriggered === rowData?.id}
            actionDone={updateActionDone === rowData?.id}
          />
          <AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={submitActionTriggered || updateActionTriggered}
          />
        </div>
      </Cell>
    );
  };

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

  const handleFieldInput = (e) => {
    if (!validateText(e)) return;
    setCriteria(e.target.value);
  };

  const handleNewSectionSelect = (e) => {
    const section = props.sections?.find((section) => section?.id === e.target.value);
    setIsSummary(section?.name === "Borrowing Base Summary");
    setCriteriaSection(e.target.value);
  };

  const handleNewColumnNameSelect = (e) => {
    setColumnName(e.target.value);
  };

  const validateSort = (e) => {
    const regex = /^[0-9 ]+$/; // This regex allows only digits. Due to input type=number, "+" is still allowed, but isn't carried over to the e.target.value.
    return regex.test(e.target.value) && !(Number(e.target.value) < 0 || (e.target.value.trim().length === 0 && e.target.value.length > 0));
  };

  const handleSortInput = (e) => {
    if (!validateSort(e)) return;
    setCriteriaSort(e.target.value);
  };

  const submit = async () => {
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const response = await api[props.endpoint].create({
      body: {
        name: criteria,
        viewColumnName: columnName,
        sectionId: !criteriaSection ? null : criteriaSection,
        isSummary,
        isPercent,
        creditId,
        sortOrder: criteriaSort,
      },
    });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setActionDone(true);
    const updatedData = await props.refreshDataset({ creditId });
    setActionDone(false);
    setDisableFields(false);
    setSubmitActionTriggered(false);
    setCriteria("");
    setCriteriaSection(0);
    setNextSort({ updatedData });
    resetFields();
    setResetSearch(updatedData);
    return response;
  };

  const resetFields = () => {
    setCriteria("");
    setColumnName("0");
    setCriteriaSection("0");
    setIsSummary(false);
    setIsPercent(false);
  };

  const setNextSort = useCallback(
    ({ updatedData }) => {
      const dataSource = updatedData ? updatedData : props.dataset;
      if (dataSource?.length) {
        const highest = dataSource?.reduce((a, b) => ((a.sortOrder || 0) >= (b.sortOrder || 0) ? a : b))?.sortOrder;
        setCriteriaSort(Number(highest) + 1);
      }
    },
    [props.dataset]
  );

  const rowToggleValidation = ({ rowData }) => {
    const rowSection = props.sections?.find((section) => section?.id === rowData?.sectionId);
    return rowSection?.name !== "Borrowing Base Summary";
  };

  useEffect(() => {
    setTableHeight(getTableContainerHeight({ id: "#bb-criteria-table-container" }));
    setNextSort({});
  }, [setNextSort]);

  return (
    <div className="custom-flex-container p-3">
      {filtered && <div className="has-text-warning-dark is-capitalized help">Clear search to enable new Section creation</div>}
      <div className="is-flex is-align-items-center">
        <TextInput
          label="New Criteria"
          value={criteria || ""}
          maxlength={128}
          onChange={handleFieldInput}
          controlClass="mt-3 is-flex-grow-1"
          disabled={disableFields || filtered}
        />
        <div className="is-flex is-flex-direction-column is-flex-grow-1 ml-3 mb-4">
          <label className="is-size-6">Column Name Map</label>
          <div className="select">
            <select
              id="bb-criteria-section-select"
              className="select-input"
              onChange={handleNewColumnNameSelect}
              value={columnName}
              disabled={disableFields || filtered}
            >
              <option defaultValue={true} value={0}>
                Choose Column Name
              </option>
              {props.columnNames?.map((entry) => (
                <option key={entry} value={entry}>
                  {entry}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-flex-grow-1 ml-3 mb-4">
          <label className="is-size-6 is-capitalized">criteria section</label>
          <div className="select">
            <select
              id="bb-criteria-section-select"
              className="select-input"
              onChange={handleNewSectionSelect}
              value={criteriaSection}
              disabled={disableFields || filtered}
            >
              <option defaultValue={true} value={0}>
                Choose Section
              </option>
              {props.sections?.map((entry) => (
                <option key={entry?.id} value={entry?.id}>
                  {entry?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-center mx-5" style={{ width: "135px" }}>
          <label className="is-size-6 is-capitalized" style={{ paddingBottom: "9px" }}>
            Summary Criteria
          </label>
          <Toggle
            className="mb-5"
            value={isSummary}
            setValue={setIsSummary}
            disabled={
              props.sections?.find((section) => section?.id === criteriaSection)?.name === "Borrowing Base Summary" ||
              criteriaSection === "0" ||
              !criteriaSection
            }
            cb={setIsSummary}
          />
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-center mx-5" style={{ width: "135px" }}>
          <label className="is-size-6 is-capitalized" style={{ paddingBottom: "9px" }}>
            Calc. By Percent
          </label>
          <Toggle className="mb-5" value={isPercent} setValue={setIsPercent} disabled={disableFields || filtered} cb={setIsPercent} />
        </div>
        <TextInput
          label="global sort"
          value={criteriaSort || ""}
          type="number"
          onChange={handleSortInput}
          disabled={disableFields || filtered}
          controlClass="mt-3 ml-3 pb-5"
          style={{ width: "100px" }}
        />
        <ApiSubmitButton
          action={() => submit()}
          className="ml-3 mt-2"
          style={{ height: "40px"}}
          icon={<i className="fa-solid fa-plus" />}
          // used to force correct rendering when parent re-renders
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
          disabled={!criteria || ((!criteriaSection || criteriaSection === "0") && !isSummary) || !criteriaSort}
        />
      </div>
      <Search
        className="pt-3"
        dataset={props.dataset}
        setDataset={props.setDataset}
        refreshDataset={props.refreshDataset}
        placeholder="Find Criteria"
        searchKey="name"
        currentSortKey={sortColumn}
        currentSortDirection={sortType}
        style={{ maxWidth: "510px" }}
        filtered={filtered}
        setFiltered={setFiltered}
        resetSearch={resetSearch}
        setResetSearch={setResetSearch}
      />
      <div id="bb-criteria-table-container" className="custom-flex-container flex-scroll extend-last-row" style={{ "--lastRowHeight": "200px" }}>
        <Table
          data={props.dataset}
          height={tableHeight}
          rowHeight={65}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={(sortColumn, sortType) => {
            const fixedSortColumn = camelCase(sortColumn);
            props.setLoading(true);
            props.dataset = orderBy(
              props?.dataset,
              [(data) => {
                if(sortColumn === "sectionId"){
                  const section = props.sections?.find((section) => section?.id === data[fixedSortColumn]);
                  return section?.name?.toLowerCase();
                }
                if(sortColumn === "isPercent"){
                  return data[fixedSortColumn] ? 1 : 0;
                }
                return typeof data[fixedSortColumn] === "string" ? data[fixedSortColumn].toLowerCase() : data[fixedSortColumn];
              }],
              [sortType]
            );
            props.setDataset(props.dataset);
            setSortColumn(fixedSortColumn);
            setSortType(sortType);
            props.setLoading(false);
          }}
          loading={props.loading}
        >
          <Column flexGrow={1} sortable>
            <HeaderCell>Criteria Name</HeaderCell>
            <InputEditCell
              dataKey="name"
              disabled={disabledRow}
              setmaxlength={128}
              customValidation={({ val }) => validateText({ target: { value: val } })}
            />
          </Column>

          <Column flexGrow={1} sortable>
            <HeaderCell>Column Name Map</HeaderCell>
            <DroplistCell dataKey="viewColumnName" disabled={disabledRow} dataset={props.columnNames} />
          </Column>

          <Column flexGrow={1} sortable>
            <HeaderCell>Criteria Section</HeaderCell>
            <DroplistCell dataKey="sectionId" disabled={disabledRow} dataset={props.sections} updateRow={updateRow} setUpdateRow={setUpdateRow} />
          </Column>

          <Column flexGrow={1} sortable align="center">
            <HeaderCell>Is Summary Criteria</HeaderCell>
            <ToggleCell className="mb-3" dataKey="isSummary" validation={rowToggleValidation} disabled={disabledRow} updateRow={updateRow} />
          </Column>

          <Column flexGrow={1} sortable align="center">
            <HeaderCell>Is Percent</HeaderCell>
            <ToggleCell className="mb-3" dataKey="isPercent" validation={() => true} disabled={disabledRow} updateRow={updateRow} />
          </Column>

          <Column width={100} sortable>
            <HeaderCell>Sort Order</HeaderCell>
            <InputEditCell
              dataKey="sortOrder"
              type="number"
              disabled={disabledRow}
              min={0}
              max={9999}
              step={1}
              customValidation={({ val }) => validateSort({ target: { value: val } })}
            />
          </Column>

          <Column width={235}>
            <HeaderCell></HeaderCell>
            <ActionsCell dataKey="actions" />
          </Column>
        </Table>
      </div>
    </div>
  );
};

export default BorrowingBaseCriteriaTable;
