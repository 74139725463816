import PageTemplate from "../PageTemplate";
import TransactionSummarySectionsTable from "../admin/TransactionSummarySectionsTable";
import TransactionSummaryFieldNameTable from "../admin/TransactionSummaryFieldNameTable";
import ReportingRequirements from "../transactionSummary/ReportingRequirements";
import { useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { orderBy } from "lodash";
import api from "../../api";

const Content = ({ sectionProps, fieldProps, creditProps }) => {
  const [tab, setTab] = useState("tsSections");
  const tabs = ["tsSections", "tsFieldNames", "reportingRequirements"];

  const handleTabClick = (t) => {
    switch (t) {
      case tabs[1]:
        if (!!fieldProps?.dataset) {
          setTab(t);
        }
        break;
      case tabs[2]:
        if (!!creditProps?.dataset) {
          setTab(t);
        }
        break;
      case tabs[0]:
      default:
        setTab(t);
        break;
    }
  };

  return (
    <div className="custom-flex-container">
      <div className="is-flex is-align-items-flex-end pl-3">
        {tabs.map((t) => (
          <div onClick={() => handleTabClick(t)} key={uuidV4()}>
            {t === tabs[0] && (
              <div className={`tab ${tab === t ? "has-background-link has-text-white" : ""} ${sectionProps?.dataset ? "" : "custom-disabled"}`}>
                Sections
              </div>
            )}
            {t === tabs[1] && (
              <div className={`tab ${tab === t ? "has-background-link has-text-white" : ""} ${fieldProps?.dataset ? "" : "custom-disabled"}`}>
                Field Names
              </div>
            )}
            {t === tabs[2] && (
              <div className={`tab ${tab === t ? "has-background-link has-text-white" : ""} ${creditProps?.dataset ? "" : "custom-disabled"}`}>
                Reporting Requirements
              </div>
            )}
          </div>
        ))}
      </div>
      <div id="transaction-summary-tab-container" className="custom-flex-container">
        {tab === tabs[0] && sectionProps?.dataset && <TransactionSummarySectionsTable {...sectionProps} />}
        {tab === tabs[1] && fieldProps?.dataset && <TransactionSummaryFieldNameTable {...fieldProps} />}
        {tab === tabs[2] && creditProps?.dataset && <ReportingRequirements {...creditProps} />}
      </div>
    </div>
  );
};

const TransactionSummaryAdmin = () => {
  const [sections, setSections] = useState(null);
  const [sectionsLoading, setSectionsLoading] = useState(false);
  const [tsFieldNames, setTsFieldNames] = useState(null);
  const [fieldNamesLoading, setFieldNamesLoading] = useState(false);
  const [credits, setCredits] = useState(null);
  const [creditsLoading, setCreditsLoading] = useState(false);

  const getCredits = async () => {
    setCreditsLoading(true);
    let response = await api.credits.get({});
    if (!response) return [];
    response = orderBy(response, [(item) => (typeof item?.name === "string" ? item?.name?.toLowerCase() : item?.name)], ["asc"]);
    setCredits([...response]);
    setCreditsLoading(false);
    return response;
  };

  const getSections = async ({ sortColumn, sortType }) => {
    setSectionsLoading(true);
    const response = await api.tsSections.get({});
    const ordered = orderBy(
      response,
      [(data) => (data[sortColumn] && typeof data[sortColumn] === "string" ? data[sortColumn]?.toLowerCase() : data?.sortOrder)],
      [sortType ? sortType : "asc"]
    );
    setSections(ordered);
    setSectionsLoading(false);
    return ordered;
  };

  const getFieldNames = async ({ sortColumn, sortType }) => {
    setFieldNamesLoading(true);
    const response = await api.tsFields.get({});
    const ordered = orderBy(
      response,
      [(data) => (data[sortColumn] && typeof data[sortColumn] === "string" ? data[sortColumn]?.toLowerCase() : data?.sortOrder)],
      [sortType ? sortType : "asc"]
    );
    setTsFieldNames(ordered);
    setFieldNamesLoading(false);
    return ordered;
  };

  const setup = async () => {
    await getSections({});
    getFieldNames({});
    getCredits();
  };

  const creditProps = {
    dataset: credits,
    setDataset: setCredits,
    refreshDataset: getCredits,
    loading: creditsLoading,
    setLoading: setCreditsLoading,
    endpoint: "credits",
  };

  // Tab View's props mapping

  const sectionProps = {
    dataset: sections,
    setDataset: setSections,
    refreshDataset: getSections,
    loading: sectionsLoading,
    setLoading: setSectionsLoading,
    endpoint: "tsSections",
  };

  const fieldProps = {
    dataset: tsFieldNames,
    sections: sections,
    setDataset: setTsFieldNames,
    refreshDataset: getFieldNames,
    loading: fieldNamesLoading,
    setLoading: setFieldNamesLoading,
    endpoint: "tsFields",
  };

  return (
    <PageTemplate
      id="transaction-summary-admin"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Transaction Summary Admin"
      defaultOpenMenus={["2"]}
      setup={setup}
      content={<Content sectionProps={sectionProps} fieldProps={fieldProps} creditProps={creditProps} />}
    />
  );
};
export default TransactionSummaryAdmin;
