import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getField = async ({ id, endpoint = `transaction-summary-fields/${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Field. Field ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getAllFields = async ({ endpoint = `transaction-summary-fields`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  iStore.tsFields = [...response];
  return iStore.tsFields;
};

const createField = async ({ body, endpoint = `transaction-summary-fields`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Field. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllFields({});
  return response;
};

const updateField = async ({ id, body, endpoint = `transaction-summary-fields/${id}`, method = 'PUT', iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Field. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllFields({});
  return response;
};

const deleteField = async ({ id, endpoint = `transaction-summary-fields/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Field. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllFields({});
  return response;
};

const endpoints = {
  get: ({ id, endpoint }) => id ? getField({ id, endpoint }) : getAllFields({endpoint}),
  create: createField,
  update: updateField,
  delete: deleteField
};

export default endpoints;