import PageTemplate from "../PageTemplate";
import CreditTable from "./CreditTable";
import { useState, useRef } from "react";
import { orderBy, isEmpty } from "lodash";
import api from "../../api";
import store from "../../store";
import skynet from "../../api/skynetData";

const Content = ({ creditProps }) => {
  return (
    <div className="custom-flex-container">
      <div id="transaction-summary-tab-container" className="custom-flex-container">
        <CreditTable {...creditProps} />
      </div>
    </div>
  );
};

const CreditAdmin = () => {
  const [credits, setCredits] = useState([]);
  const [creditsLoading, setCreditsLoading] = useState(false);
  const persons = useRef([]);

  const getCredits = async ({ sortColumn, sortType }) => {
    setCreditsLoading(true);
    const response = await api.credits.get({});
    const ordered = orderBy(
      response,
      [(data) => (data[sortColumn] ? data[sortColumn]?.toLowerCase() : data?.name?.toLowerCase())],
      [sortType ? sortType : "asc"]
    );
    setCredits(ordered);
    return ordered;
  };
  
  const setup = async () => {
    const endpoints = [getCredits({})];
    if (!store?.skynetPersonsList || isEmpty(store?.skynetPersonsList)) {
      endpoints.push(skynet.persons.get({}));
    }
    const [credits] = await Promise.all(endpoints);
    const creditPersons = store.skynetPersonsList?.filter((person) => person?.department?.toLowerCase()?.includes("credit"));
    persons.current = structuredClone([...creditPersons]);
    setCreditsLoading(false);
    return credits;
  };

  // Tab View's props mapping
  const creditProps = {
    dataset: credits,
    setDataset: setCredits,
    refreshDataset: setup,
    loading: creditsLoading,
    setLoading: setCreditsLoading,
    endpoint: "credits",
    persons: persons.current,
  };

  return (
    <PageTemplate
      id="credit-admin"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Credit Admin"
      defaultOpenMenus={["2"]}
      setup={setup}
      content={<Content creditProps={creditProps} />}
    />
  );
};
export default CreditAdmin;
