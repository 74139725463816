import { useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { startCase } from "lodash";

export const CustomSelect = ({ defaultText, onChange, disabled, data, rows, id, value }) => {
  const [show, setShow] = useState(false);

  const standardStyle = {
    maxHeight: "36px",
    fontSize: "14px",
    border: "1px solid #d7d7d7",
    padding: "5px 2rem 5px 1rem",
    borderRadius: "3px",
    zIndex: 10,
    boxShadow: "0px 2px 4px rgba($color: #d0d0d0, $alpha: 1.0)",
    cursor: "pointer",
  };

  const disabledStyle = {
    border: "1px solid #d7d7d7",
    padding: "5px 2rem 5px 1rem",
    borderRadius: "3px",
    maxHeight: "36px",
    fontSize: "14px",
    cursor: "not-allowed",
    backgroundColor: "rgb(248 248 248)",
    color: "#a5a3a3",
  };

  let showClickHandled = false; // for debouncing

  const handleShow = () => {
    if (disabled) return;
    if (!showClickHandled) {
      showClickHandled = true;
      const clickWatcher = (event) => {
        event.preventDefault();
        setShow(false);
        document.removeEventListener("click", clickWatcher);
      };
      setTimeout(() => {
        setShow(!show);
        if (!show) {
          document.addEventListener("click", clickWatcher);
        }
      }, 10);
    }
  };

  return (
    <div className="select mr-4" style={{ maxHeight: "36px" }}>
      <div
        id={`${id}-custom-select-componant`}
        className="is-relative prevent-text-select"
        onClick={handleShow}
        style={disabled ? disabledStyle : standardStyle}
      >
        <div onClick={handleShow}>
          <div>{defaultText}</div>
        </div>
        {show && (
          <div className="is-absolute custom-select-contents">
            {data?.map((entry) => {
              return (
                <div
                  key={entry?.id || value || uuidV4()}
                  className="is-flex is-flex-direction-column custom-select-entry"
                  style={{ borderBottom: "1px solid #d7d7d7", padding: ".5rem" }}
                  onClick={() => onChange(entry?.id || value)}
                >
                  {rows?.map((label) => {
                    if (typeof label !== "string" && !Array.isArray(label) && typeof label === "object") {
                      if (label?.action) {
                        return <div key={label?.id || uuidV4()}>{label.action({ entry, key: label.key })}</div>;
                      } else {
                        return <div key={label?.id || uuidV4()}>{entry[label]?.key}</div>;
                      }
                    } else {
                      return (
                        <div key={label?.id || uuidV4()}>
                          <span className="mr-2">{startCase(label)}:</span>
                          <span>{typeof entry[label] === "string" ? startCase(entry[label]) : entry[label]}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
