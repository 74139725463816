import { apiFetch } from '.';
import store from '../store';
import { isEmpty } from 'lodash';

const getReport = async ({ id, endpoint = `portfolio-performance-dashboards?creditId=${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this report. Report ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getAllReports = async ({ endpoint = `portfolio-performance-dashboards`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  iStore.reports = [...response];
  return iStore.reports;
};

const createReport = async ({ body, endpoint = `portfolio-performance-dashboards`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this report. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllReports({});
  return response;
};

const updateReport = async ({ id, body, endpoint = `portfolio-performance-dashboards/${id}`, method = 'PUT', iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this report. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllReports({});
  return response;
};

const deleteReport = async ({ id, endpoint = `portfolio-performance-dashboards/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Report. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllReports({});
  return response;
};

export const getTableauToken = async ({username, iStore = store}) => {
  if(!username) {
    const message = `Unable to get tableau token. username is undefined.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }                                         
  const response = await apiFetch({endpoint: `tableau-jwt/{}/?username=${username}`});
  if(!response) return Promise.reject('error');
  return response;
};

const endpoints = {
  get: getAllReports,
  getReport: getReport,
  create: createReport,
  update: updateReport,
  delete: deleteReport,
  getTableauToken
};

export default endpoints;