import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const asOfDatesByCreditId = async ({ creditId, endpoint = `as-of-dates?creditId=${creditId}`, iStore = store }) => {
  if (!creditId) {
    const message = `Unable to get asOfDates. creditId (${creditId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get: asOfDatesByCreditId
};

export default endpoints;