import { logout } from "../store";
import {reports, admin} from "./";

export const goToPath = (path) => {
  window.location.href = path;
};

export const links = [
  {
    label: "Reports",
    items: reports.map(entry => {
      return {
        label: entry.label,
        icon: entry.icon,
        command: () => goToPath(entry.url),
        isActive(path) {
          return path.includes(entry.url);
        },
      };
    
    }),
  },
  {
    label: "Admin",
    items: admin.map(entry => {
      return {
        label: entry.label,
        icon: entry.icon,
        permission: entry.permission,
        command: () => goToPath(entry.url),
        isActive(path) {
          return path.includes(entry.url);
        },
      };
    
    }),
  },
  {
    label: "Sign Out",
    icon: "pi pi-fw pi-sign-out",
    command: logout,
  },
];
