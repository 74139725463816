import { isEqual, differenceWith, isEmpty, findIndex, orderBy } from "lodash";

const uniqCollection = async (collection1, collection2) => {
  // does the same thing as getUniqueEntry just written out instead of lodash.
  const c1 = collection1?.length > collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  const c2 = collection1?.length < collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  for (let item of c1) {
    const matched = c2.find((entry) => isEqual(entry, item));
    const idx = c2.indexOf(matched);
    c2.splice(idx, 1);
  }
  return c2;
};

const getUniqueEntry = (collection1, collection2) => {
  // the order of collection 1 and collection 2 matters. c1 and c2 vars should do the same thing regardless of order.
  const c1 = collection1?.length < collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  const c2 = collection1?.length > collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  return differenceWith(c1, c2, isEqual);
};

const getNextValue = ({ list, key }) => {
  if (!list || isEmpty(list)) return 0;
  const currentHighestValue = list?.reduce((a, b) => (a[key] > b[key] ? a : b));
  const nextValue = typeof currentHighestValue === "number" ? Number(currentHighestValue) + 1 : Number(currentHighestValue[key]) + 1;
  return nextValue || 0;
};

export const enterKeyPressHandler = ({ ...props }) => {
  let el;
  let nextEl;
  const keyUpWatcher = (event) => {
    // used to handle tabs and enter key presses.
    event.preventDefault();
    if (event.key === "Enter") {
      props.action();
      setTimeout(() => {
        if (!isEmpty(document.getElementById(nextEl?.id))) {
          document.getElementById(nextEl?.id).focus();
        }
      }, 5);
    }
  };

  const id = props.tableid === "covenant-table-container" ? `${props.dataKey}-${props.rowData?.id}` : `${props.dataKey}-${props.rowData?.fieldName}`;
  el = document.getElementById(id);
  const indexOfNextEl = findIndex(props.allInputs.current, (input) => input?.id === id) + 1;
  nextEl = props.allInputs.current[indexOfNextEl];
  if (!props.keyBindingHandled.current) {
    props.keyBindingHandled.current = true;
    setTimeout(() => {
      el.addEventListener("keyup", keyUpWatcher);
    }, 10);
  }
};

export const mockApiTimeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const shouldDisableDate = ({ date, existingDateList, key }) => {
  const standardizedDate = date.toISOString().split("T")[0];
  const entryExistsForDate = existingDateList.find((entry) => entry[key] === standardizedDate);
  return !isEmpty(entryExistsForDate);
};

export const handleDatePicker = ({ date, bodyDate, setter }) => {
  bodyDate.date = date ? new Date(date).toISOString().split("T")[0] : null;
  setter(date);
  return bodyDate;
};

export const currency = ({ value, operation }) => {
  if(!value) return 0;
  const usd = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (value?.target?.value) {
    value = value?.target?.value;
  }
  if (typeof value === "string") {
    const tempVal = value.split(",").join("");
    value = Number(tempVal);
  }
  if (value?.target?.value === "") {
    value = 0;
  }
  value = value.toFixed(2);
  value = usd.format(value);
  value = operation === "dollar" ? value : value.substring(1);
  return value;
};

export const stringToNumber = (currencyString) => {
  // Remove dollar signs and commas
  const cleanedString = currencyString.replace(/\$|,/g, "");
  // Parse the cleaned string to a number
  const number = parseFloat(cleanedString);
  const currency = parseFloat(number.toFixed(2));
  return currency;
};

export const convertSQLDate = (date) => {

  if (!date) return null;

  const localDate = new Date(date.split('T')[0]+'T22:59:59Z');
  const mm = localDate.toLocaleString("default", { month: "long" });
  const dd = localDate.getDate();
  const yyyy = localDate.getFullYear();

  return `${mm} ${dd}, ${yyyy}`;
};

export const convertTimeToLocal = ({ date, localOrigin, dateFormat }) => {
  function formatAmPm(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + ampm;
    return strTime;
  }
  let localDate;
  if (!localOrigin) {
    // convert to date provider type
    const dateType = new Date(date).toString();
    // Remove GMT offset from the first conversion (it assumed the original time was already in local time zone);
    const dateNoAssumedTimeZone = dateType.split("-")[0];
    // convert the date again this time keeping the local time zone
    localDate = new Date(dateNoAssumedTimeZone);
    if (dateFormat) return localDate;
  } else {
    if (typeof date === "string") {
      const temp = date.split(".")[0];
      localDate = new Date(temp);
    } else {
      localDate = new Date(date);
    }
  }
  // get the am / pm formatting
  const time = formatAmPm(localDate);
  // add on the mm/dd/yyyy formatting
  const mm = localDate.toLocaleString("default", { month: "short" });
  const dd = localDate.getDate();
  const yyyy = localDate.getFullYear();
  const formattedDate = `${mm} ${dd} ${yyyy} ${time}`;
  return formattedDate;
};

export const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getCurrentQuarter = () => {
  const mm = new Date().getMonth() + 1;
  switch (true) {
    case mm <= 3:
      return "Q1";
    case mm >= 4 && mm <= 6:
      return "Q2";
    case mm >= 7 && mm <= 9:
      return "Q3";
    case mm >= 10 && mm <= 12:
    default:
      return "Q4";
  }
};

export const getYearList = ({ startYear, yearCount }) => {
  const years = [startYear];
  let current = startYear;
  do {
    current++;
    years.push(current);
  } while (current < startYear + yearCount);
  const ordered = orderBy(years, "asc");
  return ordered;
};

export const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday;
};

export const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

const microservices = {
  getUniqueEntry,
  uniqCollection,
  getNextValue,
  enterKeyPressHandler,
  mockApiTimeout,
  shouldDisableDate,
  handleDatePicker,
  currency,
  stringToNumber,
  getCurrentQuarter,
  getYearList,
  convertTimeToLocal,
  getYesterday,
  validateText,
};
export default microservices;
