const iconPrefix = "pi pi-fw pi-lock";

export const admin = [
  {
    label: "Credit Admin",
    icon: `${iconPrefix}`,
    url: "/creditAdmin",
    permission: "transactionSummaryAdmin",
  },
  {
    label: "Prime Credit Admin",
    icon: `${iconPrefix}`,
    url: "/prime/creditAdmin",
    permission: "prime",
  },
  {
    label: "Transaction Summary Admin",
    icon: `${iconPrefix}`,
    url: "/transactionSummaryAdmin",
    permission: "transactionSummaryAdmin",
  },
  {
    label: "Borrowing Base Admin",
    icon: `${iconPrefix}`,
    url: "/borrowingBaseAdmin",
    permission: "borrowingBaseAdmin",
  },
  {
    label: "Portfolio Performance Dashboard Admin",
    icon: `${iconPrefix}`,
    url: "/ppda",
    permission: "transactionSummaryAdmin",
  },
  {
    label: "LQR Admin",
    icon: `${iconPrefix}`,
    url: "/lqrAdmin",
    permission: "lqrAdmin",
  },
];