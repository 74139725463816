import { apiFetch } from "..";
import store from "../../store";
import { isEmpty } from "lodash";

export const getFundingRequest = async ({ id, endpoint = `funding-requests/${id}?includeRecipients=true`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Funding Request. reviewId (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

export const getFundingRequests = async ({ endpoint = `funding-requests?includeRecipients=true` }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

export const createFundingRequests = async ({ body, endpoint = `funding-requests?includeRecipients=true`, method = "POST", iStore = store }) => {
  if (isEmpty(body)) {
    const message = `Unable to create this Funding Request. body (${body}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

export const updateFundingRequests = async ({ id, body, endpoint = `funding-requests/${id}?includeRecipients=true`, method = "PUT", iStore = store }) => {
  if (isEmpty(body) || !id) {
    const message = `Unable to update this Funding Request. id (${id}) or (${body}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

export const deleteFundingRequests = async ({ id, body ={}, endpoint = `funding-requests/${id}`, method = "DELETE", iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Funding Request. id (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get: ({ id }) => (id ? getFundingRequest({ id }) : getFundingRequests({})),
  create: createFundingRequests,
  update: updateFundingRequests,
  delete: deleteFundingRequests
};
export default endpoints;
