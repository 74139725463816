import React, { useEffect, useState, useCallback } from "react";
import api from "../../api";
import PageTemplate from "../PageTemplate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MultiSelectAllHeader } from "../../webbank-ui";
import { startCase } from "lodash";
import { Skeleton } from "primereact/skeleton";
import { v4 as uuidv4 } from "uuid";
import { Card } from "primereact/card";
import { MultiSelect } from "primereact/multiselect";
import "../../webbank-ui/themes/webbank-light/theme.css";
import { ScrollPanel } from "primereact/scrollpanel";
import { jsPDF } from "jspdf";

const Content = ({ data }) => {
  // PrimeReact Table
  const [criteriaColumns, setCriteriaColumns] = useState([]);
  const [visibleCriteriaColumns, setVisibleCriteriaColumns] = useState(criteriaColumns);

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = criteriaColumns
      .map((mCol) => ({ field: mCol, header: mCol }))
      .filter((col) => selectedColumns.some((sCol) => sCol.field === col.field))
      .map((rCol) => rCol.field);

    setVisibleCriteriaColumns(orderedSelectedColumns);
  };

  const displayValuesTemplate = ({ rowData, column }) => {
    return <span>{rowData[column]}</span>;
  };

  const setupCriteriaTable = () => {
    return visibleCriteriaColumns.map((column, idx) => {
      return (
        <Column
          key={uuidv4()}
          field={column}
          body={(rowData) => displayValuesTemplate({ rowData, column })}
          header={column === "name" ? "Criteria" : startCase(column)}
        />
      );
    });
  };
  const setupFacilityRuleTable = () => {
    return visibleFacilityRulesColumns?.map((column, idx) => {
      return (
        <Column
          key={uuidv4()}
          field={column}
          body={(rowData) => displayValuesTemplate({ rowData, column })}
          header={column === "name" ? "Facility Rules" : startCase(column)}
        />
      );
    });
  };

  const [checkAllVisibleColumns, setCheckAllVisibleColumns] = useState(visibleCriteriaColumns?.length === criteriaColumns?.length);
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        {data?.criteria?.length ? (
          <MultiSelect
            panelHeaderTemplate={
              <MultiSelectAllHeader
                selected={checkAllVisibleColumns}
                setSelected={setCheckAllVisibleColumns}
                dataset1={visibleCriteriaColumns}
                dataset2={criteriaColumns}
                setDataset1={setVisibleCriteriaColumns}
              />
            }
            value={visibleCriteriaColumns.map((vc) => ({ field: vc, header: startCase(vc) }))}
            options={criteriaColumns.map((vc) => ({ field: vc, header: startCase(vc) }))}
            optionLabel="header"
            onChange={onColumnToggle}
            className="mr-3"
            display="chip"
            maxSelectedLabels={4}
          />
        ) : (
          <Skeleton width="20rem" height="2.75rem" className="mr-3" />
        )}
        <div className="flex justify-content-end">
          {data?.criteria?.length ? (
            <Button
              rounded
              raised
              icon="pi pi-file-pdf"
              severity="info"
              className="mr-3"
              onClick={() => exportPdf({ fileName: "LQR Summary" })}
              tooltip="Export PDF"
              tooltipOptions={{ position: "bottom" }}
            />
          ) : (
            <Skeleton shape="circle" size="2.625rem" />
          )}
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const setupCriteriaTableData = useCallback(() => {
    // copy the dataset to originalDataset so we can refer to original data when updating
    const hideColumnList = ["id", "startDate", "createdBy", "endDate", "updatedBy"];
    const tempColumns = Object.keys(data.criteria[0]).filter((key) => !hideColumnList.includes(key));
    setCriteriaColumns(tempColumns);
    setVisibleCriteriaColumns(tempColumns);
  }, [data]);

  const [facilityRulesColumns, setFacilityRulesColumns] = useState();
  const [visibleFacilityRulesColumns, setVisibleFacilityRulesColumns] = useState(facilityRulesColumns);
  const setupFacilityRulesTableData = useCallback(() => {
    // copy the dataset to originalDataset so we can refer to original data when updating
    const hideColumnList = ["id", "startDate", "createdBy", "endDate", "updatedBy"];
    const tempColumns = Object.keys(data.facilityRules[0]).filter((key) => !hideColumnList.includes(key));
    setFacilityRulesColumns(tempColumns);
    setVisibleFacilityRulesColumns(tempColumns);
  }, [data]);

  useEffect(() => {
    if (data?.criteria?.length > 0 && data?.facilityRules?.length > 0) {
      setupCriteriaTableData();
      setupFacilityRulesTableData();
    }
  }, [data, setupCriteriaTableData, setupFacilityRulesTableData]);

  const exportPdf = ({ fileName, setState = (val) => true }) => {
    setState(true);
    setTimeout(() => {
      const doc = new jsPDF({ orientation: "l", unit: "pt", compress: true });
      const colSpan = visibleCriteriaColumns.length;
      const blankRow = [{ content: "", colSpan }];
      // Convert headers to a regular row - normally would use headers but they repeat on each page, which is unwanted
      const headersAsRow = [
        [{ content: "LQR Summary Report", colSpan }],
        [{ content: "CRITERIA:", colSpan }],
        visibleCriteriaColumns.map((col) => ({ content: startCase(col) })),
      ];
      const criteriaData = headersAsRow.concat(data.criteria.map((row) => Object.keys(row).map((key) => row[key])));
      // combine criteriaData and facility data into a single array with criteria values at the start of the array
      criteriaData.push(
        blankRow,
        [{ content: "FACILITY RULES:", colSpan }],
        visibleFacilityRulesColumns.map((col) => ({ content: startCase(col) }))
      );
      const combinedData = criteriaData.concat(data.facilityRules.map((row) => Object.keys(row).map((key) => row[key])));
      const averageIdx = combinedData.findIndex((row) => row[0] === "Average");
      combinedData.splice(averageIdx, 0, blankRow);
      combinedData.splice(combinedData.length - 1, 0, blankRow);

      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const tableWidth = pageWidth * 0.95; // Use 95% of page width
      const columnWidth = tableWidth / (colSpan + 0.05); // Divide by number of columns

      const columnStyles = visibleCriteriaColumns.reduce((acc, col, idx) => {
        acc[idx] = { cellWidth: columnWidth };
        return acc;
      }, {});

      doc.autoTable({
        body: combinedData,
        columnStyles,
        margin: { top: 15, right: 15, bottom: 15, left: 15 },
        horizontalPageBreak: true,
        didParseCell: (data) => {
          // Check if the cell's row index is the one you want to style
          const rowKeys = ["criteria:", "facility rules:"];
          // check if cell?.content?.toLowerCase() exists in the rowKeys array, if it does, return the index
          if (
            data.row.raw.findIndex((cell) => {
              return rowKeys.includes(cell?.content?.toLowerCase());
            }) !== -1
          ) {
            data.cell.styles.textColor = [255, 255, 255]; // Change background color to blue
            data.cell.styles.fillColor = [111, 170, 235]; // Change background color to blue
          }
          if (data.row.index === 0) {
            data.cell.styles.textColor = [255, 255, 255]; // Change background color to blue
            data.cell.styles.fillColor = [47, 90, 198]; // Change background color to blue
          }
        },
      });

      doc.save(`${fileName}.pdf`);
      setState(false);
    }, 1000);
  };

  return (
    <div style={{ width: "calc(100vw - 70px)", paddingLeft: "1rem" }}>
      <ScrollPanel
        style={{
          height: "calc(100vh - 10rem)",
          padding: "1rem",
        }}
      >
        <Card className="mt-5">
          {data?.criteria?.length ? (
            <div id="lqr-summary-tables">
              <DataTable value={data?.criteria} tableStyle={{ minWidth: "50rem" }} header={header}>
                {setupCriteriaTable()}
              </DataTable>
              <DataTable value={data?.facilityRules} tableStyle={{ minWidth: "50rem" }}>
                {setupFacilityRuleTable()}
              </DataTable>
            </div>
          ) : (
            // DataTable NEEDS TO BE WRAPPED IN A DIV FOR THE VALUES TO WORK
            <div>
              <DataTable tableStyle={{ minWidth: "50rem" }} header={header} value={[{ name: "", analystId: "" }]}>
                <Column body={<Skeleton />} style={{ width: "23rem" }} />
                <Column body={<Skeleton />} />
              </DataTable>
            </div>
          )}
        </Card>
      </ScrollPanel>
    </div>
  );
};
const LqrSummary = () => {
  const [data, setData] = useState(null);
  const getLQRSummary = async () => {
    const response = await api.lqrReport.getLqrSummary({});
    setData(response);
    return response;
  };

  const setup = async () => {
    getLQRSummary();
  };

  return (
    <PageTemplate
      id="lqr-summary"
      pageTitle="LQR Summary"
      defaultOpenMenus={["1"]}
      setup={setup}
      content={<Content data={data} setData={setData} />}
    />
  );
};
export default LqrSummary;
