import { Table, SelectPicker } from "rsuite";
import { useState, useEffect } from "react";
import * as com from "../../components";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import api from "../../api";
import Search from "../../components/Search";
import { isEmpty, orderBy } from "lodash";
import { getTableContainerHeight } from "../../services/tsTables.microservices";
import { InputEditCell, DroplistCellSimple } from "../../components/tableComponents/customCells";

const CreditTable = ({ ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [tableHeight, setTableHeight] = useState(200);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [creditName, setCreditName] = useState("");
  const [disabledRow, setDisabledRow] = useState("");
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [resetSearch, setResetSearch] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState({});

  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);

  const update = async ({ body, setUpdateActionTriggered, setUpdateActionDone }) => {
    setUpdateActionTriggered(body?.id);
    setDisabledRow(body?.id);
    const response = await api[props.endpoint].update({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    // handle update of stored selectedCredit in sessionStorage
    const existsInSession = JSON.parse(sessionStorage.getItem("selectedCredit"))?.id === body?.id;
    if (existsInSession) {
      sessionStorage.setItem("selectedCredit", JSON.stringify(response));
    }
    setUpdateActionDone(body?.id);
    setTimeout(() => {
      setUpdateActionDone(false);
      setDisabledRow(false);
      setUpdateActionTriggered(false);
    }, 2000);
    return response;
  };

  const deleteAction = async ({ body }) => {
    const response = await api[props.endpoint].delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setTimeout(async () => {
      setDisabledRow(false);
      await props.refreshDataset({});
      setResetSearch([]);
    }, 2000);
  };

  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <ApiSubmitButton
            action={() => update({ body: rowData, setUpdateActionTriggered, setUpdateActionDone })}
            className="mr-2 row-action-button"
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
            actionTriggered={updateActionTriggered === rowData?.id}
            actionDone={updateActionDone === rowData?.id}
          />
          <AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
          />
        </div>
      </Cell>
    );
  };

  const handleCreditInput = (e) => {
    const input = e.target.value;
    if (input.trim().length === 0 && e.target.value.length > 0) {
      console.error("Input cannot be all spaces");
      return;
    }
    setCreditName(e.target.value);
  };

  const handlePersonSelect = (e) => {
    const tempPerson = props.persons?.find((person) => person.id === e);
    setSelectedPerson(tempPerson);
  };

  const submit = async () => {
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const response = await api[props.endpoint].create({ body: { name: creditName, analystId: selectedPerson?.id } });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    // create Borrowing Base Summary Criteria Section so it is set up for every new credit.
    api.bbSections.create({ body: { name: "Borrowing Base Summary", sortOrder: 0, creditId: response?.id } });
    setActionDone(true);
    const updatedData = await props.refreshDataset({});
    setActionDone(false);
    setDisableFields(false);
    setSubmitActionTriggered(false);
    setCreditName("");
    handlePersonSelect("");
    setResetSearch(updatedData);
    return response;
  };

  useEffect(() => {
    setTableHeight(getTableContainerHeight({ id: "#credit-table-container" }));
  }, []);

  return (
    <div className="custom-flex-container p-3">
      {filtered && <div className="has-text-warning-dark is-capitalized help">Clear search to enable new Credit creation</div>}
      <div className="is-flex is-align-items-start">
        <com.TextInput
          controlClass="mr-3"
          label="New Credit"
          value={creditName || ""}
          style={{ maxWidth: "510px" }}
          maxlength={32}
          onChange={handleCreditInput}
          disabled={disableFields}
        />
        <div className="input-style is-flex is-flex-direction-column">
          <label className="is-size-6">Credit Analyst</label>
          <SelectPicker
            container={document.getElementById("credit-admin")}
            style={{ width: "23em" }}
            className="mr-2"
            data={props.persons?.map((person) => ({ label: person.name, value: person.id }))}
            labelKey="label"
            valueKey="value"
            value={selectedPerson?.id || ""}
            onChange={handlePersonSelect}
            disabled={disableFields}
          />
        </div>
        <div className="is-flex is-align-items-center" style={{ height: "100%" }}>
          <ApiSubmitButton
            action={() => submit()}
            className="ml-3"
            icon={<i className="fa-solid fa-plus" />}
            // used to force correct rendering when parent re-renders
            actionTriggered={submitActionTriggered}
            actionDone={actionDone}
            disabled={!creditName || !selectedPerson || isEmpty(selectedPerson)}
          />
        </div>
      </div>
      <Search
        className="pt-3"
        dataset={props.dataset}
        setDataset={props.setDataset}
        refreshDataset={props.refreshDataset}
        placeholder="Find Credit"
        searchKey="name"
        currentSortKey={sortColumn}
        currentSortDirection={sortType}
        style={{ maxWidth: "510px" }}
        filtered={filtered}
        setFiltered={setFiltered}
        resetSearch={resetSearch}
        setResetSearch={setResetSearch}
      />
      <div id="credit-table-container" className="custom-flex-container flex-scroll extend-last-row" style={{ "--lastRowHeight": "200px" }}>
        <Table
          data={props.dataset}
          height={tableHeight}
          sortColumn={sortColumn}
          sortType={sortType}
          rowHeight={65}
          onSortColumn={(sortColumn, sortType) => {
            const handleSort = ({ sortColumn, setSortColumn, sortType, setSortType, ...props }) => {
              props.setLoading(true);
            
              let ordered = orderBy(props?.dataset, [(data) => {
                if (sortColumn === 'analystId' && data.analystId) {
                  const person = props.persons.find((person) => person.id === data.analystId);
                  return person ? person.name.toLowerCase() : '';
                }
                return data[sortColumn]?.toLowerCase();
              }], [sortType]);
            
              props.setDataset([...ordered]);
            
              setTimeout(() => {
                props.setLoading(false);
                setSortColumn(sortColumn);
                setSortType(sortType);
              }, 500);
            }
            handleSort({ sortColumn, setSortColumn, setSortType, sortType, ...props });
          }}
          loading={props.loading}
        >
          <Column flexGrow={1} sortable>
            <HeaderCell>Credit Name</HeaderCell>
            <InputEditCell dataKey="name" disabled={disabledRow} setmaxlength={32}/>
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>Analyst</HeaderCell>
            <DroplistCellSimple
              dataKey="analystId"
              dataSet={props.persons}
              showDefault="Choose Analyst"
              listDisplay={({ entry }) => entry.name}
              cb={({ val, rowData, key }) => {
                rowData[key] = !val || val === "0" ? null : val;
              }}
              disabled={disabledRow}
            />
          </Column>
          <Column width={235}>
            <HeaderCell></HeaderCell>
            <ActionsCell dataKey="actions" />
          </Column>
        </Table>
      </div>
    </div>
  );
};
export default CreditTable;
