import PageTemplate from '../PageTemplate';
import BorrowingBaseSectionsTable from './BorrowingBaseSectionsTable';
import BorrowingBaseFieldNameTable from './BorrowingBaseCriteriaTable';
import { Loader } from 'rsuite';
import { useState, useEffect } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { orderBy } from 'lodash';
import api from '../../api';
import { handleAutoCreditSelection } from '../../services/microservices';

const Content = ({ credits, dataSetup, sectionProps, criteriaProps, resetSelections, disableFields, selectedCredit, setSelectedCredit }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [tab, setTab] = useState('bbSections');
  const tabs = ['bbSections', 'bbCriteria'];
  
  const handleCreditSelect = async (e) => {
    if(e.target?.value === '0'){
      setSelectedCredit(null);
      resetSelections();
      setLoadingData(false);
      sessionStorage.removeItem("selectedCredit");
      return;
    }
    setTab('bbSections');
    criteriaProps.dataset = null;
    criteriaProps.setLoading(true);
    const temp = credits?.find(credit => credit?.id === e?.target?.value);
    setSelectedCredit({ ...temp });
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    setLoadingData(true);
    await dataSetup({ creditId: temp?.id });
    setLoadingData(false);
  };

  const handleTabClick = (t) => {
    switch (t) {
      case ('bbSections'):
        if (sectionProps?.dataset) {
          setTab(t);
        }
        break;
      case ('bbCriteria'):
        if (criteriaProps?.dataset && !!criteriaProps?.columnNames?.length) {
          setTab(t);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleAutoCreditSelection({selectedCredit, handleCreditSelect});
    // eslint-disable-next-line
  }, [selectedCredit]);

  return (
    <div className="custom-flex-container">
      <div className="p-2">
        <div className="select" style={{ maxHeight: '36px' }}>
          <select id="source-temp-select" className="has-text-link" onChange={handleCreditSelect} value={selectedCredit?.id} style={{ maxHeight: '36px', fontSize: '14px' }} disabled={disableFields}>
            <option defaultValue={true} value={0}>Choose Credit</option>
            {credits?.map(entry => <option key={entry?.id} value={entry?.id}>{entry?.name}</option>)}
          </select>
        </div>
      </div>
      {loadingData ? <Loader center size="sm" content="Loading Criteria Section Info..." />
        :
        selectedCredit && sectionProps?.dataset && !loadingData && <div className='custom-flex-container'>
          <div className="is-flex is-align-items-flex-end pl-3">
            {tabs.map(t => (
              <div onClick={() => handleTabClick(t)} key={uuidV4()}>
                {t === 'bbSections' && <div className={`tab ${tab === t ? 'has-background-link has-text-white' : ''} ${sectionProps?.dataset ? '' : 'custom-disabled'}`}>Criteria Sections</div>}
                {t === 'bbCriteria' && <div className={`tab ${tab === t ? 'has-background-link has-text-white' : ''} ${!criteriaProps.loading && criteriaProps?.dataset && !!criteriaProps?.columnNames?.length ? '' : 'custom-disabled'}`} style={{ height: "48px", minWidth: "73.12px" }}>
                  {criteriaProps.loading ? <span><Loader center size="sm" /></span> : !criteriaProps?.dataset?.length && !criteriaProps?.columnNames?.length ? <span>No Data Available</span> : <span>Criteria</span>}
                </div>}
              </div>
            ))}
          </div>
          <div id="borrowing-base-tab-container" className='custom-flex-container'>
            {tab === 'bbSections' && sectionProps?.dataset && <BorrowingBaseSectionsTable creditId={selectedCredit?.id} {...sectionProps} />}
            {tab === 'bbCriteria' && criteriaProps?.dataset && !!criteriaProps?.columnNames?.length && <BorrowingBaseFieldNameTable creditId={selectedCredit?.id} {...criteriaProps} />}
          </div>
        </div>}
    </div>
  )

};

const BorrowingBaseAdmin = () => {
  const [credits, setCredits] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState();
  const [sections, setSections] = useState(null);
  const [sectionsLoading, setSectionsLoading] = useState(false);
  const [bbCriteria, setBbCriteria] = useState(null);
  const [columnNames, setColumnNames] = useState(null);
  const [criteriaLoading, setCriteriaLoading] = useState(true);

  const resetSelections = () => {
    setSections(null);
    setBbCriteria(null);
    setColumnNames(null);
  };

  const getCredits = async () => {
    const tempCredits = await api.credits.get({});
    const ordered = orderBy(tempCredits, [data => data?.name?.toLowerCase()], ['asc']);
    setCredits([...ordered]);
  };

  const getSections = async ({ creditId, sortColumn, sortType }) => {
    setSectionsLoading(true);
    const response = await api.bbSections.get({ creditId });
    const ordered = orderBy(response, [data => data[sortColumn] && typeof data[sortColumn] === 'string' ? data[sortColumn]?.toLowerCase() : data?.sortOrder], [sortType ? sortType : 'asc']);
    setSections(ordered);
    setSectionsLoading(false);
    return ordered;
  };

  const getCriteriaNames = async ({ creditId, sortColumn, sortType }) => {
    const apiCalls = [api.bbCriteria.get({ creditId }), getViewColumnNames({ creditId })];
    setCriteriaLoading(true);
    const [response] = await Promise.all(apiCalls);;
    const ordered = orderBy(response, [data => data[sortColumn] && typeof data[sortColumn] === 'string' ? data[sortColumn]?.toLowerCase() : data?.sortOrder], [sortType ? sortType : 'asc']);
    setBbCriteria(ordered);
    setCriteriaLoading(false);
    return ordered;
  };

  const getViewColumnNames = async ({ creditId }) => {
    const tempViewColumnNames = await api.columns.get({ creditId });
    setColumnNames(tempViewColumnNames);
  };

  const dataSetup = async ({ creditId }) => {
    await getSections({ creditId });
    getCriteriaNames({ creditId });
  };

  const setup = async () => {
    await getCredits();
  };

  // Tab View's props mapping
  const sectionProps = {
    dataset: sections,
    setDataset: setSections,
    refreshDataset({ sortColumn, sortType }){
      const tempSections = getSections({ creditId: selectedCredit?.id, sortColumn, sortType });
      return tempSections;
    },
    loading: sectionsLoading,
    setLoading: setSectionsLoading,
    endpoint: 'bbSections'
  };

  const criteriaProps = {
    dataset: bbCriteria,
    sections: sections,
    columnNames: columnNames,
    setDataset: setBbCriteria,
    refreshDataset({ sortColumn, sortType }){
      const tempCriteria = getCriteriaNames({ creditId: selectedCredit?.id, sortColumn, sortType });
      return tempCriteria;
    },
    loading: criteriaLoading,
    setLoading: setCriteriaLoading,
    endpoint: 'bbCriteria'
  };

  return (
    <PageTemplate
      id="borrowing-base-admin"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Borrowing Base Admin"
      defaultOpenMenus={["2"]}
      setup={setup}
      content={
        <Content
          credits={credits}
          sectionProps={sectionProps}
          criteriaProps={criteriaProps}
          resetSelections={resetSelections}
          dataSetup={dataSetup}
          selectedCredit={selectedCredit}
          setSelectedCredit={setSelectedCredit}
        />}
    />
  )
};
export default BorrowingBaseAdmin;