import * as lqr from "../../lqr-index";
import PageTemplate from "../../PageTemplate";
import { useState, useRef } from "react";
import { v4 as uuidV4 } from "uuid";
import api from "../../../api";
import { Loader } from "rsuite";
import { orderBy } from "lodash";

const Content = ({ ...props }) => {
  const tabs = ["LQR Criteria", "LQR Choices"];

  return (
    <div className="custom-flex-container">
      <div className="is-flex is-align-items-flex-end pl-3">
        {tabs.map((t) => (
          <div onClick={() => props.setTab(t)} key={uuidV4()}>
            {t === tabs[0] && (
              <div
                className={`tab ${props?.tab === t ? "has-background-link has-text-white" : ""} ${
                  !props?.criteria?.loading ? "" : "custom-disabled"
                }`}
              >
                {props.criteria?.loading ? (
                  <span>
                    <span style={{ color: "transparent" }}>{tabs[0]}</span>
                    <Loader center size="sm" />
                  </span>
                ) : (
                  tabs[0]
                )}
              </div>
            )}
            {t === tabs[1] && (
              <div
                className={`tab ${props?.tab === t ? "has-background-link has-text-white" : ""} ${!props.choices?.loading ? "" : "custom-disabled"}`}
                style={{ height: "48px", minWidth: "73.12px" }}
              >
                {props.choices?.loading ? (
                  <span>
                    <span style={{ color: "transparent" }}>{tabs[1]}</span>
                    <Loader center size="sm" />
                  </span>
                ) : (
                  tabs[1]
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div id="transaction-summary-tab-container" className="custom-flex-container p-3">
        {props?.tab === tabs[0] && <lqr.CriteriaAdmin {...props.criteria} {...props} />}
        {props?.tab === tabs[1] && <lqr.ChoicesAdmin {...props.choices} criteria={props?.criteria?.data} />}
      </div>
    </div>
  );
};

export const LqrLanding = () => {
  const [criteriaLoading, setCriteriaLoading] = useState(true);
  const [choicesLoading, setChoicesLoading] = useState(true);
  const [tab, setTab] = useState("LQR Criteria");
  const [selectedCriteria, setSelectedCriteria] = useState();

  const lqrData = useRef({
    originalCriteria: [],
    criteria: [],
    originalChoices: [],
    choices: [],
  });

  const getLqrCriteria = async () => {
    setCriteriaLoading(true);
    let response = await api.lqrCriteria.get({});
    if (!response) return [];
    response = orderBy(response, "sortOrder", "asc");
    lqrData.originalCriteria = structuredClone([...response]);
    lqrData.criteria = structuredClone([...response]);
    setCriteriaLoading(false);
    return response;
  };
  const getLqrChoices = async () => {
    setChoicesLoading(true);
    let response = await api.lqrChoices.get({});
    if (!response) response = [];
    response = orderBy(response, "grade", "asc");
    lqrData.originalChoices = structuredClone([...response]);
    lqrData.choices = structuredClone([...response]);
    setChoicesLoading(false);
    return response;
  };

  const setup = async () => {
    await getLqrCriteria();
    getLqrChoices();
  };

  // Tab View's props mapping
  const sharedProps = {
    criteria: {
      originalCriteria: lqrData.originalCriteria,
      data: lqrData.criteria,
      loading: criteriaLoading,
      endpoint: "lqrCriteria",
      refreshDataset: getLqrCriteria,
      choicesLoading,
    },
    choices: {
      originalCriteria: lqrData.originalChoices,
      data: lqrData.choices,
      loading: choicesLoading,
      endpoint: "lqrChoices",
      refreshDataset: getLqrChoices,
      selectedCriteria,
      setSelectedCriteria,
    },
    handleChoiceLink({ criteria }) {
      setTab("LQR Choices");
      const matchedCriteria = lqrData.criteria?.find((crit) => crit?.name === criteria);
      setSelectedCriteria(matchedCriteria);
    },
    tab,
    setTab,
  };

  return (
    <PageTemplate
      id="lqr-admin"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="LQR Admin"
      defaultOpenMenus={["2"]}
      setup={setup}
      content={<Content {...sharedProps} />}
    />
  );
};
