import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getReportingRequirements = async ({ endpoint = `reporting-requirements`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response) {
    const message = 'no response from reportingRequirements api call';
    console.error(message);
    return message;
  }
  return response;
};

const createReportingRequirements = async ({ body, endpoint = `reporting-requirements`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this report requirement. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  iStore.reportingRequirements = getReportingRequirements({});
  return iStore.reportingRequirements;
};

const updateReportingRequirements = async ({ id, body, endpoint = `reporting-requirements/${id}`, method = 'PUT', iStore = store }) => {
  if(body?.dueDateOffset && typeof body?.dueDateOffset === 'string'){
    body.dueDateOffset = Number(body?.dueDateOffset);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getReportingRequirements({});
  return response;
};

const deleteReportingRequirements = async ({ id, body = {}, endpoint = `reporting-requirements/${id}`, method = 'DELETE', iStore = store }) => {
  // was only used during testing. can be cleaned up once feature is complete
  console.log(`body delete Reporting Requirements`, id, body);
  await apiFetch({ endpoint, method, body });
  return
};

const endpoints = {
  get: getReportingRequirements,
  create: createReportingRequirements,
  update: updateReportingRequirements,
  delete: deleteReportingRequirements
};
export default endpoints;