import Header from "../components/header";
import SideMenu from "../components/sidemenu";
import { Loader } from "rsuite";
import { useState, useEffect } from "react";

export default function PageTemplate({ id, pageTitle, content, setup, getPermission, defaultOpenMenus, crudControls, className, ...props }) {
  const [dataLoading, setDataLoading] = useState(true);

  async function updateAllData() {
    setDataLoading(true);
    // Do page setup here.
    if (setup) {
      await setup();
    }
    setDataLoading(false);
  }

  useEffect(() => {
    // sets legacy theme
    const themeElement = document.getElementById("prime-theme");
    themeElement.href = themeElement.href.replace("webbank-light", "webbank-legacy");
    // handle user session data
    if (getPermission) {
      getPermission();
    }
    updateAllData().catch((e) => e);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="page-template-container" className="custom-flex-container">
      <Header></Header>
      <div id="page-template-body" className="is-flex" style={{ flex: "1 1" }}>
        <SideMenu defaultOpenMenus={defaultOpenMenus}></SideMenu>
        <div id="page-template-view-container" className="custom-flex-container">
          <h4
            className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center"
            style={{ fontSize: "22px", lineHeight: "34px" }}
          >
            <span>{pageTitle}</span>
            {crudControls}
          </h4>
          <div id="page-template-view-container-body" className="custom-flex-container">
            {dataLoading ? (
              <div className="is-full">
                <Loader center size="lg" />
              </div>
            ) : (
              <div id={id} className={className}>
                {content}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
