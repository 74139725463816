import { Table, Loader } from "rsuite";
import { useState, useEffect, useRef } from "react";
import * as com from "../../components/index";
import api from "../../api";
import { isEmpty, words, keys, camelCase, orderBy } from "lodash";
import { InputEditCellInlineSave, DroplistCellSimple } from "../../components/tableComponents/customCells";
import { handleAutoCreditSelection } from "../../services/microservices";

const ReportingRequirements = ({ ...props }) => {
  const frequencies = ["Monthly", "Quarterly", "Annually"];
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [name, setName] = useState("");
  const [dueDateOffset, setDueDateOffset] = useState(0);
  const [frequency, setFrequency] = useState(frequencies[0]);
  const inputWidth = "300px";
  const [disableFields, setDisableFields] = useState(null);
  const [reportingReqsLoading, setReportingReqsLoading] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [tableDisplay, setTableDisplay] = useState([]);

  const handleCreditSelect = async (e) => {
    // set selected credit
    const temp = props.dataset?.find((credit) => credit?.id === e.target.value);
    setSelectedCredit(temp);
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    if (e.target.value === "0") {
      sessionStorage.removeItem("selectedCredit");
      return;
    }
    await getReportingRequirements({ creditId: temp?.id, tableName: "Reporting Requirements", sortColumn: "name", sortType: "asc" });
  };

  const getReportingRequirements = async ({ creditId, tableName, sortColumn, sortType }) => {
    setReportingReqsLoading(true);
    let tempReportingResponses = await api.reportingRequirements.get({ endpoint: `reporting-requirements?creditId=${creditId}` });
    const orderedReportingResponses =
      sortColumn && sortType && tableName === "Reporting Requirements"
        ? orderBy(tempReportingResponses, [(data) => data[sortColumn]?.toLowerCase()], [sortType])
        : orderBy(tempReportingResponses, "startDate", "asc");
    reportingProps.current.dataset = structuredClone([...orderedReportingResponses]);
    reportingProps.current.originalData = structuredClone([...orderedReportingResponses]);
    reportingProps.current.detailsDataSet = structuredClone([...orderedReportingResponses]);
    setTableDisplay([...orderedReportingResponses]);
    setReportingReqsLoading(false);
  };

  const reportingProps = useRef({
    sectionLabel: "Reporting Requirements",
    dataset: [],
    originalData: [],
    detailsDataSet: [],
    endpoint: `reportingRequirements`,
    setReportingReqsLoading,
    getReportingRequirements,
  });

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);
  const validateOffset = (e) => {
    const regex = /^[0-9 ]+$/; // This regex allows only digits. Due to input type=number, "+" is still allowed, but isn't carried over to the e.target.value.
    return regex.test(e.target.value) && !(Number(e.target.value) < 0 || (e.target.value.trim().length === 0 && e.target.value.length > 0));
  };

  const handleInput = ({ e, setFunction }) => {
    if (!validateText(e)) return;
    setFunction(e.target.value);
  };

  const handleOffsetInput = ({ e, setFunction }) => {
    if (!validateOffset(e)) return;
    setFunction(e.target.value);
  };

  const inlineSubmitHandler = async ({ detailProps }) => {
    if (detailProps.detailsDataSet) {
      // == Covenants and Reporting Requirements ==
      const checkNewFieldDataUpdate = ({ submitData, originalData }) => {
        // determines if submit data contains existing data, or needs to be updated.
        const excludeKeys = ["id", "creditId", "startDate", "createdBy", "endDate", "updatedBy"];
        for (let key in submitData) {
          if (excludeKeys.indexOf(key) === -1) {
            if (originalData[key] === submitData[key]) {
              continue;
            } else {
              return originalData[key] !== submitData[key];
            }
          }
        }
        return false;
      };
      const apiCalls = [];
      for (let covenant of detailProps.detailsDataSet) {
        covenant.creditId = selectedCredit?.id;
        const originalData = detailProps.originalData?.find((c) => c?.id === covenant?.id);
        const submitIt = checkNewFieldDataUpdate({ submitData: covenant, originalData });
        if (submitIt) {
          apiCalls.push(api[detailProps.endpoint].update({ id: covenant?.id, body: covenant }));
        }
      }
      await Promise.all(apiCalls);
    }
  };

  const submit = async ({ rowId }) => {
    setSubmitActionTriggered(rowId || true);
    const body = {
      creditId: selectedCredit?.id,
      name,
      frequency,
      dueDateOffset: Number(dueDateOffset),
    };
    const response = await api[reportingProps.current?.endpoint].create({ body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setActionDone(true);
    await getReportingRequirements({ creditId: selectedCredit?.id, tableName: "Reporting Requirements", sortColumn: "name", sortType: "asc"});
    setActionDone(false);
    setName(defaultFields.name);
    setFrequency(defaultFields.frequency);
    setDueDateOffset(defaultFields.dueDateOffset);
    setSubmitActionTriggered(false);
    return response;
  };

  const defaultFields = {
    id: "",
    creditId: props?.creditId,
    name: "",
    frequency: frequencies[0],
    dueDateOffset: 0,
    notes: "",
  };
  const tableColumns = keys(defaultFields);

  const addToDetails = ({ rowId, data, key }) => {
    const exists = reportingProps.current?.detailsDataSet?.find((d) => d?.id === rowId);
    if (!exists || isEmpty(exists)) {
      const tempBody = {};
      tempBody.id = rowId;
      tempBody[key] = data;
      reportingProps.current?.detailsDataSet?.push({ ...tempBody });
    } else {
      exists[key] = data;
    }
  };

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    handleAutoCreditSelection({selectedCredit, handleCreditSelect});
    if (!reportingReqsLoading) {
      const tempProps = { ...defaultTableProps, rowHeight: 65 };
      setTableProps(tempProps);
    }
    // eslint-disable-next-line
  }, [reportingReqsLoading]);

  const deleteAction = async ({ body }) => {
    await api[reportingProps.current?.endpoint].delete({ id: body?.id });
    setTimeout(() => {
      getReportingRequirements({ creditId: selectedCredit?.id });
    }, 2000);
  };

  const [actionWidth, setActionWidth] = useState(false);
  const areYouSureRef = useRef([]);
  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <com.AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={disableFields === rowData?.id || (!!areYouSureRef.current.length && !areYouSureRef.current.includes(rowData?.id))}
            hideOtherElementsCb={setActionWidth}
            cb={() => setActionDone(false)}
            data={rowData?.id}
            areYouSureRef={areYouSureRef}
          />
        </div>
      </Cell>
    );
  };

  const setFieldLengths = ({ field }) => {
    switch (field) {
      case "name":
        return 128;
      case "notes":
        return 500;
      case "frequency":
        return 16;
      default:
        return null;
    }
  };

  const setFieldWidths = ({ field }) => {
    switch (field) {
      case "frequency":
        return 200;
      case "dueDateOffset":
        return 95;
      default:
        return 300;
    }
  };

  const defaultTableProps = {
    autoHeight: true,
    onSortColumn: (sortColumn, sortType) => {
      const fixedSortColumn = camelCase(sortColumn);
      setTableLoading(true);
      setTimeout(() => {
        reportingProps.current.dataset = orderBy(
          reportingProps.current?.dataset,
          [(data) => (typeof data[fixedSortColumn] === "string" ? data[fixedSortColumn]?.toLowerCase() : data[fixedSortColumn])],
          [sortType]
        );
        setTableDisplay(reportingProps.current.dataset);
        setSortColumn(fixedSortColumn);
        setSortType(sortType);
        setTableLoading(false);
      }, 500);
    },
  };
  const [tableProps, setTableProps] = useState({});

  return (
    <div className="custom-flex-container">
      <div className="pl-0 py-2 pr-2 card is-flex-grow-1" style={{ zIndex: 10, maxHeight: "50px" }}>
        <div className="card-content is-flex is-flex-direction-row is-align-items-center p-0">
          <div className="pl-2 is-flex is-flex-direction-row is-align-items-center mr-3" style={{ zIndex: 9, background: "#fff" }}>
            <label className="is-size-6 mr-2">Credit:</label>
            <div className="select" style={{ maxHeight: "36px" }}>
              <select
                id="source-temp-select"
                className="has-text-link"
                onChange={handleCreditSelect}
                value={selectedCredit?.id}
                style={{ maxHeight: "36px", fontSize: "14px" }}
                disabled={submitActionTriggered}
              >
                <option defaultValue={true} value={0}>
                  Choose Credit
                </option>
                {props?.dataset?.map((entry) => (
                  <option key={entry?.id} value={entry?.id}>
                    {entry?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {reportingReqsLoading ? (
        <Loader center size="sm" content={`Loading Reporting Requirements for ${selectedCredit?.name}...`} />
      ) : (
        !isEmpty(selectedCredit) && (
          <div className="custom-flex-container p-3">
            {!isEmpty(reportingProps.current?.dataset) && (
              <label className="is-capitalized has-text-info-dark is-size-5 has-text-weight-bold">{reportingProps.current.sectionLabel}</label>
            )}
            <div className="is-flex">
              <com.TextInput
                label={`New ${reportingProps.current.sectionLabel} Name`}
                value={name || ""}
                style={{ width: inputWidth }}
                maxlength={128}
                controlClass={"mr-3"}
                onChange={(e) => handleInput({ e, setFunction: setName })}
                disabled={submitActionTriggered}
              />
              <div className="is-flex mr-3 is-flex-direction-column" style={{ zIndex: 9, background: "#fff" }}>
                <label className="is-size-6 mr-2">Frequency</label>
                <div className="select" style={{ maxHeight: "36px" }}>
                  <select
                    id="source-temp-select-frequency"
                    onChange={(e) => handleInput({ e, setFunction: setFrequency })}
                    value={frequency}
                    style={{ height: "40px" }}
                    disabled={submitActionTriggered}
                  >
                    {frequencies?.map((entry) => (
                      <option key={entry} value={entry}>
                        {entry}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <com.TextInput
                label="offset"
                type="number"
                value={dueDateOffset ?? 0}
                style={{ width: "75px" }}
                min={0}
                max={365}
                step={1}
                controlClass={"mr-3"}
                onChange={(e) => {
                  if (e.target.value > 365 || e.target.value < 0) {
                    return;
                  }
                  return handleOffsetInput({ e, setFunction: setDueDateOffset });
                }}
                disabled={submitActionTriggered}
              />
              <com.ApiSubmitButton
                action={() => submit({})}
                className="ml-3 mt-5"
                style={{ height: "40px" }}
                icon={<i className="fa-solid fa-plus" />}
                // used to force correct rendering when parent re-renders
                disabled={submitActionTriggered || !name}
                actionTriggered={submitActionTriggered}
                actionDone={actionDone}
              />
            </div>
            {!!reportingProps.current?.dataset.length && !reportingReqsLoading && (
              <div
                id="covenant-table-container"
                className={`custom-flex-container flex-scroll p-2 extend-last-row`}
                style={{ "--lastRowHeight": "200px" }}
              >
                <div>
                  <div>
                    <Table loading={tableLoading} data={tableDisplay} sortColumn={sortColumn} sortType={sortType} {...tableProps}>
                      {tableColumns?.map((field) => {
                        const defaultColumnProps = {
                          sortable: true,
                          key: field,
                        };
                        switch (field) {
                          case "name":
                            defaultColumnProps.flexGrow = 1;
                            break;
                          case "notes":
                            defaultColumnProps.flexGrow = 2;
                            break;
                          default:
                            defaultColumnProps.width = setFieldWidths({ field });
                            break;
                        }
                        return (
                          field !== "id" &&
                          field !== "creditId" && (
                            <Column {...defaultColumnProps}>
                              <HeaderCell className="is-capitalized">{field === "dueDateOffset" ? "Offset" : words(field).join(" ")}</HeaderCell>
                              {field !== "frequency" ? (
                                <InputEditCellInlineSave
                                  tableid="covenant-table-container"
                                  dataKey={field}
                                  disabled={disableFields}
                                  controlClass="mt-3"
                                  setDisabled={setDisableFields}
                                  type={field === "dueDateOffset" ? "number" : "text"}
                                  setmaxlength={(() => {
                                    if (field !== "dueDateOffset") return setFieldLengths({ field });
                                  })()}
                                  min={field === "dueDateOffset" ? 0 : null}
                                  max={field === "dueDateOffset" ? 365 : null}
                                  step={field === "dueDateOffset" ? 1 : null}
                                  handleValidation={({ e, column }) => {
                                    if (column === "dueDateOffset") {
                                      if (e.target.value > 365 || e.target.value < 0) {
                                        return false;
                                      }
                                    } else if (column === "name" || column === "notes") {
                                      return validateText(e);
                                    } else if (column === "offset") {
                                      return validateOffset(e);
                                    }
                                    return true;
                                  }}
                                  parentprops={reportingProps}
                                  inlineSubmitHandler={inlineSubmitHandler}
                                  autoComplete="off"
                                  cb={addToDetails}
                                />
                              ) : field === "frequency" ? (
                                <DroplistCellSimple
                                  dataKey="frequency"
                                  disabled={disableFields}
                                  dataSet={frequencies}
                                  listDisplay={({ entry }) => entry}
                                  cb={async ({ val, key, rowData }) => {
                                    rowData[key] = val;
                                    const exists = reportingProps.current?.detailsDataSet?.findIndex((item) => item?.id === rowData?.id);
                                    if (exists > -1) {
                                      reportingProps.current.detailsDataSet[exists] = { ...rowData };
                                    }
                                    await inlineSubmitHandler({
                                      detail: { current: { ...reportingProps.current.detailsDataSet[exists] } },
                                      rowData,
                                      detailProps: reportingProps.current,
                                    });
                                    addToDetails({ rowId: rowData?.id, data: rowData[key], key });
                                  }}
                                />
                              ) : (
                                <Cell dataKey={field} />
                              )}
                            </Column>
                          )
                        );
                      })}

                      <Column width={actionWidth ? 235 : 70}>
                        <HeaderCell></HeaderCell>
                        <ActionsCell dataKey="actions" disabled={disableFields} />
                      </Column>
                    </Table>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};
export default ReportingRequirements;
