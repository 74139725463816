import { OktaAuth } from '@okta/okta-auth-js'

export const authClient = new OktaAuth({
  issuer: 'https://webbank.okta.com',
  clientId: '0oayqy4tdgWbN0Umv1t7',
  redirectUri: window.location.origin + '/login',
  scopes: ['openid', 'email', 'profile', 'groups'],
  services: {
    autoRenew: true,
    syncStorage: true,
    renewOnTabActivation: true,
    tabInactivityDuration: 1800,
    expireEarlySeconds: 60
  }
});

authClient.authStateManager.subscribe((authState) => {

  if (!authState.isAuthenticated && window.location.pathname.indexOf('logout') === -1) {

    window.Location.href = window.location.origin + '/logout'
  }
});

const parseJwt = (token) => new Promise((resolve, reject) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    resolve(JSON.parse(jsonPayload));
  } catch (e) {
    reject(e);
  }
});

var groups = [];

export const getGroups = () => groups;

const setGroups = async () => {

  parseJwt(await authClient.getIdToken()).then((data) => {

    groups = [...data.groups]
      .filter((group) => group.includes('Okta-SkynetCloud-'))
      .map((group) => group.replace('Okta-SkynetCloud-',''))
      .map((group) => group.toLowerCase());

    groups.push('cloud');

  }).catch((e) => {});
};

authClient.tokenManager.on('added', async (key, newToken) => {

  if (key === 'idToken') await setGroups();
});

export const oktaReady = async (callback) => {

  if (await authClient.isAuthenticated()) callback(1);
  else authClient.tokenManager.on('added', async (key) => {

    if (key === 'idToken') callback(1);
  });
};

export const isAuthenticated = async () => {
  return await authClient.isAuthenticated()
};

export const isRedirect = () => authClient.isLoginRedirect();

export const getToken = () => authClient.getIdToken();

export const logout = () => {
  try {
    authClient.signOut({
      postLogoutRedirectUri: window.location.origin + '/logout'
    });
  } catch (e) {
    window.location.href = '/logout';
  }
}

export const authenticate = async () => {

  await authClient.start();

  if (authClient.isLoginRedirect()) {

    await authClient.storeTokensFromRedirect();

  } else if (!await authClient.isAuthenticated()) {

    await authClient.signInWithRedirect({
      scopes: ['openid', 'email', 'profile', 'groups'],
      nonce: ''
    });

    return Promise.reject(false);

  }

  await setGroups();

  return Promise.resolve();
};
