import { Table } from "rsuite";
import { useState, useEffect, useRef } from "react";
import TextInput from "../../components/TextInput";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import api from "../../api";
import { isEmpty, words, keys, camelCase, orderBy } from "lodash";
import { InputEditCell } from "../../components/tableComponents/customCells";
import { validateText } from "../../services/microservices";

const Covenants = ({ covenantProps, ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);

  const handleInput = ({ e, setFunction }) => {
    if (!validateText(e)) return;
    setFunction(e.target.value);
  };

  const submit = async ({ id, data, method = "create" }) => {
    setSubmitActionTriggered(id || true);
    const body = isEmpty(data) ? { creditId: props?.creditId, name, isPortfolio: covenantProps.current?.isPortfolio, reportId: covenantProps.current?.selectedCovRpt?.id } : data;
    const response = await api[covenantProps.current?.endpoint][method]({ id: data?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setActionDone(true);
    setTimeout(() => {
      if (method === "create") props.refreshDataset({ reportId: covenantProps.current?.selectedCovRpt?.id });
      setActionDone(false);
      setSubmitActionTriggered(false);
      setName("");
    }, 2000);
  };
  
  const defaultFields = {
    id: "",
    reportId: covenantProps.current?.selectedCovRpt?.id,
    name: "",
    actual: "",
    covenant: "",
    isPortfolio: covenantProps.current?.isPortfolio,
    inCompliance: "",
    frequency: "",
    notes: "",
  };
  const tableColumns = keys(defaultFields);
  const details = useRef(structuredClone({ ...defaultFields }));

  const addToDetails = ({ rowId, data, key }) => {
    const exists = covenantProps.current?.detailsDataSet?.find((d) => d?.id === rowId);
    if (!exists || isEmpty(exists)) {
      const tempBody = {};
      tempBody.id = rowId;
      tempBody[key] = data;
      covenantProps.current?.detailsDataSet?.push({ ...tempBody });
    } else {
      exists[key] = data;
    }
  };

  const [name, setName] = useState("");
  const inputWidth = "300px";
  const [tableLoading, setTableLoading] = useState(false);

  const clearFields = () => {
    setName("");
    details.current = structuredClone({ ...defaultFields });
  };

  const [tableOptions, setTableOptions] = useState({});
  useEffect(() => {
    if (covenantProps.current?.cancelled) {
      clearFields();
    }
    setTableOptions(props.edit ? { rowHeight: 65 } : {});
    // eslint-disable-next-line
  }, [covenantProps.current?.cancelled, props.edit]);

  const deleteAction = async ({ body }) => {
    const response = await api[covenantProps.current?.endpoint].delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setTimeout(() => {
      props.refreshDataset({ reportId: covenantProps.current?.selectedCovRpt?.id });
    }, 2000);
  };

  const [actionWidth, setActionWidth] = useState(false);
  const areYouSureRef = useRef([]);
  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <ApiSubmitButton
            action={() => submit({ id: rowData?.id, data: rowData, method: "update" })}
            className="mr-3 mb-3 row-action-button"
            icon={<i className="fa-solid fa-floppy-disk" />}
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered === rowData?.id || (!!areYouSureRef.current?.length && !areYouSureRef.current.includes(rowData?.id))}
            actionTriggered={submitActionTriggered === rowData?.id}
            actionDone={actionDone}
          />
          <AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px", marginBottom: "12px" }}
            disabled={submitActionTriggered === rowData?.id || (!!areYouSureRef.current?.length && !areYouSureRef.current.includes(rowData?.id))}
            hideOtherElementsCb={setActionWidth}
            cb={() => setActionDone(false)}
            data={rowData?.id}
            areYouSureRef={areYouSureRef}
          />
        </div>
      </Cell>
    );
  };

  const setFieldLengths = ({ field }) => {
    switch (field) {
      case "name":
      case "covenant":
      case "notes":
        return 128;
      case "actual":
        return 32;
      case "frequency":
        return 16;
      case "inCompliance":
        return 8;
      default:
        return null;
    }
  };

  const setFieldWidths = ({ field }) => {
    switch (field) {
      case "inCompliance":
        return 120;
      case "actual":
      case "covenant":
      case "frequency":
        return 135;
      case "mostRecentTestDate":
        return 170;
      default:
        return 300;
    }
  };

  return (
    <div className="custom-flex-container">
      {props.edit && (
        <div className="is-flex is-align-items-center p-2">
          <TextInput
            label={`New ${covenantProps.current.sectionLabel} Name`}
            value={name || ""}
            style={{ width: inputWidth }}
            maxlength={128}
            controlClass={"mr-3"}
            onChange={(e) => handleInput({ e, setFunction: setName })}
            disabled={submitActionTriggered === true}
          />

          <ApiSubmitButton
            defaultLabel="create"
            action={() => submit({})}
            className="ml-3 mt-3"
            icon={<i className="fa-solid fa-plus" />}
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered === true || !name}
            actionTriggered={submitActionTriggered === true}
            actionDone={actionDone}
          />
        </div>
      )}
      {!!props.dataset?.length && (
        <div
          id="covenant-table-container"
          className={`custom-flex-container flex-scroll p-2 ${props.extendTableHeight ? "extend-last-row" : ""}`}
          style={{ "--lastRowHeight": props.extendTableHeight }}
        >
          <Table
            data={props.dataset}
            autoHeight={true}
            wordWrap="break-word"
            {...tableOptions}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={(sortColumn, sortType) => {
              const fixedSortColumn = camelCase(sortColumn);
              setTableLoading(true);
              setTimeout(() => {
                covenantProps.current.dataset = orderBy(props.dataset, [(data) => data[fixedSortColumn]?.toLowerCase()], [sortType]);
                props.setDataset(orderBy(props.dataset, [(data) => data[fixedSortColumn]?.toLowerCase()], [sortType]))
                setSortColumn(fixedSortColumn);
                setSortType(sortType);
                setTableLoading(false);
              }, 500);
            }}
            loading={tableLoading}
          >
            {tableColumns?.map((field) => {
              let columnOptions;
              switch (field) {
                case "name":
                case "notes":
                  columnOptions = { sortable: true, key: field, flexGrow: 1 };
                  break;
                default:
                  columnOptions = { sortable: true, key: field, width: setFieldWidths({ field }) };
                  break;
              }
              return (
                field !== "id" &&
                field !== "creditId" &&
                field !== "reportId" &&
                field !== "isPortfolio" && (
                  <Column {...columnOptions}>
                    <HeaderCell className="is-capitalized">{words(field).join(" ")}</HeaderCell>
                    {props.edit ? (
                      <InputEditCell
                        dataKey={field}
                        disabled={submitActionTriggered}
                        cb={addToDetails}
                        autoComplete="off"
                        setmaxlength={(() => setFieldLengths({ field }))()}
                        parentprops={covenantProps}
                      />
                    ) : (
                      <Cell dataKey={field} />
                    )}
                  </Column>
                )
              );
            })}

            {props.edit && (
              <Column width={actionWidth ? 235 : 140}>
                <HeaderCell>...</HeaderCell>
                <ActionsCell dataKey="actions" />
              </Column>
            )}
          </Table>
        </div>
      )}
    </div>
  );
};
export default Covenants;
