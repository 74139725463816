import PageTemplate from './PageTemplate';

const Content = () => {
  return (
    "Dashboard Placeholder..."
  )
};

const Dashboard = () => {
  return (
    <PageTemplate
      className="is-relative"
      pageTitle="Dashboard"
      defaultOpenMenus={["1"]}
      content={<Content />}
    />
  )
};
export default Dashboard;