import { isEmpty } from "lodash";
import { useState, useEffect, useRef } from "react";
import api from "../../api";
import * as com from "../../components";

const CollectionAccountBalance = ({ ...props }) => {
  const [cABState, setCABState] = useState(2);
  // If update requirement comes back, add const [actionText, setActionText] = useState(["Submit", "Submitting..."]); and change cABState === 2 html view's apiSubmitButton labels to use the actionText const
  const [balanceInputDisplay, setBalanceInputDisplay] = useState(props.showCollectionAccountBalance?.value);
  const label = "Collection Account Adjustment";
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncingDone, setSyncingDone] = useState(false);
  const originalBbAdjustments = useRef({});

  const submitCAB = async () => {
    setSubmitTriggered(true);
    if (typeof props.selectedReview?.bbAdjustment?.collectionAccountBalance === "string") {
      props.selectedReview.bbAdjustment.collectionAccountBalance = parseFloat(props.selectedReview?.bbAdjustment?.collectionAccountBalance);
    }
    /*
      If update requirement comes back,
      create a method variable that sets the method to update if props.selectedReview?.bbAdjustment?.syncStatus exists.
      If the method is update, also change props.selectedReview.bbAdjustment.syncStatus to "submitted".
      Change api.bbAdjustments.create to api.bbAdjustments[method].
    */
    const response = await api.bbAdjustments.create({ id: props.selectedReview?.bbAdjustment?.reviewId, body: props.selectedReview.bbAdjustment });
    // set calculated CollectionAccountBalance on BBR table
    const cABExists = props.showCollectionAccountBalance?.sectionCriteria.find((secCrit) => secCrit.viewColumnName === "CollectionAccountBalance");
    cABExists.calculated = response?.collectionAccountBalance;
    props.showCollectionAccountBalance.set({ sectionCriteria: props.showCollectionAccountBalance?.sectionCriteria });
    setSubmitDone(true);
    setTimeout(() => {
      setSubmitTriggered(false);
      setSubmitDone(false);
      setCABState(3);
      props.setCabSynced(new Date());
      props.selectedReview.bbAdjustment = response;
      originalBbAdjustments.current = structuredClone({ ...props.selectedReview.bbAdjustment });
    }, 1000);
    return response;
  };

  const handleCABInput = (e) => {
    const currencyRegex = /^[0-9,.]*$/;
    const valid = currencyRegex.test(e.target.value);
    if (valid) {
      if (e.target.value.includes(".")) {
        const decimalLength = e.target.value.split(".")[1].length;
        if (decimalLength <= 2) {
          props.selectedReview.bbAdjustment.collectionAccountBalance = e.target.value;
          setBalanceInputDisplay(props.selectedReview.bbAdjustment.collectionAccountBalance);
        }
      } else {
        props.selectedReview.bbAdjustment.collectionAccountBalance = e.target.value;
        setBalanceInputDisplay(props.selectedReview.bbAdjustment.collectionAccountBalance);
      }
    } else {
      console.error("invalid character");
    }
  };

  const getBbAdjustment = async () => {
    setSyncing(true);
    const response = await api.bbAdjustments.get({ id: props.selectedReview?.id });
    if (response?.syncStatus === "synced") {
      // If update requirement comes back, just change setCabState to 2 and setActionText(["Update", "Updating..."]);
      setCABState(4);
    }
    setSyncingDone(true);
    setTimeout(() => {
      setSyncing(false);
      setSyncingDone(false);
      props.setCabSynced(new Date(new Date().toString().split("GMT")[0] + " UTC").toISOString());
      props.selectedReview.bbAdjustment = structuredClone({ ...response });
      originalBbAdjustments.current = structuredClone({ ...props.selectedReview.bbAdjustment });
    }, 1000);
    return response;
  };

  const resetActions = () => {
    props.selectedReview.bbAdjustment = structuredClone({ ...originalBbAdjustments.current });
    setBalanceInputDisplay(props.selectedReview.bbAdjustment?.collectionAccountBalance || props.showCollectionAccountBalance?.value);
    setSyncing(false);
    setSubmitTriggered(false);
    setSubmitDone(false);
    setSyncingDone(false);
  };

  useEffect(() => {
    // set original values
    if (isEmpty(originalBbAdjustments.current)) {
      if (!props.selectedReview?.bbAdjustment || isEmpty(props.selectedReview?.bbAdjustment)) {
        props.selectedReview.bbAdjustment = {
          reviewId: props.selectedReview?.id,
          collectionAccountBalance: props.showCollectionAccountBalance?.value,
        };
        originalBbAdjustments.current = structuredClone({ ...props.selectedReview.bbAdjustment });
      } else {
        originalBbAdjustments.current = structuredClone({ ...props.selectedReview.bbAdjustment });
        setCABState(4);
      }
    }
    if (props.cabDropCardActive) {
      setBalanceInputDisplay(props.selectedReview.bbAdjustment?.collectionAccountBalance || props.showCollectionAccountBalance?.value);
      switch (props.selectedReview.bbAdjustment?.syncStatus) {
        case "synced":
          // If update requirement comes back, just change setCabState to 2 and setActionText(["Update", "Updating..."]);
          setCABState(4);
          break;
        case "submitted":
          setCABState(3);
          break;
        default:
          setCABState(2);
          break;
      }
    }
    if (props.cancelTriggered) {
      props.setCancelTriggered(!props.cancelTriggered);
      resetActions();
    }
    // eslint-disable-next-line
  }, [props.cabDropCardActive, props.cancelTriggered]);

  return (
    <div>
      {cABState === 1 && props.cabDropCardActive && (
        <div>
          <button className="button is-primary mr-3" onClick={() => setCABState(2)}>
            <i className="fa-solid fa-pen-to-square mr-2" />
            <span>{label}</span>
          </button>
        </div>
      )}
      {cABState === 2 && props.cabDropCardActive && (
        <div className="is-flex is-align-items-end">
          <com.TextInput
            id="cab-input"
            controlClass="mt-3 mr-3"
            label={label}
            value={balanceInputDisplay}
            onChange={handleCABInput}
            onFocus={() => {
              const input = document.getElementById("cab-input");
              input.focus();
              input.select();
            }}
            onBlur={async () => {
              let tempModifier = balanceInputDisplay;
              if (tempModifier === "") {
                tempModifier = props.showCollectionAccountBalance?.value || 0;
              }

              let tempDisplayValue;
              if (typeof tempModifier === "string") {
                // remove commas
                const tempVal = tempModifier.split(",").join("");
                tempDisplayValue = Number(tempVal).toFixed(2);
              } else {
                tempDisplayValue = tempModifier.toFixed(2);
              }
              props.selectedReview.bbAdjustment.collectionAccountBalance = tempDisplayValue;
              setBalanceInputDisplay(props.selectedReview?.bbAdjustment?.collectionAccountBalance);
            }}
          />
          <com.ApiSubmitButton
            className="mr-3"
            // If update requirement comes back, change apiSubmitButton labels to use the actionText const useState
            defaultLabel="Submit"
            operationLabel="Submitting..."
            icon={<i className="fa-solid fa-cloud-arrow-up" />}
            action={submitCAB}
            actionTriggered={submitTriggered}
            actionDone={submitDone}
            disabled={
              !balanceInputDisplay ||
              balanceInputDisplay === "0" ||
              balanceInputDisplay === "0.00" ||
              balanceInputDisplay === "0.0" ||
              balanceInputDisplay === "0."
            }
          />
        </div>
      )}
      {cABState === 3 && props.cabDropCardActive && (
        <div>
          <span className="help">Check back periodically to see if the balance is up to date.</span>
          <com.ApiSubmitButton
            className="mr-3"
            buttonClassOverride="is-info"
            defaultLabel={`Sync ${label}`}
            operationLabel="Checking Sync Status..."
            icon={<i className="fa-solid fa-arrows-rotate" />}
            action={getBbAdjustment}
            actionTriggered={syncing}
            actionDone={syncingDone}
          />
        </div>
      )}
      {cABState === 4 &&
        (props.selectedReview?.bbAdjustment?.syncStatus === "synced" || props.selectedReview?.bbAdjustment?.syncStatus === "submitted") && (
          // If update requirement comes back, change cABState === 4 to !props.cabDropCardActive
          <div className="is-flex is-flex-direction-row is-align-items-center is-flex-grow-1 mr-3">
            <div className="mr-5">Synced: {label}</div>
            {props.selectedReview?.bbAdjustment?.syncStatus === "synced" && (
              <div>{props.selectedReview?.bbAdjustment?.collectionAccountBalance || "0.00"}</div>
            )}
            {props.selectedReview?.bbAdjustment?.syncStatus === "submitted" && (
              <div>Sync Pending For: {props.selectedReview?.bbAdjustment?.collectionAccountBalance || "0.00"}</div>
            )}
          </div>
        )}
    </div>
  );
};
export default CollectionAccountBalance;
