import { apiFetch } from "..";
import store from "../../store";
import { isEmpty } from "lodash";

export const getBorrowingBaseAdjustment = async ({ id, endpoint = `borrowing-base-adjustments/${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Borrowing Base Adjustment. reviewId (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

export const getBorrowingBaseAdjustments = async ({ endpoint = `borrowing-base-adjustments` }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

export const createBorrowingBaseAdjustment = async ({ body, endpoint = `borrowing-base-adjustments`, method = "POST", iStore = store }) => {
  if (isEmpty(body)) {
    const message = `Unable to create this Borrowing Base Adjustment. body (${body}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

// If update requirement comes back, add an update function and add the function to endpoints as 'update'.

const endpoints = {
  get: ({ id }) => (id ? getBorrowingBaseAdjustment({ id }) : getBorrowingBaseAdjustments({})),
  create: createBorrowingBaseAdjustment,
};
export default endpoints;
