import { Template } from "../../../webbank-ui";
import CreditTable from "../../admin/PrimeCreditTable";
import { useState, useRef } from "react";
import { orderBy, isEmpty } from "lodash";
import api from "../../../api";
import store from "../../../store";
import skynet from "../../../api/skynetData";
import { Card } from "primereact/card";
import { links } from "../../../config/navItems.js";
import config from "../../../config/appSettings.json";
import { ScrollPanel } from "primereact/scrollpanel";

const Content = ({ creditProps }) => {
  return (
    <Card style={{ borderRadius: "var(--border-radius)", marginBottom: "5rem" }}>
      <div className="custom-flex-container">
        <div id="transaction-summary-tab-container" className="custom-flex-container">
          <CreditTable {...creditProps} />
        </div>
      </div>
    </Card>
  );
};

const CreditAdmin = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const [credits, setCredits] = useState([]);
  const [creditsLoading, setCreditsLoading] = useState(false);
  const persons = useRef([]);

  const getCredits = async ({ sortColumn, sortType }) => {
    setCreditsLoading(true);
    const response = await api.credits.get({});
    const ordered = orderBy(
      response,
      [(data) => (data[sortColumn] ? data[sortColumn]?.toLowerCase() : data?.name?.toLowerCase())],
      [sortType ? sortType : "asc"]
    );
    setCredits(ordered);
    return ordered;
  };

  const setup = async () => {
    const endpoints = [getCredits({})];
    if (!store?.skynetPersonsList || isEmpty(store?.skynetPersonsList)) {
      endpoints.push(skynet.persons.get({}));
    }
    const [credits] = await Promise.all(endpoints);
    const creditPersons = store.skynetPersonsList?.filter((person) => person?.department?.toLowerCase()?.includes("credit"));
    persons.current = structuredClone([...creditPersons]);
    setCreditsLoading(false);
    return credits;
  };

  // Tab View's props mapping
  const creditProps = {
    dataset: credits,
    setDataset: setCredits,
    refreshDataset: setup,
    loading: creditsLoading,
    setLoading: setCreditsLoading,
    endpoint: "credits",
    persons: persons.current,
  };

  return (
    <Template
      id="credit-admin"
      pageTitle="Credit Admin"
      setup={setup}
      setDataLoading={setDataLoading}
      globalStore={store}
      links={links}
      config={config}
      content={
        <ScrollPanel style={{ height: "calc(100vh - 8rem)" }}>
          <Content creditProps={creditProps} dataLoading={dataLoading} />
        </ScrollPanel>
      }
    />
  );
};
export default CreditAdmin;
