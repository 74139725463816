import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getCovenantReportsByCreditId = async ({creditId, endpoint = `covenant-reports?creditId=${creditId}`, iStore = store}) => {
  if (!creditId) {
    const message = `Unable to get reports for creditId: ${creditId}. creditId is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getCovenantsByCovRptId = async ({ reportId, endpoint = `covenant-reports/${reportId}?includeCovenant=true`, iStore = store }) => {
  if (!reportId) {
    const message = `Unable to get covenants for reportId: ${reportId}. reportId is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response) {
    const message = 'no response from getCovenants api call';
    console.error(message);
    return message;
  }
  return response;
};

const getCovenants = async ({ endpoint = `covenants`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response) {
    const message = 'no response from getCovenants api call';
    console.error(message);
    return message;
  }
  return response;
};

const createCovRpt = async ({ body, endpoint = `covenant-reports`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this covenant-report. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const createCovenant = async ({ body, endpoint = `covenants`, method = 'POST', iStore = store }) => {
  console.log(`body covenants`, body);
  if (!body || isEmpty(body)) {
    const message = `Unable to create this report covenants. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const updateCovenant = async ({ id, body, endpoint = `covenants/${id}`, method = 'PUT' }) => {
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const deleteCovenant = async ({ id, endpoint = `covenants/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this covenant line item. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const deleteCovRpt = async ({ id, endpoint = `covenant-reports/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this covenant line item. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  getCovenantsByCovRptId,
  getCovenantReportsByCreditId,
  createCovRpt,
  deleteCovRpt,
  get: getCovenants,
  create: createCovenant,
  update: updateCovenant,
  delete: deleteCovenant
};
export default endpoints;