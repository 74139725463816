import { DatePicker, Loader } from "rsuite";
import { useState, useEffect, useRef } from "react";
import api from "../../api";
import { isEmpty } from "lodash";
import { shouldDisableDate } from "../../services/microservices";
import { v4 as uuidV4 } from "uuid";
import Covenants from "./Covenants";
import * as com from "../../components";

const CovenantReport = ({ covenantProps, ...props }) => {
  // == state ==
  const [loadingCovenants, setLoadingCovenants] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [hideDatePicker, setHideDatePicker] = useState(false);
  const [createDisplayDate, setCreateDisplayDate] = useState(new Date());
  const [covRptSaving, setCovRptSaving] = useState(false);
  const [covRptDone, setCovRptDone] = useState(false);
  const [covenants, setCovenants] = useState([]);
  const [selectedCovRpt, setSelectedCovRpt] = useState({});
  const [covRpts, setCovRpts] = useState([]);

  const getDefaultDisplayDate = () => {
    const existingForToday = shouldDisableDate({ date: new Date(), existingDateList: covenantProps.current?.covRpts, key: "reportDate" });
    return existingForToday ? "" : new Date().toISOString().split('T')[0];
  };

  // == Variables and Refs ==
  const defaultCovRptDetails = {
    creditId: props.creditId,
    reportDate: getDefaultDisplayDate(),
    isPortfolio: covenantProps.current?.isPortfolio,
  };

  const createDetails = useRef({ ...defaultCovRptDetails });

  // == Functions and callbacks ==
  const handleCovenantReportSelection = async (e) => {
    const tempSelectedCovRpt = covenantProps.current?.covRpts.find((rpt) => rpt.id === e.target.value);
    covenantProps.current.selectedCovRpt = structuredClone({ ...tempSelectedCovRpt });
    setSelectedCovRpt({ ...covenantProps.current.selectedCovRpt });
    await getCovenantsByReportId({ reportId: tempSelectedCovRpt?.id });
  };

  const toggleDatePickerModal = () => {
    if (!showContainer) {
      setShowContainer(true);
      setShowDatePicker(true);
      setHideDatePicker(false);
    } else {
      setShowDatePicker(false);
      setHideDatePicker(true);
      setTimeout(() => {
        setShowContainer(false);
      }, 300);
    }
  };

  const handlePOSTDateSelection = ({ date, setter }) => {
    setCreateDisplayDate(date);
    setter.reportDate = new Date(date).toISOString().split("T")[0];
  };

  const deleteCovRpt = async ({ id }) => {
    await api.covenants.deleteCovRpt({ id });
  };

  const getCovenantsByReportId = async ({ reportId }) => {
    if (!reportId) return;
    setLoadingCovenants(true);
    const response = await api.covenants.getCovenantsByCovRptId({ reportId });
    if (response?.covenants) {
      setCovenants(structuredClone([...response.covenants]));
    }
    setLoadingCovenants(false);
  };

  const setupCovenants = async () => {
    if (!covenantProps.current?.selectedCovRpt || isEmpty(covenantProps.current?.selectedCovRpt)) {
      covenantProps.current.selectedCovRpt = structuredClone({ ...covenantProps.current?.covRpts[0] });
    }
    await getCovenantsByReportId({ reportId: covenantProps.current.selectedCovRpt?.id });
  };

  useEffect(() => {
    setCovRpts(covenantProps.current?.covRpts);
    setupCovenants();
    if (covenantProps.current?.selectedCovRpt && !isEmpty(covenantProps.current?.selectedCovRpt)) {
      const el = document.getElementById(`${covenantProps.current?.isPortfolio ? "portfolio" : "financial"}-report-selector`);
      el.value = covenantProps.current?.selectedCovRpt?.id;
      handleCovenantReportSelection({ target: { value: covenantProps.current?.selectedCovRpt?.id } });
    }
    const covRptExistingForDate = covenantProps.current?.covRpts?.find((rpt) => rpt.reportDate === new Date().toISOString().split("T")[0]);
    if (!isEmpty(covRptExistingForDate)) {
      setCreateDisplayDate(null);
    } else {
      setCreateDisplayDate(new Date());
      if (!defaultCovRptDetails.reportDate) {
        defaultCovRptDetails.reportDate = new Date().toISOString().split("T")[0];
      }
    }
    // eslint-disable-next-line
  }, [props.edit]);

  return (
    <div className="custom-flex-container">
      {(!isEmpty(covenantProps.current?.covRpts) || props.edit) && (
        <label className={`is-capitalized has-text-info-dark is-size-5 has-text-weight-bold`}>{covenantProps.current.sectionLabel}</label>
      )}
      <div className="is-flex is-align-items-center">
        {!isEmpty(covenantProps.current?.covRpts) && (
          <div className="is-flex is-flex-direction-column mx-3 mb-4">
            <label className="is-size-6">Report Date</label>
            <div className="select" style={{ width: "200px" }}>
              <select
                id={`${covenantProps.current?.isPortfolio ? "portfolio" : "financial"}-report-selector`}
                className="select-input"
                onChange={handleCovenantReportSelection}
                value={selectedCovRpt?.id}
                disabled={props.edit}
              >
                {covRpts?.map((entry) => (
                  <option key={entry?.id} value={entry?.id}>
                    {entry?.reportDate}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {props.edit && (
          <div className="is-flex">
            <div id={`new-${covenantProps.current?.isPortfolio ? "portfolio" : "financial"}-report-button`} className="is-flex ml-4">
              <button className="button is-primary is-capitalized ml-3 mt-3" onClick={toggleDatePickerModal}>
                <i className="fa-solid fa-plus mr-2" />
                <span>{`New ${covenantProps.current?.isPortfolio ? "Portfolio" : "Financial"} Covenant Report`}</span>
              </button>
            </div>
            {!isEmpty(covenantProps.current?.covRpts) && (
              <div className="ml-3 mt-3">
                <com.AreYouSureDelete
                  action={() => deleteCovRpt({ id: covenantProps.current?.selectedCovRpt?.id })}
                  icon={<i className="fa-solid fa-trash" />}
                  defaultText={`Delete ${covenantProps.current?.isPortfolio ? "Portfolio" : "Financial"} Covenant Report`}
                  disabled={covRptSaving}
                  cb={async () => {
                    createDetails.current = structuredClone({ ...defaultCovRptDetails });
                    covenantProps.current.selectedCovRpt = null;
                    props.setEdit(false);
                    props.getCovenantReportsByCreditId({ creditId: props.creditId });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        {showContainer && (
          <div className="gateway-modal-container">
            <div className={`gateway-modal ${showDatePicker ? "show" : ""} ${hideDatePicker ? "hide" : ""}`}>
              <div className={`p-3 gateway-container`}>
                <com.Modal
                  title={`New ${covenantProps.current?.isPortfolio ? "Portfolio" : "Financial"} Covenant Report`}
                  cardStyleOverride={{ width: "450px" }}
                  hideBackground={true}
                  body={
                    <div className="is-size-6 mt-3 date-picker-container">
                      <label className="mr-3">Covenant Date</label>
                      <DatePicker
                        container={document.getElementById("transaction-summary")}
                        value={createDisplayDate}
                        block
                        shouldDisableDate={(date) => shouldDisableDate({ date, existingDateList: covenantProps.current?.covRpts, key: "reportDate" })}
                        onChange={(date) => handlePOSTDateSelection({ date, setter: createDetails.current })}
                        oneTap
                        disabled={covRptSaving === true}
                        style={{ height: "40px" }}
                      />
                    </div>
                  }
                  showCloseX={true}
                  actions={[
                    {
                      buttonClass: "is-warning",
                      text: "Cancel",
                      iconClass: "fa-solid fa-ban",
                      action: toggleDatePickerModal,
                      disabled: covRptSaving,
                    },
                    {
                      id: uuidV4(),
                      customButton: true,
                      body: (
                        <com.ApiSubmitButton
                          defaultLabel={`Create Covenant Report`}
                          operationLabel="Creating Report"
                          icon={<i className="fa-solid fa-floppy-disk" />}
                          disabled={covRptSaving || !createDisplayDate}
                          action={async () => {
                            setCovRptSaving(true);
                            const apiResponse = await api.covenants.createCovRpt({ body: createDetails.current });
                            setCovRptDone(true);
                            setTimeout(async () => {
                              setCreateDisplayDate(null);
                              createDetails.current = structuredClone({ ...defaultCovRptDetails });
                              setCovRptSaving(false);
                              toggleDatePickerModal();
                              covenantProps.current.selectedCovRpt = apiResponse;
                              setCovRptDone(false);
                              await props.getCovenantReportsByCreditId({ creditId: props.creditId });
                            }, 1000);
                            return apiResponse;
                          }}
                          // used to force correct rendering when parent re-renders
                          actionTriggered={covRptSaving}
                          actionDone={covRptDone}
                        />
                      ),
                    },
                  ]}
                  showModal={showDatePicker && !hideDatePicker}
                  handleClose={toggleDatePickerModal}
                />
              </div>
            </div>
          </div>
        )}
        {loadingCovenants ? (
          <div className="is-relative" style={{ height: "200px" }}>
            <Loader center size="sm" content={`Loading ${covenantProps.current?.isPortfolio ? "Portfolio" : "Financial"} Covenants...`} />
          </div>
        ) : (
          !isEmpty(covenantProps.current?.covRpts) && (
            <Covenants
              {...props}
              refreshDataset={getCovenantsByReportId}
              covenantProps={covenantProps}
              dataset={covenants}
              setDataset={setCovenants}
            />
          )
        )}
      </div>
    </div>
  );
};
export default CovenantReport;
