import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const bbReviewDataUpdate = async ({ id, body, endpoint = `borrowing-base-review-data/${id}`, method = 'PUT', iStore = store }) => {
  if (!id) {
    const message = `Unable to update this Borrowing Base Review Data. id (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, method, endpoint, body });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  update: bbReviewDataUpdate
};

export default endpoints;