import configFile from "./config/appSettings.json";
import React, { useState } from "react";
import reportWebVitals from "./reportWebVitals";
import "./styles/main.scss";
import { Amplify } from "aws-amplify";
import router, { routes } from "../src/routes/index.js";
import aws_exports, { env as amplifyEnv } from "./redirect-mutator";
import SignBackInModal from "./components/signbackinmodal";
import SystemMessages from "./components/messaging/systemmessages";
import store from "./store";
import { values, startCase } from "lodash";
import { PrimeReactProvider } from "primereact/api";

const applyConfigData = (config) => {
  const nameOfApp = document.querySelectorAll("#name-of-app");
  const metaDataElements = values(document.querySelectorAll("meta"));
  const metaDataDescription = metaDataElements?.find((data) => data?.name === "description");
  metaDataDescription.content = config.nameOfApp;
  nameOfApp[0].innerText = config.nameOfApp;
};
applyConfigData(configFile);

// reassignment of dispatchEvent to ignore ResizeObserver
// certain rsuite elements sometimes give a harmless error
// that is cause from the resize animation. This was the
// suggested way to ignore it from github.
const originalDispatchEvent = window.dispatchEvent;
window.dispatchEvent = (event) => {
  if (event.message.includes("ResizeObserver")) {
    console.warn("Ignored ResizeObserver error");
    return;
  }
  originalDispatchEvent(event);
};

const awsConfig = aws_exports();

Amplify.configure(awsConfig);

const EnvBanner = () => {
  if (amplifyEnv !== "production") {
    if (store.isPrimeReact) {
      return (
        <div className="banner-container">
          <div className="banner-call-to-action" style={{ cursor: "default", background: "blue !important" }}>
            <div className="p-2">{startCase(amplifyEnv)}</div>
          </div>
        </div>
      );
    }
    return (
      <div className="env-banner banner is-uppercase is-relative py-1 has-text-centered is-size-7">
        <div className="inner-banner">{amplifyEnv}</div>
      </div>
    );
  }
};

const Main = () => {
  const [globalStore, setStore] = useState(store);

  const handleStoreUpdates = async (update = globalStore) => {
    setStore({ ...update });
    return globalStore;
  };
  store.handleStoreUpdates = handleStoreUpdates;

  const value = {
    ripple: true,
  };

  return (
    <PrimeReactProvider value={value}>
      <div id="app-container" className="is-flex is-flex-direction-column" style={{ height: "100vh" }}>
        {!!globalStore?.notifications?.length && <SystemMessages globalStore={globalStore} />}
        <EnvBanner></EnvBanner>
        <SignBackInModal key={globalStore.signBackIn} globalStore={globalStore} handleStoreUpdates={handleStoreUpdates} />
        <div id="route-container" className="custom-flex-container">
          <router.RouterProvider router={routes} />
        </div>
      </div>
    </PrimeReactProvider>
  );
};

router.ReactDOM.createRoot(document.getElementById("root")).render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
