import { apiFetch } from "..";
import store from "../../store";
import { isEmpty } from "lodash";

const listChoicesByCriteria = async ({ criteriaId, endpoint = `lqr-criteria-choices?criteriaId=${criteriaId}`, iStore = store }) => {
  if (!criteriaId) {
    const message = `Unable to get this choice. Criteria ID (${criteriaId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }

  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const listChoices = async ({ endpoint = `lqr-criteria-choices`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const createChoice = async ({ body, endpoint = `lqr-criteria-choices`, method = "POST", iStore = store }) => {
  console.log("create body", body);
  if (!body || isEmpty(body)) {
    const message = `Unable to create this Field. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const updateChoice = async ({ id, body, endpoint = `lqr-criteria-choices/${id}`, method = "PUT", iStore = store }) => {
  console.log("update", id, body);
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this Field. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const deleteChoice = async ({ id, endpoint = `lqr-criteria-choices/${id}`, method = "DELETE", iStore = store }) => {
  console.log("delete id", id);
  if (!id) {
    const message = `Unable to delete this Field. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return id;
  return response;
};

const endpoints = {
  get({ criteriaId }) {
    if (criteriaId) {
      return listChoicesByCriteria({ criteriaId });
    }
    return listChoices({});
  },
  create: createChoice,
  update: updateChoice,
  delete: deleteChoice,
};

export default endpoints;
