import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../../api";
import { isEmpty, orderBy, startCase } from "lodash";
// PrimeReact Conversion
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { v4 as uuidv4 } from "uuid";
import { MultiSelect } from "primereact/multiselect";
import { exportPdf, PrimeLoader, MultiSelectAllHeader } from "../../webbank-ui";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";

const CreditTable = ({ dataLoading, ...props }) => {
  const [creditName, setCreditName] = useState("");
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [disableFields, setDisableFields] = useState(false);
  const originalDataset = useRef([]);

  const handleCreditInput = (e) => {
    const input = e.target.value;
    if (input.trim().length === 0 && e.target.value.length > 0) {
      console.error("Input cannot be all spaces");
      return;
    }
    setCreditName(e.target.value);
  };

  const handlePersonSelect = (e) => {
    const tempPerson = props.persons?.find((person) => person.id === e.value);
    setSelectedPerson(tempPerson);
  };

  const submit = async () => {
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const response = await api[props.endpoint].create({ body: { name: creditName, analystId: selectedPerson?.id } });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    // create Borrowing Base Summary Criteria Section so it is set up for every new credit.
    api.bbSections.create({ body: { name: "Borrowing Base Summary", sortOrder: 0, creditId: response?.id } });
    await props.refreshDataset({});
    setActionDone(true);
    setTimeout(() => {
      setActionDone(false);
      setDisableFields(false);
      setSubmitActionTriggered(false);
      setCreditName("");
      handlePersonSelect("");
    }, 1000);
    return response;
  };

  // toolbar
  const toolbarStart = (
    <span className="flex">
      {props?.persons?.length ? (
        <span className="flex flex-column mr-5" style={{ width: "20rem" }}>
          <label htmlFor="new-credit-name">New Credit</label>
          <InputText
            id="new-credit-name"
            value={creditName ?? ""}
            onChange={handleCreditInput}
            disabled={disableFields}
            maxLength={32}
            autoComplete="off"
          />
          <span className="char-counter">
            {creditName?.length ?? 0}/{32}
          </span>
        </span>
      ) : (
        <Skeleton width="20rem" height="2.75rem" className="mr-5" />
      )}
      {props?.persons?.length ? (
        <span className="flex flex-column">
          <label htmlFor="new-credit-analyst">Credit Analyst</label>
          <Dropdown
            value={selectedPerson?.id ?? ""}
            onChange={handlePersonSelect}
            options={props.persons?.map((person) => ({ label: person.name, value: person.id }))}
            optionLabel="label"
            placeholder="Select an Analyst"
            filter
            disabled={disableFields}
            style={{ width: "50rem", maxWidth: "20rem" }}
          />
        </span>
      ) : (
        <Skeleton width="17.5rem" height="2.75rem" />
      )}
    </span>
  );

  const toolbarEnd = (
    <span>
      {!actionDone ? (
        props?.persons?.length ? (
          <Button
            className="p-button-success"
            label="New"
            icon="pi pi-plus"
            onClick={submit}
            disabled={!creditName || !selectedPerson || isEmpty(selectedPerson)}
            loading={submitActionTriggered && !actionDone}
          />
        ) : (
          <Skeleton width="5.5rem" height="2.75rem" />
        )
      ) : (
        <Button className="p-button-success" label="Done" icon="pi pi-check" disabled />
      )}
    </span>
  );

  // PrimeReact Table
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState(1);
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteTriggered, setDeleteTriggered] = useState(false);
  const [deleteDone, setDeleteDone] = useState(false);

  const handleSort = (event) => {
    const workingData = [...props.dataset];
    const ordered = orderBy(
      workingData,
      [
        (data) => {
          if (event.sortField === "analystId") {
            const tempAnalyst = props.persons.find((person) => person.id === data.analystId);
            return tempAnalyst ? tempAnalyst?.name?.toLowerCase() : "";
          }
          return data[event.sortField]?.toLowerCase();
        },
      ],
      [event.sortOrder === 1 ? "asc" : "desc"]
    );
    originalDataset.current = structuredClone([...ordered]);
    setSortField(event.sortField);
    setSortDirection(event.sortOrder);
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns
      .map((mCol) => ({ field: mCol, header: mCol }))
      .filter((col) => selectedColumns.some((sCol) => sCol.field === col.field))
      .map((rCol) => rCol.field);

    setVisibleColumns(orderedSelectedColumns);
  };

  const creditNameTemplate = ({ rowData, column }) => {
    return <span>{rowData[column]}</span>;
  };

  const analystTemplate = ({ rowData, column }) => {
    const person = props.persons.find((person) => person.id === rowData.analystId);
    return <span>{person?.name}</span>;
  };

  const setupTable = () => {
    return visibleColumns.map((column, idx) => {
      return (
        <Column
          key={uuidv4()}
          field={column}
          body={
            column === "name"
              ? (rowData) => creditNameTemplate({ rowData, column })
              : column === "analystId"
              ? (rowData) => analystTemplate({ rowData, column })
              : (rowData) => ActionsCell({ rowData, column })
          }
          header={column !== "actions" ? startCase(column) : null}
          sortable={column !== "actions"}
          editor={column === "actions" || updateActionTriggered ? false : cellEditor} // disable edit while updating
          style={{ minWidth: "170px" }}
          onCellEditComplete={onCellEditComplete}
          {...(column === "actions" ? { style: { width: "6rem" } } : column === "name" ? { style: { width: "100vw", maxWidth: "23rem" } } : {})}
        />
      );
    });
  };

  const deleteAction = async ({ id }) => {
    setDeleteTriggered(id);
    await api[props.endpoint].delete({ id });

    setDeleteDone(id);
    setTimeout(async () => {
      const workingData = structuredClone(props.dataset);
      const originalIdx = workingData.findIndex((row) => row.id === id);
      workingData.splice(originalIdx, 1);
      originalDataset.current = structuredClone(workingData);
      props.setDataset(structuredClone(workingData));
      setDeleteTriggered(false);
      setDeleteDone(false);
      setShowDelete(false);
    }, 2000);
  };

  // refresh view updates the dom with the latest table data. It is a callback function triggered by the DataTable component's value prop updating.
  const refreshView = () => {
    props.setDataset([]);
    setTimeout(() => {
      props.setDataset(structuredClone([...originalDataset.current]));
    }, 100);
  };

  const update = async ({ body, setUpdateActionTriggered, setUpdateActionDone }) => {
    setUpdateActionTriggered(body?.id);
    const response = await api[props.endpoint].update({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    // handle update of stored selectedCredit in sessionStorage
    const existsInSession = JSON.parse(sessionStorage.getItem("selectedCredit"))?.id === body?.id;
    if (existsInSession) {
      sessionStorage.setItem("selectedCredit", JSON.stringify(response));
    }
    setUpdateActionDone(body?.id);
    setTimeout(() => {
      setUpdateActionDone(false);
      setUpdateActionTriggered(false);
    }, 2000);
    return response;
  };

  const onCellEditComplete = (event) => {
    const { rowData, field, newValue, newRowData } = event;
    let triggerUpdate = false;
    const originalRowData = originalDataset.current.find((row) => row.id === rowData.id);

    if (field === "name") {
      if (newValue.trim().length === 0) {
        event.newValue = originalRowData.name;
        event.value = originalRowData.name;
        rowData.name = originalRowData.name;
        newRowData.name = originalRowData.name;
        console.error("cannot be blank... resetting name");
        return;
      }
      if (newValue !== originalRowData.name) {
        triggerUpdate = true;
      }
    } else if (field === "analystId") {
      const originalAnalystId = originalRowData.analystId;
      const NewAnalystId = newValue;
      if (originalAnalystId !== NewAnalystId) {
        triggerUpdate = true;
      }
    }

    if (!triggerUpdate) return;

    // handle dom preflight
    const originalIdx = originalDataset.current.findIndex((row) => row.id === newRowData?.id);
    originalDataset.current[originalIdx] = { ...newRowData };
    refreshView();

    // send update
    return update({ body: newRowData, setUpdateActionTriggered, setUpdateActionDone });
  };

  const cellEditor = (options) => {
    const { rowData, field } = options;
    const handlePersonChange = (e) => {
      rowData[field] = e.value;
      options.value = e.value;
      e.preventDefault();
    };
    if (field === "name") {
      setTimeout(() => {
        const inputFocusEl = document.getElementById(rowData?.id);
        if (inputFocusEl) {
          inputFocusEl?.focus();
        }
      }, 200);
      return (
        <div className="flex flex-column">
          <InputText
            id={rowData?.id}
            type="text"
            value={options.value}
            onChange={(e) => {
              const input = e.target.value;
              if (input.trim().length === 0 && input.length > 0) {
                console.error("Input cannot be all spaces");
                return;
              }
              options.editorCallback(input);
            }}
            maxLength={32}
            autoComplete="off"
          />
          <span className="char-counter">
            {options.value?.length ?? 0}/{32}
          </span>
        </div>
      );
    }
    return (
      <Dropdown
        autoFocus={true}
        filterInputAutoFocus={true}
        placeholder="Select a Person"
        value={options.value}
        options={props.persons}
        optionLabel="name"
        optionValue="id"
        filter
        onChange={handlePersonChange}
        className="mr-3"
      />
    );
  };

  const [checkAllVisibleColumns, setCheckAllVisibleColumns] = useState(visibleColumns?.length === columns?.length);
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        {props?.persons?.length ? (
          <MultiSelect
            panelHeaderTemplate={
              <MultiSelectAllHeader
                selected={checkAllVisibleColumns}
                setSelected={setCheckAllVisibleColumns}
                dataset1={visibleColumns}
                dataset2={columns}
                setDataset1={setVisibleColumns}
              />
            }
            value={visibleColumns.map((vc) => ({ field: vc, header: startCase(vc) }))}
            options={columns.map((vc) => ({ field: vc, header: startCase(vc) }))}
            optionLabel="header"
            onChange={onColumnToggle}
            className="mr-3"
            display="chip"
          />
        ) : (
          <Skeleton width="20rem" height="2.75rem" className="mr-3" />
        )}
        <div className="flex justify-content-end">
          {props?.persons?.length ? (
            <Button
              rounded
              raised
              icon="pi pi-file-pdf"
              severity="info"
              className="mr-3"
              onClick={() => exportPdf({ fileName: "Credits_Table" })}
              tooltip="Export PDF"
              tooltipOptions={{ position: "bottom" }}
            />
          ) : (
            <Skeleton shape="circle" size="2.625rem" />
          )}
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <div className="flex justify-content-end align-items-center mr-3">
        {updateActionTriggered === rowData?.id && !updateActionDone ? (
          <PrimeLoader className="mr-3" message="Updating..." />
        ) : (
          updateActionDone === rowData?.id && (
            <span className="flex align-items-center mr-3 p-success" style={{ color: "var(--green-500)" }}>
              <i className="pi pi-check mr-2" />
              <span className="help">Done</span>
            </span>
          )
        )}
        {!deleteDone && deleteDone !== rowData?.id ? (
          <Button
            loading={deleteTriggered === rowData?.id}
            className="p-button-warning"
            icon="pi pi-trash"
            disabled={showDelete}
            onClick={() => setShowDelete(rowData?.id)}
          />
        ) : (
          deleteDone === rowData?.id && <Button className="p-button-warning" icon="pi pi-check" disabled />
        )}
      </div>
    );
  };

  const setupTableData = useCallback(() => {
    // copy the dataset to originalDataset so we can refer to original data when updating
    originalDataset.current = structuredClone(props.dataset);
    const hideColumnList = ["id", "startDate", "createdBy", "endDate", "updatedBy"];
    const tempColumns = Object.keys(props.dataset[0]).filter((key) => !hideColumnList.includes(key));
    tempColumns.push("actions");
    setColumns(tempColumns);
    setVisibleColumns(tempColumns);
  }, [props.dataset]);

  useEffect(() => {
    if (props.dataset.length > 0) {
      setupTableData();
    }
  }, [props.dataset, setupTableData]);

  return (
    <React.Fragment>
      <ConfirmDialog
        visible={showDelete}
        onHide={() => setShowDelete(false)}
        message="Are you sure you want to delete this credit?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={() => deleteAction({ id: showDelete })}
      />
      <Toolbar start={toolbarStart} end={toolbarEnd} />
      <Card className="mt-5">
        {props.dataset.length && props?.persons?.length ? (
          <DataTable
            onValueChange={refreshView}
            value={props.dataset}
            tableStyle={{ minWidth: "50rem" }}
            lazy={true} // used to prevent the table from rendering before the data is ready
            header={header}
            sortField={sortField}
            sortOrder={sortDirection}
            onSort={handleSort}
            selectionMode="cell"
          >
            {setupTable()}
          </DataTable>
        ) : (
          // DataTable NEEDS TO BE WRAPPED IN A DIV FOR THE VALUES TO WORK
          <div>
            <DataTable tableStyle={{ minWidth: "50rem" }} header={header} value={[{ name: "", analystId: "", actions: "" }]}>
              <Column field="name" header="Name" body={<Skeleton />} style={{ width: "23rem" }} />
              <Column field="analystId" header="Analyst Id" body={<Skeleton />} />
              <Column field="actions" body={<Skeleton shape="square" size="2.625rem" />} style={{ width: "6rem", paddingLeft: "2.5rem" }} />
            </DataTable>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};
export default CreditTable;
