import { useNavigate } from "react-router-dom";
import { logout } from '../store';
import config from '../config/appSettings.json';

export default function Header() {
  const navigate = useNavigate();

  const goHome = (path = '/') => {
    navigate(path);
  };

  const handleSignOut = () => {
    (async () => await logout())();
  };

  return (
    <>
      <header className="header is-flex is-justify-contents-space-between is-align-items-center use-rsuite">
        <nav className="navbar p-2 is-flex is-justify-content-space-between is-align-items-center is-width-full">
          <div className="navbar-brand is-flex is-justify-content-space-between is-clickable" onClick={() => goHome('/')}>
            <img id="brand" src="../WebBank-logo.png" alt="brand" className="src" />
            <span className="ml-2">{config.nameOfApp} <span className="is-size-7">An Asset Based Lending Management System</span></span>
          </div>
          <div className="secondary-text is-clickable">
            <i className="fa-solid fa-arrow-right-from-bracket mr-1"></i>
            <span onClick={handleSignOut}>Sign Out</span>
          </div>
        </nav>
      </header>
    </>
  )
}