import { v4 as uuidV4 } from "uuid";
import { startCase, noop } from "lodash";

export const DropCard = ({
  id,
  permission,
  triggerButtonText,
  cSSVars,
  content,
  actions,
  controlClass,
  controlIcon,
  controlStyle,
  dropCardActive,
  setDropCardActive,
}) => {
  const setClass = ({ icon, className }) => {
    return icon ? `${className} has-icon-left` : className;
  };

  return (
    permission && (
      <div id={id} className="drop-card-anchor is-flex use-rsuite" style={controlStyle || { zIndex: 99 }}>
        <div
          className={`${dropCardActive ? "active drop-card" : ""} ${controlClass}`}
          onClick={() => (dropCardActive ? noop() : setDropCardActive(!dropCardActive))}
          style={
            dropCardActive
              ? {
                  "--dropCardButtonWidth": cSSVars?.dropCardButtonWidth,
                  "--dropCardButtonHeight": cSSVars?.dropCardButtonHeight,
                  "--dropHeight": cSSVars?.dropHeight,
                  "--dropWidth": cSSVars?.dropWidth ? cSSVars?.dropWidth : "600px",
                  "--dropBorderColor": cSSVars?.dropBorderColor,
                  "--dropBackgroundColor": cSSVars?.dropBackgroundColor,
                  "--dropCardTopPosition": cSSVars?.dropCardTopPosition,
                }
              : {}
          }
        >
          <span>
            {!dropCardActive && <span>{controlIcon}</span>}
            <span>{triggerButtonText}</span>
          </span>
          {dropCardActive && (
            <div
              className="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-start drop-card-content mt-3"
              style={{ width: "100%" }}
            >
              {/* the inner contents of the drop card */}
              <div className="button-style-overrides" style={{ width: "100%" }}>
                {content}
              </div>

              <div className="drop-card-actions-container is-flex">
                {actions?.map(({ isCustom, id, icon, text, action, className, disabled }) =>
                  isCustom ? (
                    <div key={id || uuidV4()}>{isCustom}</div>
                  ) : (
                    <button key={id || uuidV4()} className={`button mr-3 ${setClass({ icon, className })}`} onClick={action} disabled={disabled}>
                      <span className={icon ? "mr-2" : ""}>{icon}</span>
                      <span>{startCase(text?.toLowerCase())}</span>
                    </button>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default DropCard;
