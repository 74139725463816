import { Checkbox, TagPicker, DatePicker } from "rsuite";
import api from "../../api";
import { useEffect, useState, useRef } from "react";
import { v4 as uuidV4 } from "uuid";
import microservices from "../../services/microservices";
import { isEmpty, words, get } from "lodash";
import CurrencyInputCell from "./CurrencyInputCell";
import * as com from "../../components";

const FundingRequest = ({ ...props }) => {
  const presetEmails = [
    { address: "spfinance@webbank.com", value: false },
    { address: "sslpgroupoperations@webbank.com", value: false },
  ];
  /* 
  !!! ==== IMPORTANT ==== !!!!
  presetFundingAmounts is the definition for funding types the UI uses to mutate what the api gives us. 
  If the backend adds a new one, we need to add it to this list manually with a code change.
  We can change this in the future if we add a lot of these to be a dev section user interface.
  */
  const presetFundingAmounts = [
    {
      id: "advanceFundingAmount",
      label: "Advance",
      value: 0,
      comparisonReferenceLabel: "Borrowing Availability",
      comparisonReferencePath: `tableData[${props.tableData?.findIndex((data) => data?.viewColumnName === "BorrowingBaseAmount")}].reported`,
      comparisonRefValue: 0,
    },
    {
      id: "waterfallFundingAmount",
      label: "Waterfall",
      value: 0,
      comparisonReferenceLabel: "Collection Account Balance",
      comparisonReferencePath: "selectedReview.bbAdjustment.collectionAccountBalance",
      comparisonRefValue: 0,
    },
  ];
  const [displayDetails, setDisplayDetails] = useState({});
  const originalFundingDetails = useRef({});
  const fundingDetails = useRef({});
  const [requestSaving, setRequestSaving] = useState(false);
  const [requestDone, setRequestDone] = useState(false);
  const [requestSubmitting, setRequestSubmitting] = useState(false);
  const [submittingDone, setSubmittingDone] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [total, setTotal] = useState(0);
  const [emails, setEmails] = useState([...presetEmails]);
  const amountsTagPickerData = presetFundingAmounts?.map((item) => ({ label: item.label, value: item.label }));
  const [amountSelections, setAmountSelections] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  const handleInputs = ({ e, field }) => {
    fundingDetails.current[field] = e.target.value;
    setDisplayDetails({ ...fundingDetails.current });
  };

  const handleDateSelection = (e) => {
    fundingDetails.current.fundingDate = e;
    setDisplayDetails({ ...fundingDetails?.current });
  };

  // === Email Handling and Validations ===
  const handleEmailPresets = async ({ e, address }) => {
    const exists = emails?.find((email) => email.address === address);
    if (!isEmpty(exists)) {
      exists.value = e;
      setEmails([...emails]);
      if (e) {
        if (Array.isArray(fundingDetails.current?.presetEmails)) {
          fundingDetails.current?.presetEmails?.push(exists?.address);
        } else {
          fundingDetails.current.presetEmails = [exists?.address];
        }
      } else if (!e && Array.isArray(fundingDetails.current?.presetEmails)) {
        const existsIdx = fundingDetails.current?.presetEmails?.indexOf(exists);
        fundingDetails.current?.presetEmails?.splice(existsIdx, 1);
      }
    }
    await transformRecipientList();
  };

  const checkInvalidEmail = async () => {
    const invalidEmails = fundingDetails?.current?.recipients?.find((email) => !email?.endsWith("@webbank.com"));
    const emailRegex = /^[\w.-]+@[\w.-]+\.\w+$/;
    let isNotEmail;
    if (fundingDetails?.current?.recipients) {
      for (let email of fundingDetails.current.recipients) {
        if (!emailRegex.test(email)) {
          isNotEmail = true;
          break;
        }
      }
    }
    return !!invalidEmails || isNotEmail;
  };

  const transformRecipientList = async () => {
    if (fundingDetails?.current?.additionalEmails && fundingDetails?.current?.additionalEmails?.includes(",")) {
      const noSpaces = fundingDetails?.current?.additionalEmails.replace(" ", "");
      const tempRecipients = noSpaces?.split(",");
      fundingDetails.current.recipients = tempRecipients;
    } else if (
      fundingDetails?.current?.additionalEmails &&
      typeof fundingDetails?.current?.additionalEmails === "string" &&
      fundingDetails?.current?.additionalEmails?.split("@")?.length > 2
    ) {
      console.error("emails aren't comma separated");
      return false;
    } else if (fundingDetails?.current?.additionalEmails && typeof fundingDetails?.current?.additionalEmails === "string") {
      const tempRecipients = [fundingDetails?.current?.additionalEmails];
      fundingDetails.current.recipients = tempRecipients;
    } else if (!Array.isArray(fundingDetails?.current?.additionalEmails)) {
      fundingDetails.current.additionalEmails = null;
    }
    if (!fundingDetails.current.additionalEmails || isEmpty(fundingDetails?.current?.additionalEmails)) {
      for (let preset of presetEmails) {
        const tempNonPresets = fundingDetails.current.recipients?.filter((item) => item !== preset.address);
        if (!isEmpty(tempNonPresets)) {
          for (let item of tempNonPresets) {
            const indexOfItem = fundingDetails.current.recipients?.findIndex((recipient) => recipient === item);
            if (indexOfItem > -1) {
              fundingDetails.current.recipients.splice(indexOfItem, 1);
            }
          }
        }
      }
    }
    const invalidEmail = await checkInvalidEmail();
    if (fundingDetails?.current?.additionalEmails && invalidEmail) {
      fundingDetails.current.recipients = [];
      console.error("emails not @ webbank");
      return false;
    }
    if (fundingDetails.current?.presetEmails?.length) {
      // was presetEmails
      if (Array.isArray(fundingDetails.current.recipients)) {
        for (let email of fundingDetails.current.presetEmails) {
          const exists = fundingDetails.current.recipients?.find((item) => item === email);
          if (!exists) {
            fundingDetails.current.recipients?.push(email);
          }
        }
      } else if (fundingDetails.current?.presetEmails) {
        fundingDetails.current.additionalEmails = structuredClone([...fundingDetails.current.presetEmails]);
      }
    } else if (fundingDetails.current?.recipients?.length) {
      for (let email of presetEmails) {
        const existsOnRecipients = fundingDetails.current.recipients?.findIndex((recipient) => recipient === email.address);
        if (existsOnRecipients > -1) {
          fundingDetails.current.recipients.splice(existsOnRecipients, 1);
        }
      }
    }
    setDisableSave(isEmpty(fundingDetails.current?.recipients));
    return true;
  };

  const save = async ({ type }) => {
    const isValidEmails = await transformRecipientList();
    if (!isValidEmails) {
      setEmailError("Emails are not entered in the correct format. Please verify all emails are correct");
      return;
    }
    // === Reruns the onBlur for Funding Amounts due to lifecycle hooks running out of order if you click a button after a blur is fired. ===
    const correspondingFundingRecords = fundingDetails.current?.fundingAmountsList?.filter((item) => {
      // solve for empty string in input
      if (!item.value) item.value = 0;
      // format display value
      item.value = typeof item.value === "string" ? microservices.stringToNumber(item.value).toFixed(2) : item.value.toFixed(2);
      return amountSelections?.includes(item?.label);
    });
    calculateTotal(amountSelections, correspondingFundingRecords);
    // add funding sources to object root
    for (let fundingSource of fundingDetails.current.fundingAmountsList) {
      fundingDetails.current[fundingSource.id] = parseFloat(fundingSource.value);
    }
    // convert displayDate to db format
    fundingDetails.current.fundingDate = new Date(fundingDetails.current.fundingDate).toISOString().split("T")[0];
    delete fundingDetails.current?.fundingAmountsList;
    delete fundingDetails.current?.presetEmails;
    delete fundingDetails.current?.additionalEmails;
    const method = fundingDetails.current?.status ? "update" : "create";
    let apiResponse;
    if (type === "save") {
      console.log("saving");
      setRequestSaving(true);
      apiResponse = await api.fundingRequest[method]({ id: fundingDetails.current?.reviewId, body: fundingDetails.current });
      setRequestDone(true);
    } else if (type === "submit") {
      console.log("submitting");
      fundingDetails.current.status = "Under Review";
      setRequestSubmitting(true);
      apiResponse = await api.fundingRequest.update({ id: fundingDetails.current?.reviewId, body: fundingDetails.current });
      setSubmittingDone(true);
    }
    if (apiResponse && !isEmpty(apiResponse)) {
      props.selectedReview.fundingRequest = apiResponse;
      await setup();
    }
    setTimeout(async () => {
      setRequestSaving(false);
      setRequestSubmitting(false);
      setRequestDone(false);
      setSubmittingDone(false);
    }, 1000);
    return apiResponse;
  };

  const cancelActions = async () => {
    console.log("clearing", displayDetails);
    fundingDetails.current = { ...originalFundingDetails.current };
    setDisplayDetails({ ...fundingDetails.current });
    setAmountSelections([]);
    setTotal(0);
    setEmails([...presetEmails]);
    props.setShowFundingModal(false);
    setDisableSave(true);
  };

  // === Funding Amounts and Field Calculations
  const calculateTotal = async (e, correspondingFundingRecords) => {
    // loop selections and calculate total value
    if (!e?.length) {
      setTotal(0);
      return;
    }
    if (!isEmpty(correspondingFundingRecords)) {
      let tempTotal = 0;
      for (let item of correspondingFundingRecords) {
        if (typeof item.value === "string") {
          const tempNumber = microservices.stringToNumber(item.value);
          if (typeof tempNumber === "number") {
            tempTotal += tempNumber;
          } else {
            console.error("string to number converter failed");
            return;
          }
        } else {
          // set whole numbers to currency format
          tempTotal += item.value;
        }
      }
      setTotal(tempTotal);
    }
  };

  const handleFundingAmountSelections = async (e) => {
    const fundingAmountsList =
      fundingDetails.current?.fundingAmountsList && !isEmpty(fundingDetails.current?.fundingAmountsList)
        ? fundingDetails.current?.fundingAmountsList
        : presetFundingAmounts;
    setAmountSelections(e);
    const correspondingFundingRecords = fundingAmountsList?.filter((item) => e?.includes(item?.label));
    calculateTotal(e, correspondingFundingRecords);
  };

  const handleDelete = async () => {
    await api.fundingRequest.delete({ id: fundingDetails.current?.reviewId });
  };

  const setup = async () => {
    // apply existing fundingRequest data here
    // fundingType transforms
    const tempSelectedTypes = [];
    for (let type of presetFundingAmounts) {
      type.value = props.selectedReview?.fundingRequest[type?.id] || "0.00";
      type.comparisonRefValue = get(props, type.comparisonReferencePath);
      type.comparisonRefValueDisplay = microservices.currency({ value: type.comparisonRefValue || 0, operation: "dollar" });
      if (Number(type.value)) {
        tempSelectedTypes.push(type?.label);
      }
    }

    // used to set fundingTypes, funding values, and total
    await handleFundingAmountSelections(tempSelectedTypes);

    // used to set preset and additional email displays
    async function handlePresetEmailSelections() {
      let recipients;
      if (props.selectedReview?.fundingRequest?.recipients?.length) {
        recipients = structuredClone([...props.selectedReview.fundingRequest.recipients]);
        for (let presetEmail of presetEmails) {
          const exists = recipients?.find((email) => email === presetEmail?.address);
          if (exists) {
            presetEmail.value = true;
            const emailIdx = recipients?.indexOf(presetEmail.address);
            if (emailIdx > -1) {
              recipients?.splice(emailIdx, 1);
            }
          }
        }
      }
      setEmails([...presetEmails]);
      return recipients || [];
    }

    // set original values before any modifications
    originalFundingDetails.current = {
      reviewId: props.selectedReview?.fundingRequest?.reviewId,
      fundingAmountsList: structuredClone([...presetFundingAmounts]),
      notes: props.selectedReview?.fundingRequest?.notes,
      recipients: props.selectedReview?.fundingRequest?.recipients || [],
      additionalEmails: await handlePresetEmailSelections(),
      fundingDate: new Date(),
      status: props.selectedReview?.fundingRequest?.status,
    };

    // make a working copy from the original values
    fundingDetails.current = structuredClone({ ...originalFundingDetails.current });
    setDisplayDetails({ ...fundingDetails.current });
    if (fundingDetails.current.recipients?.length) {
      setDisableSave(false);
    }
  };

  useEffect(() => {
    // trigger setup on modal open to keep data consistent
    if (props.showFundingModal) {
      setup();
    }
    // eslint-disable-next-line
  }, [props.showFundingModal]);

  return (
    <div style={{ zIndex: 100 }}>
      <com.Modal
        title={
          <span className="is-flex is-justify-content-space-between is-align-items-center">
            <div>{displayDetails.status !== "Under Review" ? `Funding Request` : `Funding Request - Submitted`}</div>
          </span>
        }
        cardStyleOverride={{ maxWidth: "1100px", width: "90vw" }} // maxWidth: "800px" and width: "60vw" after dev
        body={
          <div>
            <TagPicker
              container={document.getElementById("borrowing-base-main-container")}
              className="is-flex"
              data={amountsTagPickerData}
              onChange={handleFundingAmountSelections}
              value={amountSelections || []}
              placeholder="Select Funding Types"
              cleanable={false}
              disabled={requestSaving || requestSubmitting || displayDetails.status === "Under Review"}
            />
            {!!amountSelections.length && (
              <div className="is-flex is-flex-direction-column">
                <div className="is-flex is-flex-direction-column" style={{ height: "140px" }}>
                  <div id="amounts-table" className="mt-3 custom-flex-container pl-2" style={{ border: "1px solid #e0e0e0", borderRadius: "5px" }}>
                    <div className="custom-flex-container flex-scroll">
                      <div>
                        <table className="editable-cells">
                          <tbody>
                            {displayDetails?.fundingAmountsList?.map(
                              (fundingType) =>
                                !isEmpty(amountSelections?.find((selection) => fundingType?.label === selection)) && (
                                  <tr className="row-align-middle" key={uuidV4()}>
                                    <td style={{ width: "50%" }}>
                                      <div className="is-flex is-justify-content-end mr-5 pr-5">
                                        <span className="is-flex-grow-1 is-flex is-align-items-center is-capitalized">
                                          {words(fundingType?.id)?.join(" ")}
                                        </span>
                                        <span className="pl-2">
                                          <CurrencyInputCell
                                            fundingRow={fundingType}
                                            fundingDetails={fundingDetails}
                                            amountSelections={amountSelections}
                                            calculateTotal={calculateTotal}
                                            limit={fundingType?.comparisonRefValue}
                                            disabled={
                                              requestSaving ||
                                              requestSubmitting ||
                                              fundingType.comparisonRefValue === "0" ||
                                              fundingType.comparisonRefValue === "0." ||
                                              fundingType.comparisonRefValue === "0.0" ||
                                              fundingType.comparisonRefValue === "0.00" ||
                                              fundingType.comparisonRefValue === 0 ||
                                              !fundingType.comparisonRefValue ||
                                              displayDetails.status === "Under Review"
                                            }
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    <td style={{ width: "50%" }}>
                                      <div className="is-flex is-justify-content-end">
                                        <span className="is-flex-grow-1">{fundingType?.comparisonReferenceLabel}</span>
                                        <span className="pl-2 pr-5">{fundingType?.comparisonRefValueDisplay}</span>
                                      </div>
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="flex-table-row is-flex is-justify-content-end mt-2">
                    <div className="is-flex is-align-items-center">
                      <label className="mr-2">Total</label>
                      <span className="pl-3">{microservices.currency({ value: total, operation: "dollar" })}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="is-flex is-flex-direction-column mt-3" style={{ maxWidth: "200px" }}>
              <label htmlFor="">Funding Date</label>
              <DatePicker
                container={document.getElementById("borrowing-base-main-container")}
                value={displayDetails?.fundingDate}
                onChange={handleDateSelection}
                oneTap
                cleanable={false}
                disabled={requestSaving || requestSubmitting || displayDetails.status === "Under Review"}
                shouldDisableDate={(date) => date < ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date())}
              />
            </div>
            <div>
              <com.TextInput
                label="Analyst Notes"
                type="textarea"
                maxlength={5000}
                value={displayDetails?.notes}
                onChange={(e) => {
                  handleInputs({ e, field: "notes" });
                }}
                disabled={requestSaving || requestSubmitting || displayDetails.status === "Under Review"}
              />
            </div>
            <div className="is-flex is-flex-direction-column">
              <label>Email Addresses to send Funding Report</label>
              <div className="is-flex is-flex-wrap-wrap">
                {emails?.map((email) => (
                  <Checkbox
                    key={uuidV4()}
                    inline
                    disabled={requestSaving || requestSubmitting || displayDetails.status === "Under Review"}
                    checked={email.value}
                    onChange={(_, e) => handleEmailPresets({ e, address: email?.address })}
                  >
                    {email?.address}
                  </Checkbox>
                ))}
              </div>
            </div>
            <div>
              <com.TextMatcher
                id="additional-email"
                style={{ minHeight: "4em" }}
                label="Additional Email Addresses"
                placeholder="Comma separated list"
                data={props?.personsList?.current?.map((person) => person?.email)}
                maxlength={5000}
                value={displayDetails?.additionalEmails || ""}
                onChange={async (e) => {
                  handleInputs({ e, field: "additionalEmails" });
                }}
                disabled={requestSaving || requestSubmitting || displayDetails.status === "Under Review"}
                onFocus={() => {
                  setEmailError("");
                  setDisableSave(true);
                }}
                onBlur={async () => {
                  const isValidEmails = await transformRecipientList();
                  if (!isValidEmails) {
                    setEmailError("Emails are not entered in the correct format. Please verify all emails are correct");
                    return;
                  }
                }}
                dataType="email"
              />
              {emailError && <span className="help is-danger">{emailError}</span>}
            </div>
          </div>
        }
        actions={[
          {
            buttonClass: "is-warning",
            text: displayDetails?.status === "Under Review" ? "Close" : "Cancel",
            iconClass: displayDetails?.status === "Under Review" ? "fa-solid fa-close" : "fa-solid fa-ban",
            action: cancelActions,
            disabled: requestSaving || requestSubmitting,
          },
          {
            id: uuidV4(),
            customButton: true,
            iconClass: "fa-solid fa-ban",
            body: displayDetails.status !== "Under Review" && (
              <div className="mr-3">
                <com.AreYouSureDelete
                  action={handleDelete}
                  icon={<i className="fa-solid fa-trash" />}
                  defaultText={`Delete`}
                  disabled={requestSaving || requestSubmitting || displayDetails.status === "Under Review"}
                  cb={async () => {
                    props.selectedReview.fundingRequest = {
                      reviewId: props.selectedReview?.id,
                      advanceFundingAmount: null,
                      waterfallFundingAmount: null,
                      fundingDate: "",
                      notes: "",
                      status: null,
                    };
                    originalFundingDetails.current = {};
                    fundingDetails.current = {};
                    setDisplayDetails({});
                    for (let email of presetEmails) {
                      email.value = false;
                    }
                    cancelActions();
                  }}
                />
              </div>
            ),
          },
          {
            id: uuidV4(),
            customButton: true,
            body: displayDetails.status !== "Under Review" && (
              <com.ApiSubmitButton
                className="is-primary"
                defaultLabel={`Save Progress`}
                operationLabel="Saving..."
                icon={<i className="fa-solid fa-floppy-disk" />}
                disabled={requestSaving || requestSubmitting || disableSave || emailError}
                action={() => save({ type: "save" })}
                // used to force correct rendering when parent re-renders
                actionTriggered={requestSaving}
                actionDone={requestDone}
                // isMouseDown changes onClick to onMouseDown to handle blur lifecycle issues
                isMouseDown={true}
              />
            ),
          },
          {
            id: uuidV4(),
            customButton: true,
            body: displayDetails.status !== "Under Review" && (
              <com.ApiSubmitButton
                className={`ml-3 ${disableSave ? "" : "btn-call-to-action"}`}
                defaultLabel={`Submit Request`}
                operationLabel="Submitting..."
                icon={<i className="fa-solid fa-cloud-arrow-up" />}
                disabled={requestSubmitting || requestSaving || disableSave || emailError}
                action={() => save({ type: "submit" })}
                // used to force correct rendering when parent re-renders
                actionTriggered={requestSubmitting}
                actionDone={submittingDone}
                isMouseDown={true}
              />
            ),
          },
        ]}
        showModal={props.showFundingModal}
      />
    </div>
  );
};
export default FundingRequest;
