import { Table, Loader } from "rsuite";
import { useState, useEffect, useRef, useCallback } from "react";
import * as com from "../../../components";
import api from "../../../api";
import { isEmpty } from "lodash";
import { handleSortColumn } from "../../../services/microservices";
import { InputEditCell, ToggleCell, DroplistCell } from "../../../components/tableComponents/customCells";
import { v4 as uuidV4 } from "uuid";

export const CriteriaAdmin = ({ ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState("sortOrder");
  const [sortType, setSortType] = useState("asc");
  const [disabledRow, setDisabledRow] = useState("");
  const [disabledRowWeight, setDisabledRowWeight] = useState([]);
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [displayDetails, setDisplayDetails] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [showPercent, setShowPercent] = useState(false);
  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);
  const [updateRow, setUpdateRow] = useState();
  const types = ["text", "number"];
  const [isValidForm, setIsValidForm] = useState(false);

  const details = useRef({
    name: "",
    weight: 0,
    type: "text",
    sortOrder: 1,
    isFacilityRule: 0,
    isPercent: false,
  });
  const originalValues = useRef({});

  const validate = () => {
    setIsValidForm(!!details.current?.name);
    return !!details.current?.name;
  };

  const submit = async ({ tableBody, setUpdateActionTriggered, setUpdateActionDone, method }) => {
    const body = method === "update" ? tableBody : details.current;
    method === "update" ? setUpdateActionTriggered(body?.id) : setSubmitActionTriggered(true);
    if (method === "update") setDisabledRow(body?.id);

    setDisableFields(true);
    const response = await api[props.endpoint][method]({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    method === "update" ? setUpdateActionDone(body?.id) : setActionDone(true);
    setTimeout(async () => {
      const updatedData = await props.refreshDataset({});
      setActionDone(false);
      setDisableFields(false);
      setSubmitActionTriggered(false);
      details.current = structuredClone({ ...originalValues.current });
      setDisplayDetails(details.current);
      setIsValidForm(false);
      if (method === "update") {
        setNextSort({});
        setDisabledRow(false);
        setUpdateActionTriggered(false);
        setUpdateActionDone(false);
      } else {
        setNextSort({ updatedData });
      }
    }, 2000);
    return response;
  };

  const handleDelete = async ({ id }) => {
    const response = await api[props?.endpoint].delete({ id });
    const updatedData = await props.refreshDataset({});
    setNextSort({ updatedData });
    // clear selected criteria on delete
    props.handleChoiceLink({});
    return response;
  };

  const handleToggle = ({ e, field }) => {
    details.current[field] = e;
    setDisplayDetails({ ...details.current });
    validate();
  };

  const validateSort = (e) => {
    const regex = /^[0-9 ]+$/; // This regex allows only digits. Due to input type=number, "+" is still allowed, but isn't carried over to the e.target.value.
    return regex.test(e.target.value) && !(Number(e.target.value) < 0 || (e.target.value.trim().length === 0 && e.target.value.length > 0));
  };

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

  const handleInput = ({ val, key }) => {
    if (key === "sortOrder") {
      if (!validateSort({ target: { value: val } })) return;
    } else if (key === "name") {
      if (!validateText({ target: { value: val } })) return;
    }
    details.current[key] = val;
    setDisplayDetails({ ...details.current });
    validate();
  };

  const handleSelect = (e) => {
    details.current.type = e.target.value;
    setShowPercent(e.target.value === "number");
    setDisplayDetails({ ...details.current });
    validate();
  };

  const setNextSort = useCallback(
    ({ updatedData }) => {
      const dataSource = updatedData ? updatedData : props?.data;
      if (dataSource?.length) {
        const highest = dataSource?.reduce((a, b) => ((a.sortOrder || 0) >= (b.sortOrder || 0) ? a : b))?.sortOrder;
        details.current.sortOrder = Number(highest) + 1;
        setDisplayDetails(details.current);
      }
    },
    [props.data]
  );

  const setup = async () => {
    setShowPercent(displayDetails?.type === "number");
    setTableLoading(props.loading);
    // set default sort
    setNextSort({});
    setDisplayDetails(details.current);
    if (isEmpty(originalValues.current)) {
      originalValues.current = structuredClone({ ...details.current });
    }
    // set disabled weights based off of isFacilityRule
    const temp = [];
    for(let item of props.data) {
      if (item.isFacilityRule) {
        temp.push(item.id);
      }
    }
    setDisabledRowWeight(temp);
    setTableData(props.data);
    setTableLoading(props.loading);
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, [props.loading]);

  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          {props?.parentprops?.choicesLoading ? (
            <button className="button mr-2 is-link" disabled={true} style={{ maxHeight: "30px" }}>
              <Loader center size="sm" />
            </button>
          ) : (
            <button
              className="button mr-2 is-link"
              title="Choices"
              style={{ maxHeight: "30px" }}
              onClick={() => props.parentprops.handleChoiceLink({ criteria: rowData?.name })}
            >
              <span>
                <i className="fa-solid fa-square-check" />
              </span>
            </button>
          )}
          <com.ApiSubmitButton
            action={() => submit({ tableBody: rowData, setUpdateActionTriggered, setUpdateActionDone, method: "update" })}
            className="mr-2 row-action-button"
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered || updateActionTriggered}
            actionTriggered={updateActionTriggered === rowData?.id}
            actionDone={updateActionDone === rowData?.id}
          />
          <com.AreYouSureDelete
            action={() => handleDelete({ id: rowData?.id })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={submitActionTriggered || updateActionTriggered}
          />
        </div>
      </Cell>
    );
  };

  return (
    <div className="custom-flex-container">
      <div className="is-flex is-align-items-center is-width-full">
        <com.TextInput
          label="Criteria Name"
          className="mr-3"
          controlClass="mt-3 is-flex-grow-1"
          value={displayDetails?.name || ""}
          maxlength={64}
          onChange={(e) => handleInput({ val: e.target.value, key: "name" })}
          disabled={disableFields}
          controlStyle={{ maxWidth: "870px" }}
        />
        {!displayDetails.isFacilityRule && (
          <com.TextInput
            label="Weight"
            type="number"
            value={displayDetails?.weight || 0}
            style={{ maxWidth: "100px", marginBottom: "24px" }}
            onChange={(e) => handleInput({ val: e.target.value, key: "weight" })}
            disabled={disableFields}
            step={0.01}
          />
        )}
        <com.TextInput
          label="global sort"
          type="number"
          value={displayDetails?.sortOrder || ""}
          onChange={(e) => handleInput({ val: e.target.value, key: "sortOrder" })}
          disabled={disableFields}
          controlClass="mt-3 ml-3 pb-5"
          style={{ maxWidth: "100px" }}
          min={1}
        />
        <div className="is-flex is-flex-direction-column is-flex-grow-1 ml-3 mb-4" style={{ maxWidth: "150px" }}>
          <label className="is-size-6">Type</label>
          <div className="select">
            <select
              id="bb-criteria-section-select"
              className="select-input is-capitalized"
              onChange={handleSelect}
              value={displayDetails?.type}
              disabled={disableFields}
            >
              {types?.map((type) => (
                <option defaultValue={type === "Text"} value={type} key={uuidV4()}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-center mx-5" style={{ minWidth: "100px" }}>
          <label className="is-size-6 is-capitalized" style={{ paddingBottom: "9px" }}>
            Is Facility Rule
          </label>
          <com.Toggle
            className="mb-5"
            value={details?.current?.isFacilityRule}
            setValue={(e) => {
              if (e) {
                details.current.weight = 0;
              }
              handleToggle({ e, field: "isFacilityRule" });
            }}
            disabled={disableFields}
          />
        </div>
        {showPercent && (
          <div className="is-flex is-flex-direction-column is-align-items-center mx-5" style={{ minWidth: "170px" }}>
            <label className="is-size-6 is-capitalized" style={{ paddingBottom: "9px" }}>
              Is Percent
            </label>
            <com.Toggle
              className="mb-5"
              value={details?.current?.isPercent}
              setValue={(e) => handleToggle({ e, field: "isPercent" })}
              disabled={disableFields}
            />
          </div>
        )}
        <com.ApiSubmitButton
          action={() => submit({ method: "create" })}
          className="ml-3 mt-3"
          style={{ height: "40px" }}
          icon={<i className="fa-solid fa-plus" />}
          // used to force correct rendering when parent re-renders
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
          disabled={!isValidForm}
        />
      </div>
      <div id="lqr-criteria-table-container" className="custom-flex-container flex-scroll extend-last-row" style={{ "--lastRowHeight": "200px" }}>
        <div>
          <div>
            <Table
              data={tableData}
              autoHeight
              rowHeight={65}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={(sortColumn, sortType) =>
                handleSortColumn({
                  sortColumn,
                  sortType,
                  data: props.data,
                  setters: {
                    setTableLoading,
                    setSortColumn,
                    setSortType,
                    setTableData,
                  },
                })
              }
              loading={tableLoading}
            >
              <Column flexGrow={1} sortable>
                <HeaderCell>Criteria Name</HeaderCell>
                <InputEditCell
                  dataKey="name"
                  disabled={disabledRow}
                  setmaxlength={64}
                  customValidation={({ val }) => validateText({ target: { value: val } })}
                />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Weight</HeaderCell>
                <InputEditCell dataKey="weight" disabled={disabledRow || disabledRowWeight} />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Sort Order</HeaderCell>
                <InputEditCell
                  dataKey="sortOrder"
                  disabled={disabledRow}
                  type="number"
                  min={0}
                  max={9999}
                  step={1}
                  customValidation={({ val }) => validateSort({ target: { value: val } })}
                />
              </Column>
              <Column width={150} sortable>
                <HeaderCell>Type</HeaderCell>
                <DroplistCell
                  dataKey="type"
                  disabled={disabledRow}
                  dataset={types}
                  updateRow={updateRow}
                  setUpdateRow={setUpdateRow}
                  selectionCb={({ rowData }) => {
                    rowData.isPercent = false;
                    setTableData([...tableData]);
                  }}
                />
              </Column>
              <Column width={200} sortable align="center">
                <HeaderCell>Is Facility Rule</HeaderCell>
                <ToggleCell
                  dataKey="isFacilityRule"
                  validation={() => true}
                  disabled={disabledRow}
                  cb={({rowData}) => {
                    if (rowData.isFacilityRule) {
                      const tempClone = structuredClone(tableData);
                      const index = tempClone.findIndex((row) => row.id === rowData.id);
                      tempClone[index].weight = 0;
                      rowData.weight = 0;
                      const temp = structuredClone(disabledRowWeight);
                      temp.push(rowData.id);
                      setDisabledRowWeight(temp);
                      setTableData([]);
                      setTimeout(() => {
                        setTableData([...tempClone]);
                      }, 100);
                    } else {
                      setDisabledRowWeight(disabledRowWeight.filter((id) => id !== rowData.id));
                    }
                  }}
                />
              </Column>
              <Column width={100} sortable align="center">
                <HeaderCell>Is Percent</HeaderCell>
                <ToggleCell
                  dataKey="isPercent"
                  validation={() => true}
                  disabled={disabledRow}
                  hide={({ rowData, setHideCell }) => {
                    setHideCell(rowData?.type !== "number");
                  }}
                />
              </Column>
              <Column width={235}>
                <HeaderCell></HeaderCell>
                <ActionsCell dataKey="actions" parentprops={props} />
              </Column>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
