import PageTemplate from "../PageTemplate";
import { useEffect, useState, useRef, useCallback } from "react";
import { isEmpty, orderBy } from "lodash";
import TextInput from "../../components/TextInput";
import { Table, Loader, DatePicker } from "rsuite";
import InlineSaveIndicator from "../../components/InlineSaveIndicator";
import api from "../../api";
import { getUserCognitoGroups } from "../../store";
import CovenantReport from "./CovenantReport";
import ReportingHistory from "./ReportingHistory";
import DealDocuments from "./DealDocuments";
import { v4 as uuidV4 } from "uuid";
import { enterKeyPressHandler, shouldDisableDate, handleDatePicker, validateText, handleAutoCreditSelection } from "../../services/microservices";
import DropCard from "../../components/DropCard";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";

const TransactionSummaryContent = ({
  loadingResponses,
  setLoadingResponses,
  disableFields,
  credits,
  getCovenants,
  getReportingRequirements,
  getReportingHistory,
  getDealDocuments,
  reportingHistoryLoaded,
  dealDocumentsLoaded,
  edit,
  setEdit,
  tableMinHeight,
  selectedCredit,
  setSelectedCredit,
  tabs,
  tab,
  setTab,
  ...props
}) => {
  // state
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // refs and variables
  const { Column, ColumnGroup, HeaderCell, Cell } = Table;
  const rowHeightOverride = 335;
  const reloadTab = useRef("Summary");
  const heldFieldIds = useRef([]);
  const getCovenantReportsByCreditId = props.getCovenantReportsByCreditId;

  // functions, callbacks, and components
  const handleTabClick = (t) => {
    setTab(t);
    reloadTab.current = t;
  };

  const handleCreditSelect = async (e) => {
    props.financialProps.current = structuredClone({ ...props.defaultFinancialProps });
    props.portfolioProps.current = structuredClone({ ...props.defaultPortfolioProps });
    props.setDropCardActive(false);
    props.setBlackout(false);
    setEdit(null);
    const temp = credits?.find((credit) => credit?.id === e?.target?.value);
    setSelectedCredit({ ...temp });
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    if (e.target.value === "0") {
      sessionStorage.removeItem("selectedCredit");
      return;
    }
    await setup({ ...props, creditId: temp?.id });
  };

  const handleAmendmentSelect = async (e) => {
    const amendmentObject = structuredClone({ ...props.amendmentList?.find((amendment) => amendment?.id === e.target.value) });
    setEdit(null);
    props.setSelectedAmendment({});
    await setup({ ...props, creditId: selectedCredit?.id, loadHistorical: amendmentObject });
  };

  const handleSortColumn = async (sortColumn, sortType) => {
    setLoading(true);
    setup({ column: sortColumn, type: sortType });
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const inlineSubmitHandler = async ({ detail, detailProps, rowData, cb }) => {
    const method = detail.current?.id ? "update" : "create";
    let body = detail?.current?.requirementId
      ? {}
      : {
          id: detail.current?.id,
          creditId: selectedCredit?.id,
          amendmentId: props.selectedAmendment?.id,
          fieldId: detail.current?.fieldId,
          fieldRowId: detail.current?.fieldRowId,
          value: detail.current?.value,
          section: detail.current?.section,
          sectionRowId: detail.current?.tsSectionRowId,
          frequency: detail.current?.frequency,
          dueDateOffset: detail.current?.dueDateOffset,
          notes: detail.current?.notes,
        };
    if (detailProps.detailsDataSet) {
      // == Covenants and Reporting Requirements ==
      const checkNewFieldDataUpdate = ({ submitData, originalData }) => {
        // determines if submit data contains existing data, or needs to be updated.
        const excludeKeys = ["id", "creditId", "startDate", "createdBy", "endDate", "updatedBy"];
        for (let key in submitData) {
          if (excludeKeys.indexOf(key) === -1) {
            if (originalData[key] === submitData[key]) {
              continue;
            } else {
              return originalData[key] !== submitData[key];
            }
          }
        }
        return false;
      };
      const apiCalls = [];
      for (let covenant of detailProps.detailsDataSet) {
        covenant.creditId = selectedCredit?.id;
        const originalData = detailProps.originalData?.find((c) => c?.id === covenant?.id);
        const submitIt = checkNewFieldDataUpdate({ submitData: covenant, originalData });
        if (submitIt) {
          apiCalls.push(api[detailProps.endpoint].update({ id: covenant?.id, body: covenant }));
        }
      }
      await Promise.all(apiCalls);
    } else {
      // == Transaction Summary ==

      // holds any updates until the create is done.
      if (method === "create" && heldFieldIds.current.includes(detail.current.fieldId)) {
        const saveListener = setInterval(() => {
          if (!heldFieldIds.current.includes(detail.current.fieldId)) {
            clearInterval(saveListener);
            if (detail.current.fieldId === rowData?.fieldId) {
              const fireHeldApiCall = async () => {
                const response = await api[detailProps.endpoint].update({ id: detail.current?.id, body: detail.current });
                if (cb) {
                  return cb(response);
                }
              };
              return fireHeldApiCall();
            }
          }
        }, 500);
        return;
      }

      // adds the fieldId to heldFields array so we can track when that field is done saving
      if (detail.current?.fieldId) {
        heldFieldIds.current.push(detail.current?.fieldId);
      }
      const response = await api[detailProps.endpoint][method]({ id: detail.current?.id, body });
      if (cb) return cb(response);
    }
  };

  const EditCell = ({ rowData, dataKey, ...props }) => {
    const [tempEditDetail, setTempEditDetail] = useState(rowData[dataKey]);
    const [showSavingId, setShowSavingId] = useState(null);
    const [showSavingDone, setShowSavingDone] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const detail = useRef({});
    const originalData = useRef({});
    const keyBindingHandled = useRef(false); // for debouncing of keypress listener
    const allInputs = useRef([]); // used in keypress handler

    const editOnChange = ({ val, column }) => {
      if (!validateText({ target: { value: val } })) return;
      rowData[column] = val;
      if (isEmpty(detail.current)) {
        detail.current = { ...rowData };
      } else {
        detail.current[column] = val;
      }
      setTempEditDetail(val);
    };

    const checkForChange = () => {
      return originalData.current[dataKey] !== detail.current[dataKey];
    };

    const handleBlur = async () => {
      const hasChange = checkForChange();
      if (!hasChange) return;
      setDisabled(true);
      setShowSavingId(`${dataKey}-${rowData?.fieldName}`);

      // find row data in master table data so held updates will get the ID after create api call
      const masterSectionIndex = tableData.findIndex((value) => value?.id === rowData?.tsSectionId);
      const masterRecordIndex = tableData[masterSectionIndex]?.fields?.findIndex((field) => field?.fieldId === rowData?.fieldId);
      const correspondingMasterRecord = tableData[masterSectionIndex].fields[masterRecordIndex];

      const afterApiCall = (response) => {
        detail.current.id = response?.id;
        rowData = detail.current;
        correspondingMasterRecord.id = response?.id;
        // remove held field id's
        heldFieldIds.current = heldFieldIds.current.filter((entry) => entry !== rowData?.fieldId);
        originalData.current = structuredClone({ ...detail.current });
        setShowSavingDone(true);
        setTimeout(() => {
          setShowSavingId(null);
          setShowSavingDone(false);
          setDisabled(false);
        }, 2000);
      };
      // setting detail to rowData to make sure the current detail has all the same data as rowData
      detail.current = rowData;
      await inlineSubmitHandler({ detail, detailProps: { endpoint: "transactionSummary" }, rowData, cb: afterApiCall });
    };

    const setFieldLengths = ({ rowData, field }) => {
      return rowData.isTextBox && field === "value" ? 5000 : 500;
    };

    useEffect(() => {
      // grouped the value and section columns for isTextBox
      if (rowData.isTextBox) {
        rowData.section = null;
      } else if (rowData.value.length >= 500) {
        // This is to solve an edge case where an admin removes a textbox flag from field with existing data over 500 characters.
        rowData.value = rowData.value.substring(0, 500);
      }

      // Get a list of all inputs so the system can determine which is the next field upon "ENTER" keypress
      if (!allInputs.current?.length) {
        allInputs.current = Array.from(document.querySelectorAll("input"));
      }
      // Clone the data to compare before save
      detail.current = structuredClone({ ...rowData });
      originalData.current = structuredClone({ ...rowData });
      // eslint-disable-next-line
    }, []);

    return (
      <Cell {...props}>
        {edit ? (
          <div className={`is-flex ${rowData.isTextBox ? "row-textbox" : ""}`}>
            <TextInput
              id={`${dataKey}-${rowData?.fieldName}`}
              value={tempEditDetail || ""}
              placeholder={dataKey === "value" ? "Value" : "Contract Section"}
              autoComplete="off"
              type={rowData.isTextBox && dataKey === "value" ? "textarea" : "text"}
              style={{ maxHeight: "30px", marginTop: "-10px" }}
              controlClass="mt-3 mr-2 is-flex-grow-1"
              onChange={(e) => editOnChange({ val: e.target.value, column: dataKey })}
              disabled={disabled}
              onBlur={handleBlur}
              onFocus={() => enterKeyPressHandler({ action: handleBlur, keyBindingHandled, rowData, dataKey, allInputs })}
              maxlength={(() => setFieldLengths({ rowData, field: dataKey }))()}
            />
            {showSavingId && (
              <div style={{ width: "115px" }}>
                <InlineSaveIndicator showId={`${dataKey}-${rowData?.fieldName}`} showSaving={showSavingId} showSavingDone={showSavingDone} />
              </div>
            )}
          </div>
        ) : (
          <div className={`${rowData.isTextBox ? "scroll-cell" : ""}`} style={{ "--cellScrollHeight": `${rowHeightOverride}px` }}>
            {rowData[dataKey]}
          </div>
        )}
      </Cell>
    );
  };

  const setup = useCallback(async ({ creditId, ...props }) => {
    setLoadingResponses(true);
    let mostRecentAmendment;
    if (!props.loadHistorical) {
      const tsAmendments = await api.tsAmendments.get({ creditId });
      if (!tsAmendments || isEmpty(tsAmendments)) {
        // trigger a create dialogue and return
        setLoadingResponses(false);
        props.setDropCardActive(true);
        props.setBlackout(true);
        return;
      }
      props.setAmendmentList(tsAmendments);
      // set most recent as default load
      mostRecentAmendment = props.rememberSelectedAmendment
        ? tsAmendments.find((amendment) => amendment?.id === props.selectedAmendment?.id)
        : orderBy(tsAmendments, "date", "desc")[0];
    } else {
      mostRecentAmendment = props.loadHistorical;
    }

    props.setSelectedAmendment({ ...mostRecentAmendment });

    // get and set covenant reports
    getCovenantReportsByCreditId({ creditId });

    // set sections
    let tempSections;
    if (mostRecentAmendment?.sections?.length) {
      tempSections = orderBy(mostRecentAmendment?.sections, "sortOrder", "asc");
      setTableData([...tempSections]);
    }
    if (props.loadHistorical) {
      setTimeout(() => {
        setLoadingResponses(false);
      }, 1000);
    } else {
      tab === tabs[1] || reloadTab.current === tabs[1]
        ? await getReportingHistory({
            creditId,
            tableName: props.reportingHistoryProps.current?.sectionLabel,
            sortColumn: "name",
            sortType: "asc",
            ...props,
          })
        : getReportingHistory({
            creditId,
            tableName: props.reportingHistoryProps.current?.sectionLabel,
            sortColumn: "name",
            sortType: "asc",
            ...props,
          });
      tab === tabs[2] || reloadTab.current === tabs[2] ? await getDealDocuments({ creditId, ...props }) : getDealDocuments({ creditId, ...props });
      setLoadingResponses(false);
    }
    setLoading(true);

    // // transform fields into transaction summary rows
    for (let section of tempSections) {
      const tempRows = [];
      for (let field of section.transactionSummaries) {
        const tsRow = {
          id: field?.transactionSummaryDataId || "",
          fieldId: field?.transactionSummaryFieldId,
          fieldRowId: field?.transactionSummaryFieldRowId,
          fieldName: field?.transactionSummaryFieldName,
          fieldSortOrder: field?.transactionSummaryFieldSortOrder,
          isTextBox: field?.transactionSummaryFieldIsTextBox,
          tsSectionId: section?.id,
          tsSectionRowId: section?.rowId,
          tsSectionName: section?.name,
          tsSectionSortOrder: section?.sortOrder,
          creditId,
          value: field?.transactionSummaryDataValue || "",
          section: field?.transactionSummaryDataSection || "",
        };
        tempRows.push(tsRow);
      }
      const orderedRows = orderBy(tempRows, "fieldSortOrder", "asc");
      section.fields = structuredClone([...orderedRows]);
    }

    setTableData(tempSections);
    setLoading(false);
    // eslint-disable-next-line
  }, []);
  props.setup.current = setup;

  useEffect(() => {
    handleAutoCreditSelection({selectedCredit, handleCreditSelect});
    setTab(reloadTab.current);
    // used to clear rowData on edit Cancel
    if (edit === false) {
      setup({ ...props, creditId: selectedCredit?.id, rememberSelectedAmendment: true });
    }
    // eslint-disable-next-line
  }, [edit, selectedCredit]);

  return (
    <div id="transaction-summary-main-container" className="custom-flex-container">
      <div className="card">
        <div className="card-content p-2 is-flex is-align-items-center is-justify-content-space-between">
          <div className="select" style={{ maxHeight: "36px" }}>
            <select
              id="source-temp-select"
              className="has-text-link"
              onChange={handleCreditSelect}
              value={selectedCredit?.id}
              style={{ maxHeight: "36px", fontSize: "14px" }}
              disabled={disableFields}
            >
              <option defaultValue={true} value={0}>
                Choose Credit
              </option>
              {credits?.map((entry) => (
                <option key={entry?.id} value={entry?.id}>
                  {entry?.name}
                </option>
              ))}
            </select>
          </div>
          {tab === "Summary" && !!tableData[0]?.fields?.length && !loadingResponses && !props.blackout && selectedCredit?.id && (
            <div style={{ color: "salmon" }}>
              <div className="is-flex is-align-items-center">
                <div className="mr-3 is-size-5">Amendment Date:</div>
                <div className="select" style={{ maxHeight: "36px" }}>
                  <select
                    id="source-temp-select"
                    onChange={handleAmendmentSelect}
                    value={props.selectedAmendment?.id}
                    style={{ maxHeight: "36px", fontSize: "14px", color: "salmon" }}
                    disabled={disableFields}
                  >
                    {orderBy(props.amendmentList, "date", "desc")?.map((entry) => (
                      <option key={entry?.id} value={entry?.id}>
                        {entry?.date}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {loadingResponses ? (
        <Loader center size="sm" content="Loading Transaction Summary Info..." />
      ) : props.blackout ? (
        <div className="is-flex is-justify-content-center is-align-items-center p-5" style={{ zIndex: "98", flex: "1 1 auto" }}>
          <div>
            <p>No transaction summary is available for the selected credit. Create an amendment to proceed.</p>
          </div>
        </div>
      ) : (
        selectedCredit &&
        !isEmpty(selectedCredit) && (
          <div className="custom-flex-container pb-2 pl-2">
            <div className="is-flex is-align-items-flex-end">
              {tabs.map((t) => (
                <div onClick={() => handleTabClick(t)} key={uuidV4()}>
                  {t === tabs[0] && (
                    <div className={`tab ${tab === t ? "has-background-link has-text-white" : ""} ${disableFields ? "custom-disabled" : ""}`}>
                      Summary
                    </div>
                  )}
                  {t === tabs[1] && (
                    <div
                      className={`tab ${tab === t ? "has-background-link has-text-white" : ""} ${
                        !reportingHistoryLoaded || disableFields ? "custom-disabled" : ""
                      }`}
                    >
                      {tabs[1]}
                    </div>
                  )}
                  {t === tabs[2] && (
                    <div
                      className={`tab ${tab === t ? "has-background-link has-text-white" : ""} ${
                        !dealDocumentsLoaded || disableFields ? "custom-disabled" : ""
                      }`}
                    >
                      {tabs[2]}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="custom-flex-container flex-scroll pt-5">
              {tab === "Summary" && !!tableData[0]?.fields?.length && (
                <div className="mr-6">
                  {tableData?.map((data) => {
                    data.fields = data.fields.map((field) => ({ ...field, textBox: "W WWWWWWWWWWWWWW" })); // sets the correct row translation for rows beneath textbox row.
                    const defaultTableAttrs = {
                      wordWrap: "break-word",
                      data: data?.fields || [],
                      autoHeight: true,
                      minHeight: tableMinHeight,
                      sortColumn: sortColumn,
                      sortType: sortType,
                      onSortColumn: handleSortColumn,
                      loading: loading,
                    };
                    return (
                      <div key={data?.id} className="pb-5 is-relative">
                        <label
                          className="is-capitalized is-absolute has-text-info-dark is-size-5 has-text-weight-bold"
                          style={{ zIndex: "3", top: "3px" }}
                        >
                          {data?.name}
                        </label>
                        <div
                          id="ts-table-container"
                          className="custom-flex-container no-border-table"
                          style={{
                            "--lastRowHeight": "10px",
                            "--heightOverride": `${rowHeightOverride}px`,
                            "--textBoxHeightOverride": `${rowHeightOverride - 50}px`,
                          }}
                        >
                          <Table {...defaultTableAttrs}>
                            <Column flexGrow={0.5}>
                              <HeaderCell></HeaderCell>
                              <Cell dataKey="fieldName" />
                            </Column>
                            {/* grouped the value and section columns for isTextBox */}
                            <ColumnGroup groupHeaderHeight={0}>
                              <Column flexGrow={1} colSpan={2}>
                                <HeaderCell>Value</HeaderCell>
                                <EditCell dataKey="value" />
                              </Column>
                              <Column flexGrow={0.5}>
                                <HeaderCell>Contract Section</HeaderCell>
                                <EditCell dataKey="section" />
                              </Column>
                            </ColumnGroup>
                          </Table>
                        </div>
                      </div>
                    );
                  })}
                  {props.covRptsLoading ? (
                    <div className="is-relative m-5">
                      <hr />
                      <div style={{ height: "260px" }}>
                        <Loader center size="sm" content="Loading Covenants Reports..." />
                      </div>
                    </div>
                  ) : (
                    <div className="is-custom-flex">
                      <CovenantReport
                        {...props}
                        covenantProps={props.financialProps}
                        inlineSubmitHandler={inlineSubmitHandler}
                        creditId={selectedCredit?.id}
                        edit={edit}
                        setEdit={setEdit}
                        extendTableHeight="100px"
                      />
                      <CovenantReport
                        {...props}
                        covenantProps={props.portfolioProps}
                        inlineSubmitHandler={inlineSubmitHandler}
                        creditId={selectedCredit?.id}
                        edit={edit}
                        setEdit={setEdit}
                        extendTableHeight="200px"
                      />
                    </div>
                  )}
                </div>
              )}
              {tab === tabs[1] && (
                <ReportingHistory
                  tableProps={props.reportingHistoryProps}
                  inlineSubmitHandler={inlineSubmitHandler}
                  creditId={selectedCredit?.id}
                  edit={edit}
                  refreshDataset={({ tableName, sortColumn, sortType }) =>
                    setup({ creditId: selectedCredit?.id, tableName, sortColumn, sortType, ...props })
                  }
                  extendTableHeight="200px"
                  {...props}
                />
              )}
              {tab === tabs[2] && (
                <DealDocuments
                  tableProps={props.dealDocProps}
                  inlineSubmitHandler={inlineSubmitHandler}
                  creditId={selectedCredit?.id}
                  edit={edit}
                  refreshDataset={({ tableName, sortColumn, sortType }) =>
                    setup({ creditId: selectedCredit?.id, tableName, sortColumn, sortType, ...props })
                  }
                  extendTableHeight="200px"
                  {...props}
                />
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

const CrudControls = ({ loadingResponses, edit, setEdit, isTsAdmin, disabled, cancelActions, selectedCredit, tab, tabs, ...props }) => {
  const [dateValue, setDateValue] = useState(null);
  const [dateSubmitTriggered, setDateSubmitTriggered] = useState(false);
  const [dateSubmitDone, setDateSubmitDone] = useState(false);
  const submitBody = useRef({});
  submitBody.current.creditId = selectedCredit?.id;
  props = {
    ...props,
    dateValue,
    setDateValue,
    dateSubmitTriggered,
    submitBody,
  };

  const deleteAction = async ({ ...props }) => {
    await api.tsAmendments.delete({ id: props.selectedAmendment?.id });
    // refresh amendments
    props.setup.current({ ...props, creditId: selectedCredit?.id });
  };

  // DropCard
  const cSSVarsAdjustment = {
    dropBackgroundColor: "rgb(85 85 141)",
    dropBorderColor: "rgb(179 179 220)",
    dropHeight: "138px",
    dropWidth: "410px",
    dropCardButtonHeight: "25px",
    dropCardButtonWidth: "114px",
    dropCardTopPosition: "0",
  };

  const DropCardContent = ({ ...props }) => {
    const [newAmendmentDate, setNewAmendmentDate] = useState(null);

    useEffect(() => {
      if (props.selectedAmendment?.date !== new Date().toISOString().split("T")[0]) {
        const amendmentExistsForDate = props.amendmentList.find((amendment) => amendment.date === new Date().toISOString().split("T")[0]);
        if (!isEmpty(amendmentExistsForDate)) {
          setNewAmendmentDate(null);
        } else {
          setNewAmendmentDate(new Date());
          if (!props.submitBody.current.date) {
            props.submitBody.current.date = new Date().toISOString().split("T")[0];
          }
        }
      }
    }, [props.selectedAmendment?.date, props.submitBody, props.amendmentList]);

    return (
      <div className="is-flex is-align-items-center is-justify-content-start">
        <label className="mr-3">Amendment Date:</label>
        <DatePicker
          container={document.getElementById("transaction-summary")}
          value={newAmendmentDate}
          block
          shouldDisableDate={(date) => shouldDisableDate({ date, existingDateList: props.amendmentList, key: "date" })}
          onChange={(date) => handleDatePicker({ date, bodyDate: props.submitBody.current, setter: setNewAmendmentDate })}
          oneTap
          disabled={props.dateSubmitTriggered}
        />
      </div>
    );
  };

  const setupDropCardActions = () => {
    const actions = [
      {
        isCustom: (
          <ApiSubmitButton
            defaultLabel="Create Amendment"
            operationLabel="Creating Amendment"
            action={async () => {
              setDateSubmitTriggered(true);
              await api.tsAmendments.create({ body: { ...submitBody.current } });
              setDateSubmitDone(true);
              setTimeout(() => {
                setDateSubmitTriggered(false);
                setDateSubmitDone(false);
                props.setDropCardActive(false);
                setDateValue(new Date());
                // trigger reload of amendments
                props.setBlackout(false);
                props.setup.current({ ...props, creditId: selectedCredit?.id });
              }, 1000);
              return;
            }}
            className="mr-2"
            // used to force correct rendering when parent re-renders
            disabled={dateSubmitTriggered}
            actionTriggered={dateSubmitTriggered}
            actionDone={dateSubmitDone}
          />
        ),
      },
    ];
    if (!props.blackout) {
      actions.unshift({
        text: "Cancel",
        icon: <i className="fas fa-ban mr-2" />,
        className: "is-warning",
        disabled: dateSubmitTriggered,
        action() {
          setDateValue(new Date());
          props.setDropCardActive(false);
        },
      });
    }
    return actions;
  };

  return (
    <div className="is-flex is-align-items-center">
      {edit ? (
        <span className="is-flex is-align-items-center">
          <button className="title-button is-warning mr-3 button" onClick={cancelActions} disabled={disabled}>
            <i className="fa-solid fa-xmark mr-2"></i>
            <span>Exit Edit Mode</span>
          </button>
        </span>
      ) : (
        !!selectedCredit &&
        !isEmpty(selectedCredit) &&
        isTsAdmin &&
        !loadingResponses && (
          <div className="is-flex">
            {tab === tabs[0] && (
              <DropCard
                controlClass="title-button mr-3 button is-link"
                permission={true}
                triggerButtonText="Create Amendment"
                controlIcon={<i className="fa-solid fa-plus mr-2"></i>}
                cSSVars={cSSVarsAdjustment}
                content={<DropCardContent {...props} />}
                dropCardActive={props.dropCardActive}
                setDropCardActive={props.setDropCardActive}
                actions={setupDropCardActions()}
              />
            )}
            {!props.blackout && (
              <div className="is-flex">
                <button className="title-button button mr-3" onClick={() => setEdit(true)} disabled={disabled}>
                  <i className="fa-solid fa-pen-to-square mr-2"></i>
                  <span>Edit</span>
                </button>
                {tab === tabs[0] && (
                  <AreYouSureDelete
                    defaultText="Delete"
                    action={() => deleteAction({ ...props })}
                    sharedClass="title-button"
                    anchorButtonStyle={{
                      height: "25px",
                      fontSize: "1rem",
                      lineHeight: "1rem",
                      border: "1px solid rgb(179, 179, 220)",
                      background: "rgb(49, 49, 100)",
                      boxShadow: "0px 2px 4px rgb(85, 85, 141)",
                      color: "rgb(179, 179, 220)",
                      marginTop: "-5px",
                    }}
                    icon={<i className="fa-solid fa-trash" />}
                    data={[]}
                  />
                )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

const TransactionSummary = () => {
  // state
  const [edit, setEdit] = useState();
  const [credits, setCredits] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState({});
  const [isTsAdmin, setIsTsAdmin] = useState(false);
  const [reportingHistoryLoaded, setReportingHistoryLoaded] = useState(false);
  const [dealDocumentsLoaded, setDealDocumentsLoaded] = useState(null);
  const [loadingResponses, setLoadingResponses] = useState(false);
  const [cancelTriggered, setCancelTriggered] = useState(false);
  const [selectedAmendment, setSelectedAmendment] = useState({});
  const [amendmentList, setAmendmentList] = useState([]);
  const [dropCardActive, setDropCardActive] = useState(false);
  const [blackout, setBlackout] = useState(false);
  const [covRptsLoading, setCovRptsLoading] = useState(false);
  const [tab, setTab] = useState("Summary");

  // refs and variables
  const tableMinHeight = 150;
  const standAloneFields = useRef({
    financialFields: [],
    financialFieldsSubmit: [],
    portfolioFields: [],
    portfolioFieldsSubmit: [],
    reportingFields: [],
    reportingFieldsSubmit: [],
  });
  const currentRowEdit = useRef({});
  const setupCopy = useRef();

  // functions and callbacks
  const getCredits = async () => {
    const tempCredits = await api.credits.get({});
    const ordered = orderBy(tempCredits, [(data) => data?.name?.toLowerCase()], ["asc"]);
    setCredits([...ordered]);
  };

  const getCovenantReportsByCreditId = async ({ creditId }) => {
    setCovRptsLoading(true);
    const tempCovRpts = await api.covenants.getCovenantReportsByCreditId({ creditId });
    const fin = tempCovRpts?.filter((rpt) => !rpt.isPortfolio);
    const port = tempCovRpts?.filter((rpt) => rpt.isPortfolio);
    const orderedFin = orderBy(fin, "reportDate", "desc");
    const orderedPort = orderBy(port, "reportDate", "desc");
    financialProps.current.covRpts = orderedFin;
    portfolioProps.current.covRpts = orderedPort;
    setCovRptsLoading(false);
  };

  const getReportingHistory = async ({ creditId, tableName, sortColumn, sortType }) => {
    setReportingHistoryLoaded(false);
    let tempReportingHistoryResponses = await api.reportingHistory.get({ creditId });
    const orderedReportingHistory =
      sortColumn && sortType && tableName === "Reporting History"
        ? orderBy(tempReportingHistoryResponses, [(data) => data[sortColumn]?.toLowerCase()], [sortType])
        : orderBy(tempReportingHistoryResponses, "startDate", "asc");
    reportingHistoryProps.current.dataset = structuredClone([...orderedReportingHistory]);
    reportingHistoryProps.current.originalData = structuredClone([...orderedReportingHistory]);
    reportingHistoryProps.current.detailsDataSet = structuredClone([...orderedReportingHistory]);
    setReportingHistoryLoaded(true);
  };

  const getDealDocuments = async ({ creditId, sortColumn, sortType }) => {
    const response = await api.dealDocuments.get({ endpoint: `deal-documents?creditId=${creditId}` });
    dealDocProps.current.dataset = structuredClone([...response]);
    dealDocProps.current.originalData = structuredClone([...response]);
    dealDocProps.current.detailsDataSet = structuredClone([...response]);
    setDealDocumentsLoaded(response ? response : []);
  };

  const cancelActions = async () => {
    setCancelTriggered(true);
    financialProps.current.detailsDataSet = [];
    portfolioProps.current.detailsDataSet = [];
    currentRowEdit.current = {};
    standAloneFields.current.financialFieldsSubmit = structuredClone([...standAloneFields.current.financialFields]);
    standAloneFields.current.portfolioFieldsSubmit = structuredClone([...standAloneFields.current.portfolioFields]);
    standAloneFields.current.reportingFieldsSubmit = structuredClone([...standAloneFields.current.reportingFields]);
    setEdit(false);
  };

  const defaultFinancialProps = {
    sectionLabel: "Financial Covenants",
    dataset: [],
    originalData: [],
    detailsDataSet: [],
    endpoint: `covenants`,
    cancelled: cancelTriggered,
  };
  const financialProps = useRef(structuredClone({ ...defaultFinancialProps }));

  const defaultPortfolioProps = {
    sectionLabel: "Portfolio Covenants",
    dataset: [],
    originalData: [],
    detailsDataSet: [],
    endpoint: `covenants`,
    cancelled: cancelTriggered,
    isPortfolio: true,
  };
  const portfolioProps = useRef(structuredClone({ ...defaultPortfolioProps }));

  const reportingHistoryProps = useRef({
    sectionLabel: "Reporting History",
    dataset: [],
    originalData: [],
    detailsDataSet: [],
    endpoint: `reportingHistory`,
    cancelled: cancelTriggered,
  });

  const dealDocProps = useRef({
    sectionLabel: "Deal Documents",
    dataset: [],
    originalData: [],
    detailsDataSet: [],
    endpoint: `dealDocuments`,
    cancelled: cancelTriggered,
  });

  const pageSetup = async () => {
    const tempAdmin = await getUserCognitoGroups("transactionSummaryAdmin");
    setIsTsAdmin(tempAdmin);
    financialProps.current.isTsAdmin = tempAdmin;
    portfolioProps.current.isTsAdmin = tempAdmin;
    const apiCalls = [getCredits()];
    await Promise.all(apiCalls);
  };

  const tabs = ["Summary", reportingHistoryProps.current.sectionLabel, dealDocProps.current.sectionLabel];

  const sharedProps = {
    loadingResponses,
    edit,
    setEdit,
    selectedCredit,
    amendmentList,
    setAmendmentList,
    selectedAmendment,
    setSelectedAmendment,
    setup: setupCopy,
    dropCardActive,
    setDropCardActive,
    blackout,
    setBlackout,
    tabs,
    tab,
    setTab,
    reportingHistoryProps,
  };

  return (
    <PageTemplate
      id="transaction-summary"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Transaction Summary"
      defaultOpenMenus={["1"]}
      content={
        <TransactionSummaryContent
          {...sharedProps}
          getCovenantReportsByCreditId={getCovenantReportsByCreditId}
          covRptsLoading={covRptsLoading}
          getReportingHistory={getReportingHistory}
          getDealDocuments={getDealDocuments}
          credits={credits}
          tableMinHeight={tableMinHeight}
          setSelectedCredit={setSelectedCredit}
          reportingHistoryLoaded={reportingHistoryLoaded}
          dealDocumentsLoaded={dealDocumentsLoaded}
          setLoadingResponses={setLoadingResponses}
          financialProps={financialProps}
          portfolioProps={portfolioProps}
          defaultFinancialProps={defaultFinancialProps}
          defaultPortfolioProps={defaultPortfolioProps}
          dealDocProps={dealDocProps}
        />
      }
      setup={pageSetup}
      crudControls={<CrudControls {...sharedProps} isTsAdmin={isTsAdmin} cancelActions={cancelActions} />}
    />
  );
};
export default TransactionSummary;
