import { getToken, oktaReady, logout } from "./okta.js";

import { EventSourcePolyfill } from 'event-source-polyfill';

const EventSource = EventSourcePolyfill;

var baseUrl = false;

var toast = {};

const apiFetch = async ({ method = "GET", endpoint, body = undefined, showToast = true }) => {

  if (!baseUrl) throw new Error('API URL not set');

  const token = getToken();

  if (!token) {
    window.location.href = '/logout';
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }

  const headers = { "Authorization": `Bearer ${token}`, "Access-Control-Request-Private-Network": true };
  if (!(body instanceof FormData)) headers["Content-Type"] = "application/json";

  if (body && typeof body === 'object' && !(body instanceof FormData)) body = JSON.stringify(body);

  return await fetch(`${baseUrl}${endpoint}`, {
    method,
    credentials: 'include',
    headers,
    body
  }).then(async (res) => {

    if (res.ok) {

      try {
        return await res.json();
      } catch (e) {
        return res.body;
      }
    }

    if (res.status === 401) {
      logout();
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
    
    throw new Error(res.statusText);

  }).catch((err) => {

    if (err.message === 'Failed to fetch') err.message = 'Unable to reach our API server.';

    if (showToast && toast.current)
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, sticky: true });

    throw err;
  });
};

var esList = {};

const apiEventSource = async ({ endpoint, id = 'shared' }) => {

  if (esList[id]) {
    esList[id].close();
    delete esList[id];
  }

  const token = getToken();

  if (!token) {
    logout();
    throw new Error('Token not found');
  }

  esList[id] = { onopen: () => { }, onconnection: (id) => { }, onmessage: (event) => { }, onerror: (error) => { } };

  const setupES = () => {
    esList[id].es = new EventSource(`${baseUrl}${endpoint}`, {
      credentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        "Access-Control-Request-Private-Network": true
      }
    });
    esList[id].close = () => esList[id].es.close();
    esList[id].es.onopen = (event) => esList[id].onopen(event);
    esList[id].es.onmessage = (event) => {
      if (event.lastEventId === '1') {
        console.log('connectionId', event.data);
        esList[id].onconnection(event.data)
      } else if (!event.data) {
        es.close();
      } else try {
        const data = JSON.parse(event.data);
        esList[id].onmessage(data);
      } catch (e) {
        esList[id].onmessage(event.data);
      }
    };
    esList[id].es.onerror = (error) => {
      if (es.readyState === 2) setTimeout(() => {
        console.log('Reconnecting ES...');
        es.close();
        setupES();
      }, 5000);
      else esList[id].onerror(error);
    };
  };

  setupES();

  return esList[id];
};

export const WBAPI = {
  baseUrl: (url) => baseUrl = url,
  setToast: (t) => toast = t,
  list: (endpoint, showToast = true) => apiFetch({ endpoint, showToast }),
  get: (endpoint, showToast = true) => apiFetch({ endpoint, showToast }),
  create: (endpoint, body, showToast = true) => apiFetch({ endpoint, method: "POST", body, showToast }),
  update: (endpoint, body, showToast = true) => apiFetch({ endpoint, method: "PUT", body, showToast }),
  delete: (endpoint, showToast = true) => apiFetch({ endpoint, method: "DELETE", showToast }),
  fetch: ({ method = "GET", endpoint, body = undefined, showToast = true }) => apiFetch({ method, endpoint, body, showToast }),
  subscribe: (opts) => apiSubscribe(opts),
  eventSource: (endpoint, id) => { return apiEventSource({ endpoint, id }); }
};

var subscribers = {};
var subId = 0;

const apiSubscribe = (opts) => {

  const mySubId = subId++;

  const { endpoint, showToast } = opts;

  const endpointPath = new URL(endpoint, baseUrl).pathname;

  const iv = setInterval(() => {

    if (typeof opts.callback !== 'function') {
      delete subscribers[endpointPath][mySubId];

      clearInterval(iv);
    }
  }, 5000);

  if (typeof subscribers[endpointPath] !== 'object') subscribers[endpointPath] = {};

  subscribers[endpointPath][mySubId] = async () => {

    if (typeof opts.callback !== 'function') return;

    return await apiFetch({ endpoint, showToast }).then((data) => { opts.callback(data); return data; }).catch((e) => {});
  };

  (async () => await subscribers[endpointPath][mySubId]())();

  return () => {
    clearInterval(iv);
    delete subscribers[endpointPath][mySubId];
    if (Object.keys(subscribers[endpointPath]).length === 0) delete subscribers[endpointPath];
  };
};

var es = false;

oktaReady(async () => {
  es = await apiEventSource({ endpoint: '/eventstream', id: 'primary' });
  es.onmessage = (data) => {

    const { endpoint } = data;

    if (toast.current && data && data.message)
      toast.current.show({ severity: data.severity || 'info', summary: data.subject || 'Info', detail: data.message });

    if (subscribers[endpoint])
      Object.values(subscribers[endpoint]).forEach((notify) => notify());
  };
});
