import { apiFetch } from '..'
import store from '../../store';
import { isEmpty } from 'lodash';

const getDealDocumentsByCreditId = async ({ endpoint = `deal-documents` }) => {
  const response = await apiFetch({ endpoint });
  if (!response) {
    const message = 'no response from deal-documents api call';
    console.error(message);
    return message;
  }
  return response;
};

const createDealDocument = async ({ body, endpoint = `deal-documents`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this deal document. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const updateDealDocument = async ({ id, body, endpoint = `deal-documents/${id}`, method = 'PUT' }) => {
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const deleteDealDocument = async ({ id, body = {}, endpoint = `deal-documents/${id}`, method = 'DELETE' }) => {
  console.log(`body delete Deal Document`, id, body);
  await apiFetch({ endpoint, method, body });
  return
};

const endpoints = {
  get: getDealDocumentsByCreditId,
  create: createDealDocument,
  update: updateDealDocument,
  delete: deleteDealDocument
};
export default endpoints;