import { useState, useEffect } from "react";
import { startCase } from "lodash";

export const TextMatcher = ({ data, dataType, ...props }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [selectionMade, setSelectionMade] = useState(false);
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Split input value by commas
    const inputValues = value.split(",").map((val) => val.trim());
    // Filter items that match the input values
    const matches = data.filter((item) => {
      const [lastEntry] = inputValues.slice(-1);
      return item.toLowerCase().includes(lastEntry.toLowerCase());
    });
    if (e.target.value) {
      setSelectionMade(false);
      setSelectedItems(matches);
    } else {
      setSelectionMade(true);
      setSelectedItems([]);
    }
    // checking props makes it so the component works even if value and onchange aren't sent in
    if (props?.onChange) {
      props?.onChange(e);
    }
    if (typeof props.value === "undefined") {
      setInputValue(value);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && highlightedIndex < selectedItems.length - 1) {
      setHighlightedIndex((prevIndex) => prevIndex + 1);
    } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
      setHighlightedIndex((prevIndex) => prevIndex - 1);
    } else if (e.key === "Enter" || e.key === "Tab") {
      if (highlightedIndex >= 0 && highlightedIndex < selectedItems.length) {
        let newValue = "";
        if (dataType === "email") {
          const compareVal = props?.value ? props?.value : inputValue;
          if (compareVal.includes(",")) {
            const tempEmails = compareVal.split(",");
            const fullEmails = tempEmails.filter((email) => email.includes("@"));
            fullEmails.push(selectedItems[highlightedIndex]);
            newValue = fullEmails.join(",");
          } else {
            newValue = selectedItems[highlightedIndex];
          }
        } else {
          newValue = selectedItems[highlightedIndex];
        }
        // checking props makes it so the component works even if value and onchange aren't sent in
        if (props?.onChange) {
          props?.onChange({ target: { value: newValue } });
        }
        if (typeof props?.value === "undefined") {
          setInputValue(newValue);
        }
        setHighlightedIndex(-1);
        setSelectionMade(true);
        e.preventDefault(); // prevent form submission or tabbing to the next input field
      }
    }
  };
  useEffect(() => {
    setHighlightedIndex(-1); // reset highlighted index when suggestions change
  }, [selectedItems]);
  return (
    <div className={`control mt-3 ${props?.controlClass || ""}`} style={props?.controlStyle}>
      <div className={props?.label ? "is-flex is-flex-direction-column" : ""}>
        {props?.label && <label htmlFor={props?.name}>{startCase(props?.label?.toLowerCase())}</label>}
        <div className="is-flex">
          <textarea
            id={props?.id}
            type="textarea"
            name={props?.name}
            value={props.value || inputValue}
            onChange={async (e) => {
              await handleInputChange(e);
              if (props?.onChange) {
                props?.onChange(e);
              }
            }}
            onKeyDown={handleKeyDown}
            placeholder={props?.placeholder}
            onBlur={() => {
              setTimeout(() => {
                setSelectionMade(true);
                if (props?.onBlur()) {
                  props?.onBlur();
                }
              }, 500);
            }}
            onFocus={props?.onFocus}
            maxLength={props?.maxlength}
            autoComplete="off"
            className={`textarea ${props?.className || ""}`}
            style={props?.style}
            disabled={props?.disabled}
          />
        </div>
      </div>
      {!selectionMade && (
        <ul className="text-match-selector">
          {selectedItems.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                let newValue = "";
                if (dataType === "email") {
                  const compareVal = props?.value ? props?.value : inputValue;
                  if (compareVal.includes(",")) {
                    const tempEmails = compareVal.split(",");
                    const fullEmails = tempEmails.filter((email) => email.includes("@"));
                    fullEmails.push(item);
                    newValue = fullEmails.join(",");
                  } else {
                    newValue = item;
                  }
                } else {
                  newValue = item;
                }
                // checking props makes it so the component works even if value and onchange aren't sent in
                if (props?.onChange) {
                  props?.onChange({ target: { value: newValue } });
                }
                if (typeof props?.value === "undefined") {
                  setInputValue(newValue);
                }
                setSelectionMade(true);
              }}
              className={index === highlightedIndex ? "text-match-highlighted" : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
      {props?.maxlength && (
        <span>
          <span className="char-counter">
            {props?.value ? props?.value?.length : inputValue.length || 0}/{props?.maxlength}
          </span>
          <sub className="ml-3 has-text-danger">{props?.errorMessage}</sub>
        </span>
      )}
    </div>
  );
};
export default TextMatcher;
