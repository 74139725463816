import awsconfig from './amplifyconfiguration.json';
import { get, find, forEach } from 'lodash';

const envEndpoint = awsconfig.aws_cloud_logic_custom[0]?.endpoint;
let tempEnv;
if (envEndpoint) tempEnv = envEndpoint.includes('test') || envEndpoint.includes('dev') ? 'development' : 'production';
export const env = tempEnv;

export default function transformRedirect() {
  // splitting the cognito strings into arrays
  const redirectListsFromCognito = {
    cognitoSignInList: get(awsconfig, 'oauth.redirectSignIn').split(','),
    cognitoSignOutList: get(awsconfig, 'oauth.redirectSignOut').split(',')
  };

  // mapping cogito urls to envHostList object
  const envHostList = {};
  forEach(redirectListsFromCognito, (host, listType) => {
    envHostList[listType] = host.map(origin => ({ host: origin, route: origin.split(window.location.origin)[1] }));
  });

  // checks window origin against cognito url to validate client is loading from a valid cognito origin.
  const validateOrigin = cognito => { return window.location.origin + cognito.route === cognito.host };
  const validSignInOrigin = find(envHostList.cognitoSignInList, validateOrigin);
  const validSignOutOrigin = find(envHostList.cognitoSignOutList, validateOrigin);

  // overwriting aws-export with mutated redirects
  if (validSignInOrigin && validSignOutOrigin) {
    awsconfig.oauth.redirectSignIn = `${validSignInOrigin.host}`;
    awsconfig.oauth.redirectSignOut = `${validSignOutOrigin.host}`;
  }

  return awsconfig
}
