import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getAllColumnsByCreditId = async ({ creditId, endpoint = `borrowing-base-columns?creditId=${creditId}`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  iStore.bbColumns = [...response];
  return iStore.bbColumns;
};

const endpoints = {
  get: getAllColumnsByCreditId
};

export default endpoints;