import microservices from "../../services/microservices";
import TextInput from "../../components/TextInput";
import { useState } from "react";

const CurrencyInputCell = ({ fundingRow, fundingDetails, amountSelections, calculateTotal, disabled, limit }) => {
  const [displayValue, setDisplayValue] = useState(fundingRow?.value);

  const selectText = ({ id }) => {
    const input = document.getElementById(id);
    input.focus();
    input.select();
  };

  const exceedsLimit = ({ val, limit }) => {
    const numVal = typeof val === "string" ? microservices.stringToNumber(val) : val;
    const numLimit = typeof limit === "string" ? microservices.stringToNumber(limit) : limit;
    return numVal > numLimit;
  };

  const validNumberOfDecimals = (val) => {
    if (val?.includes(".")) {
      const periodCount = val.split("")?.filter((char) => char === ".")?.length;
      if (periodCount > 1) {
        return false;
      }
      // spit it by . to only run regex on cents
      const splitVal = val.split(".");
      const centsRegex = /^[0-9.]+$/;
      const tempLast = splitVal.length - 1;
      if (splitVal[tempLast]) {
        if (!centsRegex.test(splitVal[tempLast])) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <TextInput
      id={fundingRow?.label}
      value={displayValue}
      autoComplete="off"
      onChange={(e) => {
        const currencyRegex = /^[0-9,.]*$/;
        let valid = currencyRegex.test(e.target.value);
        if (valid) {
          if (exceedsLimit({ val: e.target.value, limit })) {
            e.target.value = "";
            valid = false;
            return;
          }
        }
        // check if there is already a "." and prevent more
        if (valid) {
          valid = validNumberOfDecimals(e.target.value);
        }
        // check that any commas have 3 trailing digits before allowing another comma
        if (valid) {
          if (e.target.value?.includes(",")) {
            if (e.target.value[0] === "," || e.target.value?.includes(",,") || e.target.value?.includes(",.")) return (valid = false);
            const commaSeparated = e.target.value?.split(",");
            commaSeparated.forEach((item, idx) => {
              if (!item) return;
              if (item?.includes(".")) {
                valid = item.length <= 6;
              } else {
                valid = item.length <= 3;
              }
              if (valid) {
                if (idx > 0 && idx < commaSeparated.length - 1) {
                  valid = item.length >= 3;
                  return;
                } else {
                  valid = !!item.length;
                  return;
                }
              }
              return;
            });
          }
        }
        if (valid) {
          if (e.target.value.includes(".")) {
            const decimalLength = e.target.value.split(".")[1].length;
            if (decimalLength <= 2) {
              fundingRow.value = e.target.value;
              setDisplayValue(e.target.value);
            }
          } else {
            fundingRow.value = e.target.value;
            setDisplayValue(e.target.value);
          }
        } else {
          console.error("invalid character");
        }
      }}
      disabled={disabled}
      onFocus={() => {
        selectText({ id: fundingRow?.label });
      }}
      onBlur={() => {
        // solve for empty string in input
        const isNotANumber = isNaN(microservices.stringToNumber(fundingRow.value));
        if (!fundingRow.value || isNotANumber) fundingRow.value = 0;
        // format display value
        fundingRow.value =
          typeof fundingRow.value === "string" ? microservices.stringToNumber(fundingRow.value).toFixed(2) : fundingRow.value.toFixed(2);
        setDisplayValue(fundingRow.value);
        const correspondingFundingRecords = fundingDetails.current?.fundingAmountsList?.filter((item) => amountSelections?.includes(item?.label));
        calculateTotal(amountSelections, correspondingFundingRecords);
      }}
    />
  );
};
export default CurrencyInputCell;
