
import { apiFetch } from '..';
import store from '../../store';
import { isEmpty } from 'lodash';

const getTransactionSummaryAmendmentByCreditId = async ({ creditId, endpoint = `transaction-summary-amendments/?creditId=${creditId}&includeTransactionSummaryData=true`, iStore = store }) => {
  if (!creditId) {
    const message = `Unable to get this transaction summary amendment. transaction summary amendment credit ID (${creditId}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getTransactionSummaryAmendmentById = async ({ id, endpoint = `transaction-summary-amendments/${id}?includeTransactionSummaryData=true`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this transaction summary amendment. transaction summary amendment ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const createTsAmendment = async ({ body, endpoint = `transaction-summary-amendments?includeTransactionSummaryData=true`, method = 'POST' }) => {
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const deleteTsAmendment = async ({ id, endpoint = `transaction-summary-amendments/${id}`, method = 'DELETE' }) => {
  const response = await apiFetch({ body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  return response;
};

const endpoints = {
  get: ({ id, creditId }) => id ? getTransactionSummaryAmendmentById({ id }) : getTransactionSummaryAmendmentByCreditId({ creditId }),
  create: createTsAmendment,
  delete: deleteTsAmendment
};

export default endpoints;