import { Loader } from 'rsuite';

export default function InlineSaveIndicator({ showId, showSaving, showSavingDone }) {
  const showIt = showId === showSaving;
  return (
    <>
      {showIt && !showSavingDone &&
        <div className="loader-is-info">
          <Loader className="ml-3 mt-2 has-text-info has-text-weight-semibold" size="sm" content="Saving..." />
        </div>}
        
      {showIt && showSavingDone &&
        <div className="ml-3 mt-2 has-text-success has-text-weight-semibold">
          <i className="fa-solid fa-check mr-2" />
          <span>Done</span>
        </div>}
    </>
  )
}