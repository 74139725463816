import React, { useState, useRef, useEffect, useMemo } from "react";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";

import { GetCloudLinks } from "../lib/cloud";
import { getGroups } from "../lib/okta";

import {
  Layout,
  LayoutLogo,
  LayoutSidebar,
  LayoutSidebarHeader,
  LayoutMenu,
  LayoutMenuSection,
  LayoutMenuItem,
  LayoutContentWrapper,
  LayoutContent,
  Topbar,
  TopbarStart,
  TopbarBreadcrumbs,
  TopbarBreadcrumb,
  TopbarEnd,
} from "./LayoutComponents";

var configCache = { adminLinks: [], nameOfApp: 'WebBank' };
var linksCache = [];
var envCache = 'BETA';

export var setConfig = (config) => { configCache = config; };

export var setLinks = (links) => { linksCache = links; };

export var setEnv = (env) => { envCache = env; };

export default function PrimePageTemplate({
  content,
  setup = false,
  getPermission = false,
  setDataLoading = (value) => { },
  breadcrumbs = false,
  links = false,
  config = false,
  children = false,
  anchored = false
}) {

  if (!config) config = configCache;
  if (!links) links = linksCache;

  const { nameOfApp, adminLinks } = configCache;

  const [cloudLinksList, setCloudLinksList] = useState([]);
  const [adminLinksList, setAdminLinksList] = useState([]);
  const [env, setEnvValue] = useState(envCache);
  const [sidebarLinks, setSidebarLinks] = useState(links);

  setConfig = (cfg) => { configCache = config = cfg; };
  setLinks = (list) => { linksCache = links = list; setSidebarLinks(list); };
  setEnv = (value) => { envCache = value; setEnvValue(value) };

  const groups = useMemo(getGroups, [getGroups]);

  const navigate = useNavigate();

  useEffect(() => {

    setAdminLinksList([
      {
        label: 'Settings',
        items: adminLinks.filter(link => link.groups ? groups.some((group) => link.groups.includes(group)) : true).map(link => {
          return {
            label: link.label,
            icon: link.icon,
            command: () => navigate(link.url),
            className: window.location.pathname === link.url && 'p-menuitem-active',
          }
        })
      },
    ]);
  }, [adminLinks, groups, navigate]);

  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === '1');

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const setTheme = () => {

    document.body.className = darkMode ? "dark-theme" : "light-theme";

    const themeElement = document.getElementById("prime-theme");
    if (themeElement.href.indexOf(darkMode ? "webbank-dark" : "webbank-light") === -1) {
      themeElement.href = themeElement.href.replace(/webbank-(dark|light)/g, darkMode ? "webbank-dark" : "webbank-light");
    }

    localStorage.setItem("darkMode", darkMode ? '1' : '0');
  };

  useEffect(setTheme, [darkMode]);

  useEffect(() => {

    setTheme();

    if (getPermission) getPermission();

    if (setup) (async () => {

      setDataLoading(true);

      await setup().catch((e) => e);

      setDataLoading(false);
    })();

    GetCloudLinks(config).then(setCloudLinksList);

    // eslint-disable-next-line
  }, []);

  const [permissableLinks, setPermissableLinks] = useState([]);
  const [active, setActive] = useState(null);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {

    setPermissableLinks(sidebarLinks.map((link) => {

      const items = link?.items?.map((item) => {

        if (item.isActive && item.isActive(window.location.pathname)) {
          setActiveSection(link.label);
          setActive(item.label);
        }

        return { ...item, command: () => {
          setActive(item.label);
          if (item.command) item.command();
          if (item.url) navigate(item.url);
        }};
      });
      return { ...link, items };
    }).filter((link) => link.items.length > 0));

  }, [sidebarLinks, navigate]);

  const breadcrumbComponents = useMemo(() => {

    if (breadcrumbs) {

      return (breadcrumbs).reduce((list, breadcrumb, index) => ([
        ...list,
        "/",
        <TopbarBreadcrumb key={index} onClick={() => breadcrumb.url && navigate(breadcrumb.url)}>
          {breadcrumb.label}
        </TopbarBreadcrumb>
      ]), []);

    } else if (activeSection && active) {

      return [
        "/",<TopbarBreadcrumb key="activeSection">{activeSection}</TopbarBreadcrumb>,
        "/",<TopbarBreadcrumb key="activeItem">{active}</TopbarBreadcrumb>,
      ];

    } else return null;

  }, [active, activeSection, navigate, breadcrumbs]);

  const [menuVisible, setMenu] = useState(window.matchMedia("screen and (min-width: 992px)").matches);
  const hideMenu = () => setMenu(false);
  const toggleMenu = () => setMenu(!menuVisible);

  const adminLinksMenu = useRef(null);
  const cloudLinksMenu = useRef(null);

  return (
    <Layout menuVisible={menuVisible} hideMenu={hideMenu}>
      <LayoutSidebar>
        <LayoutSidebarHeader>
          <LayoutLogo env={env} alt="WebBank" />{darkMode}
          { config.appIcon ? <img className="app-logo" src={config.appIcon} alt={config.nameOfApp} /> : '' }
        </LayoutSidebarHeader>
        <LayoutMenu>
          {permissableLinks.map((link, index) => (
            <LayoutMenuSection label={link.label} active={active} key={index} icon={link.icon} onClick={link.command}>
              {link?.items?.map((item, index) => (
                <LayoutMenuItem
                  key={index}
                  label={item.label}
                  active={item.isActive(window.location.pathname)}
                  icon={item.icon}
                  onClick={item.command}
                />
              ))}
            </LayoutMenuSection>
          ))}
        </LayoutMenu>
      </LayoutSidebar>
      <LayoutContentWrapper anchored={anchored}>
        <Topbar>
          <TopbarStart>
            <button type="button" className="topbar-menubutton p-link p-trigger" onClick={toggleMenu}>
              <i className="pi pi-bars"></i>
            </button>
            <TopbarBreadcrumbs>
              <TopbarBreadcrumb key="nameOfApp" onClick={() => navigate("/")}>{nameOfApp}</TopbarBreadcrumb>
              {breadcrumbComponents}
            </TopbarBreadcrumbs>
          </TopbarStart>
          <TopbarEnd>
            <Menu model={adminLinksList} popup ref={adminLinksMenu} id="admin_menu" />
            <Menu model={cloudLinksList} popup ref={cloudLinksMenu} id="cloud_menu" />
            <button type="button" className="topbar-menubutton p-link p-trigger" onClick={toggleDarkMode}>
              <i className="pi pi-moon"></i>
            </button>
            <button
              type="button"
              className="topbar-menubutton p-link p-trigger"
              onClick={(event) => cloudLinksMenu.current.toggle(event)}>
              <i className="pi pi-cloud"></i>
            </button>
            {adminLinksList[0]?.items?.length > 0 && (
              <button
                type="button"
                className="topbar-menubutton p-link p-trigger"
                onClick={(event) => adminLinksMenu.current.toggle(event)}>
                <i className="pi pi-cog"></i>
              </button>
            )}
          </TopbarEnd>
        </Topbar>
        <LayoutContent>{content || children}</LayoutContent>
      </LayoutContentWrapper>
    </Layout>
  );
}
