import { getGroups } from "./okta";

import { logout } from "./okta";

export const GetCloudLinks = async (config) => {

  const groups = await getGroups();

  const items = [
    { tabIndex: -1, className: config.appID === 'com.webbank.skynet' ? 'p-menuitem-active' : '', icon: 'pi wbi wbi-skynet-portal', url: 'https://skynet.webbank.com', groups: ['cloud'], label: 'Skynet' },
    { tabIndex: -1, className: config.appID === 'com.webbank.cylon' ? 'p-menuitem-active' : '', icon: 'pi wbi wbi-cylon', url: 'https://cylon.webbank.com', groups: ['cylon'], label: 'Cylon' },
    { tabIndex: -1, className: config.appID === 'com.webbank.ai' ? 'p-menuitem-active' : '', icon: 'pi wbi wbi-webby', url: 'https://ai.webbank.com', groups: ['cloud'], label: 'WebBank AI' },
  ].sort((a, b) => a.className > b.className ? -1 : 1)
    .filter((link) => groups.includes(link.groups[0]));

  return [
    { label: 'Skynet Cloud Apps', items },
    { separator: true },
    { icon: 'pi pi-sign-out', command: logout, label: 'Logout' }
  ];
};