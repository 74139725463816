import { apiFetch } from '.';
import store from '../store';
import { isEmpty } from 'lodash';

const getCredit = async ({ id, endpoint = `credits/${id}`, iStore = store }) => {
  if (!id) {
    const message = `Unable to get this Product. Product ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ id, endpoint });
  if (!response || isEmpty(response)) return;
  return response;
};

const getAllCredits = async ({ endpoint = `credits`, iStore = store }) => {
  if(store.user){
    const response = await apiFetch({ endpoint });
    if (!response || isEmpty(response)) return;
    iStore.credits = [...response];
    return iStore.credits;
  } else {
    return [];
  }
};

const createCredit = async ({ body, endpoint = `credits`, method = 'POST', iStore = store }) => {
  if (!body || isEmpty(body)) {
    const message = `Unable to create this credit. Body is empty`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({ body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllCredits({});
  return response;
};

const updateCredit = async ({ id, body, endpoint = `credits/${id}`, method = 'PUT', iStore = store }) => {
  if (!id || !body || isEmpty(body)) {
    const message = `Unable to update this credit. Id or body is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllCredits({});
  return response;
};

const deleteCredit = async ({ id, endpoint = `credits/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this credit. Id is missing`;
    store.actions.handleError({ message }, iStore);
    return console.error(message);
  }
  const response = await apiFetch({id, body: {}, endpoint, method });
  if (!response || isEmpty(response)) return;
  getAllCredits({});
  return response;
};

const endpoints = {
  get: ({ id }) => id ? getCredit({ id }) : getAllCredits({}),
  create: createCredit,
  update: updateCredit,
  delete: deleteCredit
};

export default endpoints;