import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import TransactionSummary from "../pages/transactionSummary/TransactionSummary";
import TransactionSummaryAdmin from "../pages/admin/TransactionSummaryAdmin";
import PrimeCreditAdmin from "../pages/primeReact/admin/CreditAdmin";
import CreditAdmin from "../pages/admin/CreditAdmin";
import BorrowingBaseAdmin from "../pages/admin/BorrowingBaseAdmin";
import BorrowingBaseReview from "../pages/borrowingBase/BorrowingBaseReview";
import PortfolioPerformanceDashboardAdmin from "../pages/admin/PortfolioPerformanceDashboardAdmin";
import store, { checkUser, signIn, getUserCognitoGroups } from "../store";
import PortfolioPerformanceDashboard from "../pages/portfolioPerformanceDashboard/PortfolioPerformanceDashboard";
import * as lqr from "../pages/lqr-index";

const appStore = store;
const loaderCheck = async (a) => {
  if (!appStore.user && sessionStorage.signedOut) {
    return redirect("/login");
  } else if (!appStore.user) {
    await signIn();
  }
  // protect routes:
  const isTransactionSummaryAdmin = await getUserCognitoGroups("transactionSummaryAdmin");
  if ((a.request.url.includes("transactionSummaryAdmin") || a.request.url.includes("creditAdmin")) && !isTransactionSummaryAdmin) {
    return redirect("/");
  }

  if (a.request.url.includes("ppda") && !isTransactionSummaryAdmin) {
    return redirect("/");
  }

  const isBorrowingBaseAdmin = await getUserCognitoGroups("borrowingBaseAdmin");
  if (a.request.url.includes("borrowingBaseAdmin") && !isBorrowingBaseAdmin) {
    return redirect("/");
  }

  const isLqrAdmin = await getUserCognitoGroups("lqrAdmin");
  if (a.request.url.includes("lqrAdmin") && !isLqrAdmin) {
    return redirect("/");
  }

  const isPrime = await getUserCognitoGroups("prime");
  if(a.request.url.includes("prime") && !isPrime){
    return redirect("/");
  }

  return !store.user ? redirect("/login") : null;
};

export const routes = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/creditAdmin",
    element: <CreditAdmin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/prime/creditAdmin",
    element: <PrimeCreditAdmin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/transactionSummaryAdmin",
    element: <TransactionSummaryAdmin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/borrowingBaseAdmin",
    element: <BorrowingBaseAdmin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/ppda",
    element: <PortfolioPerformanceDashboardAdmin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/ppd",
    element: <PortfolioPerformanceDashboard />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/borrowingBaseReview",
    element: <BorrowingBaseReview />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/lqrAdmin",
    element: <lqr.LqrLanding />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/lqrReport",
    element: <lqr.LqrReport />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/lqrReport/summary",
    element: <lqr.LqrSummary />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/",
    element: <lqr.LqrSummary />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/transactionSummary",
    element: <TransactionSummary />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const router = {
  ReactDOM,
  RouterProvider,
};
export default router;
