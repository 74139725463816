import React, { useState, useEffect } from 'react'; // reactive state
import { Sidebar, Sidenav, Nav } from 'rsuite';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getUserCognitoGroups } from '../store';

export default function SideMenu(props) {

  // sidebar
  const [openKeys, setOpenKeys] = useState(props.defaultOpenMenus);
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(true);
  const contract = () => setExpanded(false);
  const SpdIcon = () => (<i className="side-nav-icon" />);
  const ReportsPortal = () => (<i className="fa-solid fa-chart-line side-nav-icon" />);
  const Admin = () => (<i className="fa-solid fa-lock side-nav-icon" />);
  const navigate = useNavigate();
  const [transactionSummaryAdmin, setTransactionSummaryAdmin] = useState(false);
  const [borrowingBaseAdmin, setBorrowingBaseAdmin] = useState(false);
  const [lqrAdmin, setLqrAdmin] = useState(false);
  const [prime, setPrime] = useState(false);

  const NavItemLink = React.forwardRef((props, ref) => {
    const location = useLocation();
    const goToPath = (path = '/') => {
      navigate(path);
    };

    function handleState() {
      props.name ? navigate(props.to, { state: { title: props.name, reportUrl: props.reporturl } }) : goToPath(props.to);
    }

    return (
      <Nav.Item
        {...props}
        ref={ref}
        active={props.to === location.pathname}
        componentclass={NavLink}
        onClick={handleState}
      />
    );
  });

  const toggleOpenMenus = key => {
    const keyIdx = openKeys.indexOf(key);
    const temp = [...openKeys];
    if (keyIdx > -1) {
      temp.splice(keyIdx, 1);
    } else {
      temp.push(key);
    }
    setOpenKeys([...temp]);
  }

  const setup = async () => {
    const tempTransactionSummaryAdmin = await getUserCognitoGroups('transactionSummaryAdmin');
    if (!!tempTransactionSummaryAdmin) {
      setTransactionSummaryAdmin(true);
    }
    const tempBbAdmin = await getUserCognitoGroups('borrowingBaseAdmin');
    if (!!tempBbAdmin) {
      setBorrowingBaseAdmin(true);
    }
    const tempLqrAdmin = await getUserCognitoGroups('lqrAdmin');
    if (!!tempLqrAdmin) {
      setLqrAdmin(true);
    }
    const isPrime = await getUserCognitoGroups('prime');
    if (!!isPrime) {
      setPrime(true);
    }
  };

  useEffect(() => {
    setup();
  }, []);

  return (
    <Sidebar collapsible width={expanded ? 300 : 56} style={{ zIndex: 11, position: 'relative' }} className='use-rsuite'>
      <Sidenav expanded={expanded} style={{ height: '100%' }} onMouseOver={expand} onMouseOut={contract} openKeys={openKeys}>
        <Sidenav.Body>
          <Nav activeKey="1">
            <Nav.Menu
              className="pl-0"
              title="Reports"
              eventKey="1"
              icon={<ReportsPortal />}
              onClick={() => toggleOpenMenus("1")}
            >
              <NavItemLink className="pl-0" eventKey="1-1" icon={<SpdIcon />} to="/transactionSummary">
                <span className='hover-3'>Transaction Summary</span>
              </NavItemLink>
              <NavItemLink className="pl-0" eventKey="1-2" icon={<SpdIcon />} to="/borrowingBaseReview">
                <span className='hover-3'>Borrowing Base Review</span>
              </NavItemLink>
              <NavItemLink className="pl-0" eventKey="1-3" icon={<SpdIcon />} to="/lqrReport">
                <span className='hover-3'>LQR Report</span>
              </NavItemLink>
              <NavItemLink className="pl-0" eventKey="1-4" icon={<SpdIcon />} to="/lqrReport/summary">
                <span className='hover-3'>LQR Summary</span>
              </NavItemLink>
              <NavItemLink className="pl-0" eventKey="1-5" icon={<SpdIcon />} to="/ppd">
                <span className='hover-3'>Portfolio Performance Dashboard</span>
              </NavItemLink>
            </Nav.Menu>
            <Nav.Menu
              className="pl-0"
              title="Admin"
              eventKey="2"
              icon={<Admin />}
              onClick={() => toggleOpenMenus("2")}
            >
              {transactionSummaryAdmin && <NavItemLink className="pl-0" eventKey="2-1" icon={<SpdIcon />} to="/creditAdmin">
                <span className='hover-3'>Credit Admin</span>
              </NavItemLink>}
              {prime && <NavItemLink className="pl-0" eventKey="2-1" icon={<SpdIcon />} to="/prime/creditAdmin">
                <span className='hover-3'>Prime Credit Admin</span>
              </NavItemLink>}
              {transactionSummaryAdmin && <NavItemLink className="pl-0" eventKey="2-2" icon={<SpdIcon />} to="/transactionSummaryAdmin">
                <span className='hover-3'>Transaction Summary Admin</span>
              </NavItemLink>}
              {borrowingBaseAdmin && <NavItemLink className="pl-0" eventKey="2-3" icon={<SpdIcon />} to="/borrowingBaseAdmin">
                <span className='hover-3'>Borrowing Base Admin</span>
              </NavItemLink>}
              {transactionSummaryAdmin && <NavItemLink className="pl-0" eventKey="2-4" icon={<SpdIcon />} to="/ppda">
                <span className='hover-3'>Portfolio Performance Dashboard Admin</span>
              </NavItemLink>}
              {lqrAdmin && <NavItemLink className="pl-0" eventKey="2-5" icon={<SpdIcon />} to="/lqrAdmin">
                <span className='hover-3'>LQR Admin</span>
              </NavItemLink>}
            </Nav.Menu>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </Sidebar>
  )
}