import PageTemplate from '../PageTemplate';
import { Loader } from 'rsuite';
import { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import api from '../../api';
import PortfolioPerformanceDashboardTable from './PortfolioPerformanceDashboardAdminTable';
import { handleAutoCreditSelection } from '../../services/microservices';

const Content = ({ credits, dataSetup, reportProps, resetSelections, disableFields, selectedCredit, setSelectedCredit }) => {
  const [loadingData, setLoadingData] = useState(false);

  const handleCreditSelect = async (e) => {
    if(e.target?.value === '0'){
      setSelectedCredit(null);
      resetSelections();
      sessionStorage.removeItem("selectedCredit");
      setLoadingData(false);
      return;
    }
    const temp = credits?.find(credit => credit?.id === e?.target?.value);
    setSelectedCredit({ ...temp });
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    setLoadingData(true);
    await dataSetup({ creditId: temp?.id });
    setLoadingData(false);
  };

  useEffect(() => {
    handleAutoCreditSelection({selectedCredit, handleCreditSelect});
    // eslint-disable-next-line
  }, [selectedCredit]);

  return (
    <div className="custom-flex-container">
      <div className="p-2">
        <div className="select" style={{ maxHeight: '36px' }}>
          <select id="source-temp-select" className="has-text-link" onChange={handleCreditSelect} value={selectedCredit?.id} style={{ maxHeight: '36px', fontSize: '14px' }} disabled={disableFields}>
            <option defaultValue={true} value={0}>Choose Credit</option>
            {credits?.map(entry => <option key={entry?.id} value={entry?.id}>{entry?.name}</option>)}
          </select>
        </div>
      </div>
      {loadingData ? <Loader center size="sm" content="Loading Tableau Reports..." />
        :
        selectedCredit && reportProps?.dataset && !loadingData && <div className='custom-flex-container'>
          <div id="borrowing-base-tab-container" className='custom-flex-container'>
            <PortfolioPerformanceDashboardTable creditId={selectedCredit?.id} {...reportProps} />
          </div>
        </div>}
    </div>
  )

};

const PortfolioPerformanceDashboardAdmin = () => {
  const [credits, setCredits] = useState([]);
  const [tableauReports, setTableauReports] = useState(null);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState();

  const dataSetup = async ({ creditId }) => {
    await getTableauReports({ creditId });
  };

  const getReports = async () => {
    const tempCredits = await api.credits.get({});
    const ordered = orderBy(tempCredits, [data => data?.name?.toLowerCase()], ['asc']);
    setCredits([...ordered]);
  };

  const getTableauReports = async ({ creditId, sortColumn, sortType }) => {
    setReportsLoading(true);
    const response = await api.reports.getReport({ id: creditId, endpoint: `portfolio-performance-dashboards?creditId=${creditId}` });
    const ordered = orderBy(response, [data => data[sortColumn] && typeof data[sortColumn] === 'string' ? data[sortColumn]?.toLowerCase() : data?.sortOrder], [sortType ? sortType : 'asc']);
    setTableauReports(ordered);
    setReportsLoading(false);
    return ordered;
  };

  const setup = async () => {
    await getReports();
  };

  // props mapping
  const reportProps = {
    dataset: tableauReports,
    setDataset: setTableauReports,
    refreshDataset({ sortColumn, sortType }){
      const tempReports = getTableauReports({ creditId: selectedCredit?.id, sortColumn, sortType });
      return tempReports;
    },
    loading: reportsLoading,
    setLoading: setReportsLoading
  };

  return (
    <PageTemplate
      id="portfolio-performance-dashbaord-admin"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Portfolio Performance Dashboard Admin"
      defaultOpenMenus={["2"]}
      setup={setup}
      content={
        <Content
          credits={credits}
          reportProps={reportProps}
          dataSetup={dataSetup}
          selectedCredit={selectedCredit}
          setSelectedCredit={setSelectedCredit}
        />}
    />
  )
};
export default PortfolioPerformanceDashboardAdmin;