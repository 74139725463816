const iconPrefix = "pi pi-fw pi-";

export const reports = [
  {
    label: "Transaction Summary",
    icon: `${iconPrefix}lock`,
    url: "/transactionSummary",
  },
  {
    label: "Borrowing Base Review",
    icon: `${iconPrefix}credit-card`,
    url: "/borrowingBaseReview",
  },
  {
    label: "LQR Report",
    icon: `${iconPrefix}star`,
    url: "/lqrReport",
  },
  {
    label: "Portfolio Performance Dashboard",
    icon: `${iconPrefix}chart-pie`,
    url: "/ppd",
  },
];