import { useState, useEffect } from "react";
import { startCase } from "lodash";

export const ApiSubmitButton = ({
  actionTriggered,
  actionDone,
  action,
  cb,
  icon,
  operationLabel,
  disabled,
  defaultLabel,
  className,
  title,
  buttonClassOverride,
  isMouseDown,
  style,
}) => {
  const [performingAction, setPerformingAction] = useState(false);
  const [donePerformingAction, setDonePerformingAction] = useState(false);

  const reset = () => {
    setPerformingAction(false);
    setDonePerformingAction(false);
  };

  useEffect(() => {
    if (actionTriggered) {
      setPerformingAction(actionTriggered);
    }
    if (actionDone) {
      setDonePerformingAction(true);
    }
  }, [actionTriggered, actionDone]);

  const handleAction = () => {
    async function asyncActions() {
      setPerformingAction(true);
      const response = await action();
      if (!response) {
        if (cb) {
          cb({ response: false });
        }
        return reset();
      }
      setDonePerformingAction(true);
      setTimeout(() => {
        setDonePerformingAction(false);
        setPerformingAction(false);
        if (cb) {
          cb(response);
        }
      }, 2000);
    }
    asyncActions().catch((error) => {
      console.error(error);
    });
  };

  const mainButtonProps = {
    className: `button ${buttonClassOverride ? buttonClassOverride : "is-success"} ${className || ""}`,
    disabled,
    title,
  };

  // isMouseDown changes onClick to onMouseDown to handle blur lifecycle issues
  isMouseDown ? (mainButtonProps.onMouseDown = handleAction) : (mainButtonProps.onClick = handleAction);

  return !performingAction ? (
    <button {...mainButtonProps} style={style}>
      <span className={defaultLabel ? "mr-2" : ""}>{icon || <i className="fa-solid fa-floppy-disk"></i>}</span>
      <span>{startCase(defaultLabel?.toLowerCase())}</span>
    </button>
  ) : !donePerformingAction ? (
    <button className={`button ${buttonClassOverride ? buttonClassOverride : "is-success"} ${className || ""}`} style={style}>
      <span className={"loader " + (operationLabel ? "mr-2" : "")}></span>
      <span>{operationLabel}</span>
    </button>
  ) : (
    <button className={`button ${buttonClassOverride ? buttonClassOverride : "is-success"} ${className || ""}`} style={style}>
      <span className={defaultLabel ? "mr-2" : ""}>
        <i className="fa-solid fa-check"></i>
      </span>
      <span>{defaultLabel ? "Done" : ""}</span>
    </button>
  );
};
export default ApiSubmitButton;
