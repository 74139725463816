import PageTemplate from "../PageTemplate";
import { Loader, SelectPicker } from "rsuite";
import { useState, useEffect } from "react";
import { orderBy } from "lodash";
import api from "../../api";
import store from "../../store";
import * as com from "../../components";
import { handleAutoCreditSelection } from "../../services/microservices";

const Content = ({
  credits,
  dataSetup,
  selectedReport,
  setSelectedReport,
  tableauReports,
  resetSelections,
  disableFields,
  token,
  setSelectedCredit,
  selectedCredit,
  loadingData,
  setLoadingData,
}) => {
  const [showTabView, setShowTabView] = useState(false);
  const handleCreditSelect = async (e) => {
    setLoadingData(true);
    setSelectedReport(null);
    if (e.target?.value === "0") {
      setSelectedCredit(null);
      resetSelections();
      setLoadingData(false);
      return;
    }
    const temp = credits?.find((credit) => credit?.id === e?.target?.value);
    setSelectedCredit({ ...temp });
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    if (e.target.value === "0") {
      sessionStorage.removeItem("selectedCredit");
      return;
    }
    setLoadingData(true);
    await dataSetup({ creditId: temp?.id });
    setLoadingData(false);
  };

  const handleReportSelect = async (e) => {
    let tempReport = e;
    setLoadingData(true);
    if (!e) {
      setSelectedReport(null);
      setLoadingData(false);
      return;
    }
    if (typeof e === "string") {
      tempReport = tableauReports?.find((report) => report?.id === e);
    }
    setSelectedReport(tempReport);
    setLoadingData(false);
  };

  useEffect(() => {
    handleAutoCreditSelection({ selectedCredit, handleCreditSelect });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="custom-flex-container">
      <div className="card p-2 is-flex is-justify-content-space-between" style={{ maxWidth: "calc(100vw - 50px)" }}>
        <div className="select" style={{ maxHeight: "36px" }}>
          <select
            id="source-temp-select"
            className="has-text-link"
            onChange={handleCreditSelect}
            value={selectedCredit?.id}
            style={{ maxHeight: "36px", fontSize: "14px" }}
            disabled={disableFields}
          >
            <option defaultValue={true} value={0}>
              Choose Credit
            </option>
            {credits?.map((entry) => (
              <option key={entry?.id} value={entry?.id}>
                {entry?.name}
              </option>
            ))}
          </select>
        </div>
        {selectedCredit && !loadingData && (
          <div className="is-flex is-align-items-center">
            <label className="is-size-6">Tabs</label>
            <com.Toggle value={showTabView} setValue={setShowTabView} disabled={disableFields} />
          </div>
        )}
      </div>
      {loadingData ? (
        <Loader center size="sm" content="Loading Tableau Reports..." />
      ) : (
        selectedCredit &&
        !loadingData && (
          <div className="custom-flex-container">
            <div className="is-flex is-align-items-flex-end pl-3" style={{ position: "relative", top: -8 }}>
              <div id="report-selection-interface" className="is-flex my-3" style={{ width: "100%", overflowX: "scroll" }}>
                {showTabView ? (
                  <div className="is-flex">
                    <div className="is-flex" style={{ width: 0 }}>
                      <div className="is-flex">
                        {tableauReports?.map((t) => (
                          <div
                            key={t.id}
                            onClick={() => handleReportSelect(t)}
                            className={`tab is-flex is-justify-content-center is-align-items-center ${
                              selectedReport === t ? "has-background-link has-text-white" : ""
                            } ${disableFields ? "custom-disabled" : ""}`}
                            style={{ width: "242px" }}
                          >
                            <div>{t.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  tableauReports && (
                    <SelectPicker
                      container={document.getElementById("portfolio-performance-dashbaord")}
                      data={tableauReports ?? []}
                      valueKey="id"
                      labelKey="name"
                      placeholder="Select Report"
                      value={selectedReport?.id}
                      onChange={handleReportSelect}
                      style={{ width: 300 }}
                      disabled={disableFields}
                    />
                  )
                )}
              </div>
            </div>
            <div id="tableau-content" className="custom-flex-container flex-scroll">
              <div className="flex-grow-1">
                {selectedReport ? (
                  <tableau-viz id="tableau-viz" src={selectedReport.tableauEmbedUrl} toolbar="bottom" token={token}></tableau-viz>
                ) : (
                  <div style={{ margin: "1rem" }}>No reports to show...</div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const PortfolioPerformanceDashboard = () => {
  const [credits, setCredits] = useState([]);
  const [tableauReports, setTableauReports] = useState(null);
  const [selectedReport, setSelectedReport] = useState();
  const [selectedCredit, setSelectedCredit] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const fetchData = async () => {
    setLoadingData(true);
    const tempToken = await api.reports.getTableauToken({ username: store?.user?.email });
    setTableauJwt(tempToken?.token);
    setLoadingData(false);
  };

  const [tableauJwt, setTableauJwt] = useState("");
  const interval = setInterval(fetchData, 1050000000); // 105000ms = 1:45

  useEffect(() => {
    if (!tableauJwt) {
      fetchData();
    }

    return () => {
      clearInterval(interval);
    };
  }, [selectedCredit, selectedReport, tableauJwt, interval]);

  const resetSelections = () => {
    setSelectedCredit(null);
    setSelectedReport(null);
  };

  const dataSetup = async ({ creditId }) => {
    await getTableauReports({ creditId });
  };

  const getReports = async () => {
    const tempCredits = await api.credits.get({});
    const ordered = orderBy(tempCredits, [(data) => data?.name?.toLowerCase()], ["asc"]);
    setCredits([...ordered]);
  };

  const getTableauReports = async ({ creditId, sortColumn, sortType }) => {
    const response = await api.reports.getReport({ id: creditId, endpoint: `portfolio-performance-dashboards?creditId=${creditId}` });
    const ordered = orderBy(
      response,
      [(data) => (data[sortColumn] && typeof data[sortColumn] === "string" ? data[sortColumn]?.toLowerCase() : data?.sortOrder)],
      [sortType ? sortType : "asc"]
    );
    setTableauReports(ordered);
    if (!selectedReport && ordered) {
      setSelectedReport(ordered[0]);
    }
    return ordered;
  };

  const setup = async () => {
    await getReports();
  };

  return (
    <PageTemplate
      id="portfolio-performance-dashbaord"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Portfolio Performance Dashboard"
      defaultOpenMenus={["2"]}
      setup={setup}
      content={
        tableauJwt && (
          <Content
            credits={credits}
            tableauReports={tableauReports}
            dataSetup={dataSetup}
            setSelectedReport={setSelectedReport}
            selectedReport={selectedReport}
            resetSelections={resetSelections}
            setSelectedCredit={setSelectedCredit}
            selectedCredit={selectedCredit}
            token={tableauJwt}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          />
        )
      }
    />
  );
};
export default PortfolioPerformanceDashboard;
