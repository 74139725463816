import { Table } from "rsuite";
import { useState, useEffect, useCallback } from "react";
import TextInput from "../../components/TextInput";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import api from "../../api";
import Search from "../../components/Search";
import { isEmpty, orderBy } from "lodash";
import { getTableContainerHeight } from "../../services/tsTables.microservices";
import { InputEditCell } from "../../components/tableComponents/customCells";

const TransSumSectionsTable = ({ ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [tableHeight, setTableHeight] = useState(200);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [sectionName, setSectionName] = useState("");
  const [sectionSort, setSectionSort] = useState();
  const [disabledRow, setDisabledRow] = useState("");
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);

  const update = async ({ body, setUpdateActionTriggered, setUpdateActionDone, setNextSort }) => {
    setUpdateActionTriggered(body?.id);
    setDisabledRow(body?.id);
    const response = await api[props.endpoint].update({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setUpdateActionDone(body?.id);
    setTimeout(() => {
      setUpdateActionDone(false);
      setDisabledRow(false);
      setUpdateActionTriggered(false);
      setNextSort({});
    }, 2000);
    return response;
  };

  const deleteAction = async ({ body }) => {
    const response = await api[props.endpoint].delete({ id: body?.id });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setTimeout(() => {
      setDisabledRow(false);
      props.refreshDataset({});
      setResetSearch(true);
    }, 2000);
  };

  const ActionsCell = ({ rowData, dataKey, setNextSort, ...props }) => {
    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <ApiSubmitButton
            action={() => update({ body: rowData, setUpdateActionTriggered, setUpdateActionDone, setNextSort })}
            className="mr-2 row-action-button"
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
            actionTriggered={updateActionTriggered === rowData?.id}
            actionDone={updateActionDone === rowData?.id}
          />
          <AreYouSureDelete
            action={() => deleteAction({ body: rowData })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={submitActionTriggered === rowData?.id || updateActionTriggered === rowData?.id}
          />
        </div>
      </Cell>
    );
  };

  const validateSort = (e) => {
    const regex = /^[0-9 ]+$/; // This regex allows only digits. Due to input type=number, "+" is still allowed, but isn't carried over to the e.target.value.
    return regex.test(e.target.value) && !(Number(e.target.value) < 0 || (e.target.value.trim().length === 0 && e.target.value.length > 0));
  };

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

  const handleSectionInput = (e) => {
    const isValid = validateText(e);
    if (!isValid) return;
    setSectionName(e.target.value);
  };

  const handleSortInput = (e) => {
    const isValid = validateSort(e);
    if (!isValid) return;
    setSectionSort(e.target.value);
  };

  const submit = async () => {
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const response = await api[props.endpoint].create({ body: { name: sectionName, sortOrder: sectionSort } });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    setActionDone(true);
    const updatedData = await props.refreshDataset({});
    setActionDone(false);
    setDisableFields(false);
    setSubmitActionTriggered(false);
    setSectionName("");
    setNextSort({ updatedData });
    setResetSearch(updatedData);
    return response;
  };

  const setNextSort = useCallback(
    ({ updatedData }) => {
      const dataSource = updatedData ? updatedData : props.dataset;
      const highest = !!dataSource?.length ? dataSource?.reduce((a, b) => ((a.sortOrder || 0) >= (b.sortOrder || 0) ? a : b))?.sortOrder : dataSource;
      setSectionSort(Number(highest) + 1);
    },
    [props.dataset]
  );

  useEffect(() => {
    setTableHeight(getTableContainerHeight({ id: "#ts-section-table-container" }));
    setNextSort({});
  }, [setNextSort]);

  return (
    <div className="custom-flex-container p-3">
      {filtered && <div className="has-text-warning-dark is-capitalized help">Clear search to enable new Section creation</div>}
      <div className="is-flex is-align-items-center">
        <TextInput
          label="New Section"
          value={sectionName || ""}
          style={{ width: "510px" }}
          maxlength={32}
          onChange={handleSectionInput}
          disabled={disableFields || filtered}
        />
        <TextInput
          label="sort order"
          value={sectionSort || ""}
          type="number"
          onChange={handleSortInput}
          disabled={disableFields || filtered}
          controlClass="mt-3 ml-3 pb-5"
          style={{ width: "100px" }}
          min={0}
          max={9999}
          step={1}
        />
        <ApiSubmitButton
          action={() => submit()}
          className="ml-3 mt-3"
          style={{ height: "40px" }}
          icon={<i className="fa-solid fa-plus" />}
          // used to force correct rendering when parent re-renders
          disabled={filtered || !sectionName || !sectionSort || submitActionTriggered || updateActionTriggered}
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
        />
      </div>
      <Search
        className="pt-3"
        dataset={props.dataset}
        setDataset={props.setDataset}
        refreshDataset={props.refreshDataset}
        placeholder="Find Section"
        searchKey="name"
        currentSortKey={sortColumn}
        currentSortDirection={sortType}
        style={{ maxWidth: "510px" }}
        filtered={filtered}
        setFiltered={setFiltered}
        resetSearch={resetSearch}
        setResetSearch={setResetSearch}
      />
      <div id="ts-section-table-container" className="custom-flex-container flex-scroll extend-last-row" style={{ "--lastRowHeight": "200px" }}>
        <Table
          data={props.dataset}
          height={tableHeight}
          sortColumn={sortColumn}
          sortType={sortType}
          rowHeight={65}
          onSortColumn={(sortColumn, sortType) => {
            const handleSort = ({ sortColumn, setSortColumn, sortType, setSortType, ...props }) => {
              props.setLoading(true);

              let ordered = orderBy(
                props?.dataset,
                [(data) => (typeof data[sortColumn] === "string" ? data[sortColumn]?.toLowerCase() : data[sortColumn])],
                [sortType]
              );

              props.setDataset([...ordered]);

              setTimeout(() => {
                props.setLoading(false);
                setSortColumn(sortColumn);
                setSortType(sortType);
              }, 500);
            };
            handleSort({ sortColumn, setSortColumn, setSortType, sortType, ...props });
          }}
          loading={props.loading}
        >
          <Column flexGrow={1} sortable>
            <HeaderCell>Section</HeaderCell>
            <InputEditCell
              dataKey="name"
              disabled={disabledRow}
              setmaxlength={32}
              customValidation={({ val }) => validateText({ target: { value: val } })}
            />
          </Column>

          <Column width={100} sortable>
            <HeaderCell>Sort Order</HeaderCell>
            <InputEditCell
              dataKey="sortOrder"
              type="number"
              disabled={disabledRow}
              max={9999}
              min={0}
              step={1}
              customValidation={({ val }) => validateSort({ target: { value: val } })}
            />
          </Column>

          <Column width={235}>
            <HeaderCell></HeaderCell>
            <ActionsCell dataKey="actions" setNextSort={setNextSort} />
          </Column>
        </Table>
      </div>
    </div>
  );
};
export default TransSumSectionsTable;
