import { SelectPicker } from "rsuite";
import * as com from "../../../components";
import { useEffect, useState, useRef, useCallback } from "react";
import { Table } from "rsuite";
import { handleSortColumn } from "../../../services/microservices";
import { InputEditCell } from "../../../components/tableComponents/customCells";
import { isEmpty } from "lodash";
import api from "../../../api";

export const ChoicesAdmin = ({ ...props }) => {
  // state
  const [displayType, setDisplayType] = useState();
  const [displayDetails, setDisplayDetails] = useState();
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState("grade");
  const [sortType, setSortType] = useState("asc");
  const [disabledRow, setDisabledRow] = useState("");
  const [updateActionTriggered, setUpdateActionTriggered] = useState(false);
  const [updateActionDone, setUpdateActionDone] = useState(false);

  // vars and refs
  const { Column, HeaderCell, Cell } = Table;
  const loadedTableData = useRef([]);
  const details = useRef({
    numericMin: "",
    numericMax: "",
    grade: "",
    textResponse: "",
  });
  const originalValues = useRef({});

  // functions and effects
  const setNextGrade = useCallback(({ updatedData }) => {
    const dataSource = updatedData ? updatedData : loadedTableData.current;
    if (dataSource?.length) {
      const highest = dataSource?.reduce((a, b) => ((a.grade || 0) >= (b.grade || 0) ? a : b))?.grade;
      if (Number(highest) + 1 === 8) {
        details.current.grade = "";
        return;
      }
      details.current.grade = Number(highest) + 1;
    } else {
      details.current.grade = 1;
    }
    setDisplayDetails({ ...details.current });
  }, []);

  const loadView = async ({ selectedCriteria }) => {
    loadedTableData.current = structuredClone([...props.data.filter((data) => data?.criteriaId === selectedCriteria?.id)]);
    setDisplayType(selectedCriteria?.type);
    for (let row of loadedTableData.current) {
      if (!row?.textResponse) {
        row.disableUpdate = true;
      }
    }
    setTableData([...loadedTableData.current]);
    setNextGrade({});
  };

  const handleCriteriaSelection = (e) => {
    details.current = structuredClone({ ...originalValues.current });
    const matchedCriteria = props?.criteria?.find((criteria) => criteria?.name === e);
    props.setSelectedCriteria(matchedCriteria);
    loadView({ selectedCriteria: matchedCriteria });
  };

  const validateText = (e) => !(e.target.value.trim().length === 0 && e.target.value.length > 0);

  const handleInputValidation = ({ val, key, rowData }) => {
    let regex, valid;
    // set switch since the object display isn't working
    switch (key) {
      case "numericMin":
        regex = /^\d{1,6}(?:\.\d{0,2})?$/;
        valid = regex.test(val);
        if (!valid && val !== "") {
          return valid;
        } else if (val === "") {
          rowData ? (rowData.numericMax = "") : (details.current.numericMax = "");
        }
        break;
      case "numericMax":
        regex = /^\d{1,6}(?:\.\d{0,2})?$/;
        valid = regex.test(val);
        if (!valid && val !== "") return valid;
        break;
      case "grade":
        regex = /^[1-7]$/;
        valid = regex.test(val);
        if (!valid && val !== "") return valid;
        break;
      default:
        if (!validateText({ target: { value: val } })) return false;
        valid = true;
        break;
    }
    const condition1 = valid && (val || (!val && val === ""));
    const condition2 = !valid && (val || (!val && val === ""));
    return condition1 || condition2;
  };

  const validateForm = () => {
    const validNumeric = !!details.current?.numericMin && !!details.current?.numericMax && !!details.current?.grade;
    const validText = !!details.current?.textResponse && !!details.current?.grade;
    const validRange = Number(details.current.numericMin) < Number(details.current.numericMax);
    const tempValid = displayType === "text" ? validText : validNumeric && validRange;
    setIsValidForm(tempValid);
    return tempValid;
  };

  const handleInput = async ({ val, key }) => {
    const valid = handleInputValidation({ val, key });
    if (!valid) return;
    details.current[key] = val;
    setDisplayDetails({ ...details.current });
    validateForm();
  };

  const submit = async ({ tableBody, setUpdateActionTriggered, setUpdateActionDone, method }) => {
    const body = method === "update" ? tableBody : details.current;
    body.criteriaId = props?.selectedCriteria?.id;
    body.criteriaRowId = props?.selectedCriteria?.rowId;
    method === "update" ? setUpdateActionTriggered(body?.id) : setSubmitActionTriggered(true);
    if (method === "update") setDisabledRow(body?.id);

    setDisableFields(true);
    const response = await api[props.endpoint][method]({ id: body?.id, body });
    if (!response || isEmpty(response)) {
      console.error("no response from api call");
    }
    method === "update" ? setUpdateActionDone(body?.id) : setActionDone(true);
    setTimeout(async () => {
      setTableLoading(true);
      const updatedData = (await props.refreshDataset({})) || [];
      props.data = [...updatedData];
      await loadView({ selectedCriteria: props?.selectedCriteria });
      setActionDone(false);
      setDisableFields(false);
      setSubmitActionTriggered(false);
      details.current = structuredClone({ ...originalValues.current });
      setDisplayDetails({ ...details.current });
      setIsValidForm(false);
      if (method === "update") {
        setNextGrade({});
        setDisabledRow(false);
        setUpdateActionTriggered(false);
        setUpdateActionDone(false);
      } else {
        setNextGrade({ updatedData });
      }
      setTableLoading(false);
    }, 2000);
    return response;
  };

  const handleDelete = async ({ id }) => {
    const response = await api[props?.endpoint].delete({ id });
    setTableLoading(true);
    const updatedData = await props.refreshDataset({});
    props.data = [...updatedData];
    await loadView({ selectedCriteria: props?.selectedCriteria });
    setNextGrade({ updatedData });
    setTableLoading(false);
    return response;
  };

  const setup = async () => {
    setTableLoading(props.loading);
    if (!isEmpty(props.selectedCriteria)) {
      await loadView({ selectedCriteria: props.selectedCriteria });
    }
    // set default sort
    setDisplayDetails({ ...details.current });
    if (isEmpty(originalValues.current)) {
      originalValues.current = structuredClone({ ...details.current });
    }

    setTableLoading(props.loading);
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  const enableRowUpdate = async ({ rowData, valid }) => {
    if (!valid) return;
    const validNumeric = !!rowData?.numericMin && !!rowData?.numericMax && !!rowData?.grade;
    const validText = !!rowData?.textResponse && !!rowData?.grade;
    const validRange = Number(rowData.numericMin) < Number(rowData.numericMax);
    const tempValid = displayType === "text" ? validText : validNumeric && validRange;
    rowData.disableUpdate = !tempValid;
    setTableData([...loadedTableData.current]);
  };

  const ActionsCell = ({ rowData, dataKey, ...props }) => {
    const [disableUpdates, setDisableUpdates] = useState(true);

    useEffect(() => {
      setDisableUpdates(rowData?.disableUpdate);
    }, [rowData.disableUpdate]);

    return (
      <Cell {...props}>
        <div className="is-flex is-justify-content-flex-end is-align-items-center mr-3">
          <com.ApiSubmitButton
            action={() => submit({ tableBody: rowData, setUpdateActionTriggered, setUpdateActionDone, method: "update" })}
            className="mr-2 row-action-button"
            // used to force correct rendering when parent re-renders
            disabled={submitActionTriggered || updateActionTriggered || disableUpdates}
            actionTriggered={updateActionTriggered === rowData?.id}
            actionDone={updateActionDone === rowData?.id}
          />
          <com.AreYouSureDelete
            action={() => handleDelete({ id: rowData?.id })}
            icon={<i className="fa-solid fa-trash" />}
            style={{ maxHeight: "30px" }}
            disabled={submitActionTriggered || updateActionTriggered}
          />
        </div>
      </Cell>
    );
  };

  return (
    <div className="custom-flex-container">
      <div className="is-flex is-align-items-start">
        <div id="criteria-picker" className="is-flex is-flex-direction-column is-flex-grow-1 mr-3 mt-3" style={{ maxWidth: "150px" }}>
          <label className="is-size-6">Criteria</label>
          <SelectPicker
            container={document.getElementById("lqr-admin")}
            data={props?.criteria?.map((criteria) => ({ label: criteria?.name, value: criteria?.name })) || []}
            labelKey="label"
            valueKey="value"
            value={props?.selectedCriteria?.name || ""}
            onChange={handleCriteriaSelection}
            onClean={handleCriteriaSelection}
            disabled={disableFields}
          />
        </div>
        {props.selectedCriteria && (
          <div className="is-flex is-justify-contents-center is-align-items-start">
            {displayType === "text" ? (
              <com.TextInput
                label="Text Response"
                className="mr-3"
                controlClass="mt-3 is-flex-grow-1"
                value={displayDetails?.textResponse || ""}
                maxlength={64}
                onChange={(e) => handleInput({ val: e.target.value, key: "textResponse" })}
                disabled={disableFields}
              />
            ) : (
              <div className="is-flex is-align-items-start">
                <com.TextInput
                  label="Range Minimum"
                  className="mr-3"
                  controlClass="mt-3 is-flex-grow-1"
                  value={displayDetails?.numericMin || ""}
                  onChange={(e) => handleInput({ val: e.target.value, key: "numericMin" })}
                  disabled={disableFields}
                />
                <com.TextInput
                  label="Range Maximum"
                  className="mr-3"
                  controlStyle={{ maxWidth: "200px" }}
                  value={displayDetails?.numericMax || ""}
                  onChange={(e) => handleInput({ val: e.target.value, key: "numericMax" })}
                  disabled={disableFields || !details.current?.numericMin}
                />
              </div>
            )}
            <com.TextInput
              label="Grade: 1-7"
              className="mr-3"
              controlStyle={{ maxWidth: "6rem" }}
              value={displayDetails?.grade || ""}
              onChange={(e) => handleInput({ val: e.target.value, key: "grade" })}
              disabled={disableFields}
              maxlength={1}
            />
            <com.ApiSubmitButton
              action={() => submit({ method: "create" })}
              className="ml-3"
              style={{ height: "40px", marginTop: "32px"}}
              icon={<i className="fa-solid fa-plus" />}
              // used to force correct rendering when parent re-renders
              actionTriggered={submitActionTriggered}
              actionDone={actionDone}
              disabled={!isValidForm}
            />
          </div>
        )}
      </div>
      {props.selectedCriteria && (
        <div
          id="lqr-criteria-table-container"
          className="custom-flex-container flex-scroll extend-last-row mt-4"
          style={{ "--lastRowHeight": "200px" }}
        >
          <div>
            <div>
              <Table
                data={tableData}
                autoHeight
                rowHeight={65}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={(sortColumn, sortType) =>
                  handleSortColumn({
                    sortColumn,
                    sortType,
                    data: tableData,
                    setters: {
                      setTableLoading,
                      setSortColumn,
                      setSortType,
                      setTableData,
                    },
                  })
                }
                loading={tableLoading}
              >
                {displayType !== "text" && (
                  <Column flexGrow={1} sortable>
                    <HeaderCell>Range Minimum</HeaderCell>
                    <InputEditCell dataKey="numericMin" disabled={disabledRow} customValidation={handleInputValidation} cb={enableRowUpdate} />
                  </Column>
                )}
                {displayType !== "text" && (
                  <Column flexGrow={1} sortable>
                    <HeaderCell>Range Maximum</HeaderCell>
                    <InputEditCell dataKey="numericMax" disabled={disabledRow} customValidation={handleInputValidation} cb={enableRowUpdate} />
                  </Column>
                )}
                {displayType === "text" && (
                  <Column flexGrow={1} sortable>
                    <HeaderCell>Text Response</HeaderCell>
                    <InputEditCell
                      dataKey="textResponse"
                      setmaxlength={64}
                      disabled={disabledRow}
                      cb={enableRowUpdate}
                      customValidation={({ val }) => validateText({ target: { value: val } })}
                    />
                  </Column>
                )}
                <Column flexGrow={1} sortable>
                  <HeaderCell>Grade</HeaderCell>
                  <InputEditCell dataKey="grade" disabled={disabledRow} customValidation={handleInputValidation} />
                </Column>
                <Column width={235}>
                  <HeaderCell></HeaderCell>
                  <ActionsCell dataKey="actions" parentprops={props} />
                </Column>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
