export const handleSortColumn = async ({ tableName, sortColumn, setSortColumn, sortType, setSortType, ...props }) => {
  props.setLoading(true);
  await props.refreshDataset({ tableName, sortColumn, sortType });
  setTimeout(() => {
    props.setLoading(false);
    setSortColumn(sortColumn);
    setSortType(sortType);
  }, 500);
};

export const getTableContainerHeight = ({ id }) => {
  return document.querySelectorAll(id)[0]?.clientHeight;
};

const services = {
  handleSortColumn,
  getTableContainerHeight
};
export default services;