import PageTemplate from "../PageTemplate";
import { useEffect, useState, useRef } from "react";
import { isEmpty, orderBy, findIndex } from "lodash";
import { Table, Loader, Animation, Whisper, Tooltip } from "rsuite";
import api from "../../api";
import store, { getUserCognitoGroups } from "../../store";
import { InputEditCurrencyCell, CurrencyCell, PercentCell } from "../../components/tableComponents/customCells";
import FundingRequest from "./FundingRequest";
import CollectionAccountBalance from "./CollectionAccountBalance";
import * as com from "../../components";
import { convertTimeToLocal, handleAutoCreditSelection } from "../../services/microservices";

const Content = ({ ...props }) => {
  // state
  const [reviewInputReviewedBy, setReviewInputReviewedBy] = useState("");
  const [reviewInputReviewDate, setReviewInputReviewDate] = useState("");
  const [reviewInputComments, setReviewInputComments] = useState("");
  const [reviewInputApproved, setReviewInputApproved] = useState(null);
  const [showFundingModal, setShowFundingModal] = useState(false);

  // refs and variables
  const { Column, HeaderCell, Cell } = Table;
  const selectedReviewRef = [];

  // functions, callbacks, and components
  const handleCreditSelect = async (e) => {
    resetSelections();
    props.setEdit(null);
    const temp = props.credits?.find((credit) => credit?.id === e?.target?.value);
    props.setSelectedCredit({ ...temp });
    sessionStorage.setItem("selectedCredit", JSON.stringify({ ...temp }));
    if (e.target.value === "0") {
      sessionStorage.removeItem("selectedCredit");
      return;
    }
    if (!e.target.value || e.target.value === "0") return;
    const creditId = temp?.id;
    await props.setupPageData({ creditId });
  };

  const resetReviewInputs = () => {
    setReviewInputReviewedBy("");
    setReviewInputReviewDate("");
    setReviewInputComments("");
    setReviewInputApproved(null);
    if (!isEmpty(props.originalReviewValues.current)) {
      props.selectedReview.reviewDate = props.originalReviewValues.current?.reviewDate;
      props.selectedReview.reviewedBy = props.originalReviewValues.current?.reviewedBy;
      props.selectedReview.approved = props.originalReviewValues.current?.approved;
      props.selectedReview.comments = props.originalReviewValues.current?.comments;
    }
  };

  const resetSelections = () => {
    props.setIsError(false);
    props.setCriteriaSections(null);
    props.setSelectedCriteriaSection(null);
    props.setReviews(null);
    selectedReviewRef.current = null;
    props.setSelectedReview({ ...selectedReviewRef.current });

    props.setTableData(null);
    props.originalReviewSections.current = [];
    props.setShowCollectionAccountBalance(false);
    props.setSelectedReview({ ...selectedReviewRef });
    resetReviewInputs();
  };

  const handleCriteriaSectionSelect = async (e) => {
    props.setSelectedCriteriaSection(null);
    props.setTableData(null);
    props.setEdit(null);
    const temp = props.criteriaSections?.find((criteriaSection) => criteriaSection?.id === e?.target?.value);
    props.setSelectedCriteriaSection({ ...temp });
    if (!e.target.value || e.target.value === "0") return;
    const ordered = orderBy(temp?.reviewData, "sortOrder", "asc");
    await props.setupReviewData({ sectionCriteria: ordered });
  };

  const handleReviewSelection = async (e) => {
    props.setLoadingReviewData("Loading Borrowing Base reviews...");
    const tempReview = props.reviews.find((review) => review?.id === e);
    await props.setupPageData({ creditId: props.selectedCredit?.id, updatedReview: tempReview });
  };

  const handleAcceptReject = ({ val }) => {
    setReviewInputApproved(val === reviewInputApproved ? null : val);
    props.selectedReview.approved = val === props.selectedReview.approved ? null : val;
    props.setSelectedReview({ ...props.selectedReview });
  };

  useEffect(() => {
    handleAutoCreditSelection({ selectedCredit: props.selectedCredit, handleCreditSelect });
    const reviewedBy = props.selectedReview?.reviewedBy || store.user?.name;
    if (!isEmpty(props.selectedReview)) {
      props.selectedReview.reviewedBy = reviewedBy;
      props.setSelectedReview(props.selectedReview);

      setReviewInputReviewedBy(reviewedBy);
      setReviewInputReviewDate(props.selectedReview?.reviewDate);
      setReviewInputComments(props.selectedReview?.comments);
      setReviewInputApproved(props.selectedReview?.approved);
    }
    if (!props.edit) {
      resetReviewInputs();
    }
    // eslint-disable-next-line
  }, [props.edit]);

  const CustomSelect = ({ defaultText, onChange, disabled, data }) => {
    const [show, setShow] = useState(false);

    const standardStyle = {
      maxHeight: "36px",
      fontSize: "14px",
      border: "1px solid #d7d7d7",
      padding: "5px 2rem 5px 1rem",
      borderRadius: "3px",
      zIndex: 10,
      boxShadow: "0px 2px 4px rgba($color: #d0d0d0, $alpha: 1.0)",
      cursor: "pointer",
    };

    const disabledStyle = {
      border: "1px solid #d7d7d7",
      padding: "5px 2rem 5px 1rem",
      borderRadius: "3px",
      maxHeight: "36px",
      fontSize: "14px",
      cursor: "not-allowed",
      backgroundColor: "rgb(248 248 248)",
      color: "#a5a3a3",
    };

    let showClickHandled = false; // for debouncing

    const handleShow = () => {
      if (disabled) return;
      if (!showClickHandled) {
        showClickHandled = true;
        const clickWatcher = (event) => {
          event.preventDefault();
          setShow(false);
          document.removeEventListener("click", clickWatcher);
        };
        setTimeout(() => {
          setShow(!show);
          if (!show) {
            document.addEventListener("click", clickWatcher);
          }
        }, 10);
      }
    };

    return (
      <div
        id="custom-select-componant"
        className="is-relative prevent-text-select"
        onClick={handleShow}
        style={disabled ? disabledStyle : standardStyle}
      >
        <div onClick={handleShow}>
          <div>{defaultText}</div>
        </div>
        {show && (
          <div className="is-absolute custom-select-contents">
            {data?.map((entry) => {
              const getStatus = ({ entry }) => {
                switch (entry?.approved) {
                  case 1:
                    return "Approved";
                  case 0:
                    return "Rejected";
                  default:
                    return "N/A";
                }
              };
              return (
                <div
                  key={entry?.id}
                  className="is-flex is-flex-direction-column custom-select-entry"
                  style={{ borderBottom: "1px solid #d7d7d7", padding: ".5rem" }}
                  onClick={() => onChange(entry?.id)}
                >
                  <div>Review Date: {entry?.reviewDate}</div>
                  <div>As of: {entry?.asOfDate}</div>
                  <div>Status: {getStatus({ entry })}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const InferenceCell = ({ rowData, dataKey, ...props }) => {
    if (rowData?.isPercent) {
      return <PercentCell {...props} rowData={rowData} dataKey={dataKey} />;
    }
    return <CurrencyCell {...props} rowData={rowData} dataKey={dataKey} />;
  };

  return (
    <div id="borrowing-base-main-container" className="custom-flex-container">
      {!!props.credits.length && (
        <div className="pl-0 py-2 pr-2 card" style={{ zIndex: 10 }}>
          <div className="card-content is-flex is-flex-direction-row is-align-items-center p-0">
            <div className="pl-2 is-flex is-flex-direction-row is-align-items-center mr-3" style={{ zIndex: 9, background: "#fff" }}>
              <label className="is-size-6 mr-2">Credit:</label>
              <div className="select" style={{ maxHeight: "36px" }}>
                <select
                  id="source-temp-select"
                  className="has-text-link"
                  onChange={handleCreditSelect}
                  value={props.selectedCredit?.id}
                  style={{ maxHeight: "36px", fontSize: "14px" }}
                  disabled={props.disableFields || props.edit}
                >
                  <option defaultValue={true} value={0}>
                    Choose Credit
                  </option>
                  {props.credits?.map((entry) => (
                    <option key={entry?.id} value={entry?.id}>
                      {entry?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="is-flex-grow-1">
              <Animation.Slide
                in={
                  !!props.selectedCredit &&
                  !isEmpty(props.selectedCredit) &&
                  !!props.criteriaSections?.length &&
                  !isEmpty(props.selectedCriteriaSection)
                }
                placement="left"
              >
                <div className="custom-flex-container">
                  <div className="is-flex is-align-items-center is-justify-content-space-between">
                    <div className="is-flex is-align-items-center">
                      <label className="is-size-6 mr-2">Criteria Section:</label>
                      <div className="select" style={{ maxHeight: "36px" }}>
                        <select
                          id="source-temp-select"
                          className="has-text-link"
                          onChange={handleCriteriaSectionSelect}
                          value={props.selectedCriteriaSection?.id}
                          style={{ maxHeight: "36px", fontSize: "14px" }}
                          disabled={props.disableFields || props.edit}
                        >
                          {props.criteriaSections?.map((entry) => (
                            <option key={entry?.id} value={entry?.id}>
                              {entry?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {!!props.reviews?.length && (
                      <div className="select mr-4" style={{ maxHeight: "36px" }}>
                        <CustomSelect
                          defaultText="Historical Reviews"
                          onChange={handleReviewSelection}
                          disabled={props.disableFields || props.edit}
                          data={props.reviews}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Animation.Slide>
            </div>
          </div>
        </div>
      )}
      {props.isBbAdmin && props.selectedReview?.approved === 1 && (
        <FundingRequest {...props} showFundingModal={showFundingModal} setShowFundingModal={setShowFundingModal} />
      )}
      {props.loadingData ? (
        <Loader center size="sm" content="Loading Borrowing Base Criteria Sections and Summary Review..." />
      ) : props.loadingReviewData ? (
        <Loader center size="sm" content={props.loadingReviewData || "Loading Borrowing Base data..."} />
      ) : props.isError ? (
        <div className="custom-flex-container m-3">
          <div className="has-text-danger">{props.isError}</div>
        </div>
      ) : (
        props.tableData &&
        !isEmpty(props.tableData) && (
          <div
            id="ts-field-table-container"
            className="custom-flex-container no-border-table flex-scroll extend-last-row mt-3 p-2"
            style={{ "--lastRowHeight": "100px" }}
          >
            <Table data={props.tableData} autoHeight={true} bordered={false} cellBordered={false}>
              <Column flexGrow={1} align="center">
                <HeaderCell></HeaderCell>
                <Cell dataKey="name" />
              </Column>

              <Column flexGrow={1} align="center">
                <HeaderCell>Reported</HeaderCell>
                {props.isBbAdmin && props.edit ? (
                  <InputEditCurrencyCell dataKey="reported" data={props.tableData} refreshData={props.setTableData} />
                ) : (
                  <InferenceCell dataKey="reported" />
                )}
              </Column>

              <Column flexGrow={1} align="center">
                <HeaderCell>Calculated</HeaderCell>
                <InferenceCell dataKey="calculated" />
              </Column>

              <Column flexGrow={1} align="center">
                <HeaderCell>Difference</HeaderCell>
                <Cell dataKey="calcRptDiff" />
              </Column>

              <Column flexGrow={1} align="center">
                <HeaderCell>%Difference</HeaderCell>
                <Cell dataKey="differencePercent" />
              </Column>
            </Table>
            {props.selectedCriteriaSection?.name === "Borrowing Base Summary" && (
              <div className="pr-5" style={{ width: "100%" }}>
                <div className="is-flex is-flex-direction-row is-align-items-end is-justify-content-space-between" style={{ maxWidth: "512px" }}>
                  <div className="help has-text-primary-dark is-flex-grow-1">
                    {props.cabSynced && props.selectedReview?.bbAdjustment?.syncStatus !== "synced"
                      ? `Last Synced Check: ${convertTimeToLocal({ date: props.cabSynced, localOrigin: true })}`
                      : `Data Synced as of: ${
                          props.cabSynced
                            ? convertTimeToLocal({ date: props.cabSynced, localOrigin: true })
                            : props?.tableData?.length && props?.tableData[0]?.startDate
                            ? convertTimeToLocal({ date: props.tableData[0].startDate })
                            : convertTimeToLocal({ date: new Date(), localOrigin: true })
                        }`}
                  </div>
                </div>
                <div className="mb-5">
                  <label>As of date: {props.selectedReview?.asOfDate}</label>
                </div>
                <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                  {props.isBbAdmin && props.edit ? (
                    <div className="is-flex is-flex-direction-row">
                      <com.TextInput
                        label="Reviewed By"
                        value={reviewInputReviewedBy}
                        style={{ width: `${window.innerWidth / 2 - 200}px` }}
                        maxlength={128}
                        onChange={(e) => {
                          setReviewInputReviewedBy(e.target.value);
                          props.selectedReview.reviewedBy = e.target.value;
                          props.setSelectedReview(props.selectedReview);
                        }}
                        disabled={false}
                        controlClass="mt-3 mr-3"
                      />
                      <com.TextInput
                        label="Reviewed Date"
                        type="date"
                        value={reviewInputReviewDate?.split("T")[0] || props.selectedReview.reviewDate?.split("T")[0] || ""}
                        onChange={(e) => {
                          setReviewInputReviewDate(e.target.value);
                          props.selectedReview.reviewDate = e.target.value;
                          props.setSelectedReview(props.selectedReview);
                        }}
                        disabled={false}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            const resetDate = props.originalReviewValues.current?.reviewDate
                              ? props.originalReviewValues.current?.reviewDate
                              : new Date();
                            setReviewInputReviewDate(resetDate);
                            props.selectedReview.reviewDate = resetDate;
                            props.setSelectedReview(props.selectedReview);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="is-flex is-flex-direction-row is-flex-grow-1">
                      <div className="is-flex is-flex-direction-row is-align-items-center mr-5">
                        <label className="mr-2" style={{ fontSize: "1rem" }}>
                          Reviewed By:
                        </label>
                        {props.selectedReview?.reviewedBy ? (
                          <span>{props.selectedReview?.reviewedBy}</span>
                        ) : (
                          <span className="has-text-warning-dark">Not Reviewed</span>
                        )}
                      </div>
                      <div className="is-flex is-flex-direction-row is-align-items-center mx-5">
                        <label className="mr-2" style={{ fontSize: "1rem" }}>
                          Review Date:
                        </label>
                        <span>{props.selectedReview.reviewDate}</span>
                      </div>
                      {props.selectedReview?.approved !== null && (
                        <div className="is-flex is-justify-content-space-between" style={{ flex: "1 1 auto" }}>
                          <div className="is-flex is-flex-direction-row is-align-items-center ml-5">
                            <label className="mr-2" style={{ fontSize: "1rem" }}>
                              Status:
                            </label>
                            <span
                              className={`has-text-weight-bold has-text-${
                                props.selectedReview?.approved === 1 ? "success" : props.selectedReview?.approved === 0 ? "danger" : "warning"
                              }`}
                            >
                              {props.selectedReview?.approved === 1 ? "Approved" : props.selectedReview?.approved === 0 ? "Rejected" : "N/A"}
                            </span>
                          </div>
                          {props.selectedReview?.approved === 1 && (
                            <button className="button is-link" onClick={() => setShowFundingModal(true)}>
                              <i className="fa-solid fa-piggy-bank mr-2" />
                              <span>Funding Request</span>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {props.isBbAdmin && props.edit && (
                    <div className="is-flex is-flex-direction-row is-align-items-center mt-3">
                      <Whisper
                        container={document.getElementById("borrowing-base-reviews")}
                        trigger="hover"
                        placement="left"
                        preventOverflow
                        speaker={
                          <Tooltip>
                            <div className="help has-text-danger">
                              Submitting after Approving OR Rejecting will lock all Criteria Sections for this review. <br /> Please verify all
                              sections are filled out before submitting.
                            </div>
                          </Tooltip>
                        }
                      >
                        <i className="fa-solid fa-triangle-exclamation mr-2 has-text-danger" />
                      </Whisper>
                      <button
                        className={`button mr-3 is-primary ${
                          reviewInputApproved === true || props.selectedReview?.approved === true ? "" : "is-light"
                        }`}
                        onClick={() => handleAcceptReject({ val: true })}
                      >
                        <i className="fas fa-check mr-2" />
                        <span>Approve</span>
                      </button>
                      <button
                        className={`button is-danger ${reviewInputApproved === false || props.selectedReview?.approved === false ? "" : "is-light"}`}
                        onClick={() => handleAcceptReject({ val: false })}
                      >
                        <i className="fas fa-thumbs-down mr-2" />
                        <span>Reject</span>
                      </button>
                    </div>
                  )}
                </div>
                {props.isBbAdmin && props.edit ? (
                  <com.TextInput
                    maxlength={5000}
                    controlClass="mt-3 mb-5"
                    label="Comments"
                    type="textarea"
                    value={reviewInputComments || ""}
                    onChange={(e) => {
                      setReviewInputComments(e.target.value);
                      props.selectedReview.comments = e.target.value;
                      props.setSelectedReview(props.selectedReview);
                    }}
                    disabled={false}
                  />
                ) : (
                  <div className="is-flex is-flex-direction-row is-align-items-center mt-5">
                    <label className="mr-2" style={{ fontSize: "1rem" }}>
                      Comments:
                    </label>
                    <span>{props.selectedReview.comments || "N/A"}</span>
                  </div>
                )}
                {/* forcing bottom padding */}
                <div style={{ height: "100px" }}>
                  <div style={{ height: "100px" }}></div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

const CrudControls = ({ ...props }) => {
  const [actionTriggered, setActionTriggered] = useState();
  const [actionDone, setActionDone] = useState();

  const submit = async () => {
    setActionTriggered(true);
    const [newReviewInfo, newReviewDataInfo] = await props.differenceChecker();
    const apiCalls = [];
    // save any reviewData that is new
    if (newReviewDataInfo.length) {
      for (let entry of newReviewDataInfo) {
        apiCalls.push(
          api.borrowingBaseReviewData.update({
            id: entry?.id,
            body: { id: entry?.id, reported: entry?.reported, criteriaRowId: entry?.criteriaRowId, sectionRowId: entry?.sectionRowId },
          })
        );
      }
    }
    await Promise.all(apiCalls);
    // save any review information that is new
    if (newReviewInfo && !isEmpty(newReviewInfo)) {
      await api.borrowingBaseReview.update({ id: newReviewInfo?.id, body: newReviewInfo });
    }
    if (!newReviewDataInfo.length && (!newReviewInfo || isEmpty(newReviewInfo))) {
      setActionTriggered(false);
      return;
    }
    setActionDone(true);
    props.setCriteriaSections(null);
    props.setLoadingData(true);
    props.setLoadingReviewData(true);
    await props.getAsOfDates({ creditId: props.selectedCredit?.id });
    await props.setupPageData({ creditId: props.selectedCredit?.id });
    setTimeout(() => {
      setActionTriggered(false);
      setActionDone(false);
    }, 2000);
  };

  const handleAsOfDateSelect = async (e) => {
    if (e.target.value === "0") return;
    setDropCardActive(!dropCardActive);
    props.setCriteriaSections(null);
    props.setLoadingData(true);
    props.setLoadingReviewData(true);
    await api.borrowingBaseReview.create({ body: { creditId: props.selectedCredit?.id, asOfDate: e.target.value } });
    await props.setupPageData({ creditId: props.selectedCredit?.id });
  };

  // DropCard
  const [dropCardActive, setDropCardActive] = useState(false);
  const [cabDropCardActive, setCabDropCardActive] = useState(false);
  const cSSVarsAdjustment = {
    dropBackgroundColor: "rgb(85 85 141)",
    dropBorderColor: "rgb(179 179 220)",
    dropHeight: "184px",
    dropWidth: "500px",
    dropCardButtonHeight: "25px",
    dropCardButtonWidth: "114px",
    dropCardTopPosition: "-10px",
  };
  const cSSVarsNew = {
    dropBackgroundColor: "rgb(85 85 141)",
    dropBorderColor: "rgb(179 179 220)",
    dropHeight: "144px",
    dropWidth: "234px",
    dropCardButtonHeight: "25px",
    dropCardButtonWidth: "114px",
    dropCardTopPosition: "0",
  };

  return (
    props.isBbAdmin && (
      <div className="is-flex is-align-items-center">
        {!isEmpty(props.selectedCredit) &&
          !props.loadingData &&
          !props.loadingReviewData &&
          !props.selectedReview?.approved &&
          !!props.showCollectionAccountBalance && (
            <com.DropCard
              controlClass="title-button is-link mr-3 button"
              permission={true}
              triggerButtonText="Enter Adjustments"
              controlIcon={<i className="fa-solid fa-pen-to-square mr-2"></i>}
              cSSVars={cSSVarsAdjustment}
              content={
                <div className="is-flex is-justify-content-start mb-5">
                  <CollectionAccountBalance {...props} cabDropCardActive={cabDropCardActive} />
                </div>
              }
              dropCardActive={cabDropCardActive}
              setDropCardActive={setCabDropCardActive}
              actions={[
                {
                  text: "Close",
                  icon: <i className="fas fa-close mr-2" />,
                  className: "is-warning",
                  action() {
                    setCabDropCardActive(!cabDropCardActive);
                  },
                },
              ]}
            />
          )}
        {props.edit ? (
          <span className="is-flex is-align-items-center is-relative">
            <button className="title-button is-warning mr-3 button" onClick={props.cancelActions} disabled={props.disabled || actionTriggered}>
              <i className="fa-solid fa-ban mr-2"></i>
              <span>Cancel</span>
            </button>
            {props.edit && (
              <com.ApiSubmitButton
                className={`title-button ${
                  props.selectedReview?.approved === null || props.selectedReview?.approved === undefined ? "" : "btn-call-to-action"
                }`}
                defaultLabel={props.selectedReview?.approved === null || props.selectedReview?.approved === undefined ? "Save Progress" : "Submit"}
                operationLabel={props.selectedReview?.approved === null || props.selectedReview?.approved === undefined ? "Saving" : "Submitting"}
                icon={
                  props.selectedReview?.approved === null || props.selectedReview?.approved === undefined ? (
                    <i className="fa-solid fa-floppy-disk" />
                  ) : (
                    <i className="fa-solid fa-cloud-arrow-up" />
                  )
                }
                action={submit}
                actionTriggered={actionTriggered}
                actionDone={actionDone}
              />
            )}
          </span>
        ) : (
          !isEmpty(props.selectedCredit) &&
          !props.loadingData &&
          !props.loadingReviewData &&
          !!props.bbrSectionsState?.length &&
          !!props.asOfDates?.length && (
            <div className="is-flex">
              <com.DropCard
                controlClass="title-button-inverse is-link mr-3 button"
                permission={true}
                triggerButtonText="Create New Review"
                controlIcon={<i className="fa-solid fa-plus mr-2"></i>}
                cSSVars={cSSVarsNew}
                content={
                  <div className="is-flex is-justify-content-start mb-5">
                    <div className="select" style={{ maxHeight: "36px" }}>
                      <select
                        id="source-temp-select"
                        className="has-text-link"
                        onChange={handleAsOfDateSelect}
                        value={0}
                        style={{ maxHeight: "36px", fontSize: "14px" }}
                      >
                        <option defaultValue={true} value={0}>
                          Choose an 'as of' date
                        </option>
                        {props.asOfDates?.map((entry) => (
                          <option key={entry} value={entry}>
                            {entry}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                }
                dropCardActive={dropCardActive}
                setDropCardActive={setDropCardActive}
                actions={[
                  {
                    text: "Cancel",
                    icon: <i className="fas fa-ban mr-2" />,
                    className: "is-warning",
                    action() {
                      setDropCardActive(!dropCardActive);
                    },
                  },
                ]}
              />
              {!isEmpty(props.selectedReview) && props.selectedReview?.approved === null && (
                <button
                  className="title-button mr-3 button is-capitalized"
                  onClick={() => props.setEdit(true)}
                  disabled={props.disabled || actionTriggered}
                >
                  <i className="fa-solid fa-pen-to-square mr-2"></i>
                  <span>edit</span>
                </button>
              )}
            </div>
          )
        )}
      </div>
    )
  );
};

const BorrowingBaseReview = () => {
  // state
  const [edit, setEdit] = useState();
  const [credits, setCredits] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [loadingData, setLoadingData] = useState();
  const [loadingReviewData, setLoadingReviewData] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isBbAdmin, setIsBbAdmin] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [criteriaSections, setCriteriaSections] = useState(null);
  const [selectedCriteriaSection, setSelectedCriteriaSection] = useState();
  const [cabSynced, setCabSynced] = useState(null);
  const [cancelTriggered, setCancelTriggered] = useState(false);
  const [showCollectionAccountBalance, setShowCollectionAccountBalance] = useState(false);
  const [bbrSectionsState, setBbrSectionsState] = useState([]);
  const [asOfDates, setAsOfDates] = useState(null);

  // refs and variables
  const originalReviewValues = useRef({});
  const originalReviewDataValues = useRef([]);
  const originalReviewSections = useRef([]);
  const personsList = useRef([]);
  const bbSectionsRef = useRef([]);
  const asOfDatesRef = useRef([]);

  // functions and callbacks
  const getCredits = async () => {
    const tempCredits = await api.credits.get({});
    const ordered = orderBy(tempCredits, [(data) => data?.name?.toLowerCase()], ["asc"]);
    setCredits([...ordered]);
  };

  const getAsOfDates = async ({ creditId }) => {
    const tempAsOfDates = await api.asOfDates.get({ creditId });
    const mapIt = tempAsOfDates?.map((date) => ({ date }));
    const ordered = orderBy(mapIt, "date", "desc");
    const dateArray = [];
    ordered.forEach((val) => {
      dateArray.push(val.date);
    });
    asOfDatesRef.current = [...dateArray];
    setAsOfDates(asOfDatesRef.current);
  };

  const cancelActions = () => {
    setEdit(false);
    setTableData(structuredClone([...originalReviewDataValues.current]));
    setCancelTriggered(!cancelTriggered);
  };

  const setupPageData = async ({ creditId, updatedReview }) => {
    setCriteriaSections(null);
    setSelectedCriteriaSection(null);
    setTableData(null);
    setEdit(null);

    const getReviews = async ({ creditId }) => {
      setCabSynced(null);
      const apiCalls = [api.borrowingBaseReview.get({ creditId }), api.bbSections.get({ creditId }), getAsOfDates({ creditId })];
      const [tempReview, tempBBCreditSections] = await Promise.all(apiCalls);
      bbSectionsRef.current = [...tempBBCreditSections];
      setBbrSectionsState([...bbSectionsRef.current]);
      const orderedReviews = orderBy(tempReview, "reviewDate", "desc");
      if (!tempReview?.length) {
        setIsError("This credit does not have any reviews.");
        setLoadingReviewData(false);
        return;
      }
      return orderedReviews;
    };

    const getReviewById = async ({ id }) => {
      const tempReview = await api.borrowingBaseReview.getById({ id });
      if (isEmpty(tempReview)) {
        setIsError("This credit does not have any reviews.");
        setLoadingReviewData(false);
        return;
      }
      return tempReview;
    };

    //Now that we know the credit that the user wants to use, we need to:
    // 1. Get review by creditId.
    // 2. Get reveiw Data by reviewId
    setLoadingData(true);
    let tempReviews = reviews || [],
      mostRecentReviewData = {};
    if (!updatedReview) {
      tempReviews = await getReviews({ creditId });
      setReviews(tempReviews);
      const mostRecentReview = orderBy(tempReviews, "reviewDate", "desc")[0];
      if (mostRecentReview && !isEmpty(mostRecentReview)) {
        mostRecentReviewData = await getReviewById({ id: mostRecentReview?.id });
      }
    } else {
      if (updatedReview && !isEmpty(updatedReview)) {
        mostRecentReviewData = await getReviewById({ id: updatedReview?.id });
      }
    }
    setLoadingData(false);
    if (!bbSectionsRef.current?.length) {
      const message = isBbAdmin ? (
        <span>
          <span>No Criteria Sections are defined for the selected credit. Please assign criteria sections </span>
          <a href="/borrowingBaseAdmin">here</a>
        </span>
      ) : (
        <span>No Criteria Sections are defined for the selected credit. Please the Borrowing Base Admin to assign criteria sections</span>
      );
      setIsError(message);
      setLoadingReviewData(false);
      return;
    }

    if ((!tempReviews || isEmpty(tempReviews)) && !!asOfDatesRef.current?.length) {
      const message = isBbAdmin ? (
        <span>
          No Borrowing Base Reviews have been created for the selected credit. Please click "Create New Review" button in the top right of the page.
        </span>
      ) : (
        <span>No Borrowing Base Reviews have been created for the selected credit. Please contact the Borrowing Base Admin to create it</span>
      );
      setIsError(message);
      setLoadingReviewData(false);
      return;
    } else if (!asOfDatesRef.current?.length) {
      const message = (
        <span>
          Initial data import has NOT been completed for the selected credit. Please contact{" "}
          <a href="mailto:devteam@webbank.com">devteam@webbank.com</a> to get data imported.
        </span>
      );
      setIsError(message);
      setLoadingReviewData(false);
      return;
    }

    // 3. Set review sections for the sections list
    setLoadingReviewData("Loading Borrowing Base review data...");
    setCriteriaSections([...mostRecentReviewData.sections]);

    // 4. set selectedSection to borrowing base summary if a section hasn't been selected
    const borrowingBaseSummaryExists = mostRecentReviewData.sections?.find((section) => section?.name === "Borrowing Base Summary");
    let cabReviewData;
    if (borrowingBaseSummaryExists && !isEmpty(borrowingBaseSummaryExists)) {
      setSelectedCriteriaSection(borrowingBaseSummaryExists);
      const orderedCriteria = orderBy(borrowingBaseSummaryExists?.reviewData, "sortOrder", "asc");
      cabReviewData = await setupReviewData({ sectionCriteria: orderedCriteria });
    } else {
      // set the default review Data to whatever exists
      setSelectedCriteriaSection(mostRecentReviewData.sections[0]);
      const orderedCriteria = orderBy(mostRecentReviewData.sections[0].reviewData, "sortOrder", "asc");
      cabReviewData = await setupReviewData({ sectionCriteria: orderedCriteria });
    }

    const postReviewApiCalls = [api.bbAdjustments.get({ id: mostRecentReviewData?.id }), api.fundingRequest.get({ id: mostRecentReviewData?.id })];
    const [bbAdjustment, tempFundingRequest] = await Promise.all(postReviewApiCalls);
    // 7. set adjustment if exists
    if (bbAdjustment && !isEmpty(bbAdjustment)) {
      mostRecentReviewData.bbAdjustment = bbAdjustment;
    } else if (cabReviewData && !isEmpty(cabReviewData)) {
      mostRecentReviewData.bbAdjustment = {
        reviewId: mostRecentReviewData?.id,
        collectionAccountBalance: cabReviewData?.value,
      };
    }

    // 8. get existing funding requests
    if (tempFundingRequest && !isEmpty(tempFundingRequest)) {
      mostRecentReviewData.fundingRequest = tempFundingRequest;
    } else {
      // setting default value
      mostRecentReviewData.fundingRequest = {
        reviewId: mostRecentReviewData?.id,
        advanceFundingAmount: null,
        waterfallFundingAmount: null,
        fundingDate: "",
        notes: "",
        status: null,
      };
    }

    // 9. set selected review
    setSelectedReview(mostRecentReviewData);

    originalReviewValues.current = structuredClone({ ...mostRecentReviewData });

    // 10. show page
    setLoadingReviewData(false);

    // 11. get persons list for funding request type ahead
    personsList.current = store.skynetPersonsList;
  };

  const setupReviewData = async ({ sectionCriteria }) => {
    // 5. set difference calculations.
    const currency = (value, operation) => {
      const usd = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      if (value?.target?.value) {
        value = value?.target?.value;
      }
      if (typeof value === "string") {
        const tempVal = value.split(",").join("");
        value = Number(tempVal);
      }
      value = value.toFixed(2);
      value = usd.format(value);
      value = operation === "dollar" ? value : value.substring(1);
      return value;
    };

    const calculations = ({ rowData, operation }) => {
      let reported, calculated;
      reported = typeof rowData.reported === "string" ? Number(rowData.reported) : rowData.reported;
      calculated = typeof rowData.calculated === "string" ? Number(rowData.calculated) : rowData.calculated;
      if (rowData.reported && rowData.reported !== "0" && rowData.reported !== 0) {
        const getPercentChange = ({ reported, calculated }) => {
          let percentDif = (100 - (Math.abs(reported) / calculated) * 100).toString();
          if (percentDif.includes(".")) {
            const splitDecimal = percentDif.split(".");
            const zeroValueArray = splitDecimal[1].split("");
            const indexOfFirstNonZero = findIndex(zeroValueArray, (num) => Number(num) > 0);
            percentDif = Number(percentDif);
            const float = indexOfFirstNonZero + 2;
            return Math.abs(percentDif).toFixed(float);
          } else if (percentDif.includes("nfinity")) {
            return percentDif;
          } else {
            return Math.abs(percentDif).toFixed(2);
          }
        };
        return operation === "dollar" ? currency(Math.abs(reported - calculated), operation) : getPercentChange({ reported, calculated });
      }
      return "--";
    };

    for (let criteria of sectionCriteria) {
      if (criteria.isPercent) {
        criteria.calcRptDiff = criteria.reported ? `${Math.abs(Number(criteria.reported) - Number(criteria.calculated)).toFixed(2)}%` : "--";
      } else {
        criteria.calcRptDiff = calculations({ rowData: criteria, operation: "dollar" });
      }
      criteria.differencePercent = calculations({ rowData: criteria, operation: "percent" });
    }

    // 6. set tableData
    setTableData(sectionCriteria);
    originalReviewDataValues.current = structuredClone([...sectionCriteria]);

    // Setup hide/show collectionAccountBalance
    const cABExists = sectionCriteria.find((secCrit) => secCrit.viewColumnName === "CollectionAccountBalance");
    if (cABExists && !isEmpty(cABExists)) {
      const cabReviewData = { collectionAccountBalance: cABExists, value: cABExists?.calculated, sectionCriteria, set: setupReviewData };
      setShowCollectionAccountBalance(cabReviewData);
      return cabReviewData;
    }
  };

  const differenceChecker = async () => {
    // Check review info
    let newReviewInfo = false;
    for (let key in originalReviewValues.current) {
      if (key !== "sections") {
        const misMatch = selectedReview[key] === originalReviewValues.current[key];
        if (!misMatch) {
          newReviewInfo = selectedReview;
          break;
        }
      }
    }
    // Check review Data info
    const newReviewDataInfo = [];
    for (let data of tableData) {
      const exists = originalReviewDataValues.current?.find((d) => d.id === data?.id);
      if (exists) {
        const misMatch = exists.reported === data.reported;
        if (!misMatch) {
          newReviewDataInfo.push(data);
        }
      }
    }
    return [newReviewInfo, newReviewDataInfo];
  };

  const pageSetup = async () => {
    const tempAdmin = await getUserCognitoGroups("borrowingBaseAdmin");
    setIsBbAdmin(tempAdmin);
    const apiCalls = [getCredits()];
    await Promise.all(apiCalls);
  };

  const sharedProps = {
    selectedCredit,
    edit,
    setEdit,
    loadingData,
    loadingReviewData,
    selectedReview,
    isBbAdmin,
    setSelectedReview,
    setupPageData,
    originalReviewValues,
    originalReviewDataValues,
    setLoadingData,
    setLoadingReviewData,
    setCriteriaSections,
    cabSynced,
    setCabSynced,
    cancelTriggered,
    setCancelTriggered,
    showCollectionAccountBalance,
    setShowCollectionAccountBalance,
    personsList,
    bbrSectionsState,
    asOfDates,
    getAsOfDates,
  };

  return (
    <PageTemplate
      id="borrowing-base-reviews"
      className="is-relative custom-flex-container use-rsuite"
      pageTitle="Borrowing Base Review"
      defaultOpenMenus={["1"]}
      content={
        <Content
          {...sharedProps}
          isError={isError}
          setIsError={setIsError}
          credits={credits}
          setSelectedCredit={setSelectedCredit}
          tableData={tableData}
          setTableData={setTableData}
          reviews={reviews}
          setReviews={setReviews}
          disableFields={false}
          criteriaSections={criteriaSections}
          originalReviewSections={originalReviewSections}
          selectedCriteriaSection={selectedCriteriaSection}
          setSelectedCriteriaSection={setSelectedCriteriaSection}
          setupReviewData={setupReviewData}
        />
      }
      setup={pageSetup}
      crudControls={<CrudControls {...sharedProps} cancelActions={cancelActions} api={api} differenceChecker={differenceChecker} />}
    />
  );
};
export default BorrowingBaseReview;
