import { apiFetch } from "..";
import store from "../../store";
import { orderBy } from "lodash";
import awsconfig from '../../amplifyconfiguration.json';
//prod skynetUrl
const skynetUrl = `https://wg8yci8p65.execute-api.us-east-2.amazonaws.com/production`;

export const getPersons = async ({ endpoint = `persons?userPoolId=${awsconfig.aws_user_pools_id}`, iStore = store }) => {
  if(store.user){
    let response = await apiFetch({ endpoint, skynetUrl });
    if (!response) {
      console.error("Failed to get persons list from skynet");
      return;
    }
    response = orderBy(response, "name", "asc");
    iStore.skynetPersonsList = response;
    console.log("persons list retrieved from Skynet");
    return iStore.skynetPersonsList;
  } else {
    return [];
  }
};

const exportList = {
  persons: {
    get: getPersons,
  },
};
export default exportList;
