import { Table, SelectPicker, DatePicker } from "rsuite";
import { useState, useEffect, useRef } from "react";
import { isEmpty, words, keys, camelCase, orderBy } from "lodash";
import { InputEditCellInlineSave, DateCell } from "../../components/tableComponents/customCells";
import * as com from "../../components/index";
import api from "../../api";
import { getYesterday } from "../../services/microservices";

const ReportingHistory = ({ ...props }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [disableFields, setDisableFields] = useState(null);
  const [tableDisplay, setTableDisplay] = useState([]);
  const [displayDetails, setDisplayDetails] = useState({});
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const inputWidth = "300px";
  const [periods, setPeriods] = useState([]);
  const [periodsLoading, setPeriodsLoading] = useState(false);

  const newHistoryFieldsDefault = {
    requirementId: null,
    creditId: props?.creditId,
    period: null,
    dueDate: null,
    dueDateString: "",
    reportingDate: null,
    reportingDateString: "",
    url: "",
  };
  const newHistoryFields = useRef({});

  const handleInput = ({ e, field, setFunction, cb }) => {
    newHistoryFields.current[field] = typeof e === "string" ? e : e?.target?.value;
    setFunction({ ...newHistoryFields.current });
    if (cb) cb({ [field]: newHistoryFields.current[field] });
  };

  const handleDate = ({ date, field, setFunction }) => {
    const standardizedDate = date.toISOString().split("T")[0];
    newHistoryFields.current[field] = date;
    newHistoryFields.current[`${field}String`] = standardizedDate;
    setFunction({ ...newHistoryFields.current });
  };

  const resetForm = () => {
    newHistoryFields.current = structuredClone({ ...newHistoryFieldsDefault });
    setDisplayDetails({ ...newHistoryFields.current });
  };

  const submit = async ({ rowId }) => {
    setSubmitActionTriggered(rowId || true);
    const body = { ...newHistoryFields.current };
    const response = await api.reportingHistory.create({ body });
    setActionDone(true);
    setTimeout(() => {
      setActionDone(false);
      resetForm();
      setSubmitActionTriggered(false);
      props.refreshDataset({ tableName: "Reporting History", sortColumn: "name", sortType: "asc" });
    }, 2000);
    return response;
  };

  const defaultFields = {
    id: "",
    creditId: props?.creditId,
    name: "",
    frequency: "Monthly",
    period: "",
    dueDate: null,
    reportingDate: "",
    url: "",
  };
  const tableColumns = keys(defaultFields);

  const addToDetails = ({ rowId, data, key }) => {
    const exists = props.tableProps.current?.detailsDataSet?.find((d) => d?.id === rowId);
    if (!exists || isEmpty(exists)) {
      const tempBody = {};
      tempBody.id = rowId;
      tempBody[key] = data;
      props.tableProps.current?.detailsDataSet?.push({ ...tempBody });
    } else {
      exists[key] = data;
    }
  };

  const setFieldLengths = ({ field }) => {
    return field === "url" ? 256 : null;
  };

  const setFieldWidths = ({ field }) => {
    switch (field) {
      case "frequency":
        return 135;
      case "name":
      case "url":
        return 300;
      default:
        return 170;
    }
  };

  const defaultTableProps = {
    autoHeight: true,
    onSortColumn: (sortColumn, sortType) => {
      const fixedSortColumn = camelCase(sortColumn);
      setTableLoading(true);
      setTimeout(() => {
        props.tableProps.current.dataset = orderBy(props.tableProps.current?.dataset, [(data) => data[fixedSortColumn]?.toLowerCase()], [sortType]);
        setTableDisplay([...props.tableProps.current.dataset]);
        setSortColumn(fixedSortColumn);
        setSortType(sortType);
        setTableLoading(false);
      }, 500);
    },
    loading: tableLoading,
  };
  const [tableProps, setTableProps] = useState({});

  const checkDate = ({ date, type }) => {
    return type === "due" ? date > getYesterday() : date > new Date();
  };

  useEffect(() => {
    const tempProps = props.edit ? { ...defaultTableProps, rowHeight: 60 } : { ...defaultTableProps, wordWrap: "break-word" };
    setTableProps(tempProps);
    setTableDisplay([...props.tableProps.current.dataset]);
    if (isEmpty(newHistoryFields.current)) {
      newHistoryFields.current = structuredClone({ ...newHistoryFieldsDefault });
    }
    setDisplayDetails({ ...newHistoryFields.current });
    // eslint-disable-next-line
  }, [props.tableProps.current?.cancelled, props.edit]);
  return (
    <div className="custom-flex-container">
      <label className="is-capitalized has-text-info-dark is-size-5 has-text-weight-bold">{props.tableProps.current.sectionLabel}</label>
      {props?.edit && (
        <div className="is-flex my-3">
          <div className="is-flex is-flex-direction-column mr-3">
            <label className="is-size-6">Report</label>
            <SelectPicker
              container={document.getElementById("transaction-summary")}
              className="custom-rs-styles"
              data={tableDisplay?.map((item) => ({ label: item.name, value: item.id }))}
              labelKey="label"
              valueKey="value"
              value={displayDetails?.reportId ?? ""}
              onChange={(e) =>
                handleInput({
                  e,
                  field: "reportId",
                  setFunction: setDisplayDetails,
                  cb: async ({ reportId }) => {
                    if (!reportId) return;
                    const requirementId = tableDisplay.find((item) => item.id === reportId)?.requirementId;
                    newHistoryFields.current.requirementId = requirementId;
                    setDisplayDetails({ ...newHistoryFields.current });
                    setPeriodsLoading(true);
                    const tempPeriods = await api.reportingHistory.getPeriods({ id: requirementId });
                    if (tempPeriods?.length) {
                      setPeriods([...tempPeriods]);
                    }
                    setPeriodsLoading(false);
                  },
                })
              }
              onClean={() => resetForm()}
              style={{ "--selectPickerHeight": "39px", maxWidth: inputWidth }}
              disabled={submitActionTriggered}
            />
          </div>
          {periodsLoading ? (
            <span className="is-flex" style={{ marginTop: "2.5em" }}>
              <span className={`loader mr-2`}></span>
              <span>Loading Periods...</span>
            </span>
          ) : (
            displayDetails?.requirementId &&
            !!periods?.length && (
              <div className="is-flex">
                <div className="is-flex is-flex-direction-column mr-3">
                  <label className="is-size-6">Period</label>
                  <SelectPicker
                    container={document.getElementById("transaction-summary")}
                    className="custom-rs-styles"
                    data={periods?.map((item) => ({ label: item, value: item }))}
                    labelKey="label"
                    valueKey="value"
                    value={displayDetails?.period ?? ""}
                    searchable={false}
                    onChange={(e) => {
                      const tempPeriod = periods.find((item) => item === e);
                      handleInput({
                        e: tempPeriod,
                        field: "period",
                        setFunction: setDisplayDetails,
                      });
                    }}
                    style={{ "--selectPickerHeight": "39px" }}
                    disabled={submitActionTriggered}
                  />
                </div>
                <div className="is-flex is-flex-direction-column mr-3">
                  <label className="is-size-6">Due Date:</label>
                  <DatePicker
                    container={document.getElementById("transaction-summary")}
                    className="custom-rs-styles"
                    value={displayDetails?.dueDate ?? null}
                    onChange={(date) => handleDate({ date, field: "dueDate", setFunction: setDisplayDetails })}
                    oneTap
                    cleanable
                    disabled={submitActionTriggered}
                    shouldDisableDate={(date) => checkDate({ date, type: "due" })}
                    style={{ "--selectPickerHeight": "39px" }}
                  />
                </div>

                <div className="is-flex is-flex-direction-column mr-3">
                  <label className="is-size-6">Reporting Date:</label>
                  <DatePicker
                    container={document.getElementById("transaction-summary")}
                    className="custom-rs-styles"
                    value={displayDetails?.reportingDate ?? null}
                    onChange={(date) => handleDate({ date, field: "reportingDate", setFunction: setDisplayDetails })}
                    oneTap
                    cleanable
                    disabled={submitActionTriggered}
                    shouldDisableDate={(date) => checkDate({ date, type: "reportingDate" })}
                    style={{ "--selectPickerHeight": "39px" }}
                  />
                </div>
                <com.TextInput
                  label={`Document Link`}
                  value={displayDetails?.url ?? ""}
                  controlStyle={{ maxWidth: inputWidth }}
                  maxlength={256}
                  controlClass={"mr-3 is-flex-grow-1"}
                  onChange={(e) => handleInput({ e, field: "url", setFunction: setDisplayDetails })}
                  disabled={submitActionTriggered}
                />
                <com.ApiSubmitButton
                  action={() => submit({})}
                  className="ml-3 mt-5"
                  style={{ height: "40px" }}
                  icon={<i className="fa-solid fa-plus" />}
                  // used to force correct rendering when parent re-renders
                  disabled={
                    submitActionTriggered ||
                    !displayDetails?.url ||
                    !displayDetails?.reportingDate ||
                    !displayDetails?.dueDate ||
                    !displayDetails?.requirementId ||
                    !displayDetails?.period
                  }
                  actionTriggered={submitActionTriggered}
                  actionDone={actionDone}
                />
              </div>
            )
          )}
        </div>
      )}
      <div
        id="reporting-history-table-container"
        className={`custom-flex-container flex-scroll p-2 ${props.extendTableHeight ? "extend-last-row" : ""}`}
        style={{ "--lastRowHeight": props.extendTableHeight }}
      >
        <div>
          <div>
            <Table data={tableDisplay} sortColumn={sortColumn} sortType={sortType} {...tableProps}>
              {tableColumns?.map((field) => {
                const defaultColumnProps = {
                  sortable: true,
                  key: field,
                };
                switch (field) {
                  case "name":
                    defaultColumnProps.flexGrow = 1;
                    break;
                  case "url":
                    defaultColumnProps.flexGrow = 2;
                    break;
                  default:
                    defaultColumnProps.width = setFieldWidths({ field });
                    break;
                }
                return (
                  field !== "id" &&
                  field !== "creditId" &&
                  field !== "periodId" && (
                    <Column {...defaultColumnProps}>
                      <HeaderCell className="is-capitalized">{field === "url" ? "Document Link" : words(field).join(" ")}</HeaderCell>
                      {props.edit && field === "url" ? (
                        <InputEditCellInlineSave
                          tableid="covenant-table-container"
                          dataKey={field}
                          disabled={disableFields}
                          controlClass="mt-1"
                          setDisabled={setDisableFields}
                          type={"text"}
                          setmaxlength={(() => setFieldLengths({ field }))()}
                          parentprops={props.tableProps}
                          inlineSubmitHandler={props.inlineSubmitHandler}
                          cb={addToDetails}
                        />
                      ) : props.edit && field === "reportingDate" ? (
                        <DateCell
                          container={document.getElementById("transaction-summary")}
                          dataKey={field}
                          onChange={async ({ rowData, dataKey, apiDate }) => {
                            rowData[dataKey] = apiDate?.date;
                            const existsIdx = props.reportingHistoryProps.current?.detailsDataSet?.findIndex((item) => item?.id === rowData?.id);
                            let detailProps = {};
                            if (existsIdx > -1) {
                              props.reportingHistoryProps.current.detailsDataSet[existsIdx] = { ...rowData };
                              detailProps = props.reportingHistoryProps.current;
                            }
                            await props.inlineSubmitHandler({
                              detail: { current: { ...rowData } },
                              detailProps,
                              rowData,
                            });
                            // update original object
                            const originalObjIdx = props.reportingHistoryProps.current?.originalData?.findIndex((item) => item?.id === rowData?.id);
                            if (originalObjIdx > -1) {
                              props.reportingHistoryProps.current.originalData[originalObjIdx] = { ...rowData };
                            }
                          }}
                          oneTap={true}
                          cleanable="true"
                          // disabled={disabled}
                        />
                      ) : (
                        <Cell dataKey={field} />
                      )}
                    </Column>
                  )
                );
              })}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportingHistory;
